import { Subject } from 'rxjs';
import { ChatMessage } from '../__shared/graphql';

interface PreviewMessagesData {
    messages: ChatMessage[];
    previewMessage?: ChatMessage;
}

const onShowPreviewMessagesSubject = new Subject<PreviewMessagesData>();
export const publishShowPreviewMessages = (
    messages: ChatMessage[],
    previewMessage?: ChatMessage,
): void => {
    onShowPreviewMessagesSubject.next({ messages, previewMessage });
};

export const onShowPreviewMessages$ =
    onShowPreviewMessagesSubject.asObservable();

import React from 'react';
import { useTranslation } from 'react-i18next';
import RegisterCard from '../../components/registerCard/registerCard';
import CardHeader from '../../components/cardHeader/cardHeader';
import Button from '../../../../components/button/button';
import Input from '../../../../components/input/input';
import styles from './verify.module.scss';

interface VerifyProps {
    title: string;
    text: string;
    placeholder: string;
    code: string;
    isLoading: boolean;
    onBack?: () => void;
    onNext: () => void;
    onChange: (value: string) => void;
    onResendCode: () => void;
}

const Verify: React.FC<VerifyProps> = ({
    title,
    text,
    placeholder,
    code,
    isLoading,
    onBack,
    onNext,
    onChange,
    onResendCode,
}) => {
    const { t } = useTranslation();

    return (
        <RegisterCard>
            <CardHeader title={title} onBack={() => onBack && onBack()} />
            <p className={styles.text}>{text}</p>
            <div className={styles.form}>
                <Input
                    id="business-verify-email"
                    className={styles.input}
                    value={code}
                    placeholder={placeholder}
                    onChange={e => onChange(e.target.value)}
                />
            </div>
            <Button
                disabled={isLoading || !code?.length}
                className={styles.button}
                text={t('common.buttons.next')}
                onClick={onNext}
            />
            <Button
                disabled={isLoading}
                style="secondary"
                className={styles.button}
                text={t('screens.business.userProfile.sendCode')}
                onClick={onResendCode}
            />
        </RegisterCard>
    );
};

export default Verify;

import { gql } from '@apollo/client';

const FullResponse = gql`
    mutation deleteServiceHours($serviceHoursId: Int!) {
        deleteServiceHours(serviceHoursId: $serviceHoursId)
    }
`;

export interface DeleteServiceHoursData {
    deleteServiceHours: number;
}

export const DeleteServiceHoursMutation = {
    FullResponse,
};

import { gql } from '@apollo/client';
import { Chat } from '../dto';
import { ChatFragments } from '../fragments';

const FullResponse = gql`
    mutation UpdateChat($chat: ChatUpdate!) {
        updateChat(chat: $chat) {
            ...FullChat
        }
    }
    ${ChatFragments.FullChat}
`;

export interface UpdateChatData {
    updateChat: Chat;
}

export const UpdateChatMutation = {
    FullResponse,
};

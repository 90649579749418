import { gql } from '@apollo/client';
import { ChatMessage } from './dto';
import { ChatMessageFragments } from './fragments';

const CreateChatMessageAsSoonAsFileDescriptionHasUrlFullChatMessage = gql`
    mutation CreateChatMessageAsSoonAsFileDescriptionHasUrl(
        $chatMessage: ChatMessageCreate!
    ) {
        createChatMessageAsSoonAsFileDescriptionHasUrl(
            chatMessage: $chatMessage
        ) {
            ...FullChatMessage
        }
    }
    ${ChatMessageFragments.FullResponse}
`;

export interface CreateChatMessageAsSoonAsFileDescriptionHasUrlData {
    createChatMessageAsSoonAsFileDescriptionHasUrl: ChatMessage;
}

export const GraphQLChatMessage = {
    Queries: {},
    Mutations: {
        CreateChatMessageAsSoonAsFileDescriptionHasUrl: {
            FullChatMessage:
                CreateChatMessageAsSoonAsFileDescriptionHasUrlFullChatMessage,
        },
    },
};

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CypressProp } from '../../types/cypressProps';
import Input from '../input/input';
import styles from './phoneNumberInput.module.scss';

const NumberRegex = /^[0-9]*$/;
const NonNumericCharRegex = /\D/g;
const LeadingZeroRegex = /^0+/;
const CountryPrefix = '+49';
const CountryPrefixWithZero = '0049';

const isHtmlInputElement = (
    element?: HTMLElement | null,
): element is HTMLInputElement => {
    const tagName = element?.tagName.toLowerCase();
    return tagName === 'input' || tagName === 'textarea';
};

interface PhoneNumberInputProps extends CypressProp {
    value: string;
    id: string;
    onChange: (value: string) => void;
    inputProps: {
        className?: string;
        placeholder?: string;
        limit?: number;
        disabled?: boolean;
    };
    errorClassName?: string;
}

const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({
    value,
    onChange,
    id,
    inputProps,
    errorClassName,
}) => {
    const [showError, setShowError] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        setShowError(false);
        if (!value?.length) {
            return;
        }
        if (value.startsWith(CountryPrefix)) {
            setPhoneNumber(value.replace(CountryPrefix, ''));
        }
    }, [value]);

    const handlePaste = (e: React.ClipboardEvent) => {
        const inputElement = document.getElementById(id);
        if (!isHtmlInputElement(inputElement)) {
            return;
        }
        const { selectionStart, selectionEnd } = inputElement as {
            selectionStart: number;
            selectionEnd: number;
        };

        let clipboardData = e.clipboardData.getData('text');
        // remove country prefix
        if (clipboardData.startsWith(CountryPrefix)) {
            clipboardData = clipboardData.substring(CountryPrefix.length);
        }
        // remove country prefix
        if (clipboardData.startsWith(CountryPrefixWithZero)) {
            clipboardData = clipboardData.substring(
                CountryPrefixWithZero.length,
            );
        }
        // remove non numeric characters
        clipboardData = clipboardData.replace(NonNumericCharRegex, '');
        // remove leading zeros
        clipboardData = clipboardData.replace(LeadingZeroRegex, '');

        let newValue = inputElement.value;
        let pre = newValue;
        let post = '';
        if (selectionStart === 0 && selectionEnd === 0) {
            pre = '';
            post = newValue;
        } else {
            pre = newValue.substring(0, selectionStart);
            post = newValue.substring(selectionEnd);
        }

        newValue = `${pre}${clipboardData}${post}`;

        handleChange(newValue);
        e.preventDefault();
    };

    const handleChange = (newValue: string) => {
        if (!NumberRegex.test(newValue) || newValue.startsWith('0')) {
            setShowError(true);
            return;
        }
        setShowError(false);
        setPhoneNumber(newValue);
        onChange(`${CountryPrefix}${newValue}`);
    };

    return (
        <div className={styles.container}>
            <Input
                {...inputProps}
                id={id}
                className={styles.inputContainer}
                type="text"
                value={phoneNumber}
                onChange={e => handleChange(e.target.value)}
                prefixText="+49"
                prefixTextClassName={styles.prefixText}
                inputClassName={styles.phoneNumberInput}
                errorClassName={showError ? styles.inputError : ''}
                onPaste={e => handlePaste(e)}
            />
            {showError && (
                <p className={`${styles.error} ${errorClassName ?? ''}`.trim()}>
                    {t('form.errors.phoneNumberFormat')}
                </p>
            )}
        </div>
    );
};

export default PhoneNumberInput;

import { gql } from '@apollo/client';
import { User } from '../dto';
import { UserFragments } from '../fragments';

const FullUpdateEmail = gql`
    mutation UpdateEmail($email: String!, $activationCode: String!) {
        updateEmail(email: $email, activationCode: $activationCode) {
            ...FullUser
        }
    }
    ${UserFragments.FullUser}
`;

export interface UpdateEmailResponse {
    updateEmail: User;
}
export const UpdateEmailMutation = {
    FullUpdateEmail,
};

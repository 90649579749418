import { gql } from '@apollo/client';

const FullNotificationFragment = gql`
    fragment FullNotificationFragment on Notification {
        title
        body
        badgeCount
        clickUrl
    }
`;

export const NotificationFragments = {
    FullNotificationFragment,
};

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextButton from '../../../../../components/textButton/textButton';
import useSendActivationCodes from '../../../../business/views/profile/useSendActivationCodes';
import styles from './smsActivationCodeForm.module.scss';
import { isValidRequiredPhoneNumberWithCountryCodePrefixAndLimit } from '../../../../../utils/validationUtil';
import { Limits } from '../../../../../utils/inputLimits';
import Input from '../../../../../components/input/input';
import { useAlert } from '../../../../../hooks/useAlert';

interface SmsActivationCodeForm {
    phoneNumber: string;
    onClose: () => void;
    onSave: (phoneNumber: string, activationCode: string) => void;
}

const SmsActivationCodeForm: React.FC<SmsActivationCodeForm> = ({
    phoneNumber,
    onClose,
    onSave,
}) => {
    const { t } = useTranslation();
    const alert = useAlert();

    const { sendSmsActivationCode } = useSendActivationCodes();

    const [smsActivationCode, setSmsActivationCode] = useState('');
    const [phoneNumberForCurrentCode, setPhoneNumberForCurrentCode] = useState<
        string | undefined
    >(undefined);

    const validPhoneNumberInput =
        isValidRequiredPhoneNumberWithCountryCodePrefixAndLimit(
            Limits.PhoneNumber,
            phoneNumber,
        );
    const canSendSmsCode = validPhoneNumberInput;

    return (
        <div className={styles.container}>
            <TextButton
                text={t('screens.business.userProfile.sendCode')}
                onClick={() => {
                    sendSmsActivationCode(phoneNumber);
                    setPhoneNumberForCurrentCode(phoneNumber);
                }}
                className={styles.codeButton}
                disabled={!canSendSmsCode}
            />
            <p>{t('screens.business.userProfile.phoneNumberText')}</p>
            <Input
                value={smsActivationCode}
                className={styles.input}
                onChange={e => setSmsActivationCode(e.target.value)}
                id={`user-profile-phonenumber-code`}
                placeholder={t('screens.business.userProfile.smsCode')}
            />

            <div className={styles.actions}>
                <TextButton
                    style="secondary"
                    className={`${styles.button} ${styles.secondaryButton}`}
                    text={t('common.buttons.cancel')}
                    onClick={() => {
                        setSmsActivationCode('');
                        onClose();
                    }}
                />
                <TextButton
                    className={styles.button}
                    disabled={
                        !isValidRequiredPhoneNumberWithCountryCodePrefixAndLimit(
                            Limits.PhoneNumber,
                            phoneNumber,
                        )
                    }
                    text={t('common.buttons.save')}
                    onClick={() => {
                        if (phoneNumberForCurrentCode === phoneNumber) {
                            onSave(phoneNumber, smsActivationCode);
                        } else {
                            alert.error(
                                t('screens.business.userProfile.codeIsInvalid'),
                            );
                        }
                    }}
                />
            </div>
        </div>
    );
};

export default SmsActivationCodeForm;

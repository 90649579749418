export const TestLoggerPrefix = '[TEST_LOG]';
export const TestLoggerDataPrefix = '{{## '; // blank space for better human readability
export const TestLoggerDataSuffix = ' ##}}'; // blank space for better human readability

export const TestLoggerMessages = {
    ClaimLog: 'This log is for user: ',
    SetInstanceId: 'This log is for instanceId: ',
    ServerUpAndRunning: 'Server is up and running',
    LoginWithEmailOrUsername: 'User logs in via email or username',
    LoginWithEmail: 'User logs in via email only',
    UserCreatedChat: 'User created new Chat',
    CreateChatMessage: 'Creating chat message',
    NotifyAboutChatMessageAdded: 'Notifying about chat message added',
    NotifyAboutChatMessageEdited: 'Notifying about chat message edited',
    NotifyAboutChatMessagesEdited:
        'Notifying about multiple chat messages edited',
    ReceivedFileRequest: 'Received a file request',
    LoadingFileDescriptionFromUrl: 'Loading file description from url',
    LoadingFileDescriptionFromUrlAndStoreToDisk:
        'Loading file description from url and store to disk',
    SubscriptionOnChatUpdateForUser: 'Received onChatUpdateForUser',
    SubscriptionOnChatMessageAdded: 'Received onChatMessageAdded',
    SubscriptionOnChatMessageEdited: 'Received onChatMessageEdited',
    SubscriptionOnChatMessagesEdited: 'Received onChatMessagesEdited',
    SubscriptionOnAnimalContextUpdateForUser:
        'Received onAnimalContextUpdateForUser',
};

import { gql } from '@apollo/client';
import { ChatMessage } from '../dto';
import { ChatMessageFragments } from '../fragments';
const FullResponse = gql`
    mutation CreateChatMessageWithOrderAndAppointment(
        $chatMessage: ChatMessageCreate!
        $appointment: AppointmentCreate!
        $order: OrderCreate!
    ) {
        createChatMessageWithOrderAndAppointment(
            chatMessage: $chatMessage
            order: $order
            appointment: $appointment
        ) {
            ...FullChatMessage
        }
    }
    ${ChatMessageFragments.FullResponse}
`;

export interface CreateChatMessageWithOrderAndAppointmentData {
    createChatMessageWithOrderAndAppointment: ChatMessage;
}

export const CreateChatMessageWithOrderAndAppointmentMutation = {
    FullResponse,
};

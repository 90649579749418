import { gql } from '@apollo/client';
import { Animal } from '../dto';
import { FileDescriptionFragments } from '../fragments';

const FullUpdateAnimal = gql`
    mutation updateAnimal(
        $animal: AnimalUpdate!
        $imageResolutions: [ImageResolution!]
    ) {
        updateAnimal(animal: $animal, imageResolutions: $imageResolutions) {
            id
            name
            type
            breed
            specification
            description
            birthday
            isBirthdayEstimated
            createdAt
            updatedAt
            deletedAt
            deletedBy
            imageFile {
                ...FullFileDescription
            }
            colorBundle {
                hexColor
                hexColorGradient
            }
            owners {
                user {
                    id
                }
            }
        }
    }
    ${FileDescriptionFragments.FullResponse}
`;

export interface UpdateAnimalResponse {
    updateAnimal: Animal;
}

export const UpdateAnimalMutation = {
    FullUpdateAnimal,
};

export type ChatMessageType =
    | 'auto-created'
    | 'chat-created'
    | 'default'
    | 'inactive-company'
    | 'msg-date-badge'
    | 'out-of-service-hours'
    | 'user-joined-chat'
    | 'user-left-chat'
    | 'user-added'
    | 'user-removed';

export type UserJoinedOrLeftMessageTypes =
    | 'user-joined-chat'
    | 'user-left-chat'
    | 'user-added'
    | 'user-removed';

interface IChatMessageTypes {
    AutoCreated: 'auto-created';
    ChatCreated: 'chat-created';
    Default: 'default';
    InactiveCompany: 'inactive-company';
    MsgDateBadge: 'msg-date-badge';
    OutOfServiceHours: 'out-of-service-hours';
    UserJoinedTheChat: 'user-joined-chat';
    UserLeftTheChat: 'user-left-chat';
    UserWasAddedToChat: 'user-added';
    UserWasRemovedFromChat: 'user-removed';
}

export const ChatMessageTypes: IChatMessageTypes = {
    AutoCreated: 'auto-created',
    ChatCreated: 'chat-created',
    Default: 'default',
    InactiveCompany: 'inactive-company',
    MsgDateBadge: 'msg-date-badge',
    OutOfServiceHours: 'out-of-service-hours',
    UserJoinedTheChat: 'user-joined-chat',
    UserLeftTheChat: 'user-left-chat',
    UserWasAddedToChat: 'user-added',
    UserWasRemovedFromChat: 'user-removed',
};

export const SystemChatMessageTypes: string[] = [
    ChatMessageTypes.ChatCreated,
    ChatMessageTypes.AutoCreated,
    ChatMessageTypes.UserJoinedTheChat,
    ChatMessageTypes.UserLeftTheChat,
    ChatMessageTypes.UserWasAddedToChat,
    ChatMessageTypes.UserWasRemovedFromChat,
];

export const ClientChatMessageType: string[] = [
    ChatMessageTypes.MsgDateBadge,
    ChatMessageTypes.OutOfServiceHours,
    ChatMessageTypes.InactiveCompany,
];

import React, { useEffect, useMemo, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import Select, { GroupBase, StylesConfig } from 'react-select';
import Button from '../../../../../../components/button/button';
import Switch from '../../../../../../components/switch/switch';
import { useObservable } from '../../../../../../hooks/useObservable';
import RxCompany from '../../../../../../rxjs/RxCompany';
import { buildNameFromUser } from '../../../../../../utils/nameUtils';
import { Option } from '../../../../../../utils/option';
import { DateUtil } from '../../../../../../__shared/common';
import styles from './chatInputAppointment.module.scss';
import { AppointmentForm, useAppointmentForm } from './useAppointmentForm';

interface ChatInputAppointmentProps {
    onClose: () => void;
    onSend: (form: AppointmentForm) => void;
    customerOptions: Option<number>[];
}

const dropDownStyle: StylesConfig<
    Option<number>,
    false,
    GroupBase<Option<number>>
> = {
    menu: provided => ({
        ...provided,
        top: 'auto',
        bottom: '100%',
        borderRadius: '10px',
    }),
    control: provided => ({
        ...provided,
        borderColor: 'transparent',
        borderRadius: '10px',
        paddingLeft: '.5rem',
        paddingRight: '.5rem',
    }),
    menuList: provided => ({
        ...provided,
        paddingTop: '8px',
        paddingBottom: '8px',
    }),
};

const ChatInputAppointment: React.FC<ChatInputAppointmentProps> = ({
    onClose,
    onSend,
    customerOptions,
}) => {
    // cannot figure out the correct select type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const consultantRef = useRef<any>(null);
    const { form, updateField, fixPrice, clearCurrencySign } =
        useAppointmentForm();
    const company = useObservable(RxCompany.currentCompany$);
    const [employeeOptions, setEmployeeOptions] = useState<Option<number>[]>(
        [],
    );
    const { t } = useTranslation();

    useEffect(() => {
        if (!company?.employees?.length) {
            return;
        }
        const _employeeOptions = company.employees.map(employee => ({
            value: employee.id,
            label: buildNameFromUser(employee?.user),
        }));
        setEmployeeOptions(_employeeOptions);
        updateField.updateIsAdvancePayment(!!company?.defaultIsAdvancePayment);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [company]);

    useEffect(() => {
        // set default consultant once the options are available
        // do not overwrite a user selection (form.consultant) if the options (company.employees) change while this component is mounted
        if (
            employeeOptions.length === 1 &&
            // consultantRef.current &&
            // ensure setValue is a function until the ref type is set correctly
            typeof consultantRef.current?.setValue === 'function' &&
            !form.consultant
        ) {
            consultantRef.current.setValue(employeeOptions[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [employeeOptions]);

    const defaultCustomerValue = useMemo(() => {
        // do not overwrite values when customerOptions change but the user already did select a value
        if (!!form.customer || !customerOptions.length) {
            return undefined;
        }

        // keep form value in sync with default customer value
        const firstCustomer = customerOptions[0];
        updateField.updateCustomer(firstCustomer.value);
        return firstCustomer;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerOptions]);

    return (
        <div className={styles.container}>
            <div
                style={{
                    maxWidth: 540,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                }}>
                <div className={styles.title}>
                    <div>
                        {t('screens.chat.appointment.offerAppointmentTitle')}
                    </div>
                </div>
                <div className={styles.form}>
                    <div className={`${styles.field} ${styles.datepicker}`}>
                        <label>
                            {t('screens.chat.appointment.fields.begin')}
                        </label>
                        <DatePicker
                            // popperClassName={styles.reactDatepicker}
                            selected={form.begin}
                            onChange={updateField.updateBegin}
                            popperPlacement="top-start"
                            dateFormat="dd.MM.yyyy - HH:mm"
                            timeFormat="HH:mm"
                            showTimeSelect
                        />
                    </div>
                    <div className={styles.field}>
                        <label>
                            {t('screens.chat.appointment.fields.duration')}
                        </label>
                        <div>
                            <input
                                value={form.duration}
                                type="number"
                                onChange={event =>
                                    updateField.updateDuration(
                                        event.target.value,
                                    )
                                }
                            />
                        </div>
                    </div>
                    <div className={styles.field}>
                        <label>
                            {t('screens.chat.appointment.fields.end')}
                        </label>
                        <span>{DateUtil.toDDMMYYYHHMM(form.end)}</span>
                    </div>
                    <div className={styles.field}>
                        <label>
                            {t('screens.chat.appointment.fields.price')}
                        </label>
                        <div>
                            <input
                                value={form.price}
                                onChange={event =>
                                    updateField.updatePrice(event.target.value)
                                }
                                onBlur={fixPrice}
                                onFocus={clearCurrencySign}
                            />
                        </div>
                    </div>
                    <div className={styles.field}>
                        <label>
                            {t('screens.chat.appointment.fields.consultant')}
                        </label>
                        <div>
                            <Select
                                ref={consultantRef}
                                styles={dropDownStyle}
                                options={employeeOptions}
                                placeholder={t('common.pleaseSelect')}
                                onChange={option => {
                                    if (option) {
                                        updateField.updateConsultant(
                                            option.value,
                                        );
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className={styles.field}>
                        <label>
                            {t('screens.chat.appointment.fields.customer')}
                        </label>
                        <div>
                            <Select
                                styles={dropDownStyle}
                                options={customerOptions}
                                defaultValue={defaultCustomerValue}
                                placeholder={t('common.pleaseSelect')}
                                onChange={option => {
                                    if (option) {
                                        updateField.updateCustomer(
                                            option.value,
                                        );
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className={styles.field}>
                        <label>
                            {t(
                                'screens.chat.appointment.fields.advancePayment',
                            )}
                        </label>
                        <div className={styles.switchContainer}>
                            <Switch
                                className={styles.switch}
                                isOn={form.isAdvancePayment}
                                handleToggle={
                                    updateField.updateIsAdvancePayment
                                }
                                id="appointment-advance-payment-switch"
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.actions}>
                    <Button
                        onClick={onClose}
                        text={t('common.buttons.cancel')}
                        style="secondary"
                    />
                    <Button
                        onClick={() => onSend(form)}
                        disabled={!form.isValid}
                        text={t('common.buttons.sendOffer')}
                        dataCy={'send-appointment-offer'}
                    />
                </div>
            </div>
        </div>
    );
};

export default ChatInputAppointment;

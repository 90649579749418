import { gql } from '@apollo/client';
import { ChatMessageFragments } from './chatMessageFragments';

const FullChatMessageToUser = gql`
    fragment FullChatMessageToUser on ChatMessageToUser {
        id
        receivedAt
        notifiedAt
        viewedAt
        createdAt
        updatedAt
    }
`;

const FullChatMessageToUserIdWithFullChatMessage = gql`
    fragment ChatMessageToUserIdWithFullChatMessage on ChatMessageToUser {
        ...FullChatMessageToUser
        chatMessage {
            ...FullChatMessage
        }
    }
    ${FullChatMessageToUser}
    ${ChatMessageFragments.FullResponse}
`;

export const ChatMessageToUserFragments = {
    FullChatMessageToUser,
    ChatMessageToUserIdWithFullChatMessage:
        FullChatMessageToUserIdWithFullChatMessage,
};

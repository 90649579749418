import { gql } from '@apollo/client';
import { AddressFragments } from './addressFragments';
import { FileDescriptionFragments } from './fileDescriptionFragments';
import { NotificationSettingFragments } from './notificationSettingFragments';
import { ContactFragments } from './contactFragments';

const FullUser = gql`
    fragment FullUser on User {
        id
        email
        username
        firstname
        lastname
        nametag
        phoneNumber
        gender
        title
        addressId
        imageFileId
        lastActiveAt
        createdAt
        updatedAt
        deletedAt
        deletedBy
        isActive
        emailConfirmedAt
        phoneNumberConfirmedAt
        messageCounts {
            unreadMessageCountForUser
            unreadMessageCountForUserAsEmployee
            messageCountWithOnlyConfirmedUpcomingAppointmentsForToday
        }
        colorBundle {
            hexColor
            hexColorGradient
        }
        address {
            ...FullAddress
        }
        imageFile {
            ...FullFileDescription
        }
        notificationSettings {
            ...FullNotificationSetting
        }
    }
    ${AddressFragments.FullAddress}
    ${FileDescriptionFragments.FullResponse}
    ${NotificationSettingFragments.FullNotificationSetting}
`;

const FullUserPrivate  = gql`
    fragment FullUserPrivate on UserPrivate {
        id
        title
        firstname
        lastname
        username
        nametag
        gender
        isActive
        phoneNumber
        email
        createdAt
        deletedAt
        colorBundle {
            hexColor
            hexColorGradient
        }
        imageFile {
            ...FullFileDescription
        }
        address {
            ...FullAddress
        }
        employees {
            id
            title
            contact {
                ...FullContact
            }
            company {
                id
                name
                colorBundle {
                    hexColor
                    hexColorGradient
                }
                imageFile {
                    ...FullFileDescription
                }
            }
            role {
                key
            }
        
        }
    }
    ${ContactFragments.FullContact}
    ${AddressFragments.FullAddress}
    ${FileDescriptionFragments.FullResponse}
`;


const FullUserPrivateOnUser = gql`
    fragment FullUserPrivateOnUser on User {
        id
        title
        firstname
        lastname
        username
        nametag
        gender
        isActive
        phoneNumber
        email
        createdAt
        deletedAt
        colorBundle {
            hexColor
            hexColorGradient
        }
        imageFile {
            ...FullFileDescription
        }
        address {
            ...FullAddress
        }
        employees {
            id
            title
            contact {
                ...FullContact
            }
            company {
                id
                name
                colorBundle {
                    hexColor
                    hexColorGradient
                }
                imageFile {
                    ...FullFileDescription
                }
            }
            role {
                key
            }
        
        }
    }
    ${ContactFragments.FullContact}
    ${AddressFragments.FullAddress}
    ${FileDescriptionFragments.FullResponse}
`;

const FullUserWithInvitationAndLicences = gql`
    fragment FullUserWithInvitationAndLicences on User {
        ...FullUser
        invitations {
            userId
            employeeId
            canAccept
            employee {
                company {
                    id
                    name
                    colorBundle {
                        hexColor
                        hexColorGradient
                    }
                    imageFile {
                        ...FullFileDescription
                    }
                }
            }
        }
        licencesToCompany {
            id
            licenceId
            companyId
            cancelledAt
            cancelledFor
            cancelledBy
            specialCancellationFor
            reasonForCancellation
            validSince
            userId
            licence {
                id
                type
                durationInMonths
                periodOfNoticeInMonths
            }
        }
    }
    ${FullUser}
`;

export const UserFragments = {
    FullUser,
    FullUserWithInvitationAndLicences,
    FullUserPrivate,
    FullUserPrivateOnUser,
};

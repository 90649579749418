import { gql } from '@apollo/client';
import { Contact } from '../dto';
import { ContactFragments } from '../fragments';

const FullResponse = gql`
    mutation UpdateContact($contact: ContactUpdate!) {
        updateContact(contact: $contact) {
            ...FullContact
        }
    }
    ${ContactFragments.FullContact}
`;

export interface UpdateContactData {
    updateContact: Contact;
}

export const UpdateContactMutation = {
    FullResponse,
};

import { gql } from '@apollo/client';
import { User } from '../dto';
import { UserFragments } from '../fragments';

const FullUpdatePassword = gql`
    mutation UpdatePassword($oldPassword: String!, $newPassword: String!) {
        updatePassword(oldPassword: $oldPassword, newPassword: $newPassword) {
            ...FullUser
        }
    }
    ${UserFragments.FullUser}
`;

export interface UpdatePasswordData {
    updatePassword: User;
}

export const UpdatePasswordMutation = {
    FullUpdateUser: FullUpdatePassword,
};

import { Chat, User, Customer } from '../__shared/graphql';

export const findChatContextOwnerUserFromCustomers = (
    chat?: Chat | null,
): User | null | undefined => {
    if (!chat) {
        return undefined;
    }
    const customers: Customer[] = [];
    if (!customers?.length || customers?.length === 0) {
        chat.participants?.forEach(p => {
            if (p.customer) {
                customers.push(p.customer);
            }
        });
    }

    let user;
    if (chat.animal) {
        user = customers.find(
            c => !!c.user?.id && c.user?.id === chat.animal?.createdBy,
        )?.user;
    } else if (chat.animalGroup) {
        user = customers.find(
            c => !!c.user?.id && c.user?.id === chat.animalGroup?.createdBy,
        )?.user;
    } else if (chat.topic) {
        user = customers.find(
            c => !!c.user?.id && c.user?.id === chat.topic?.createdBy,
        )?.user;
    }

    return user;
};

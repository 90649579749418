import { Address } from '../../__shared/graphql';
import React from 'react';

interface AddressLineProps {
    address?: Address | null;
    className?: string;
}

const addPart = (previous: string, part?: string): string => {
    if (previous?.length) {
        if (part?.length) {
            return `${previous}, ${part}`.trim();
        }
        return previous.trim();
    }
    return part?.trim() ?? '';
};

const AddressLine: React.FC<AddressLineProps> = ({ address, className }) => {
    let streetPart = '';
    if (address?.streetName) {
        streetPart += address.streetName;
    }
    if (address?.houseNumber) {
        streetPart += ` ${address.houseNumber}`;
    }
    let cityPart = '';
    if (address?.zipCode) {
        cityPart += address.zipCode;
    }
    if (address?.city) {
        cityPart += ` ${address.city}`;
    }

    let addressLine = addPart('', streetPart);
    addressLine = addPart(addressLine, cityPart);
    addressLine = addPart(addressLine, address?.country);

    return <p className={className ?? ''}>{addressLine}</p>;
};

export default AddressLine;

import { ChatMessage } from '../__shared/graphql';

export const sortByCreatedAt = <T extends { createdAt?: Date }>(
    a: T,
    b: T,
    reverse = false,
): number => {
    if (!b.createdAt || !a.createdAt) {
        return 0;
    }
    return reverse
        ? b.createdAt.getTime() - a.createdAt.getTime()
        : a.createdAt.getTime() - b.createdAt.getTime();
};

// The GQL create types allow id null, so we have to accept them here though in reality id should never be null
export const mergeArraysById = <
    T extends { id?: number | null; createdAt?: Date },
>(
    old?: T[],
    newArray?: T[],
    sortByDate = false,
): T[] => {
    if (!old?.length) {
        if (!newArray?.length) {
            return [];
        }
        return newArray;
    }
    if (!newArray?.length) {
        return old;
    }

    const result = [...old];
    newArray.forEach(item => {
        const index = result.findIndex(oldItem => oldItem.id === item.id);
        if (index === -1) {
            result.push(item);
        } else {
            result[index] = item;
        }
    });

    if (sortByDate) {
        return result.sort((a, b) => sortByCreatedAt(a, b, true));
    }
    return result;
};

export const mergeChatMessagesByUuid = (
    old?: ChatMessage[],
    newArray?: ChatMessage[],
    sortByDate?: boolean,
): ChatMessage[] => {
    if (!old?.length) {
        if (!newArray?.length) {
            return [];
        }
        return newArray;
    }
    if (!newArray?.length) {
        return old;
    }

    const result = [...old];
    newArray.forEach(item => {
        const index = result.findIndex(oldItem => oldItem.uuid === item.uuid);
        if (index === -1) {
            result.unshift(item);
        } else {
            const newReceivedBy = mergeArraysById(
                old[index]?.receivedBy ?? [],
                item?.receivedBy ?? [],
            );
            const newViewedBy = mergeArraysById(
                old[index]?.viewedBy ?? [],
                item?.viewedBy ?? [],
            );
            const newItem = {
                ...item,
            };
            newItem.receivedBy = newReceivedBy;
            newItem.viewedBy = newViewedBy;
            newItem.isReceived = item?.isReceived || old[index]?.isReceived;
            newItem.isViewed = item?.isViewed || old[index]?.isViewed;
            newItem.isReceivedByMe =
                item?.isReceivedByMe || old[index]?.isReceivedByMe;
            newItem.isViewedByMe =
                item?.isViewedByMe || old[index]?.isViewedByMe;
            result.splice(index, 1, newItem);
        }
    });

    if (sortByDate) {
        return result.sort((a, b) => sortByCreatedAt(a, b, true));
    }

    return result;
};

import React from 'react';
import { Employee } from '../../__shared/graphql';
import ProfileIcon from '../profileIcon/profileIcon';
import styles from './employeeItem.module.scss';
import { buildNameFromEmployee } from '../../utils/nameUtils';
import { ClassNameProp } from '../../types/stylingProps';

interface EmployeeItemProps extends ClassNameProp {
    employee?: Employee;
}

const EmployeeItem: React.FC<EmployeeItemProps> = ({ employee, className }) => {
    return (
        <div className={`${styles.employee} ${className ?? ''}`.trim()}>
            <ProfileIcon
                fileDescription={employee?.user?.imageFile}
                colorBundle={employee?.user?.colorBundle}
            />
            <div className={styles.text}>
                <span className={styles.name}>
                    {buildNameFromEmployee(employee)}
                </span>
                <span className={styles.jobTitle}>{employee?.title ?? ''}</span>
            </div>
        </div>
    );
};

export default EmployeeItem;

import {
    Company,
    CreateServiceHoursData,
    CreateServiceHoursMutation,
    DeleteServiceHoursData,
    DeleteServiceHoursMutation,
    GetCompanyData,
    GetCompanyQuery,
    MutationCreateServiceHoursArgs,
    MutationDeleteServiceHoursArgs,
    MutationUpdateServiceHoursArgs,
    QueryGetCompanyArgs,
    ServiceHours,
    UpdateServiceHoursData,
    UpdateServiceHoursMutation,
} from '../../../../__shared/graphql';
import { DateUtil } from '../../../../__shared/common';
import {
    ApolloQueryResult,
    LazyQueryResult,
    useLazyQuery,
    useMutation,
} from '@apollo/client';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../../../../components/icon/icon';
import Input from '../../../../components/input/input';
import Switch from '../../../../components/switch/switch';
import TextButton from '../../../../components/textButton/textButton';
import { useAlert } from '../../../../hooks/useAlert';
import { useObservable } from '../../../../hooks/useObservable';
import RxCompany from '../../../../rxjs/RxCompany';
import styles from './serviceTimesDetails.module.scss';

const days = [0, 1, 2, 3, 4, 5, 6];
type MarkerId = {
    markerId: string;
};
interface ServiceTimesState {
    [key: number]: {
        times: (ServiceHours & MarkerId)[];
        opened: boolean;
    };
}

const initialState: ServiceTimesState = {
    1: { times: [], opened: false },
    2: { times: [], opened: false },
    3: { times: [], opened: false },
    4: { times: [], opened: false },
    5: { times: [], opened: false },
    6: { times: [], opened: false },
    0: { times: [], opened: false },
};

type GetCompanyResult =
    | ApolloQueryResult<GetCompanyData>
    | LazyQueryResult<GetCompanyData, QueryGetCompanyArgs>;

const getDateFromHoursAndMinutesString = (time: string): Date => {
    const [hours, minutes] = time.split(':');
    const result = new Date();
    result.setHours(Number.parseInt(hours));
    result.setMinutes(Number.parseInt(minutes));
    return result;
};

const toDSTSaveDate = (date: Date): Date => {
    const now = new Date();
    return new Date(
        Date.UTC(
            now.getFullYear(),
            now.getMonth(),
            now.getDate(),
            date.getHours(),
            date.getMinutes() + date.getTimezoneOffset(),
        ),
    );
};

const ServiceTimeDetails: React.FC = () => {
    const { t } = useTranslation();
    const [isLoading, setLoading] = useState(false);
    const [idsToDelete, setIdsToDelete] = useState<string[]>([]);
    const company = useObservable(RxCompany.currentCompany$);
    const [serviceHours, setServiceHours] = useState(initialState);
    const alert = useAlert();

    const [createServiceHours] = useMutation<
        CreateServiceHoursData,
        MutationCreateServiceHoursArgs
    >(CreateServiceHoursMutation.FullResponse);
    const [updateServiceHours] = useMutation<
        UpdateServiceHoursData,
        MutationUpdateServiceHoursArgs
    >(UpdateServiceHoursMutation.FullResponse);
    const [deleteServiceHours] = useMutation<
        DeleteServiceHoursData,
        MutationDeleteServiceHoursArgs
    >(DeleteServiceHoursMutation.FullResponse);

    const [getCompany, { refetch: refetchCompany, called }] = useLazyQuery<
        GetCompanyData,
        QueryGetCompanyArgs
    >(GetCompanyQuery.FullResponse);

    const mapServiceTimesFromCompany = useCallback((company: Company) => {
        if (company?.serviceHours) {
            // spread operator holds deep references, so we cannot use it here or we push times to the initial state
            const newState = JSON.parse(JSON.stringify(initialState));
            days.forEach(day => {
                newState[day].opened = company.serviceHours?.some(
                    sh => sh.dayOfTheWeek === day,
                );
                company.serviceHours
                    ?.filter(sh => sh.dayOfTheWeek === day)
                    .forEach(sh => {
                        if (sh.from && sh.to) {
                            const fixedSh = {
                                ...sh,
                                from: toDSTSaveDate(sh.from),
                                to: toDSTSaveDate(sh.to),
                            };
                            newState[day].times.push({
                                ...fixedSh,
                                markerId: `existing-${sh.id}`,
                            });
                        }
                    });
                newState[day].times.sort(
                    (a: ServiceHours, b: ServiceHours) => a.from - b.from,
                );
            });
            setServiceHours(newState);
        }
    }, []);

    useEffect(() => {
        if (company) {
            mapServiceTimesFromCompany(company);
        }
    }, [mapServiceTimesFromCompany, company]);

    const reloadCompany = useCallback(async () => {
        let companyResponse: GetCompanyResult;
        try {
            if (called) {
                companyResponse = await refetchCompany({
                    companyId: company?.id ?? 0,
                });
            } else {
                companyResponse = await getCompany({
                    variables: { companyId: company?.id ?? 0 },
                });
            }
            if (companyResponse.data?.getCompany) {
                RxCompany.addCompany(companyResponse.data.getCompany);
                RxCompany.setCurrentCompany(companyResponse.data.getCompany);
                mapServiceTimesFromCompany(companyResponse.data.getCompany);
            }
        } catch (e) {
            console.warn('error while fetching company', e);
            alert.error('common.alerts.defaultError');
        }
    }, [
        alert,
        getCompany,
        refetchCompany,
        called,
        company?.id,
        mapServiceTimesFromCompany,
    ]);

    const addTime = (day: number) => {
        const newDate = new Date();
        newDate.setHours(8);
        newDate.setMinutes(0);

        const newDate2 = new Date(newDate);
        newDate2.setHours(12);
        setServiceHours(prev => ({
            ...prev,
            [day]: {
                ...prev[day],
                times: [
                    ...prev[day].times,
                    {
                        from: newDate,
                        to: newDate2,
                        markerId: `new-${Math.floor(Math.random() * 1000)}`,
                    },
                ],
            },
        }));
    };

    const save = useCallback(
        async (stateToSave: ServiceTimesState) => {
            // const timesToCreate: ServiceHours[] = [];
            // const timesToUpdate: ServiceHours[] = [];
            try {
                setLoading(true);
                await Promise.all(
                    Object.entries(stateToSave).map(async entry => {
                        await Promise.all(
                            entry[1].times.map(
                                async (
                                    serviceHours: ServiceHours & MarkerId,
                                ) => {
                                    if (
                                        !serviceHours.from ||
                                        !serviceHours.to
                                    ) {
                                        return;
                                    }
                                    if (
                                        idsToDelete.includes(
                                            serviceHours.markerId,
                                        )
                                    ) {
                                        if (serviceHours.id) {
                                            await deleteServiceHours({
                                                variables: {
                                                    serviceHoursId:
                                                        serviceHours.id,
                                                },
                                            });
                                        }
                                        return;
                                    }
                                    if (serviceHours.id) {
                                        await updateServiceHours({
                                            variables: {
                                                serviceHours: {
                                                    id: serviceHours.id,
                                                    companyId: company?.id ?? 0,
                                                    dayOfTheWeek:
                                                        serviceHours.dayOfTheWeek,
                                                    from: serviceHours.from,
                                                    to: serviceHours.to,
                                                    isEmergencyService:
                                                        serviceHours.isEmergencyService,
                                                },
                                            },
                                        });
                                        return;
                                    }

                                    serviceHours.from.setSeconds(0);
                                    serviceHours.to.setSeconds(0);
                                    serviceHours.from.setMilliseconds(0);
                                    serviceHours.to.setMilliseconds(0);
                                    await createServiceHours({
                                        variables: {
                                            serviceHours: {
                                                companyId: company?.id ?? 0,
                                                dayOfTheWeek: Number.parseInt(
                                                    entry[0],
                                                ),
                                                from: serviceHours.from,
                                                to: serviceHours.to,
                                                isEmergencyService: false,
                                            },
                                        },
                                    });
                                },
                            ),
                        );
                    }),
                );
            } catch (e) {
                console.warn('error while saving service times', e);
                alert.error('common.alerts.defaultError');
            } finally {
                setLoading(false);
                setIdsToDelete([]);
            }
            reloadCompany();
            alert.success('common.alerts.savedChanges');
        },
        [
            alert,
            company,
            reloadCompany,
            createServiceHours,
            updateServiceHours,
            deleteServiceHours,
            idsToDelete,
        ],
    );

    const onToggleDelete = (markerId: string) => {
        if (idsToDelete.includes(markerId)) {
            setIdsToDelete(prev => prev.filter(sh => sh !== markerId));
            return;
        }
        setIdsToDelete(prev => [...prev, markerId]);
    };

    const toggleOpenedState = (day: number, isOpened: boolean) => {
        setServiceHours(prev => ({
            ...prev,
            [day]: {
                ...prev[day],
                opened: !prev[day].opened,
            },
        }));

        const toggleTimes = serviceHours[day].times;
        if (isOpened) {
            setIdsToDelete(prev =>
                prev.filter(
                    markerId => !toggleTimes.some(t => t.markerId === markerId),
                ),
            );
            return;
        }

        const newIdsToDelete = [
            ...idsToDelete.filter(
                markerId => !toggleTimes.some(t => t.markerId === markerId),
            ),
            ...toggleTimes.map(t => t.markerId),
        ];
        setIdsToDelete(newIdsToDelete);
    };

    const updateTimeValue = (
        timeString: string,
        dayString: string,
        markerId: string,
        property: 'from' | 'to',
    ) => {
        const newState = { ...serviceHours };
        const day = Number.parseInt(dayString);
        const sh = newState[day].times.find(sh => sh.markerId === markerId);
        if (sh) {
            const index = newState[day].times.findIndex(
                sh => sh.markerId === markerId,
            );
            newState[day].times[index][property] =
                getDateFromHoursAndMinutesString(timeString);
            setServiceHours(newState);
        }
    };

    return (
        <div className={styles.container}>
            {/* <p className={styles.title}>
        {t('screens.settings.serviceTimes.openingTimes')}
      </p> */}
            {Object.entries(serviceHours).map(entry => {
                return (
                    <React.Fragment key={`${entry[0]}-${entry[1].opened}`}>
                        <div className={styles.row}>
                            <span className={styles.day}>
                                {t(`common.days.${entry[0]}`)}
                            </span>
                            {entry[1].opened && (
                                <div
                                    className={styles.addButton}
                                    onClick={() =>
                                        addTime(Number.parseInt(entry[0]))
                                    }>
                                    <Icon name="add-circle-outline" />
                                </div>
                            )}
                        </div>

                        <div className={styles.row}>
                            <div className={styles.openingStatus}>
                                {entry[1].opened
                                    ? t('screens.settings.serviceTimes.opened')
                                    : t('screens.settings.serviceTimes.closed')}
                            </div>
                        </div>
                        <div className={styles.openingStatusSwitch}>
                            <Switch
                                id={`day-${entry[0]}`}
                                isOn={entry[1].opened}
                                handleToggle={checked => {
                                    toggleOpenedState(
                                        Number.parseInt(entry[0]),
                                        checked,
                                    );
                                }}
                            />
                        </div>
                        <div className={styles.row} key={entry[0]}>
                            <div style={{ flex: 1 }}>
                                <div className={styles.times}>
                                    {entry[1].times.map(
                                        (t: ServiceHours & MarkerId) => {
                                            const markedAsDeleted =
                                                idsToDelete.includes(
                                                    t.markerId,
                                                );
                                            return (
                                                <div
                                                    className={styles.time}
                                                    key={`${entry[0]}-${t.markerId}`}>
                                                    <div
                                                        className={
                                                            styles.inputContainer
                                                        }>
                                                        <Input
                                                            className={
                                                                styles.input
                                                            }
                                                            id={`service-times-form-input-from`}
                                                            type={'time'}
                                                            disabled={
                                                                markedAsDeleted
                                                            }
                                                            value={DateUtil.toHHMM(
                                                                t.from,
                                                            )}
                                                            onChange={e =>
                                                                updateTimeValue(
                                                                    e.target
                                                                        .value,
                                                                    entry[0],
                                                                    t.markerId,
                                                                    'from',
                                                                )
                                                            }
                                                        />
                                                        <span
                                                            className={
                                                                styles.inputSeparator
                                                            }>
                                                            {'-'}
                                                        </span>
                                                        <Input
                                                            className={
                                                                styles.input
                                                            }
                                                            id={`service-times-form-input-to`}
                                                            type={'time'}
                                                            disabled={
                                                                markedAsDeleted
                                                            }
                                                            value={DateUtil.toHHMM(
                                                                t.to,
                                                            )}
                                                            onChange={e =>
                                                                updateTimeValue(
                                                                    e.target
                                                                        .value,
                                                                    entry[0],
                                                                    t.markerId,
                                                                    'to',
                                                                )
                                                            }
                                                        />
                                                        <div
                                                            className={
                                                                styles.deleteButton
                                                            }
                                                            onClick={() =>
                                                                onToggleDelete(
                                                                    t.markerId,
                                                                )
                                                            }>
                                                            {!markedAsDeleted && (
                                                                <Icon name="trash-outline" />
                                                            )}
                                                            {markedAsDeleted &&
                                                                entry[1]
                                                                    .opened && (
                                                                    <Icon name="close-circle-outline" />
                                                                )}
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        },
                                    )}
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                );
            })}
            <div className={styles.actions}>
                <TextButton
                    text={t('common.buttons.cancel')}
                    style="secondary"
                    onClick={() => {
                        if (company) {
                            setIdsToDelete([]);
                            mapServiceTimesFromCompany(company);
                        }
                    }}
                />
                <TextButton
                    text={t('common.buttons.apply')}
                    style="primary"
                    disabled={isLoading}
                    onClick={() => save(serviceHours)}
                />
            </div>
        </div>
    );
};

export default ServiceTimeDetails;

import { gql } from '@apollo/client';
import { Chat } from '../dto';
import { ChatFragments } from '../fragments';

const FullResponse = gql`
    query FindChatsForUser(
        $searchInput: String!
        $sortOrder: String
        $pageSize: Int
        $idCursor: Int
        $animalId: Int
        $animalGroupId: Int
        $companyId: Int
        $topicId: Int
        $userId: Int
        $type: String
    ) {
        findChatsForUser(
            searchInput: $searchInput
            sortOrder: $sortOrder
            pageSize: $pageSize
            idCursor: $idCursor
            animalId: $animalId
            animalGroupId: $animalGroupId
            companyId: $companyId
            topicId: $topicId
            userId: $userId
            type: $type
        ) {
            ...FullChat
        }
    }
    ${ChatFragments.FullChat}
`;

export type FindChatsForUserData = {
    findChatsForUser: Chat[];
};

export const FindChatsForUserQuery = {
    FullResponse,
};

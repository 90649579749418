import { DateUtil } from '../__shared/common';
import logUtil from './logUtil';

const RESET = '\x1b[0m';
const SUBSCRIPTION = '\x1b[33m';
const MUTATION = '\x1b[36m';
const QUERY = '\x1b[32m';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const logData = (data: any) => {
    if (data) {
        logUtil.log(data);
    }
    logUtil.log(`\n${RESET}`);
};

const getTime = () => `${DateUtil.toHHMMSS(new Date())}:   `;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export const logSubscription = (name: string, data: any): void => {
    logUtil.log(`${getTime()}${SUBSCRIPTION}Subscription[${name}]`);
    logData(data);
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export const logMutation = (name: string, data: any): void => {
    logUtil.log(`${getTime()}${MUTATION}Mutation[${name}]`);
    logData(data);
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export const logQuery = (name: string, data: any): void => {
    logUtil.log(`${getTime()}${QUERY}Query[${name}]`);
    logData(data);
};

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from '../../../../components/button/button';
import CheckBox from '../../../../components/checkBox/checkBox';
import TextButton from '../../../../components/textButton/textButton';
import { useObservable } from '../../../../hooks/useObservable';
import RxAuthentication from '../../../../rxjs/RxAuthentication';
import AnimalChatRoutes from '../../../../utils/routing';
import { openUrlInBrowser } from '../../../../__shared/electron';
import RegisterCard from '../../components/registerCard/registerCard';
import styles from './termsOfUse.module.scss';

interface TermsOfUseProps {}

const TermsOfUseUrl = 'https://animalchat.net/terms-of-use';
const PrivacyPolicyUrl = 'https://animalchat.net/privacy';

const TermsOfUse: React.FC<TermsOfUseProps> = () => {
    const [isAcceptTermsOfUse, setIsAcceptTermsOfUse] = useState(false);
    const [isAcceptPrivacyPolicy, setIsAcceptPrivacyPolicy] = useState(false);
    const userId = useObservable(RxAuthentication.userId$);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const onNext = () => {
        if (userId) {
            navigate(AnimalChatRoutes.businessSubs.firstStepsSubs.person);
            return;
        }
        navigate(AnimalChatRoutes.businessSubs.firstStepsSubs.createAccount);
    };

    return (
        <RegisterCard cardClassName={styles.card}>
            <img
                src="/images/animalchat_app_icon.png"
                alt="AnimalChat Logo"
                className={styles.logo}
            />
            <h4>{t('screens.business.tos.animalChatBusiness')}</h4>
            <p className={styles.text}>{t('screens.business.tos.readText')}</p>
            <TextButton
                onClick={() => openUrlInBrowser(TermsOfUseUrl)}
                text={t('screens.business.tos.showTermsOfUse')}
                className={styles.textButton}
            />
            <TextButton
                onClick={() => openUrlInBrowser(PrivacyPolicyUrl)}
                text={t('screens.business.tos.showPrivacyPolicy')}
                className={styles.textButton}
            />
            <div className={styles.checkboxes}>
                <div className={styles.checkbox}>
                    <CheckBox
                        checked={isAcceptTermsOfUse}
                        onChange={e => setIsAcceptTermsOfUse(e.target.checked)}
                        className={styles.checkbox}
                    />
                    <span onClick={() => setIsAcceptTermsOfUse(prev => !prev)}>
                        {t('screens.business.tos.acceptTermsOfUse')}
                    </span>
                </div>
                <div className={styles.checkbox}>
                    <CheckBox
                        checked={isAcceptPrivacyPolicy}
                        onChange={e =>
                            setIsAcceptPrivacyPolicy(e.target.checked)
                        }
                        className={styles.checkbox}
                    />
                    <span
                        onClick={() => setIsAcceptPrivacyPolicy(prev => !prev)}>
                        {t('screens.business.tos.acceptPrivacyPolicy')}
                    </span>
                </div>
            </div>
            <Button
                onClick={onNext}
                text={t('screens.business.tos.continue')}
                disabled={!isAcceptPrivacyPolicy || !isAcceptTermsOfUse}
            />
            <TextButton
                onClick={() =>
                    navigate(AnimalChatRoutes.businessSubs.firstSteps)
                }
                text={t('screens.business.tos.cancel')}
                className={styles.textButton}
            />
        </RegisterCard>
    );
};

export default TermsOfUse;

export interface ImageResolution {
    height: number;
    width: number;
}

export type Thumbnail = 'Thumbnail';
export type ProfilePicture = 'ProfilePicture';
export type DetailPicture = 'DetailPicture';

export type ImageResolutionKeys = ProfilePicture | Thumbnail | DetailPicture;

export type ImageResolutionKeysProfileAndDetail =
    | ProfilePicture
    | DetailPicture;

export type ImageResolutionKeysThumbnailAndDetail = Thumbnail | DetailPicture;
export type ImageResolutionKeysThumbnailAndProfile = Thumbnail | ProfilePicture;

export type ImageResolutions = {
    [key in ImageResolutionKeys]: ImageResolution;
};

export type ImageResolutionsThumbnailOnly = {
    Thumbnail: ImageResolution;
};

export type ImageResolutionsProfilePictureOnly = {
    ProfilePicture: ImageResolution;
};

export type ImageResolutionsDetailPictureOnly = {
    DetailPicture: ImageResolution;
};

export type ImageResolutionThumbnailAndDetail = {
    [key in ImageResolutionKeysThumbnailAndDetail]: ImageResolution;
};

export type ImageResolutionThumbnailAndProfile = {
    [key in ImageResolutionKeysThumbnailAndProfile]: ImageResolution;
};

export type ImageResolutionProfileAndDetail = {
    [key in ImageResolutionKeysProfileAndDetail]: ImageResolution;
};

const ThumbnailSize = 128;
const ProfilePictureSize = 256;
const DetailPictureSize = 512;

export const PredefinedSizes = {
    ThumbnailSize,
    ProfilePictureSize,
    DetailPictureSize,
};

export interface ResolutionRange {
    max: number;
    min: number;
}

export const PredefinedRanges: {
    [key in ImageResolutionKeys]: ResolutionRange;
} = {
    Thumbnail: {
        min: 100,
        max: 400,
    },
    ProfilePicture: {
        min: 200,
        max: 600,
    },
    DetailPicture: {
        min: 350,
        max: 1500,
    },
};

export const PredefinedImageResolutions: ImageResolutions = {
    Thumbnail: { width: ThumbnailSize, height: ThumbnailSize },
    ProfilePicture: { width: ProfilePictureSize, height: ProfilePictureSize },
    DetailPicture: { width: DetailPictureSize, height: DetailPictureSize },
};

export const PredefinedImageResolutionsThumbnailOnly: ImageResolutionsThumbnailOnly = {
    Thumbnail: { width: ThumbnailSize, height: ThumbnailSize },
};

export const PredefinedImageResolutionsProfilePictureOnly: ImageResolutionsProfilePictureOnly = {
    ProfilePicture: { width: ProfilePictureSize, height: ProfilePictureSize },
};

export const PredefinedImageResolutionsDetailOnly: ImageResolutionsDetailPictureOnly = {
    DetailPicture: { width: DetailPictureSize, height: DetailPictureSize },
};

export const PredefinedImageResolutionsThumbailAndProfile: ImageResolutionThumbnailAndProfile = {
    Thumbnail: { width: ThumbnailSize, height: ThumbnailSize },
    ProfilePicture: {
        width: ProfilePictureSize,
        height: ProfilePictureSize,
    },
};

export const PredefinedImageResolutionsProfileAndDetail: ImageResolutionProfileAndDetail = {
    ProfilePicture: {
        width: ProfilePictureSize,
        height: ProfilePictureSize,
    },
    DetailPicture: { width: DetailPictureSize, height: DetailPictureSize },
};

export const PredefinedImageResolutionsThumbnailAndDetail: ImageResolutionThumbnailAndDetail = {
    Thumbnail: { width: ThumbnailSize, height: ThumbnailSize },
    DetailPicture: { width: DetailPictureSize, height: DetailPictureSize },
};

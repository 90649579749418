export const API_BASE_URL =
    process?.env?.REACT_APP_API_BASE_URL ?? '{{REACT_APP_API_BASE_URL}}';
export const WS_BASE_URL =
    process?.env?.REACT_APP_WS_BASE_URL ?? '{{REACT_APP_WS_BASE_URL}}';
export const DASHBOARD_VERSION =
    process?.env?.REACT_APP_DASHBOARD_VERSION ??
    '{{REACT_APP_DASHBOARD_VERSION}}';
export const DASHBOARD_URL =
    process?.env?.REACT_APP_DASHBOARD_URL ?? '{{REACT_APP_DASHBOARD_URL}}';
export const PAGE_SIZE = process?.env?.REACT_APP_PAGE_SIZE ?? 8;
export const IsConsoleEnabled = process?.env?.REACT_APP_CONSOLE === 'enabled';
export const IsAppInWebAllowed =
    process.env.REACT_APP_ALLOW_APP_IN_WEB === 'enabled';
export const IsTestLoggingEnabled =
    process.env.REACT_APP_TEST_LOGGING === 'enabled';
export const LogLevel = process.env.REACT_APP_LOG_LEVEL;
export const IsHttpLoggingEnabled =
    process.env.REACT_APP_IS_HTTP_LOGGING === 'enabled';

import React, { useEffect } from 'react';
import { useAlert } from '../../../hooks/useAlert';
import { onSaveFileCompleteListener } from '../../../__shared/electron';
import ChatSection from './chatSection';
import styles from './chatsLayout.module.scss';
import ChatNavigation from './navigation/chatNavigation';

const ChatsLayout: React.FC = () => {
    const alert = useAlert();

    useEffect(() => {
        onSaveFileCompleteListener((filePath: string) => {
            if (filePath) {
                const successAlert = alert.success(
                    'common.alerts.downloadSuccess',
                );
                setTimeout(() => {
                    successAlert.close();
                }, 1500);
            }
        });
        // only subscribe once on layout creation!
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={styles.container}>
            <ChatNavigation />
            <ChatSection />
        </div>
    );
};

export default ChatsLayout;

import { gql } from '@apollo/client';
import { FileDescription } from '../dto';
import { FileDescriptionFragments } from '../fragments';

const FullResponse = gql`
    mutation singleFileUpload(
        $file: Upload!
        $fileInfo: FileInfo
        $uuid: String
        $resolutions: [ImageResolution!]
        $skipVideoConversion: Boolean
    ) {
        singleFileUpload(
            file: $file
            fileInfo: $fileInfo
            uuid: $uuid
            resolutions: $resolutions
            skipVideoConversion: $skipVideoConversion
        ) {
            ...FullFileDescription
        }
    }
    ${FileDescriptionFragments.FullResponse}
`;

export interface SingleFileUploadData {
    singleFileUpload: FileDescription;
}

export const SingleFileUploadMutation = { FullResponse };

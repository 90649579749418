import { createBrowserHistory } from 'history';
import React from 'react';
import { render } from 'react-dom';
import { setDashboardShellVersion } from './api/apolloClient';
import App from './app';
import {
    IsConsoleEnabled,
    IsHttpLoggingEnabled,
    IsTestLoggingEnabled,
    LogLevel,
} from './environment/env';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import { PageLifecycleUtils } from './utils/pageLifecycleUtils';
import { LogClient, parseLogLevel } from './__shared/common/logging/logClient';
import { UuidUtils } from './__shared/common/utils/uuidUtils';

// Note: Needed for React 17 for version react-datepicker 4.8.0
// TODO: Check if still needed, is compatible version available?
import 'react-datepicker/dist/react-datepicker.css';
import { getElectronShellVersion } from './__shared/electron';

export const dashboardHistory = createBrowserHistory();

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL.replace(
    '/api/graphql',
    '',
);

LogClient.prepare({
    baseUrl: apiBaseUrl,
    client: 'dashboard-web',
    instanceId: UuidUtils.generateUuidV4(),
    isTestLoggingEnabled: IsTestLoggingEnabled,
    isConsoleLoggingEnabled: IsConsoleEnabled,
    isHttpDisabled: !IsHttpLoggingEnabled,
    logLevel: parseLogLevel(LogLevel),
});

getElectronShellVersion()
    .then(version => {
        setDashboardShellVersion(version);
    })
    .finally(() => {
        render(
            <React.StrictMode>
                <App />
            </React.StrictMode>,
            document.getElementById('root'),
        );
    });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

PageLifecycleUtils.init(document);

import { gql } from '@apollo/client';
import { User } from '../dto';
import { UserFragments } from '../fragments';

const FullUpdateUser = gql`
    mutation UpdateUser(
        $user: UserUpdate!
        $imageResolutions: [ImageResolution!]
    ) {
        updateUser(user: $user, imageResolutions: $imageResolutions) {
            ...FullUser
        }
    }
    ${UserFragments.FullUser}
`;

export interface UpdateUserData {
    updateUser: User;
}

export const UpdateUserMutation = {
    FullUpdateUser,
};

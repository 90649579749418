import React from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../../../../components/icon/icon';
import logUtil from '../../../../utils/logUtil';
import styles from './widgetSettings.module.scss';

interface IWidgetSettings {
    active: boolean;
    position: 'top left' | 'top right' | 'bottem left' | 'bottom right';
    averageResponseTime: number;
    greeting: string;
}

const widgetSettings: IWidgetSettings = {
    active: true,
    position: 'bottom right',
    averageResponseTime: 7,
    greeting:
        'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.',
};

const WidgetSettings: React.FC = () => {
    const { t } = useTranslation();

    return (
        <div className={styles.container}>
            <div className={styles.row}>
                <div className={styles.field}>
                    {/* <Logo className={styles.logo} /> */}
                </div>
            </div>
            <div className={styles.row}>
                <div className={styles.field}>
                    <p className={styles.name}>
                        {t('screens.settings.widget.fields.status')}
                    </p>
                    <p className={styles.value}>
                        {t(
                            `screens.settings.widget.${
                                widgetSettings.active ? 'active' : 'inactive'
                            }`,
                        )}
                    </p>
                </div>
                <Icon
                    name="pencil-outline"
                    className={styles.editIcon}
                    onClick={() => logUtil.log('test')}
                />
            </div>

            <div className={styles.row}>
                <div className={styles.field}>
                    <p className={styles.name}>
                        {t('screens.settings.widget.fields.position')}
                    </p>
                    <p className={styles.value}>{widgetSettings.position}</p>
                </div>
                <Icon
                    name="pencil-outline"
                    className={styles.editIcon}
                    onClick={() => logUtil.log('edit companyType')}
                />
            </div>
            <div className={styles.row}>
                <div className={styles.field}>
                    <p className={styles.name}>
                        {t(
                            'screens.settings.widget.fields.averageResponseTime',
                        )}
                    </p>
                    <p className={styles.value}>
                        {widgetSettings.averageResponseTime}
                    </p>
                </div>
                <Icon
                    name="pencil-outline"
                    className={styles.editIcon}
                    onClick={() => logUtil.log('edit fullName')}
                />
            </div>
            <div className={styles.row}>
                <div className={styles.field}>
                    <p className={styles.name}>
                        {t('screens.settings.widget.fields.greeting')}
                    </p>
                    <p className={styles.value}>{widgetSettings.greeting}</p>
                </div>
                <Icon
                    name="pencil-outline"
                    className={styles.editIcon}
                    onClick={() => logUtil.log('edit description')}
                />
            </div>
        </div>
    );
};

export default WidgetSettings;

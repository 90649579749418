import { useSubscription } from '@apollo/client';
import React from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import {
    ChatSubscriptions,
    OnChatUpdateForUserData,
} from '../graphql/subscriptions/chatSubscriptions';
import { useObservable } from '../hooks/useObservable';
import RxChat from '../rxjs/RxChat';
import { logSubscription } from '../utils/loggingUtil';
import { ChatMessageTypes } from '../__shared/common';
import { LogClient } from '../__shared/common/logging/logClient';
import { TestLoggerMessages } from '../__shared/common/logging/testConstants';
import { sendNotification } from '../__shared/electron';
import { Chat } from '../__shared/graphql';
import useChatMessageManager from './useChatMessageManager';

interface ChatDataHandlerProps {
    userId: number;
}

const LogPrefix = '[ChatDataHandler]';

const isLastMessageFromCurrentUser = (userId: number, chat: Chat): boolean => {
    return userId === chat.lastMessage?.author?.id;
};

const isLastMessageANewMessage = (newChat: Chat, oldChat?: Chat): boolean => {
    if (!newChat.lastMessage?.id) {
        return false;
    }

    return newChat.lastMessage.id !== oldChat?.lastMessage?.id;
};

const triggerNewMessageNotification = (
    userId: number,
    t: TFunction,
    updatedChat?: Chat,
    oldChat?: Chat,
) => {
    if (
        !updatedChat?.id ||
        !updatedChat.lastMessage ||
        !updatedChat.lastMessage.notification ||
        updatedChat.lastMessage?.type !== ChatMessageTypes.Default ||
        isLastMessageFromCurrentUser(userId, updatedChat) ||
        !isLastMessageANewMessage(updatedChat, oldChat)
    ) {
        return;
    }
    const { id: chatId, lastMessage } = updatedChat;

    sendNotification({
        title: lastMessage.notification?.title ?? t('screens.chat.newMessage'),
        body: {
            chatId,
            message:
                lastMessage.notification?.body ??
                t('screens.chat.newMessageBody'),
        },
        icon: lastMessage.notification?.icon ?? '',
    });
};

const ChatDataHandler: React.FC<ChatDataHandlerProps> = ({ userId }) => {
    const chats = useObservable(RxChat.chats$, []);
    const { t } = useTranslation();

    const { markMessagesAsReceived } = useChatMessageManager();

    useSubscription<OnChatUpdateForUserData>(
        ChatSubscriptions.OnChatUpdateForUser,
        {
            variables: { userId },
            // context: { Headers: { authToken } },
            shouldResubscribe: true,
            onSubscriptionData: ({ subscriptionData: { data } }) => {
                LogClient.info(
                    `${LogPrefix} Received Subscription onChatUpdateForUser`,
                );
                LogClient.test(
                    TestLoggerMessages.SubscriptionOnChatUpdateForUser,
                    {
                        chatId: data?.onChatUpdateForUser?.id,
                    },
                );
                logSubscription('ON_CHAT_UPDATE_FOR_USER', data);
                const newChat = data?.onChatUpdateForUser;
                if (newChat) {
                    RxChat.addChats([newChat]);
                    if (
                        newChat.lastMessage &&
                        !newChat.lastMessage.isReceived
                    ) {
                        markMessagesAsReceived([newChat.lastMessage]);
                    }
                    RxChat.onChatUpdateForUser(newChat);

                    triggerNewMessageNotification(
                        userId,
                        t,
                        newChat,
                        chats.find(c => c.id === newChat.id),
                    );
                }
            },
        },
    );

    return <></>;
};

export default ChatDataHandler;

import jwt_decode from 'jwt-decode';
import { BehaviorSubject, first } from 'rxjs';

export interface DecodedJwt {
    userId: number;
    iat: number;
}

let currentAccessToken: string | undefined;

const accessTokenSubject = new BehaviorSubject<string | undefined>(undefined);
const accessToken$ = accessTokenSubject.asObservable();

const userIdSubject = new BehaviorSubject(0);
const userId$ = userIdSubject.asObservable();

const logout = (): void => {
    currentAccessToken = undefined;
    accessTokenSubject.next(undefined);
    setUserId(0);
};

const setUserId = (newId: number): void => {
    userId$.pipe(first()).subscribe(oldId => {
        if (newId !== oldId) {
            userIdSubject.next(newId);
        }
    });
};

const setAccessToken = (accessToken: string): void => {
    accessToken$.pipe(first()).subscribe(oldToken => {
        if (oldToken === accessToken) {
            return;
        }
        currentAccessToken = accessToken;
        try {
            const decodedToken: DecodedJwt = jwt_decode(accessToken);
            accessTokenSubject.next(accessToken);
            setUserId(decodedToken.userId);
        } catch (error) {
            console.warn('Could not decode access token. Performing logout...');
            logout();
        }
    });
};

const handleOldApiVersionError = (): void => {
    logout();
};

// used for apollo clients
export const getAccessToken = (): string | undefined => currentAccessToken;

const RxAuthentication = {
    setAccessToken,
    handleOldApiVersionError,
    logout,
    accessToken$,
    userId$,
};

export default RxAuthentication;

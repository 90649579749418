import { ChatMessage } from '../dto';
import { gql } from '@apollo/client';
import { ChatMessageFragments } from '../fragments';

const FullResponse = gql`
    query GetChatMessagesForUserUpdatedSince($lastUpdatedAt: Date!) {
        getChatMessagesForUserUpdatedSince(lastUpdatedAt: $lastUpdatedAt) {
            ...FullChatMessage
        }
    }
    ${ChatMessageFragments.FullResponse}
`;

export interface GetChatMessagesForUserUpdatedSinceData {
    getChatMessagesForUserUpdatedSince: ChatMessage[];
}

export const GetChatMessagesForUserUpdatedSinceQuery = { FullResponse };

import * as ionicIcons from 'ionicons/icons/index';
import React, { createElement } from 'react';
import { AnimalChatConstants } from '../../constants';

import styles from './icon.module.scss';

export const IconNames = ionicIcons;
interface Style {
    [key: string]: unknown;
}
export interface IconProps {
    name: string;
    size?: number;
    className?: string;
    onClick?: (e: React.ChangeEvent<MouseEvent>) => void;
}

const Icon: React.FC<IconProps> = ({
    name,
    size = AnimalChatConstants.IconSize,
    className,
    onClick,
}) => {
    const style: Style = {
        style: {
            width: `${size}px`,
            height: `${size}px`,
        },
    };
    if (className) {
        style.class = className;
    }
    const props: Style = {
        name,
        ...style,
    };
    if (typeof onClick === 'function') {
        style.class = `${style.class} ${styles.clickable}`;
        props.onClick = onClick;
    }
    return createElement('ion-icon', props);
};

export default Icon;

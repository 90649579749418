import { gql } from '@apollo/client';
import { LicenceToCompany } from '../dto';
import { LicenceToCompanyFragments } from '../fragments';

const FullLicenceToCompany = gql`
    query GetLicencesToCompanies($filter: LicenceToCompanyFilter) {
        getLicencesToCompanies(filter: $filter) {
            ...FullLicenceToCompany
        }
    }
    ${LicenceToCompanyFragments.FullLicenceToCompany}
`;

export interface GetLicencesToCompaniesData {
    getLicencesToCompanies: LicenceToCompany[];
}

const GetLicencesToCompaniesQueries = {
    FullLicenceToCompany,
};

export const LicenceToCompanyQueries = {
    GetLicencesToCompaniesQueries,
};

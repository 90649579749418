import { gql } from '@apollo/client';

const FullResponse = gql`
    mutation UpdateActivationCodeEmail($email: String!) {
        updateActivationCodeEmail(email: $email)
    }
`;

export interface UpdateActivationCodeEmailResponse {
    updateActivationCodeEmail: boolean;
}

export const UpdateActivationCodeEmailMutation = {
    FullResponse,
};

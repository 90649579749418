import { ColorBundle } from './__shared/graphql';

export const AnimalChatConstants = {
    IconSize: 24,
    ProfileSize: 48,
};

export type SortOrder = 'asc' | 'desc';

export const AppointmentJoinBufferInMinutes = 5;

export const AppointmentTypes = {
    VideoChat: 'video-chat',
};

export const OrderTypes = {
    Appointment: 'appointment',
};

export const PermissionTypes = {
    canCancel: 'canCancel',
    canConfirm: 'canConfirm',
    canJoin: 'canJoin',
};

export interface OutdatedApiVersionError {
    message: string;
    errorCode: string;
    supportedApiVersion: {
        'animalchat-app-ios-version'?: string;
        'animalchat-app-android-version'?: string;
        'animalchat-dashboard-version'?: string;
        'animalchat-dashboard-shell-version'?: string;
        'animalchat-pro-app-ios-version'?: string;
        'animalchat-pro-app-android-version'?: string;
        'animalchat-widget-version'?: string;
    };
}

export type CssInterface = string | number;

export const CompanyTypes = {
    VetPractice: 'vet-practice',
    VetClinic: 'vet-clinic',
    DogGroomer: 'dog-groomer',
    Other: 'other',
};

export const DefaultColorBundle: ColorBundle = {
    hexColor: '#1f6ac3',
    hexColorGradient: '#1b5faf',
};

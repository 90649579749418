import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './support.module.scss';

const Support: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <p>{t('screens.settings.support.question')}</p>
      <a href='mailto:support@animalpool.de'>support@animalpool.de</a>
      <p>{t('screens.settings.support.visitWebsite')}</p>
      <a
        href='https://www.animalchat.net'
        target='_blank'
        rel='noopener noreferrer'
      >
        www.animalchat.net
      </a>
    </div>
  );
};

export default Support;

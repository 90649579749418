import React from 'react';
import ReactPlayer from 'react-player';
import { DateUtil, getThumbnail } from '../../__shared/common';
import { isImageType, isVideoType } from '../../utils/fileUtils';
import ApiImage from '../apiImage/apiImage';
import ApiVideoPlayer from '../apiVideoPlayer/apiVideoPlayer';
import { MediaFile } from '../fullscreenMedia/fullscreenMedia';
import Icon from '../icon/icon';
import LocalFileImage from '../localFileImage/localFileImage';
import LocalFileVideoPlayer from '../localFileVideoPlayer/localFileVideoPlayer';
import styles from './mediaPreviewList.module.scss';

interface MediaPreviewListProps {
    files: MediaFile[];
    setCursor: (index: number) => void;
    cursor: number;
    className?: string;
    onAddElement?: () => void;
}

const MediaPreviewList: React.FC<MediaPreviewListProps> = ({
    files: mediaFiles,
    setCursor,
    cursor,
    className,
    onAddElement,
}) => {
    const canAddElement = typeof onAddElement === 'function';

    let containerStyles = styles.container;
    if (className) {
        containerStyles += ` ${className}`;
    }

    return (
        <div className={containerStyles}>
            <ul className={styles.previewList}>
                {mediaFiles?.map((mediaFile, index) => {
                    const isSelected = index === cursor;
                    const fileDescription = mediaFile?.filesDescriptions?.length
                        ? mediaFile.filesDescriptions[0]
                        : undefined;
                    return (
                        <li key={`${index}-${mediaFile.name}`}>
                            {isImageType(mediaFile.type) &&
                                (mediaFile.file ? (
                                    <LocalFileImage
                                        file={mediaFile.file}
                                        nativeProps={{
                                            onClick: () => setCursor(index),
                                            className: `${styles.preview}${
                                                isSelected
                                                    ? ` ${styles.selected}`
                                                    : ''
                                            }`,
                                        }}
                                    />
                                ) : fileDescription ? (
                                    <ApiImage
                                        fileDescription={fileDescription}
                                        fileDescriptionWithOptimalResolution={getThumbnail(
                                            fileDescription,
                                        )}
                                        nativeProps={{
                                            onClick: () => setCursor(index),
                                            className: `${styles.preview}${
                                                isSelected
                                                    ? ` ${styles.selected}`
                                                    : ''
                                            }`,
                                        }}
                                    />
                                ) : (
                                    <img
                                        src={mediaFile.uri}
                                        onClick={() => setCursor(index)}
                                        className={`${styles.preview}${
                                            isSelected
                                                ? ` ${styles.selected}`
                                                : ''
                                        }`}
                                    />
                                ))}
                            {isVideoType(mediaFile.type) && (
                                <>
                                    <div
                                        className={
                                            isSelected
                                                ? `${styles.preview} ${styles.videoContainer} ${styles.selected}`
                                                : `${styles.preview} ${styles.videoContainer}`
                                        }
                                        onClick={event => {
                                            setCursor(index);
                                            event.stopPropagation();
                                            event.preventDefault();
                                        }}>
                                        {mediaFile?.file ? (
                                            <LocalFileVideoPlayer
                                                nativeProps={{
                                                    pip: false,
                                                    className:
                                                        styles.videoContainer,
                                                }}
                                                file={mediaFile.file}
                                            />
                                        ) : !mediaFile?.filesDescriptions
                                              ?.length ? null : fileDescription ? (
                                            <ApiVideoPlayer
                                                nativeProps={{
                                                    pip: false,
                                                    className:
                                                        styles.videoContainer,
                                                }}
                                                fileDescription={
                                                    fileDescription
                                                }
                                            />
                                        ) : (
                                            <ReactPlayer
                                                pip={false}
                                                url={mediaFile.uri}
                                            />
                                        )}
                                    </div>
                                    <div className={styles.videoInfo}>
                                        <Icon size={14} name="videocam" />
                                        {!!(
                                            mediaFile.filesDescriptions
                                                ?.length &&
                                            fileDescription?.duration
                                        ) && (
                                            <span>
                                                {DateUtil.getMMSSFromSeconds(
                                                    fileDescription?.duration,
                                                )}
                                            </span>
                                        )}
                                    </div>
                                </>
                            )}
                        </li>
                    );
                })}
                {canAddElement && (
                    <li className={styles.addImage}>
                        <Icon
                            size={40}
                            name="add-circle-outline"
                            onClick={onAddElement}
                            className={styles.iconButton}
                        />
                    </li>
                )}
            </ul>
        </div>
    );
};

export default MediaPreviewList;

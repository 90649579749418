import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Menu, { Layouts } from '../components/menu/menu';
import useAutoLogin from '../hooks/useAutoLogin';
import { useObservable } from '../hooks/useObservable';
import { onOpenSettings$ } from '../rxjs/onAnimalChatElectronSubject';
import RxAuthentication from '../rxjs/RxAuthentication';
import RxChat from '../rxjs/RxChat';
import RxCompany from '../rxjs/RxCompany';
import RxUserProfile from '../rxjs/RxUserProfile';
import ChatDataHandler from '../stateHandlers/chatDataHandler';
import CompanyDataHandler from '../stateHandlers/companyDataHandler';
import UserDataHandler from '../stateHandlers/userDataHandler';
import AnimalChatRoutes from '../utils/routing';
import { LogClient } from '../__shared/common/logging/logClient';
import { TestLoggerMessages } from '../__shared/common/logging/testConstants';
import { isElectron } from '../__shared/electron';
import ChatsLayout from './app/chatLayout/chatsLayout';
import MissingLicence from './app/chatLayout/missingLicence';
import SettingsLayout from './app/settingsLayout/settingsLayout';
import UserProfileLayout from './app/userProfileLayout/userProfileLayout';

const AppLayout: React.FC = () => {
    useAutoLogin();
    const navigate = useNavigate();
    if (!isElectron() && process.env.REACT_APP_ALLOW_APP_IN_WEB !== 'enabled') {
        navigate(AnimalChatRoutes.businessSubs.login);
    }

    const userId = useObservable(RxAuthentication.userId$, 0);
    const authToken = useObservable(RxAuthentication.accessToken$);
    const currentCompany = useObservable(RxCompany.currentCompany$);
    const currentUser = useObservable(RxUserProfile.userProfile$);

    const [activeLayout, setActiveLayout] = useState<Layouts>('chats');
    const [hasLicence, setHasLicence] = useState<boolean | undefined>(
        undefined,
    );

    useEffect(() => {
        if (userId) {
            LogClient.test(`${TestLoggerMessages.ClaimLog}${userId}`);
        }
    }, [userId]);

    useEffect(() => {
        if (currentUser?.licencesToCompany && currentCompany?.id) {
            setHasLicence(
                currentUser?.licencesToCompany.filter(
                    x => x.companyId === currentCompany.id,
                ).length > 0,
            );
        }
    }, [currentUser, currentCompany]);

    useEffect(() => {
        if (!hasLicence) {
            RxChat.setCurrentChatId(0);
        }
    }, [hasLicence]);

    useEffect(() => {
        const openSettingsSubscription = onOpenSettings$.subscribe(() => {
            setActiveLayout('settings');
        });
        return () => {
            if (openSettingsSubscription) {
                openSettingsSubscription.unsubscribe();
            }
        };
    }, []);

    const loadUserAndCompanyDataHandler =
        userId && authToken && currentCompany?.id;

    const showChatLayout =
        userId && authToken && currentCompany?.id && hasLicence !== undefined;

    if (!showChatLayout) {
        return null;
    }

    return (
        <>
            <Menu
                userId={userId}
                onShowLayout={setActiveLayout}
                activeLayout={activeLayout}
            />

            {!!loadUserAndCompanyDataHandler && (
                <>
                    <UserDataHandler userId={userId} />
                    {currentCompany?.id && (
                        <CompanyDataHandler
                            userId={userId}
                            companyId={currentCompany?.id}
                        />
                    )}
                </>
            )}

            {!!showChatLayout && (
                <>
                    <ChatDataHandler userId={userId} />
                    {hasLicence ? <ChatsLayout /> : <MissingLicence />}
                    <div
                        style={
                            activeLayout !== 'settings'
                                ? { display: 'none' }
                                : {}
                        }>
                        <SettingsLayout show={activeLayout === 'settings'} />
                    </div>
                    <div
                        style={
                            activeLayout !== 'userProfile'
                                ? { display: 'none' }
                                : {}
                        }>
                        <UserProfileLayout
                            show={activeLayout === 'userProfile'}
                        />
                    </div>
                </>
            )}
        </>
    );
};

export default AppLayout;

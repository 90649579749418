import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { closeModal, onShowModal$ } from '../../rxjs/onShowModalSubject';
import TextButton from '../textButton/textButton';
import styles from './modal.module.scss';

export interface ModalProps {
    title: string;
    text: string;
    okButtonText?: string;
    cancelButtonText?: string;
    hideCancelButton?: boolean;
    disableCloseOnEscape?: boolean;
    closeOnBackdropClick?: boolean;
}

const Modal: React.FC = () => {
    const { t } = useTranslation();
    const [initial, setInitial] = useState(true);
    const [containerClassName, setContainerClassName] = useState(
        styles.container,
    );
    const [modalProps, setModalProps] = useState<ModalProps | undefined>(
        undefined,
    );
    const modalPropsRef = useRef<ModalProps | undefined>();

    useEffect(() => {
        const sub = onShowModal$.subscribe(props => {
            setModalProps(props);
        });

        return () => {
            if (sub) {
                sub.unsubscribe();
            }
        };
    }, []);

    const onClose = useCallback((response: boolean) => {
        setModalProps(undefined);
        closeModal(response);
    }, []);

    useEffect(() => {
        // don't register listener if no dialog is visible
        if (!modalProps || modalProps.disableCloseOnEscape) {
            return;
        }

        const handleEscapeKey = (e: KeyboardEvent) => {
            switch (e.key) {
                case 'Escape':
                    onClose(false);
                    break;

                default:
                    break;
            }
        };
        document.addEventListener('keydown', handleEscapeKey);
        return () => document.removeEventListener('keydown', handleEscapeKey);
    }, [onClose, modalProps]);

    useEffect(() => {
        if (modalProps) {
            setContainerClassName(`${styles.container} ${styles.show}`);
            modalPropsRef.current = modalProps;
        } else {
            if (!initial) {
                setContainerClassName(`${styles.container} ${styles.hide}`);
            } else {
                setContainerClassName(styles.container);
                setInitial(false);
            }
        }
        // went don't want to trigger this function when the initial state changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalProps]);

    return (
        <div
            className={containerClassName}
            onAnimationEnd={() => {
                setContainerClassName(
                    `${styles.container} ${
                        modalProps ? styles.shown : ''
                    }`.trim(),
                );
                if (!modalProps) {
                    modalPropsRef.current = undefined;
                    setInitial(true);
                }
            }}
            onClick={() => {
                if (modalProps?.closeOnBackdropClick) {
                    onClose(false);
                }
            }}>
            <div
                className={styles.box}
                onClick={e => {
                    // stop click propagation up to the backdrop
                    e.stopPropagation();
                }}>
                <div className={styles.titleBox}>
                    <h3 className={styles.title}>
                        {modalPropsRef.current?.title}
                    </h3>
                </div>
                <div className={styles.contentBox}>
                    <span className={styles.content}>
                        {modalPropsRef.current?.text}
                    </span>
                </div>
                <div className={styles.actionsBox}>
                    {!modalPropsRef.current?.hideCancelButton && (
                        <TextButton
                            className={styles.button}
                            style="secondary"
                            text={
                                modalPropsRef.current?.cancelButtonText ??
                                t('common.buttons.cancel')
                            }
                            onClick={() => onClose(false)}
                        />
                    )}
                    <TextButton
                        className={styles.button}
                        text={
                            modalPropsRef.current?.okButtonText ??
                            t('common.buttons.ok')
                        }
                        onClick={() => onClose(true)}
                    />
                </div>
            </div>
        </div>
    );
};

export default Modal;

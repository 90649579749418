import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import animalchatIcon from '../../assets/images/animalchat_app_icon.png';
import { useObservable } from '../../hooks/useObservable';
import RxChat from '../../rxjs/RxChat';
import RxLicenceToCompanies from '../../rxjs/RxLicenceToCompanies';
import RxUserProfile from '../../rxjs/RxUserProfile';
import Icon from '../icon/icon';
import ProfileIcon from '../profileIcon/profileIcon';
import styles from './menu.module.scss';
import MenuEntry from './menuEntry';

export type Layouts = 'chats' | 'settings' | 'userProfile';

interface MenuProps {
    onShowLayout: (layout: Layouts) => void;
    activeLayout: Layouts;
    userId: number;
}

const Menu: React.FC<MenuProps> = ({ onShowLayout, activeLayout }) => {
    const { t } = useTranslation();
    const user = useObservable(RxUserProfile.userProfile$);
    const unreadMessageCount = useObservable(RxChat.unreadMessageCount$, 0);
    const [hasLicence, setHasLicence] = useState(false);
    const licences = useObservable(RxLicenceToCompanies.licenceToCompanies$);

    useEffect(() => {
        setHasLicence(!!licences?.length);
    }, [licences]);

    const logo = (
        <div className={styles.logo}>
            <img className={styles.icon} src={animalchatIcon} />
        </div>
    );

    return (
        <div className={styles.menu}>
            <MenuEntry icon={logo} className={styles.logo} />
            <MenuEntry
                icon={<Icon name="chatbubble" />}
                title={t('menu.entries.chats')}
                active={activeLayout === 'chats'}
                messageCount={unreadMessageCount}
                onClick={() => onShowLayout('chats')}
            />
            {/* <MenuEntry
                icon={<Icon name="stats-chart" />}
                title={t("menu.entries.analysis")}
                active={location.pathname.startsWith(AnimalChatRoutes.analysis)}
                onClick={() => onShowLayout('analysis')}
            /> */}
            {hasLicence ? (
                <MenuEntry
                    icon={<Icon name="settings" />}
                    title={t('menu.entries.settings')}
                    active={activeLayout === 'settings'}
                    onClick={() => onShowLayout('settings')}
                />
            ) : null}
            <div className={styles.separator} />
            <div
                className={`${styles.profile} ${
                    activeLayout === 'userProfile' ? styles.active : ''
                }`.trim()}
                onClick={() => onShowLayout('userProfile')}>
                <ProfileIcon
                    size={48}
                    fileDescription={user?.imageFile}
                    className={styles.profileIcon}
                    colorBundle={user?.colorBundle}
                />
            </div>
        </div>
    );
};

export default Menu;

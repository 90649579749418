import React from 'react';
import Icon from '../icon/icon';
import styles from './inputAdornment.module.scss';

export interface InputAdornmentProps {
    name: string;
    position: 'end' | 'start';
    size?: number;
    onClick?(event: React.ChangeEvent<MouseEvent>): void;
}

const InputAdornment: React.FC<InputAdornmentProps> = ({
    name,
    position,
    onClick,
    size = 24,
}) => {
    const hasClickFunction = typeof onClick === 'function';
    const className = `${styles.icon} ${
        position === 'start' ? styles.start : styles.end
    } ${hasClickFunction ? styles.hover : ''}`.trim();
    const handleClick = (e: React.ChangeEvent<MouseEvent>) => {
        if (hasClickFunction) {
            onClick(e);
        }
    };

    return (
        <Icon
            onClick={(e: React.ChangeEvent<MouseEvent>) => handleClick(e)}
            name={name}
            size={size}
            className={className}
        />
    );
};

export default InputAdornment;

import { gql } from '@apollo/client';

const FullResponse = gql`
    mutation SoftDeleteAndAnonymiseUserIfPossible($userId: Int!) {
        softDeleteAndAnonymiseUserIfPossible(userId: $userId)
    }
`;

export interface SoftDeleteAndAnonymiseUserIfPossibleData {
    softDeleteAndAnonymiseUserIfPossible: boolean;
}

export const SoftDeleteAndAnonymiseUserIfPossibleMutation = {
    FullResponse,
};

import { gql } from '@apollo/client';
import { UserPrivate } from '../dto';
import { UserFragments } from '../fragments';

const FullResponse = gql`
    query getPrivateUserProfile($userId: Int!) {
        getPrivateUserProfile(userId: $userId) {
            ...FullUserPrivate
        }
    }
    ${UserFragments.FullUserPrivate}
`;

export interface GetPrivateUserProfileData {
    getPrivateUserProfile: UserPrivate
}

export const GetPrivateUserProfileQuery = {
    FullResponse,
};

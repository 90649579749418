import React from 'react';

const NotFound = (): JSX.Element => (
    <div
        style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            textAlign: 'center',
            alignContent: 'center',
            padding: '40px',
        }}>
        <h1>Wuff! Page Not Found!</h1>{' '}
    </div>
);

export default NotFound;

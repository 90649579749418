import { gql } from '@apollo/client';

const FullLicenceToCompanyFragment = gql`
    fragment FullLicenceToCompanyFragment on LicenceToCompany {
        id
        licenceId
        companyId
        cancelledAt
        cancelledFor
        cancelledBy
        specialCancellationFor
        reasonForCancellation
        validSince
        userId
        createdBy
        createdAt
        updatedBy
        updatedAt
    }
`;

export const LicenceToCompanyFragments = {
    FullLicenceToCompanyFragment,
};

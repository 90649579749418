import { IpcRenderer } from 'electron';

export const isElectron = (): boolean => {
    // Renderer process
    if (
        typeof window !== 'undefined' &&
        typeof window.process === 'object' &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window.process as any).type === 'renderer'
    ) {
        return true;
    }

    // Main process
    if (
        typeof process !== 'undefined' &&
        typeof process.versions === 'object' &&
        !!process.versions.electron
    ) {
        return true;
    }

    // Detect the user agent when the `nodeIntegration` option is set to false
    if (
        typeof navigator === 'object' &&
        typeof navigator.userAgent === 'string' &&
        navigator.userAgent.indexOf('Electron') >= 0
    ) {
        return true;
    }

    return false;
};

export const OpenNewWindowEvent = 'AC_NEW_WINDOW';
export interface OpenNewWindowPayload {
    url: string;
}

export const openVideoCall = (url: string): void => {
    let ipcRenderer: IpcRenderer | undefined = undefined;
    if (window && isElectron()) {
        ipcRenderer = window.require('electron').ipcRenderer;
        ipcRenderer.send(OpenNewWindowEvent, { url });
    } else {
        window.open(
            url,
            'AnimalChat',
            'height=768,width=1024,left=100,top=100',
        );
    }
};

export const OpenUrlInBrowserEvent = 'AC_OPEN_IN_BROWSER';
export interface OpenUrlInBrowserPayload {
    url: string;
}

export const ReloadMainWindow = 'AC_RELOAD_MAIN_WINDOW';
export const reloadBrowserWindow = (): void => {
    if (window) {
        if (isElectron()) {
            let ipcRenderer: IpcRenderer | undefined = undefined;
            ipcRenderer = window.require('electron').ipcRenderer;
            if (ipcRenderer) {
                ipcRenderer.send(ReloadMainWindow);
            }
        } else {
            window.location.reload();
        }
    }
};

export const openUrlInBrowser = (url: string): void => {
    if (window) {
        if (isElectron()) {
            let ipcRenderer: IpcRenderer | undefined = undefined;
            ipcRenderer = window.require('electron').ipcRenderer;
            ipcRenderer.send(OpenUrlInBrowserEvent, { url });
        } else {
            window.open(url, '_blank');
        }
    }
};

export const downloadFile = (url: string): void => {
    if (window) {
        if (isElectron()) {
            let ipcRenderer: IpcRenderer | undefined = undefined;
            ipcRenderer = window.require('electron').ipcRenderer;
            ipcRenderer.send(OpenUrlInBrowserEvent, { url });
        } else {
            const a = document.createElement('a');
            a.href = url;
            a.download = url;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
    }
};

export const IsWindowsRequest = 'AC_IS_WINDOWS_REQUEST';
export const IsWindowsResponse = 'AC_IS_WINDOWS_RESPONSE';

export const isWindowsOs = (): Promise<boolean> => {
    if (isElectron()) {
        const ipcRenderer: IpcRenderer | undefined = window.require('electron')
            .ipcRenderer;
        if (ipcRenderer) {
            return new Promise(resolve => {
                ipcRenderer.on(
                    IsWindowsResponse,
                    (_: unknown, response: boolean) => {
                        resolve(response);
                    },
                );
                ipcRenderer.send(IsWindowsRequest);
            });
        }
    }
    return Promise.resolve(false);
};

export const GetShellVersionRequest = 'AC_GET_SHELL_VERSION_REQUEST';
export const GetShellVersionResponse = 'AC_GET_SHELL_VERSION_RESPONSE';

export const getElectronShellVersion = (): Promise<string | undefined> => {
    if (isElectron()) {
        const ipcRenderer: IpcRenderer | undefined = window.require('electron')
            .ipcRenderer;
        if (ipcRenderer) {
            return new Promise(resolve => {
                ipcRenderer.on(
                    GetShellVersionResponse,
                    (_: unknown, version: string | undefined) => {
                        resolve(version);
                    },
                );
                ipcRenderer.send(GetShellVersionRequest);
            });
        }
    }
    return Promise.resolve(undefined);
};

export const AcRestartEvent = 'AC_RESTART';

export const restartApplication = (): void => {
    if (isElectron()) {
        const ipcRenderer: IpcRenderer | undefined = window.require('electron')
            .ipcRenderer;
        if (ipcRenderer) {
            ipcRenderer.send(AcRestartEvent);
        }
    }
};

export const AcOpenDownloadSetupLink = 'AC_OPEN_DOWNLOAD_SETUP_LINK';
export const openDownloadSetupLinkFromShell = (): void => {
    if (window) {
        if (isElectron()) {
            let ipcRenderer: IpcRenderer | undefined = undefined;
            ipcRenderer = window.require('electron').ipcRenderer;
            ipcRenderer.send(AcOpenDownloadSetupLink);
        }
    }
};

export const WindowsSetupUrl =
    'https://pro.animalchat.net/animalchat-pro-setup.exe';
export const MacDmgUrl = 'https://pro.animalchat.net/animalchat-pro.dmg';

export const AcSafeFileAs = 'AC_SAVE_FILE_AS';
interface SaveFileAsParams {
    url: string;
    fileType: string;
    fileName: string;
    accessToken: string;
    headers?: {};
}
export const saveFileAs = ({
    url,
    fileType,
    fileName,
    accessToken,
    headers = {},
}: SaveFileAsParams): void => {
    if (!window) {
        return;
    }
    if (isElectron()) {
        let ipcRenderer: IpcRenderer | undefined = undefined;
        ipcRenderer = window.require('electron').ipcRenderer;
        ipcRenderer.send(AcSafeFileAs, {
            url,
            properties: { accessToken, fileType, fileName },
        });
    } else {
        fetch(url, {
            mode: 'cors',
            headers: {
                ...headers,
            },
        })
            .then(res => res.blob())
            .then(blob => {
                const oUrl = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = oUrl;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                URL.revokeObjectURL(oUrl);
            });
    }
};

export const AcSafeFileComplete = 'AC_SAVE_FILE_COMPLETE';
export const onSaveFileCompleteListener = (
    callback: (filePath: string) => void,
): void => {
    if (!window) {
        return;
    }
    if (isElectron()) {
        let ipcRenderer: IpcRenderer | undefined = undefined;
        ipcRenderer = window.require('electron').ipcRenderer;
        ipcRenderer.on(AcSafeFileComplete, (_, file) => {
            callback(file);
        });
    }
};

import { gql } from '@apollo/client';
import { FileDescriptionFragments } from './fileDescriptionFragments';

const FullAnimalGroup = gql`
    fragment FullAnimalGroup on AnimalGroup {
        id
        name
        type
        breed
        specification
        description
        averageBirthday
        groupSize
        isGroupSizeEstimated
        createdAt
        createdBy
        updatedAt
        deletedAt
        deletedBy
        colorBundle {
            hexColor
            hexColorGradient
        }
        imageFile {
            ...FullFileDescription
        }
        owners {
            user {
                id
            }
        }
    }
    ${FileDescriptionFragments.FullResponse}
`;

export const AnimalGroupFragments = { FullAnimalGroup };

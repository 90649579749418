import React from 'react';
import { useTranslation } from 'react-i18next';
import { ClassNameProp } from '../../types/stylingProps';
import Icon from '../icon/icon';
import styles from './submenuEntry.module.scss';

interface SubmenuEntryProps extends ClassNameProp {
    isActive: boolean;
    translationKey: string;
    iconName: string;
    onClick?: () => void;
    disabled?: boolean;
    counter?: number;
}

const SubmenuEntry: React.FC<SubmenuEntryProps> = ({
    isActive,
    translationKey,
    iconName,
    onClick,
    className,
    disabled,
    counter,
}) => {
    const { t } = useTranslation();

    let entryClass = styles.entry;
    if (isActive) {
        entryClass += ` ${styles.active}`;
    }
    if (className) {
        entryClass += ` ${className}`;
    }
    if (disabled) {
        entryClass += ` ${styles.disabled}`;
    }

    const handleClick = () => {
        if (!disabled && onClick) {
            onClick();
        }
    };

    return (
        <li className={entryClass} onClick={handleClick}>
            <div className={styles.iconContainer}>
                <Icon name={iconName} />
                {!!counter && <div className={styles.counter}>{counter}</div>}
            </div>
            <span>{t(translationKey)}</span>
        </li>
    );
};

export default SubmenuEntry;

import { BehaviorSubject, first } from 'rxjs';
import { Company } from '../__shared/graphql';

const companiesSubject = new BehaviorSubject<Company[]>([]);
const companies$ = companiesSubject.asObservable();

const currentCompanySubject = new BehaviorSubject<Company | null | undefined>(
    undefined,
);
const currentCompany$ = currentCompanySubject.asObservable();

const addCompany = (company: Company): void => {
    companies$.pipe(first()).subscribe(prev => {
        companiesSubject.next([
            ...prev.filter(c => c.id !== company.id),
            company,
        ]);
    });
};

const clear = (): void => {
    companiesSubject.next([]);
    currentCompanySubject.next(undefined);
};

const setCompanies = (companies: Company[]): void => {
    companiesSubject.next(companies);
};

const setCurrentCompany = (company: Company | null | undefined): void => {
    currentCompanySubject.next(company);
};

const RxCompany = {
    addCompany,
    clear,
    setCompanies,
    setCurrentCompany,
    companies$,
    currentCompany$,
};

export default RxCompany;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { DateUtil } from '../../../../../../__shared/common';
import styles from './chatMessageDateBadge.module.scss';

interface ChatMessageDateBadgeProps {
    date: Date;
    hasHeaderPadding?: boolean;
}

const ChatMessageDateBadge: React.FC<ChatMessageDateBadgeProps> = ({
    date,
    hasHeaderPadding = true,
}) => {
    const { t } = useTranslation();
    const text = DateUtil.getPrettyDateOffsetText(date, t);

    return (
        <div
            className={`${styles.container} ${
                hasHeaderPadding ? '' : styles.noHeaderPadding
            }`.trim()}>
            {text}
        </div>
    );
};

export default ChatMessageDateBadge;

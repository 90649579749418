import { FileDescription } from '../../__shared/graphql';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlayer, { ReactPlayerProps } from 'react-player';
import { FadeLoader } from 'react-spinners';
import {
    AcceptMediaString,
    isImageType,
    isVideoType,
} from '../../utils/fileUtils';
import ApiImage from '../apiImage/apiImage';
import ApiVideoPlayer from '../apiVideoPlayer/apiVideoPlayer';
import Icon from '../icon/icon';
import Input from '../input/input';
import LocalFileImage from '../localFileImage/localFileImage';
import LocalFileVideoPlayer from '../localFileVideoPlayer/localFileVideoPlayer';
import MediaPreviewList from '../mediaPreviewList/mediaPreviewList';
import styles from './fullscreenMedia.module.scss';

export interface MediaFile {
    name: string;
    uri?: string;
    type: 'image' | 'video';
    filesDescriptions?: FileDescription[];
    file?: File;
    shouldReadFile?: boolean;
}

interface FullscreenMediaProps {
    mediaFiles: MediaFile[];
    onClose: () => void;
    cursor?: number;
    loading?: boolean;
    onAddMediaFiles?: (files: FileList) => void;
    onSend?: (message: string, files: File[]) => void;
}

const FullscreenMedia: React.FC<FullscreenMediaProps> = ({
    mediaFiles,
    onAddMediaFiles: addMediaFiles,
    cursor = 0,
    loading = false,
    onClose,
    onSend,
}) => {
    const uploadInputRef = useRef<HTMLInputElement>(null);
    const [localCursor, setCursor] = useState(cursor ?? 0);
    const [textMessage, setTextMessage] = useState('');
    const { t } = useTranslation();

    const isCreateMessageMode = typeof addMediaFiles === 'function';

    let cursorContainer = styles.cursorContainer;
    let previewListStyles = styles.previewList;
    if (isCreateMessageMode) {
        cursorContainer += ` ${styles.create}`;
        previewListStyles += ` ${styles.create}`;
    }

    const previous = useCallback(
        (oldCursor: number) => {
            if (oldCursor > 0) {
                setCursor(s => s - 1);
            }
        },
        [setCursor],
    );

    const next = useCallback(
        (oldCursor: number, mediaFilesLength: number) => {
            if (oldCursor < mediaFilesLength - 1) {
                setCursor(s => s + 1);
            }
        },
        [setCursor],
    );

    useEffect(() => {
        const handleLeftRightKeyEvent = (e: KeyboardEvent) => {
            switch (e.key) {
                case 'ArrowLeft':
                    previous(localCursor);
                    break;

                case 'ArrowRight':
                    next(localCursor, mediaFiles?.length ?? 0);
                    break;

                case 'Escape':
                    onClose();
                    break;

                default:
                    break;
            }
        };
        document.addEventListener('keydown', handleLeftRightKeyEvent);
        return () =>
            document.removeEventListener('keydown', handleLeftRightKeyEvent);
    }, [previous, next, onClose, localCursor, mediaFiles]);

    const getMediaElement = () => {
        const cursorFile = mediaFiles[localCursor];
        if (cursorFile) {
            if (isImageType(cursorFile.type)) {
                if (cursorFile.shouldReadFile && cursorFile.file) {
                    return <LocalFileImage file={cursorFile.file} />;
                }
                if (cursorFile.filesDescriptions?.length) {
                    return (
                        <ApiImage
                            fileDescription={cursorFile.filesDescriptions[0]}
                            fileDescriptionWithOptimalResolution={
                                cursorFile.filesDescriptions[0]
                            }
                        />
                    );
                }
                return <img src={cursorFile.uri} />;
            }
            if (isVideoType(cursorFile.type)) {
                if (cursorFile.shouldReadFile && cursorFile.file) {
                    return (
                        <LocalFileVideoPlayer
                            nativeProps={{ pip: false, controls: true }}
                            file={cursorFile.file}
                        />
                    );
                }
                if (cursorFile.filesDescriptions?.length) {
                    const fileDescription = cursorFile.filesDescriptions[0];
                    const nativeProps: ReactPlayerProps = {
                        pip: false,
                        controls: true,
                    };
                    if (fileDescription.height && fileDescription.width) {
                        const isPortraitResolution =
                            (fileDescription?.height ?? 0) >
                            (fileDescription?.width ?? 0);
                        const height = isPortraitResolution ? '100%' : 'auto';
                        const width = isPortraitResolution ? 'auto' : '100%';
                        nativeProps.height = height;
                        nativeProps.width = width;
                    }
                    return (
                        <ApiVideoPlayer
                            nativeProps={nativeProps}
                            fileDescription={fileDescription}
                        />
                    );
                }
                return (
                    <ReactPlayer
                        pip={false}
                        controls={true}
                        url={cursorFile.uri}
                    />
                );
            }
        }

        return null;
    };

    return (
        <div className={styles.container}>
            {isCreateMessageMode && (
                <input
                    style={{ visibility: 'hidden' }}
                    type="file"
                    accept={AcceptMediaString}
                    multiple
                    onChange={e => {
                        if (isCreateMessageMode && e.target.files) {
                            addMediaFiles(e.target.files);
                        }
                    }}
                    ref={uploadInputRef}
                    className={styles.fileInput}
                />
            )}
            <div className={styles.actions}>
                <Icon
                    onClick={onClose}
                    size={40}
                    name="close-outline"
                    className={`${styles.close} ${styles.iconButton}`}
                />
            </div>
            <div className={cursorContainer}>
                <div className={styles.imageContainer}>{getMediaElement()}</div>
                <div className={styles.arrowContainer}>
                    <Icon
                        onClick={() => previous(localCursor)}
                        size={40}
                        name="arrow-back-circle-outline"
                        className={`${styles.arrow} ${styles.iconButton}`}
                    />
                    <Icon
                        onClick={() => next(localCursor, mediaFiles?.length)}
                        size={40}
                        name="arrow-forward-circle-outline"
                        className={`${styles.arrow} ${styles.iconButton}`}
                    />
                </div>
            </div>
            {isCreateMessageMode && (
                <div className={styles.message}>
                    <Input
                        className={styles.input}
                        id="chat-input-media"
                        value={textMessage}
                        onChange={e => setTextMessage(e.target.value)}
                        onChangeText={setTextMessage}
                        multiline
                        placeholder={t('screens.chat.placeHolderMessageInput')}
                    />
                </div>
            )}
            <div className={previewListStyles}>
                <MediaPreviewList
                    files={mediaFiles}
                    cursor={localCursor}
                    setCursor={setCursor}
                    onAddElement={
                        isCreateMessageMode
                            ? () => uploadInputRef?.current?.click()
                            : undefined
                    }
                />
                {isCreateMessageMode &&
                    (!loading ? (
                        <Icon
                            className={`${styles.sendButton} ${styles.iconButton}`}
                            size={40}
                            onClick={() => {
                                if (onSend) {
                                    onSend(
                                        textMessage,
                                        mediaFiles
                                            .filter(
                                                mediaFile => !!mediaFile?.file,
                                            )
                                            // media.file is checked for beeing defined
                                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                            .map(mediaFile => mediaFile.file!),
                                    );
                                }
                            }}
                            name="arrow-up-circle-outline"
                        />
                    ) : (
                        <div
                            style={{
                                paddingRight: '70px',
                                paddingBottom: '52px',
                            }}>
                            <FadeLoader
                                color={'white'}
                                height={10}
                                width={4}
                                radius={4}
                            />
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default FullscreenMedia;

import { Address } from '../dto';
import { gql } from '@apollo/client';
import { AddressFragments } from '../fragments';

const FullResponse = gql`
    mutation UpdateAddress($address: AddressUpdate!) {
        updateAddress(address: $address) {
            ...FullAddress
        }
    }
    ${AddressFragments.FullAddress}
`;

export interface UpdateAddressData {
    updateAddress: Address;
}

export const UpdateAddressMutation = { FullResponse };

import { gql } from '@apollo/client';

const FullResponse = gql`
    mutation CreateStripeConnectAccountForCompany($companyId: Int!) {
        createStripeConnectAccountForCompany(companyId: $companyId)
    }
`;

export interface CreateStripeConnectAccountForCompanyData {
    createStripeConnectAccountForCompany: string;
}

export const CreateStripeConnectAccountForCompanyMutation = {
    FullResponse,
};

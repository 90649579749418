import { gql } from '@apollo/client';
import { AnimalGroup } from '../dto';
import { FileDescriptionFragments } from '../fragments';

const FullUpdateAnimalGroup = gql`
    mutation updateAnimalGroup(
        $animalGroup: AnimalGroupUpdate!
        $imageResolutions: [ImageResolution!]
    ) {
        updateAnimalGroup(
            animalGroup: $animalGroup
            imageResolutions: $imageResolutions
        ) {
            id
            name
            type
            breed
            specification
            description
            averageBirthday
            groupSize
            isGroupSizeEstimated
            createdAt
            updatedAt
            deletedAt
            deletedBy
            imageFile {
                ...FullFileDescription
            }
            colorBundle {
                hexColor
                hexColorGradient
            }
            owners {
                user {
                    id
                }
            }
        }
    }
    ${FileDescriptionFragments.FullResponse}
`;

export interface UpdateAnimalGroupResponse {
    updateAnimalGroup: AnimalGroup;
}

export const UpdateAnimalGroupMutation = {
    FullUpdateAnimalGroup,
};

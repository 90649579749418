import { gql } from '@apollo/client';
import {
    AnimalFragments,
    AnimalGroupFragments,
    ChatMessageFragments,
    ChatToUserFragments,
    CustomerFragments,
    FileDescriptionFragments,
} from '../../__shared/graphql';
import { NotificationFragments } from './notificationFragment';

const ChatForChatListEntryFragment = gql`
    fragment ChatForChatListEntryFragment on Chat {
        id
        unreadMessageCount
        hasOpenRequest
        type
        animal {
            ...FullAnimal
            colorBundle {
                hexColor
                hexColorGradient
            }
            imageFile {
                ...FullFileDescription
            }
            owners {
                user {
                    id
                    imageFile {
                        ...FullFileDescription
                    }
                }
            }
        }
        animalGroup {
            ...FullAnimalGroup
            colorBundle {
                hexColor
                hexColorGradient
            }
            imageFile {
                ...FullFileDescription
            }
            owners {
                user {
                    id
                    imageFile {
                        ...FullFileDescription
                    }
                }
            }
        }
        company {
            id
            name
            type
            isActive
            colorBundle {
                hexColor
                hexColorGradient
            }
            imageFile {
                ...FullFileDescription
            }
        }
        lastMessage {
            ...FullChatMessage
            notification {
                ...FullNotificationFragment
            }
        }
        chatToUser {
            ...FullChatToUser
        }
        topic {
            id
            name
            createdBy
            imageFile {
                ...FullFileDescription
            }
        }
        customers {
            id
            contact {
                id
                title
                firstname
                lastname
                email
            }
        }
        participants {
            user {
                id
                title
                firstname
                lastname
            }
            customer {
                ...CustomerWithUserAndContactAndAddressAndCompany
            }
            employee {
                id
                title
                company {
                    id
                    name
                }
            }
        }
    }
    ${ChatToUserFragments.FullChatToUser}
    ${NotificationFragments.FullNotificationFragment}
    ${ChatMessageFragments.FullResponse}
    ${CustomerFragments.CustomerWithUserAndContactAndAddressAndCompany}
    ${FileDescriptionFragments.FullResponse}
    ${AnimalFragments.FullAnimal}
    ${AnimalGroupFragments.FullAnimalGroup}
`;

export const ChatFragments = {
    ChatForChatListEntryFragment,
};

import { useCallback, useEffect, useMemo, useState } from 'react';
import {
    fixPriceValue,
    isAllowedCurrencyTypingInput,
    toIntegerValueWithProperThousandsSeparators,
} from '../../../../../../utils/currencyUtils';
import logUtil from '../../../../../../utils/logUtil';

const now = new Date();
const defaultBeginDate = new Date(
    now.setHours(Math.max(now.getHours() + 1, 11), 0, 0, 0),
);

export interface AppointmentForm {
    begin: Date;
    duration: number;
    end: Date;
    consultant: number;
    customer: number;
    price: string;
    isAdvancePayment: boolean;
    isValid: boolean;
}

interface AppointmentFormErrors {
    begin?: string;
    duration?: string;
    price?: string;
    consultant?: number;
}

interface UseAppointmentForm {
    form: AppointmentForm;
    errors?: AppointmentFormErrors;
    updateField: {
        updateBegin: (date: Date) => void;
        updateDuration: (duration: string) => void;
        updatePrice: (price: string) => void;
        updateConsultant: (consultant: number) => void;
        updateCustomer: (customer: number) => void;
        updateIsAdvancePayment: (newIsAdvancePayment: boolean) => void;
    };
    fixPrice: () => void;
    clearCurrencySign: () => void;
}

export const useAppointmentForm = (): UseAppointmentForm => {
    const [begin, setBegin] = useState(defaultBeginDate);
    const [end, setEnd] = useState(new Date());
    const [duration, setDuration] = useState(15);
    const [price, setPrice] = useState('25,00 €');
    const [consultant, setConsultant] = useState(0);
    const [customer, setCustomer] = useState(0);
    const [isAdvancePayment, setIsAdvancePayment] = useState(false);
    const [isValid, setIsValid] = useState(false);

    const updateDuration = useCallback(
        (newDurationString: string) => {
            try {
                const newDuration = Number.parseInt(newDurationString, 10);
                if (newDuration > 0) {
                    setDuration(newDuration);
                }
            } catch (e) {
                logUtil.warn('could not parse duration', e);
            }
        },
        [setDuration],
    );

    // validating form
    useEffect(() => {
        let newValid = true;
        if (price.startsWith('0,00')) {
            newValid = false;
        }
        if (begin < new Date()) {
            newValid = false;
        }
        if (consultant < 1) {
            newValid = false;
        }

        setIsValid(newValid);
    }, [begin, price, consultant]);

    const updatePrice = useCallback((newPrice: string) => {
        if (!isAllowedCurrencyTypingInput(newPrice)) {
            return;
        }
        const commaIndex = newPrice.indexOf(',');
        if (commaIndex === -1) {
            setPrice(toIntegerValueWithProperThousandsSeparators(newPrice));
            return;
        }
        // eslint-disable-next-line prefer-const
        let [integerValue, decimalValue] = newPrice.split(',');
        integerValue = toIntegerValueWithProperThousandsSeparators(
            integerValue,
        );
        setPrice(`${integerValue},${decimalValue}`);
    }, []);

    const fixPrice = useCallback(() => {
        setPrice(fixPriceValue(price));
    }, [price]);

    const clearCurrencySign = useCallback(() => {
        setPrice(price.replace('€', '').replace(' ', ''));
    }, [price]);

    useEffect(() => {
        if (begin) {
            const newEnd = new Date(begin);
            newEnd.setMinutes(begin.getMinutes() + duration);
            setEnd(newEnd);
        }
    }, [begin, duration]);

    const updateField = useMemo(
        () => ({
            updateBegin: (date: Date) => setBegin(date),
            updateDuration: (duration: string) => updateDuration(duration),
            updatePrice: (price: string) => updatePrice(price),
            updateConsultant: (consultant: number) => setConsultant(consultant),
            updateCustomer: (customer: number) => setCustomer(customer),
            updateIsAdvancePayment: (newIsAdvancePayment: boolean) =>
                setIsAdvancePayment(newIsAdvancePayment),
        }),
        [updateDuration, updatePrice],
    );

    return {
        form: {
            begin,
            duration,
            price,
            consultant,
            customer,
            end,
            isAdvancePayment,
            isValid,
        },
        updateField,
        fixPrice,
        clearCurrencySign,
    };
};

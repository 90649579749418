import React from 'react';
import { useTranslation } from 'react-i18next';
import { AppointmentTypes } from '../../../../../../constants';
import { buildNameFromUserCustomerOrEmployee } from '../../../../../../utils/nameUtils';
import { ChatMessage } from '../../../../../../__shared/graphql';
import styles from './chatMessageTitle.module.scss';

export interface ChatMessageTitleProps {
    message: ChatMessage;
}

const ChatMessageTitle: React.FC<ChatMessageTitleProps> = ({ message }) => {
    const { t } = useTranslation();

    if (!message) {
        return null;
    }

    let title = buildNameFromUserCustomerOrEmployee({
        user: message.author,
        customer: message.authorCustomer,
        employee: message.authorEmployee,
    });

    const isVideoAppointment =
        message?.order?.appointment?.type === AppointmentTypes.VideoChat;
    if (isVideoAppointment) {
        title = t('screens.chat.appointment.types.video-chat');
    }

    // const order: Order | undefined | null = message.order;
    // const isPaymentRequest = order && !order.canceledAt && !order.paidAt;
    // if (isPaymentRequest) {
    //   title = t('screens.chat.payment.request');
    // }

    return <span className={styles.title}>{title}</span>;
};

export default ChatMessageTitle;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NavBackButton from '../../../../components/navBackButton/navBackButton';
import { useObservable } from '../../../../hooks/useObservable';
import { useScreenHasLessWidthThan } from '../../../../hooks/useScreenSize';
import RxElectron from '../../../../rxjs/RxElectron';
import AnimalChatRoutes from '../../../../utils/routing';
import { isElectron } from '../../../../__shared/electron';
import ChangePassword from '../changePassword/changePassword';
import UserProfileForm from '../userProfileForm/userProfileForm';
import styles from './userProfileMain.module.scss';

interface UserProfileMainProps {
    subRoute?: string;
    setShowMain?(show: boolean): void;
}

const UserProfileMain: React.FC<UserProfileMainProps> = ({
    subRoute,
    setShowMain,
}) => {
    const { t } = useTranslation();
    const isWindows = useObservable(RxElectron.isWindows$, true);
    const [titleKey, setTitleKey] = useState<string>('');

    useEffect(() => {
        switch (subRoute) {
            case AnimalChatRoutes.userProfileSub.profile:
                setTitleKey('screens.userProfile.navigation.entries.profile');
                break;

            default:
                setTitleKey('screens.userProfile.navigation.entries.profile');
                break;
        }
    }, [subRoute]);

    const screenHasLessWidthThanXxs = useScreenHasLessWidthThan('xxs');

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                {screenHasLessWidthThanXxs && (
                    <NavBackButton
                        position={{
                            top: 14,
                            left: isElectron() && !isWindows ? 74 : 14,
                        }}
                        onClick={() =>
                            setShowMain ? setShowMain(false) : null
                        }
                    />
                )}
                <div
                    style={
                        screenHasLessWidthThanXxs && isElectron() && !isWindows
                            ? { paddingLeft: '60px' }
                            : {}
                    }>
                    {t(titleKey)}
                </div>
            </div>
            <div className={styles.content}>
                {subRoute === AnimalChatRoutes.userProfileSub.profile && (
                    <UserProfileForm />
                )}
                {subRoute ===
                    AnimalChatRoutes.userProfileSub.changePassword && (
                    <ChangePassword />
                )}
            </div>
        </div>
    );
};

export default UserProfileMain;

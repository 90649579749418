import { gql } from '@apollo/client';
import { User } from '../dto';
import { UserFragments } from '../fragments';

const FullUpdatePhoneNumber = gql`
    mutation UpdatePhoneNumber(
        $phoneNumber: String!
        $activationCode: String!
    ) {
        updatePhoneNumber(
            phoneNumber: $phoneNumber
            activationCode: $activationCode
        ) {
            ...FullUser
        }
    }
    ${UserFragments.FullUser}
`;

export interface UpdatePhoneNumberResponse {
    updatePhoneNumber: User;
}

export const UpdatePhoneNumberMutation = {
    FullUpdatePhoneNumber,
};

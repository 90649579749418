import { isNumber, isString } from '../../__shared/common';
import React, { useEffect, useRef } from 'react';
import { CypressProp } from '../../types/cypressProps';
import styles from './input.module.scss';
import InputAdornment, { InputAdornmentProps } from './inputAdornment';

export interface InputProps {
    type?: 'text' | 'password' | 'number' | 'time';
    value?: string | number;
    inputAdornment?: InputAdornmentProps;
    className?: string;
    inputClassName?: string;
    prefixTextClassName?: string;
    errorClassName?: string;
    placeholder?: string;
    prefixText?: string;
    limit?: number;
    textarea?: boolean;
    disabled?: boolean;
    multiline?: boolean;
    id: string;
    onChange?: (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => void;
    onChangeText?: (text: string) => void;
    onPaste?: (event: React.ClipboardEvent) => void;
}

const Input: React.FC<InputProps & CypressProp> = ({
    type = 'text',
    value,
    id,
    className,
    inputClassName,
    prefixTextClassName,
    errorClassName,
    placeholder,
    disabled = false,
    multiline = false,
    textarea = false,
    limit,
    onChange,
    onChangeText,
    onPaste,
    inputAdornment,
    dataCy,
    prefixText,
}) => {
    const textareaDivRef = useRef<HTMLDivElement>(null);
    let inputStyle;

    switch (type) {
        case 'number':
            inputStyle = styles.number;
            break;

        default:
            inputStyle = styles.text;
            break;
    }

    const errorClass = `${styles.error} ${errorClassName ?? ''}`.trim();
    const hasError = isNumber(limit) && isString(value) && value.length > limit;

    //#region handle value binding for div editablecontent
    // there is an issue with the caret (cursor) position
    // it resets to the start if the value updates
    // therefore we only update the div value on start and on clear (empty value)
    useEffect(() => {
        if (textareaDivRef?.current) {
            textareaDivRef.current.innerText = value as string;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [textareaDivRef]);

    useEffect(() => {
        if (textareaDivRef?.current && (!value || value === '')) {
            textareaDivRef.current.innerText = '';
        }
    }, [textareaDivRef, value]);
    //#endregion

    useEffect(() => {
        const handleKeyUp = () => {
            if (onChangeText) {
                onChangeText(textareaDivRef?.current?.innerText ?? '');
            }
        };
        let ref: HTMLDivElement;
        if (textareaDivRef?.current) {
            ref = textareaDivRef.current;
            textareaDivRef.current.addEventListener('keyup', handleKeyUp);
        }
        return () => {
            ref?.removeEventListener('keyup', handleKeyUp);
        };
    }, [textareaDivRef, onChangeText]);

    let inputAdornmentElement = undefined;
    if (inputAdornment) {
        inputAdornmentElement = inputAdornment && (
            <InputAdornment {...inputAdornment} />
        );

        inputStyle = `${inputStyle} ${
            inputAdornment.position === 'start'
                ? styles.inputAdornmentStart
                : styles.inputAdornmentEnd
        }`;
    }

    if (inputClassName) {
        inputStyle = `${inputStyle} ${inputClassName}`;
    }

    useEffect(() => {
        const handlePasteEvent = (e: ClipboardEvent) => {
            e.preventDefault();
            const text = e.clipboardData?.getData('text/plain');
            if (text !== undefined && textareaDivRef.current) {
                const range = document.getSelection()?.getRangeAt(0);
                if (range) {
                    range.deleteContents();

                    const textNode = document.createTextNode(text);
                    range.insertNode(textNode);
                    range.selectNodeContents(textNode);
                    range.collapse(false);

                    const selection = window.getSelection();
                    selection?.removeAllRanges();
                    selection?.addRange(range);
                }
            }
        };
        let ref: HTMLDivElement;
        if (textareaDivRef.current) {
            ref = textareaDivRef.current;
            ref.addEventListener('paste', handlePasteEvent);
        }

        return () => {
            ref?.removeEventListener('paste', handlePasteEvent);
        };
    }, [textareaDivRef]);

    let containerClass = styles.container;
    if (className) {
        containerClass += ` ${className}`;
    }

    if (multiline) {
        return (
            <div className={containerClass}>
                <div className={styles.inputWrapper}>
                    {inputAdornment?.position === 'start' &&
                        inputAdornmentElement}
                    <div
                        className={`${inputStyle} ${styles.textarea}`}
                        role="textbox"
                        ref={textareaDivRef}
                        contentEditable
                        id={id}
                        placeholder={placeholder}
                        suppressContentEditableWarning
                        data-cy={dataCy}
                    />
                    {inputAdornment?.position === 'end' &&
                        inputAdornmentElement}
                </div>
            </div>
        );
    }

    if (textarea) {
        return (
            <div>
                <textarea
                    data-cy={dataCy}
                    className={`${styles.textarea} ${containerClass}`}
                    id={id}
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder}
                />

                {hasError && (
                    <p className={errorClass}>
                        {value.length}/{limit}
                    </p>
                )}
            </div>
        );
    }

    return (
        <div className={containerClass}>
            <div className={styles.inputWrapper}>
                {!!prefixText && (
                    <span className={prefixTextClassName ?? ''}>
                        {prefixText}
                    </span>
                )}
                {inputAdornment?.position === 'start' && inputAdornmentElement}
                <input
                    data-cy={dataCy}
                    type={type}
                    className={inputStyle}
                    id={id}
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                    placeholder={placeholder}
                    onPaste={onPaste}
                />
                {inputAdornment?.position === 'end' && inputAdornmentElement}
            </div>
            {hasError && (
                <p className={errorClass}>
                    {value.length}/{limit}
                </p>
            )}
        </div>
    );
};

export default Input;

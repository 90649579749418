import { gql } from '@apollo/client';
import { CompanyFragments } from '../fragments/';
import { Company } from '../dto';

const PublicCompany = gql`
    query GetCompanyByReferenceNumber($referenceNumber: Int!) {
        getCompanyByReferenceNumber(referenceNumber: $referenceNumber) {
            ...PublicCompany
        }
    }
    ${CompanyFragments.PublicCompany}
`;

export type GetCompanyByReferenceNumberData = {
    getCompanyByReferenceNumber: Company;
};

export const GetCompanyByReferenceNumber = {
    PublicCompany,
};

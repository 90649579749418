import { useMutation } from '@apollo/client';
import { useState } from 'react';
import {
    UpdateActivationCodeEmailData,
    UpdateActivationCodeSmsData,
    UserMutations,
} from '../../../../graphql/mutations/userMutations';
import { useAlert } from '../../../../hooks/useAlert';
import { useErrorAlert } from '../../../../hooks/useErrorAlert';
import logUtil from '../../../../utils/logUtil';
import {
    MutationUpdateActivationCodeEmailArgs,
    MutationUpdateActivationCodeSmsArgs,
} from '../../../../__shared/graphql';

interface UseSendActivationCodes {
    sendEmailActivationCode: (email: string) => void;
    sendSmsActivationCode: (phoneNumber: string) => void;
    loading: boolean;
}

const useSendActivationCodes = (): UseSendActivationCodes => {
    const [loading, setLoading] = useState(false);
    const { alertGraphQlError, alertError } = useErrorAlert();
    const alert = useAlert();

    const [updateActivationCodeEmailMutation] = useMutation<
        UpdateActivationCodeEmailData,
        MutationUpdateActivationCodeEmailArgs
    >(UserMutations.UpdateActivationCodeEmail);

    const [updateActivationCodeSmsMutation] = useMutation<
        UpdateActivationCodeSmsData,
        MutationUpdateActivationCodeSmsArgs
    >(UserMutations.UpdateActivationCodeSms);

    const sendEmailActivationCode = async (email: string) => {
        setLoading(true);
        try {
            const response = await updateActivationCodeEmailMutation({
                variables: {
                    email,
                },
            });
            const { data, errors } = response;
            if (errors?.length) {
                alertGraphQlError(errors);
                return;
            }
            if (data?.updateActivationCodeEmail) {
                alert.success(
                    'screens.business.userProfile.emailCodeSendMessage',
                );
            }
        } catch (error) {
            logUtil.log('Error while updating email activation code', error);
            alertError(error);
        } finally {
            setLoading(false);
        }
    };

    const sendSmsActivationCode = async (phoneNumber: string) => {
        setLoading(true);
        try {
            const response = await updateActivationCodeSmsMutation({
                variables: {
                    phoneNumber,
                },
            });
            const { data, errors } = response;
            if (errors?.length) {
                alertGraphQlError(errors);
                return;
            }
            if (data?.updateActivationCodeSms) {
                alert.success(
                    'screens.business.userProfile.smsCodeSendMessage',
                );
            }
        } catch (error) {
            logUtil.log('Error while updating email activation code', error);
            alertError(error);
        } finally {
            setLoading(false);
        }
    };

    return {
        sendEmailActivationCode,
        sendSmsActivationCode,
        loading,
    };
};

export default useSendActivationCodes;

import { gql } from '@apollo/client';

const FullServiceHours = gql`
    fragment FullServiceHours on ServiceHours {
        id
        companyId
        dayOfTheWeek
        from
        to
        isEmergencyService
        createdAt
        createdBy
        updatedAt
        updatedBy
    }
`;

export const ServiceHoursFragments = { FullServiceHours };

import { BehaviorSubject } from 'rxjs';
import logUtil from '../utils/logUtil';
import { isWindowsOs } from '../__shared/electron';

const isWindowsSubject = new BehaviorSubject<boolean>(true);
const isWindows$ = isWindowsSubject.asObservable();

const setIsWindows = (isWindows: boolean): void => {
    logUtil.log(`Setting isWindows: ${isWindows}`);
    isWindowsSubject.next(isWindows);
};

const setInitialIsWindows = async () => {
    const isWindows = await isWindowsOs();
    setIsWindows(isWindows);
};
setInitialIsWindows();

const RxElectron = {
    setIsWindows,
    isWindows$,
};

export default RxElectron;

import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NavBackButton from '../../../../components/navBackButton/navBackButton';
import { useAlert } from '../../../../hooks/useAlert';
import { useObservable } from '../../../../hooks/useObservable';
import { useScreenHasLessWidthThan } from '../../../../hooks/useScreenSize';
import RxElectron from '../../../../rxjs/RxElectron';
import { mergeArraysById } from '../../../../utils/arrayUtils';
import logUtil from '../../../../utils/logUtil';
import { isElectron } from '../../../../__shared/electron';
import {
    Company,
    GetLicencesToCompaniesData,
    LicenceToCompany,
    LicenceToCompanyQueries,
    QueryGetLicencesToCompaniesArgs,
} from '../../../../__shared/graphql';
import SplitScreen from '../main/splitScreen';
import SubscriptionCreate, {
    LicenceTypeTranslations,
} from './subscriptionCreate';
import SubscriptionDetail from './subscriptionDetail';
import SubscriptionList from './subscriptionList';
import styles from './subscriptions.module.scss';

interface SubscriptionsProps {
    company?: Company;
}

const Subscriptions: React.FC<SubscriptionsProps> = ({ company }) => {
    const isWindows = useObservable(RxElectron.isWindows$, true);
    const [subscriptions, setSubscriptions] = useState<LicenceToCompany[]>([]);
    const [subscription, setSubscription] = useState<LicenceToCompany>();
    const alert = useAlert();
    const { t } = useTranslation();

    const { data, loading, refetch } = useQuery<
        GetLicencesToCompaniesData,
        QueryGetLicencesToCompaniesArgs
    >(
        LicenceToCompanyQueries.GetLicencesToCompaniesQueries
            .FullLicenceToCompany,
        {
            variables: {
                filter: {
                    companyId: company?.id,
                    active: true,
                },
            },
        },
    );

    const onUpdate = (newSubscription: LicenceToCompany) => {
        alert.success('common.alerts.savedChanges');
        setSubscriptions(prev => mergeArraysById(prev, [newSubscription]));
        setSubscription(newSubscription);
    };

    const onUpgrade = (
        oldSubscription: LicenceToCompany,
        newSubscription: LicenceToCompany,
        shouldReload?: boolean,
    ) => {
        alert.success('common.alerts.savedChanges');
        if (shouldReload) {
            refetch();
            return;
        }
        setSubscriptions(prev =>
            mergeArraysById(
                [newSubscription],
                prev.filter(s => s.id !== oldSubscription.id),
            ),
        );
        setSubscription(newSubscription);
    };

    useEffect(() => {
        if (data?.getLicencesToCompanies?.length) {
            logUtil.log(
                `Got Subscriptions: ${data.getLicencesToCompanies.length}`,
            );
            setSubscriptions(data.getLicencesToCompanies);
        }
    }, [data]);

    const screenHasLessWidthThanXxs = useScreenHasLessWidthThan('xxs');
    const screenHasLessWidthThanSm = useScreenHasLessWidthThan('sm');
    const [show, setShow] = useState(false);

    const mainStyle = screenHasLessWidthThanSm
        ? show
            ? { left: '0px', width: '100%' }
            : { left: '100vw' }
        : {};

    const right = (
        <div className={styles.splitScreenRightContainer} style={mainStyle}>
            <div className={styles.header}>
                {screenHasLessWidthThanSm && (
                    <>
                        <NavBackButton
                            position={{
                                top: 14,
                                left:
                                    screenHasLessWidthThanXxs &&
                                    isElectron() &&
                                    !isWindows
                                        ? 74
                                        : 14,
                            }}
                            onClick={() => setShow(false)}
                        />
                        <div
                            style={
                                screenHasLessWidthThanXxs &&
                                isElectron() &&
                                !isWindows
                                    ? { paddingLeft: '60px' }
                                    : {}
                            }>
                            {subscription && subscription.licence
                                ? t(
                                      LicenceTypeTranslations[
                                          subscription.licence.type
                                      ],
                                  )
                                : 'Abonnement hinzufügen'}
                        </div>
                    </>
                )}
            </div>
            <div className={styles.content}>
                {subscription ? (
                    <SubscriptionDetail
                        subscription={subscription}
                        onUpdate={onUpdate}
                        onUpgrade={onUpgrade}
                    />
                ) : (
                    <SubscriptionCreate onLicenceAdded={() => refetch()} />
                )}
            </div>
        </div>
    );

    return (
        <SplitScreen
            left={
                <SubscriptionList
                    subscriptions={subscriptions}
                    loading={loading}
                    onClick={sub => {
                        setSubscription(sub);
                        setShow(true);
                    }}
                    subscription={subscription}
                />
            }
            right={right}
        />
    );
};

export default Subscriptions;

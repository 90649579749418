import {
    OnUserUpdateData,
    ON_USER_UPDATE_WITH_INVITATIONS_AND_LICENCES,
} from '../__shared/graphql';
import { useSubscription } from '@apollo/client';
import React from 'react';
import RxUserProfile from '../rxjs/RxUserProfile';
import { logSubscription } from '../utils/loggingUtil';

interface UserDataHandlerProps {
    userId: number;
}

const UserDataHandler: React.FC<UserDataHandlerProps> = ({ userId }) => {
    useSubscription<OnUserUpdateData>(
        ON_USER_UPDATE_WITH_INVITATIONS_AND_LICENCES,
        {
            variables: { userId },
            shouldResubscribe: true,
            onSubscriptionData: ({ subscriptionData: { data } }) => {
                logSubscription(
                    'ON_USER_UPDATE_WITH_INVITATIONS_AND_LICENCES',
                    data,
                );
                const user = data?.onUserUpdate;
                if (!user) {
                    return;
                }
                RxUserProfile.setUser(user);
                if (user.address) {
                    RxUserProfile.setAddress(user.address);
                }
            },
        },
    );

    return <></>;
};

export default UserDataHandler;

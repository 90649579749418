import React, { useState } from 'react';
import { FadeLoader } from 'react-spinners';
import ApiVideoPlayer from '../apiVideoPlayer/apiVideoPlayer';
import { PreviewItemMediaProps } from '../previewItemMedia/previewItemMedia';
import styles from './previewItemVideo.module.scss';

interface PreviewItemVideoProps extends PreviewItemMediaProps {}

const CustomerProfileVideoPreviewItem: React.FC<PreviewItemVideoProps> = ({
    message,
    onClick,
    className,
}) => {
    const [loading, setLoading] = useState<boolean>();
    const [_, setError] = useState<unknown | undefined>(undefined);

    const file = message?.files?.length ? message?.files[0] : undefined;

    if (!file) {
        return null;
    }

    const hasClickFunction = typeof onClick === 'function';

    const containerClass = `${styles.container} ${styles.videoContainer} ${
        loading ? styles.loadingSpinner : ''
    } ${hasClickFunction ? styles.clickable : ''}`.trim();

    const handleClick = () => {
        if (hasClickFunction) {
            onClick(message);
        }
    };

    return (
        <div
            className={`${containerClass} ${className ?? ''}`.trim()}
            onClick={handleClick}>
            {loading && <FadeLoader color={'gray'} />}
            <ApiVideoPlayer
                key={message.uuid}
                fileDescription={file}
                onLoadingChanged={setLoading}
                onError={setError}
                nativeProps={
                    loading
                        ? {
                              style: {
                                  display: 'none',
                              },
                          }
                        : {
                              pip: false,
                              controls: false,
                              height: '',
                              width: '',
                          }
                }
            />
        </div>
    );
};

export default CustomerProfileVideoPreviewItem;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextButton from '../../../../../components/textButton/textButton';
import useSendActivationCodes from '../../../../business/views/profile/useSendActivationCodes';
import styles from './emailActivationCodeForm.module.scss';
import { isValidEmailAddress } from '../../../../../utils/validationUtil';
import { Limits } from '../../../../../utils/inputLimits';
import Input from '../../../../../components/input/input';
import { useAlert } from '../../../../../hooks/useAlert';

interface EmailActivationCodeForm {
    email: string;
    onClose: () => void;
    onSave: (email: string, activationCode: string) => void;
}

const EmailActivationCodeForm: React.FC<EmailActivationCodeForm> = ({
    email,
    onClose,
    onSave,
}) => {
    const { t } = useTranslation();
    const alert = useAlert();

    const { sendEmailActivationCode } = useSendActivationCodes();

    const [emailActivationCode, setEmailActivationCode] = useState('');
    const [emailForCurrentCode, setEmailForCurrentCode] = useState<
        string | undefined
    >(undefined);
    const validEmailAddressInput = isValidEmailAddress({
        email: email,
        limit: Limits.Email,
    });
    const canSendEmailCode = validEmailAddressInput;

    return (
        <div className={styles.container}>
            <TextButton
                text={t('screens.business.userProfile.sendCode')}
                onClick={() => {
                    sendEmailActivationCode(email);
                    setEmailForCurrentCode(email);
                }}
                className={styles.codeButton}
                disabled={!canSendEmailCode}
            />
            <p>{t('screens.business.userProfile.emailText')}</p>
            <Input
                value={emailActivationCode}
                className={styles.input}
                onChange={e => setEmailActivationCode(e.target.value)}
                id={`user-profile-email-code`}
                placeholder={t('screens.business.userProfile.emailCode')}
            />

            <div className={styles.actions}>
                <TextButton
                    style="secondary"
                    className={`${styles.button} ${styles.secondaryButton}`}
                    text={t('common.buttons.cancel')}
                    onClick={() => {
                        setEmailActivationCode('');
                        onClose();
                    }}
                />
                <TextButton
                    className={styles.button}
                    disabled={
                        !isValidEmailAddress({
                            email: email,
                            limit: Limits.Email,
                        })
                    }
                    text={t('common.buttons.save')}
                    onClick={() => {
                        if (emailForCurrentCode === email) {
                            onSave(email, emailActivationCode);
                        } else {
                            alert.error(
                                t('screens.business.userProfile.codeIsInvalid'),
                            );
                        }
                    }}
                />
            </div>
        </div>
    );
};

export default EmailActivationCodeForm;

import { getProfilePicture } from '../../__shared/common';
import React, { useState } from 'react';
import { FadeLoader } from 'react-spinners';
import ApiImage from '../apiImage/apiImage';
import { PreviewItemMediaProps } from '../previewItemMedia/previewItemMedia';
import styles from './previewItemImage.module.scss';

interface PreviewItemImageProps extends PreviewItemMediaProps {}

const PreviewItemImage: React.FC<PreviewItemImageProps> = ({
    message,
    onClick,
    className,
}) => {
    const [loading, setLoading] = useState<boolean>();
    const [_, setError] = useState<unknown | undefined>(undefined);

    const file = message?.files?.length ? message?.files[0] : undefined;

    if (!file) {
        return null;
    }

    const hasClickFunction = typeof onClick === 'function';

    const containerClass = `${styles.container} ${
        loading ? styles.loadingSpinner : ''
    } ${hasClickFunction ? styles.clickable : ''}`.trim();

    const handleClick = () => {
        if (hasClickFunction) {
            onClick(message);
        }
    };

    return (
        <div
            className={`${containerClass} ${className ?? ''}`.trim()}
            onClick={handleClick}>
            {loading && <FadeLoader color={'gray'} />}
            <ApiImage
                onLoadingChanged={setLoading}
                onError={setError}
                key={message.uuid}
                fileDescription={file}
                fileDescriptionWithOptimalResolution={getProfilePicture(file)}
                nativeProps={
                    loading
                        ? { className: styles.imageHidden }
                        : { className: styles.image }
                }
            />
        </div>
    );
};

export default PreviewItemImage;

import { gql } from '@apollo/client';

const FullResponse = gql`
    mutation SoftDeleteEmployee($employeeId: Int!) {
        softDeleteEmployee(employeeId: $employeeId)
    }
`;

export interface SoftDeleteEmployeeData {
    softDeleteEmployee: number;
}

export const SoftDeleteEmployeeMutation = {
    FullResponse,
};

import React, {
    MutableRefObject,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useInViewport } from 'react-in-viewport';
import CtxMenu from '../../../../../components/ctxMenu/ctxMenu';
import CtxMenuEntry from '../../../../../components/ctxMenu/ctxMenuEntry';
import Icon from '../../../../../components/icon/icon';
import { DASHBOARD_VERSION } from '../../../../../environment/env';
import { useObservable } from '../../../../../hooks/useObservable';
import RxAuthentication from '../../../../../rxjs/RxAuthentication';
import {
    isApplicationType,
    isImageType,
    isVideoType,
} from '../../../../../utils/fileUtils';
import logUtil from '../../../../../utils/logUtil';
import { buildNameFromUser } from '../../../../../utils/nameUtils';
import {
    ChatMessageTypes,
    createFileNameForFileDownload,
    DateUtil,
    SystemChatMessageTypes,
    VersionHeaders,
} from '../../../../../__shared/common';
import { saveFileAs } from '../../../../../__shared/electron';
import {
    ChatMessage as ChatMessageType,
    Participant,
} from '../../../../../__shared/graphql';
import styles from './chatMessage.module.scss';
import ChatMessageActions from './chatMessageActions/chatMessageActions';
import ChatMessageApplicationFilePreview from './chatMessageApplicationFilePreview/chatMessageApplicationFilePreview';
import ChatMessageCheckmark from './chatMessageCheckmark/chatMessageCheckmark';
import ChatMessageDateBadge from './chatMessageDateBadge/chatMessageDateBadge';
import ChatMessageMediaFilePreview from './chatMessageMediaFilePreview/chatMessageMediaFilePreview';
import ChatMessageReference from './chatMessageReference/chatMessageReference';
import ChatMessageText from './chatMessageText/chatMessageText';
import ChatMessageTitle from './chatMessageTitle/chatMessageTitle';

export interface ChatMessageProps {
    message: ChatMessageType;
    userId: number;
    id: string;
    authorParticipant?: Participant;
    hasHeaderPaddingForDateBadges?: boolean;
    onChatMessageReplyOptionClicked: (chatMessage: ChatMessageType) => void;
    onShowImage: () => void;
    onWasVisible?: (message: ChatMessageType, visible: boolean) => void;
    onVisibilityChanged?: (message: ChatMessageType, visible: boolean) => void;
}

const isSystemMessage = (messageType?: string | null): boolean => {
    if (!messageType) {
        return false;
    }
    return SystemChatMessageTypes.some(type => type === messageType);
};

interface GetSystemMessageTextParams {
    message: ChatMessageType;
    t: TFunction;
    userId?: number;
}

export const getSystemMessageText = ({
    message,
    t,
    userId,
}: GetSystemMessageTextParams): string => {
    let name = message.text;
    switch (message.type) {
        case ChatMessageTypes.AutoCreated:
            return t('screens.chat.systemMessages.autoChatCreated');
        case ChatMessageTypes.ChatCreated:
            if (message.author?.firstname || message.author?.lastname) {
                name = buildNameFromUser(message.author);
            }
            return t('screens.chat.systemMessages.chatCreated', {
                name: name,
            });
        case ChatMessageTypes.UserJoinedTheChat:
            if (userId === message.createdBy) {
                return t('screens.chat.systemMessages.youJoined');
            }
            return t('screens.chat.systemMessages.userJoined', {
                name: name,
            });
        case ChatMessageTypes.UserWasAddedToChat:
            if (userId === message.createdBy) {
                return t('screens.chat.systemMessages.youWereAdded');
            }
            return t('screens.chat.systemMessages.userWasAdded', {
                name: name,
            });
        case ChatMessageTypes.UserLeftTheChat:
            if (userId === message.createdBy) {
                return t('screens.chat.systemMessages.youLeft');
            }
            return t('screens.chat.systemMessages.userLeft', {
                name: name,
            });
        case ChatMessageTypes.UserWasRemovedFromChat:
            if (userId === message.createdBy) {
                return t('screens.chat.systemMessages.youWereRemoved');
            }
            return t('screens.chat.systemMessages.userWasRemoved', {
                name: name,
            });

        default:
            throw new Error(`Unknown system message type: ${message.type}`);
    }
};

const ChatMessage: React.FC<ChatMessageProps> = ({
    message,
    userId,
    authorParticipant,
    onShowImage,
    onWasVisible,
    onVisibilityChanged,
    onChatMessageReplyOptionClicked,
    id,
    hasHeaderPaddingForDateBadges = true,
}) => {
    const { t } = useTranslation();
    const ref = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;
    const accessToken = useObservable(RxAuthentication.accessToken$);
    const { inViewport } = useInViewport(ref);
    const [wasVisible, setWasVisible] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [showChatMessageOptions, setShowChatMessageOptions] = useState(false);
    // Still necessary??
    // const [chatMessage, setChatMessage] = useState<ChatMessageType>();

    // useEffect(() => {
    //     setChatMessage(message);
    // }, [message]);

    useEffect(() => {
        if (!wasVisible && inViewport) {
            setWasVisible(true);
            if (typeof onWasVisible === 'function') {
                onWasVisible(message, true);
            }
        }
    }, [onWasVisible, message, inViewport, wasVisible]);

    useEffect(() => {
        if (typeof onVisibilityChanged !== 'function') {
            return;
        }
        if (inViewport !== isVisible) {
            onVisibilityChanged(message, inViewport);
        }
        setIsVisible(inViewport);
    }, [onVisibilityChanged, message, inViewport, isVisible]);

    let messageClass = styles.message;
    const isMessageFromAnotherEmployee =
        authorParticipant?.employee?.company?.id;
    if (isMessageFromAnotherEmployee) {
        messageClass += ` ${styles.messageFromAnotherEmployee}`;
    }

    const isOwnMessage = message.author?.id === userId;
    if (isOwnMessage) {
        messageClass += ` ${styles.ownMessage}`;
    }

    const fileDescription = message?.files?.length ? message.files[0] : null;

    // Note: Memorized chat message component to avoid rerendering on visibility change
    const memorizedChatMessage = useMemo(() => {
        logUtil.log('memorizedChatMessage changed');

        const hasFile = !!fileDescription;
        const hasImage = hasFile && isImageType(fileDescription?.fileType);
        const hasVideo = hasFile && isVideoType(fileDescription?.fileType);
        const hasDocument =
            hasFile && isApplicationType(fileDescription?.fileType);

        return (
            <div data-cy={`chat-message-${message.id}`}>
                <ChatMessageTitle message={message} />
                {message?.repliedChatMessage?.uuid && (
                    <ChatMessageReference
                        id={`replied-message-${message?.repliedChatMessage?.uuid}`}
                        message={message?.repliedChatMessage}
                        userId={userId}
                    />
                )}
                {fileDescription && (hasImage || hasVideo) && (
                    <div className={styles.mediaMessage}>
                        <ChatMessageMediaFilePreview
                            onShowImage={onShowImage}
                            fileDescription={fileDescription}
                        />
                    </div>
                )}
                {fileDescription && hasDocument && (
                    <ChatMessageApplicationFilePreview
                        fileDescription={fileDescription}
                    />
                )}
                <ChatMessageText message={message} />
                <ChatMessageActions message={message} />
                <div className={styles.footer}>
                    <span className={styles.timestamp}>
                        {DateUtil.toHHMM(message.createdAt)}
                    </span>
                    {(isOwnMessage || isMessageFromAnotherEmployee) && (
                        <ChatMessageCheckmark message={message} />
                    )}
                    {!isOwnMessage && !message.isViewedByMe && (
                        <span className={styles.unreadMessageBubble}></span>
                    )}
                </div>
            </div>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [message]);

    const memorizedChatMessageOptions = useMemo(() => {
        logUtil.log('memorizedChatMessageOptions changed');

        const hasFileDownloadOption = !!fileDescription;
        const hasReplyToMessageOption = false; // TODO: Enable if app has reply feature!
        const hasOptions = hasFileDownloadOption || hasReplyToMessageOption;

        if (!hasOptions) {
            return null;
        }

        return (
            <CtxMenu
                offsets={{ right: 4, top: 14 }}
                button={<Icon size={20} name="chevron-down-outline" />}
                className={styles.contextMenuContainer}>
                {!!hasFileDownloadOption && (
                    <CtxMenuEntry
                        icon={{
                            iconName: 'download-outline',
                        }}
                        text={t('common.download')}
                        onClick={() => {
                            if (fileDescription.safeUrl) {
                                const hasDocument = isApplicationType(
                                    fileDescription?.fileType,
                                );
                                const fileName =
                                    hasDocument && fileDescription?.name
                                        ? fileDescription?.name
                                        : createFileNameForFileDownload(
                                              'AnimalChat Pro',
                                              fileDescription?.fileType ?? '',
                                          );
                                saveFileAs({
                                    url: fileDescription.safeUrl,
                                    accessToken: accessToken ?? '',
                                    fileName,
                                    fileType: fileDescription.fileType ?? '',
                                    headers: {
                                        [VersionHeaders.AnimalchatDashboardVersion]:
                                            DASHBOARD_VERSION,
                                    },
                                });
                            }
                        }}
                    />
                )}
                {hasReplyToMessageOption && (
                    <CtxMenuEntry
                        icon={{
                            iconName: 'arrow-undo-outline',
                        }}
                        text={t('common.buttons.reply')}
                        onClick={() => {
                            onChatMessageReplyOptionClicked(message);
                        }}
                    />
                )}
            </CtxMenu>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileDescription, showChatMessageOptions]);

    if (message.type === ChatMessageTypes.MsgDateBadge) {
        return (
            <ChatMessageDateBadge
                date={message.createdAt}
                hasHeaderPadding={hasHeaderPaddingForDateBadges}
            />
        );
    }
    if (isSystemMessage(message.type)) {
        return (
            <div className={styles.systemMessage} id={id} ref={ref}>
                <span>{getSystemMessageText({ message, t, userId })}</span>
            </div>
        );
    }

    if (message.type === ChatMessageTypes.AutoCreated) {
        return (
            <div className={styles.systemMessage} id={id} ref={ref}>
                <span>{t('screens.chat.systemMessages.autoChatCreated')}</span>
            </div>
        );
    }

    return (
        <div
            key={message.uuid}
            id={id}
            className={messageClass}
            ref={ref}
            onMouseEnter={() => setShowChatMessageOptions(true)}
            onMouseLeave={() => setShowChatMessageOptions(false)}>
            {memorizedChatMessageOptions}
            {memorizedChatMessage}
        </div>
    );
};

export default ChatMessage;

import { gql } from '@apollo/client';
import { Chat } from '../dto';
import { ChatFragments } from '../fragments';

export const ON_CHAT_UPDATE_FOR_USER = gql`
    subscription OnChatUpdateForUser($userId: Int!) {
        onChatUpdateForUser(userId: $userId) {
            ...FullChat
        }
    }
    ${ChatFragments.FullChat}
`;

export interface OnChatUpdateForUserData {
    onChatUpdateForUser: Chat;
}

import {
    Company,
    GetCompaniesForUserData,
    GetCompaniesForUserQuery,
    OnCompanyUpdateData,
    ON_COMPANY_UPDATE,
} from '../__shared/graphql';
import { useQuery, useSubscription } from '@apollo/client';
import React from 'react';
import { useAlert } from '../hooks/useAlert';
import RxCompany from '../rxjs/RxCompany';
import { logSubscription } from '../utils/loggingUtil';

interface CompanyDataHandlerProps {
    userId: number;
    companyId: number;
}

const CompanyDataHandler: React.FC<CompanyDataHandlerProps> = ({ userId }) => {
    const alert = useAlert();

    useQuery<GetCompaniesForUserData>(GetCompaniesForUserQuery.FullResponse, {
        onCompleted: data => {
            if (!data.getCompaniesForUser.length) {
                return;
            }
            RxCompany.setCompanies(data.getCompaniesForUser);
            const firstCompany = data.getCompaniesForUser[0];
            if (firstCompany?.id) {
                RxCompany.setCurrentCompany(firstCompany);
            }
        },
        onError: _error => {
            alert.error('common.alerts.defaultError');
        },
    });

    useSubscription<OnCompanyUpdateData>(ON_COMPANY_UPDATE, {
        variables: { userId },
        onSubscriptionData: ({ subscriptionData: { data } }) => {
            logSubscription('ON_COMPANY_UPDATE', data);
            if (!data?.onCompanyUpdate) {
                return;
            }
            const company = data?.onCompanyUpdate as Company;
            if (company.id) {
                RxCompany.setCurrentCompany(company);
            }
        },
    });

    return <></>;
};

export default CompanyDataHandler;

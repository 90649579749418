import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../components/button/button';
import Input from '../../../../../components/input/input';
import { Limits } from '../../../../../utils/inputLimits';
import Card from '../../../components/card/card';
import CardHeader from '../../../components/cardHeader/cardHeader';
import { Address, isValidAddress } from './createCompany';
import styles from './createCompanyAddress.module.scss';

interface CreateCompanyAddressProps {
    formData: Address;
    onBack: (data: Address) => void;
    // onCreate: (data: Address) => void;
    onNext: (data: Address) => void;
}

const CreateCompanyAddress: React.FC<CreateCompanyAddressProps> = ({
    formData,
    onBack,
    onNext,
    // onCreate,
}) => {
    const [form, setForm] = useState<Address>(formData);
    const [isValid, setIsValid] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        setIsValid(isValidAddress(form));
    }, [form]);

    const onChange = (key: string, value: string) =>
        setForm(prev => ({ ...prev, [key]: value }));

    return (
        <Card>
            <CardHeader
                title={t('screens.business.company.common.title')}
                onBack={() => onBack(form)}
            />
            <div className={styles.row}>
                <Input
                    value={form.streetName}
                    onChange={e => onChange('streetName', e.target.value)}
                    placeholder={`${t(
                        'screens.business.company.address.streetName',
                    )}*`}
                    id="create-company-streetName-input"
                    dataCy="create-company-streetName-input"
                    limit={Limits.StreetName}
                />
            </div>
            <div className={styles.row}>
                <Input
                    value={form.houseNumber}
                    onChange={e => onChange('houseNumber', e.target.value)}
                    placeholder={`${t(
                        'screens.business.company.address.houseNumber',
                    )}*`}
                    id="create-company-houseNumber-input"
                    dataCy="create-company-houseNumber-input"
                    limit={Limits.HouseNumber}
                />
            </div>
            <div className={styles.row}>
                <Input
                    value={form.city}
                    onChange={e => onChange('city', e.target.value)}
                    placeholder={`${t(
                        'screens.business.company.address.city',
                    )}*`}
                    id="create-company-city-input"
                    dataCy="create-company-city-input"
                    limit={Limits.City}
                />
            </div>
            <div className={styles.row}>
                <Input
                    value={form.zipCode}
                    onChange={e => onChange('zipCode', e.target.value)}
                    placeholder={`${t(
                        'screens.business.company.address.zipCode',
                    )}*`}
                    id="create-company-zipCode-input"
                    dataCy="create-company-zipCode-input"
                    limit={Limits.ZipCode}
                />
            </div>
            <div className={styles.row}>
                <Input
                    value={form.country}
                    onChange={e => onChange('country', e.target.value)}
                    placeholder={`${t(
                        'screens.business.company.address.country',
                    )}*`}
                    id="create-company-country-input"
                    dataCy="create-company-country-input"
                    limit={Limits.Country}
                />
            </div>
            <Button
                text={t('common.buttons.next')}
                onClick={() => onNext(form)}
                className={styles.button}
                disabled={!isValid}
            />
            <p className={styles.legend}>
                {t('screens.business.requiredField')}
            </p>
        </Card>
    );
};

export default CreateCompanyAddress;

import { Chat, ChatMessage } from '../../../../../__shared/graphql';
import React, { useCallback, useEffect, useState } from 'react';
import Icon from '../../../../../components/icon/icon';
import { useObservable } from '../../../../../hooks/useObservable';
import {
    onShowPreviewMessages$,
    publishShowPreviewMessages,
} from '../../../../../rxjs/onShowPreviewMessages';
import RxAuthentication from '../../../../../rxjs/RxAuthentication';
import RxChat from '../../../../../rxjs/RxChat';
import { findChatContextOwnerUserFromCustomers } from '../../../../../utils/chatUtils';
import { filterMessagesWithImageAndVideo } from '../../../../../utils/fileUtils';
import logUtil from '../../../../../utils/logUtil';
import AnimalContextDetail from '../../detail/animalContextDetail';
import ChatDetail from '../../detail/chatDetail';
import ChatHeader from '../chatHeader/chatHeader';
import ChatImageView from '../chatImageView/ChatImageView';
import ChatInput from '../chatInput/chatInput';
import ChatMessageReference from '../chatMessage/chatMessageReference/chatMessageReference';
import ChatMessages from '../chatMessages/chatMessages';
import styles from './chatMain.module.scss';
import useChatMain from './useChatMain';

interface ChatMainProps {
    show: boolean;
    loaded?: boolean;
    chat?: Chat;
    hide?(show: boolean): void;
}

const ChatMain: React.FC<ChatMainProps> = ({
    show,
    chat,
    loaded = false,
    hide,
}) => {
    const [showDetail, setShowDetail] = useState<boolean>(false);

    const [chatMessageReference, setChatMessageReference] = useState<
        ChatMessage | undefined
    >(undefined);

    const [showAnimalContextDetail, setShowAnimalContextDetail] =
        useState<boolean>(false);

    useEffect(() => {
        if (!show) {
            setShowDetail(false);
            setShowAnimalContextDetail(false);
        }
    }, [show]);

    const {
        messages,
        loading: loadingChatMessages,
        addMessages,
        messagesWithFiles,
        loadNextPage,
    } = useChatMain(chat?.id ?? 0, '', loaded);

    const [previewMessage, setPreviewMessage] = useState<ChatMessage>();
    const [previewMessages, setPreviewMessages] = useState<ChatMessage[]>();

    const onShowPreviewMessages = useObservable(
        onShowPreviewMessages$,
        undefined,
    );

    useEffect(() => {
        if (onShowPreviewMessages) {
            setPreviewMessage(onShowPreviewMessages.previewMessage);
            setPreviewMessages(onShowPreviewMessages.messages ?? []);
        } else {
            setPreviewMessage(undefined);
            setPreviewMessages([]);
        }
    }, [onShowPreviewMessages, chat?.id]);

    useEffect(() => {
        if (showDetail) {
            setShowAnimalContextDetail(false);
        }
    }, [showDetail]);

    const onChatMessageCreated = useCallback(
        (msg: ChatMessage) => {
            addMessages([msg]);
            setChatMessageReference(undefined);
            if (msg.chatId && msg.uuid) {
                RxChat.scrollChatToEnd({
                    chatId: msg.chatId,
                    scrollEnd: 'bottom',
                    behavior: 'smooth',
                });
            }
        },
        [addMessages, setChatMessageReference],
    );

    const onChatMessagesMarkedAsViewed = useCallback(
        (viewedMessages: ChatMessage[]) => {
            if (viewedMessages?.length) {
                addMessages(viewedMessages);
            }
        },
        [addMessages],
    );

    const toggleShowAnimalContextDetail = useCallback(() => {
        setShowDetail(false);
        setShowAnimalContextDetail(show => !show);
    }, [setShowAnimalContextDetail]);

    const onChatMessageReplyOptionClicked = useCallback(
        (chatMessage: ChatMessage | undefined) => {
            logUtil.log('onChatMessageReplyOptionClicked:', chatMessage);
            setChatMessageReference(chatMessage);
        },
        [setChatMessageReference],
    );

    const userId = useObservable(RxAuthentication.userId$, 0); // get from above?

    const [loadingPage, setLoadingPage] = useState<boolean>(loaded);
    useEffect(() => {
        if (loadingChatMessages) {
            setLoadingPage(false);
        }
    }, [loadingChatMessages, setLoadingPage]);

    if (!show && !loaded) {
        return null;
    }

    const user = findChatContextOwnerUserFromCustomers(chat);
    const isDeletedCustomerUser = !!user?.deletedAt;

    return (
        <div
            className={styles.container}
            style={!show ? { display: 'none' } : { display: 'flex' }}>
            <section className={styles.main} data-cy={`chat-main-${chat?.id}`}>
                <ChatHeader
                    chat={chat}
                    toggleShowDetail={() => setShowDetail(!showDetail)}
                    closeCurrentChat={() => (hide ? hide(false) : null)}
                />
                <ChatMessages
                    chat={chat}
                    chatMessages={messages}
                    isLoading={loadingChatMessages || loadingPage}
                    loadNextPage={loadNextPage}
                    show={true}
                    onChatMessageReplyOptionClicked={
                        onChatMessageReplyOptionClicked
                    }
                    onSecondaryHeaderClicked={toggleShowAnimalContextDetail}
                    onChatMessagesMarkedAsViewed={onChatMessagesMarkedAsViewed}
                />
                {chat ? (
                    <>
                        {!!chatMessageReference && (
                            <div className={styles.messageReferenceContainer}>
                                <div className={styles.messageReference}>
                                    <ChatMessageReference
                                        id={`chat-input-message-reference-${chat?.id}`}
                                        message={chatMessageReference}
                                        userId={userId}
                                    />
                                </div>
                                <div className={styles.closeButton}>
                                    <Icon
                                        name="close-circle-outline"
                                        size={32}
                                        onClick={() =>
                                            onChatMessageReplyOptionClicked(
                                                undefined,
                                            )
                                        }
                                    />
                                </div>
                            </div>
                        )}
                        {!isDeletedCustomerUser && (
                            <ChatInput
                                chat={chat}
                                chatMessageReference={{
                                    ...chatMessageReference,
                                }}
                                allowEmptyMessageText={false}
                                onChatMessageCreated={onChatMessageCreated}
                            />
                        )}
                    </>
                ) : null}
            </section>
            {show && chat && (
                <ChatDetail
                    show={showDetail}
                    chat={chat}
                    chatMessagesWithFiles={filterMessagesWithImageAndVideo(
                        messagesWithFiles,
                    )}
                    hideDetail={() => setShowDetail(false)}
                    showDetail={() => setShowDetail(true)}
                />
            )}
            {show && chat && (chat.animal || chat.animalGroup) && (
                <AnimalContextDetail
                    show={showAnimalContextDetail}
                    animalContext={{
                        animal: chat.animal,
                        animalGroup: chat.animalGroup,
                    }}
                    hideDetail={() => setShowAnimalContextDetail(false)}
                />
            )}

            {previewMessage && (
                <ChatImageView
                    messages={previewMessages ?? []}
                    onClose={() => publishShowPreviewMessages([], undefined)}
                    showMessage={previewMessage}
                />
            )}
        </div>
    );
};

export default ChatMain;

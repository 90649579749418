import { useMutation } from '@apollo/client';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FadeLoader } from 'react-spinners';
import { uploadClient } from '../../../../../api/apolloClient';
import ProfileIcon from '../../../../../components/profileIcon/profileIcon';
import useUpdateCompany from '../../../../../hooks/useUpdateCompany';
import {
    AcceptImageFilesString,
    getFileInfoFromImage,
} from '../../../../../utils/fileUtils';
import {
    PredefinedImageResolutionsProfilePictureOnly,
    PredefinedImageResolutionsThumbnailAndDetail,
} from '../../../../../__shared/common';
import {
    Company,
    FileDescription,
    MutationSingleFileUploadArgs,
    SingleFileUploadData,
    SingleFileUploadMutation,
} from '../../../../../__shared/graphql';
import styles from './editCompanyProfilePicture.module.scss';

interface EditCompanyProfilePictureProps {
    company: Company;
    onSaved: (imageFileId: number) => void;
}

const EditCompanyProfilePicture: React.FC<EditCompanyProfilePictureProps> = ({
    company,
    onSaved,
}) => {
    const [companyImageFile, setCompanyImageFile] =
        useState<FileDescription | null>();
    const { t } = useTranslation();
    const uploadInputRef = useRef<HTMLInputElement>(null);
    const { updateCompany } = useUpdateCompany();

    const [singleFileUpload, { loading, reset }] = useMutation<
        SingleFileUploadData,
        MutationSingleFileUploadArgs
    >(SingleFileUploadMutation.FullResponse, { client: uploadClient });

    useEffect(() => {
        setCompanyImageFile(company.imageFile);
    }, [company]);

    const onChange = async (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files?.length) {
            return;
        }
        const file = event.target.files[0];
        if (file) {
            const fileInfo = getFileInfoFromImage(file);
            const result = await singleFileUpload({
                variables: {
                    file,
                    fileInfo,
                    resolutions: Object.values(
                        PredefinedImageResolutionsProfilePictureOnly,
                    ),
                },
            });
            if (result.data?.singleFileUpload?.id) {
                onSaved(result.data.singleFileUpload.id);

                updateCompany({
                    company: {
                        id: company.id,
                        imageFileId: result.data?.singleFileUpload?.id,
                    },
                    imageResolutions: Object.values(
                        PredefinedImageResolutionsThumbnailAndDetail,
                    ),
                });
            }
            reset();
        }
    };

    const onClick = () => {
        if (!loading && uploadInputRef?.current) {
            uploadInputRef.current.click();
        }
    };

    return (
        <div className={styles.container}>
            <input
                type="file"
                accept={AcceptImageFilesString}
                onChange={onChange}
                ref={uploadInputRef}
                className={styles.fileInput}
            />
            <div className={styles.profile} onClick={onClick}>
                {!loading && (
                    <>
                        <ProfileIcon
                            size={200}
                            fileDescription={companyImageFile}
                            colorBundle={company?.colorBundle}
                        />
                        {/* <img src={company.imageFile?.safeUrl ?? ''} /> */}
                        <span className={styles.text}>
                            {t('common.buttons.uploadNewImage')}
                        </span>
                    </>
                )}
                {loading && (
                    <div className={styles.spinner}>
                        <FadeLoader />
                    </div>
                )}
            </div>
        </div>
    );
};

export default EditCompanyProfilePicture;

import { gql } from '@apollo/client';
import { Chat } from '../../__shared/graphql';
import { ChatFragments } from '../fragments/chatFragments';

const MarkChatAsReadForChatListEntry = gql`
    mutation MarkChatAsRead($chatId: Int!) {
        markChatAsRead(chatId: $chatId) {
            ...ChatForChatListEntryFragment
        }
    }
    ${ChatFragments.ChatForChatListEntryFragment}
`;

export const ChatMutations = {
    MarkChatAsReadForChatListEntry,
};

export interface MarkChatAsReadData {
    markChatAsRead: Chat;
}

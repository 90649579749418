import { useMutation } from '@apollo/client';
import Picker, { IEmojiData } from 'emoji-picker-react';
import React, {
    ChangeEvent,
    memo,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { FadeLoader } from 'react-spinners';
import { uploadClient } from '../../../../../api/apolloClient';
import CtxMenu from '../../../../../components/ctxMenu/ctxMenu';
import CtxMenuEntry from '../../../../../components/ctxMenu/ctxMenuEntry';
import Icon from '../../../../../components/icon/icon';
import Input from '../../../../../components/input/input';
import { AppointmentTypes, OrderTypes } from '../../../../../constants';
import { useAlert } from '../../../../../hooks/useAlert';
import { useErrorAlert } from '../../../../../hooks/useErrorAlert';
import { useObservable } from '../../../../../hooks/useObservable';
import { showModal } from '../../../../../rxjs/onShowModalSubject';
import RxAuthentication from '../../../../../rxjs/RxAuthentication';
import RxCompany from '../../../../../rxjs/RxCompany';
import { toAmountInEuroCent } from '../../../../../utils/currencyUtils';
import {
    AcceptApplicationFilesString,
    AcceptImageFilesString,
    AcceptMediaString,
    AcceptVideoFilesString,
    getFileInfoFromApplicationFile,
    isApplicationType,
} from '../../../../../utils/fileUtils';
import logUtil from '../../../../../utils/logUtil';
import { buildNameFromUser } from '../../../../../utils/nameUtils';
import { Option } from '../../../../../utils/option';
import { ChatMessageTypes, ChatTypes } from '../../../../../__shared/common';
import {
    AppointmentCreate,
    Chat,
    ChatMessage,
    ChatMessageCreate,
    CreateChatMessageData,
    CreateChatMessageMutation,
    CreateChatMessageWithOrderAndAppointmentData,
    CreateChatMessageWithOrderAndAppointmentMutation,
    FileInfo,
    MutationCreateChatMessageArgs,
    MutationCreateChatMessageWithOrderAndAppointmentArgs,
    MutationSingleFileUploadArgs,
    OrderCreate,
    SingleFileUploadData,
    SingleFileUploadMutation,
} from '../../../../../__shared/graphql';
import styles from './chatInput.module.scss';
import ChatInputAppointment from './chatInputAppointment/chatInputAppointment';
import { AppointmentForm } from './chatInputAppointment/useAppointmentForm';
import ChatInputMedia from './chatInputMedia/chatInputMedia';

const emojiUrl =
    process.env.PUBLIC_URL + '/emoji-datasource-apple/img/apple/64';

const iconSize = 30;
interface ChatInputProps {
    chat: Chat;
    chatMessageReference?: ChatMessage;
    allowEmptyMessageText: boolean;
    onChatMessageCreated: (msg: ChatMessage) => void;
}

const chatInputActions = {
    shareImage: 'shareImage',
    shareVideo: 'shareVideo',
    shareDocument: 'shareDocument',
    offerAppointment: 'offerAppointment',
    cancel: 'cancel',
};

const _chatInputOptions: Option[] = [
    {
        value: chatInputActions.shareImage,
        label: `screens.chat.messageActions.${chatInputActions.shareImage}`,
    },
    {
        value: chatInputActions.shareVideo,
        label: `screens.chat.messageActions.${chatInputActions.shareVideo}`,
    },
    {
        value: chatInputActions.shareDocument,
        label: `screens.chat.messageActions.${chatInputActions.shareDocument}`,
    },
    {
        value: chatInputActions.offerAppointment,
        label: `screens.chat.messageActions.${chatInputActions.offerAppointment}`,
    },
    {
        value: chatInputActions.cancel,
        label: `screens.chat.messageActions.${chatInputActions.cancel}`,
    },
];

const ChatInput: React.FC<ChatInputProps> = ({
    chat,
    chatMessageReference,
    allowEmptyMessageText = false,
    onChatMessageCreated,
}) => {
    const { t } = useTranslation();
    const alert = useAlert();
    const [chatInputOptions, setChatInputOptions] = useState<Option[]>([]);
    const uploadInputRef = useRef<HTMLInputElement>(null);
    const [value, setValue] = useState<string>('');
    const [customerOptions, setCustomerOptions] = useState<Option<number>[]>(
        [],
    );
    const [showAppointmentForm, setShowAppointmentForm] = useState(false);
    const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
    const userId = useObservable(RxAuthentication.userId$, 0);
    const { alertError } = useErrorAlert();

    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [isLoadingMediaFiles, setIsLoadingMediaFiles] = useState(false);
    const currentCompany = useObservable(RxCompany.currentCompany$);

    const [singleFileUpload, { loading: singleFileUploadLoading }] =
        useMutation<SingleFileUploadData, MutationSingleFileUploadArgs>(
            SingleFileUploadMutation.FullResponse,
            { client: uploadClient },
        );

    useEffect(() => {
        if (chat.type === ChatTypes.Internal) {
            setChatInputOptions(
                _chatInputOptions.filter(
                    o => o.value !== chatInputActions.offerAppointment,
                ),
            );
            return;
        }
        setChatInputOptions(_chatInputOptions);
    }, [chat]);

    const onEmojiClick = (
        _: React.MouseEvent<Element, MouseEvent>,
        emojiObject: IEmojiData,
    ) => {
        try {
            if (!chat?.id) {
                return;
            }
            const chatInputForCurrentChat = document.getElementById(
                `chat-input-${chat.id}`,
            );
            if (chatInputForCurrentChat) {
                chatInputForCurrentChat.innerHTML =
                    chatInputForCurrentChat?.innerHTML + emojiObject?.emoji;

                chatInputForCurrentChat?.focus();
                setCaret(chatInputForCurrentChat);
                setValue(chatInputForCurrentChat?.innerText);
            }
        } catch (e) {
            logUtil.log('onEmojiClick error:', e);
        }
    };

    function setCaret(el: HTMLElement | null) {
        if (el) {
            const range = document.createRange();
            const sel = window.getSelection();
            const end = el.innerText.length;
            range.setStart(el.childNodes[0], end);
            range.collapse(true);
            sel?.removeAllRanges();
            sel?.addRange(range);
        }
    }

    const [
        createChatMessage,
        { loading: createChatMessageLoading, reset: createChatMessageReset },
    ] = useMutation<CreateChatMessageData, MutationCreateChatMessageArgs>(
        CreateChatMessageMutation.FullResponse,
    );

    const [
        createChatMessageWithOrderAndAppointment,
        { loading: loadingCreateAppointment },
    ] = useMutation<
        CreateChatMessageWithOrderAndAppointmentData,
        MutationCreateChatMessageWithOrderAndAppointmentArgs
    >(CreateChatMessageWithOrderAndAppointmentMutation.FullResponse, {
        onError: alertError,
    });

    const handleSend = useCallback(
        async text => {
            if (createChatMessageLoading) {
                logUtil.warn('handleSend: already sending message');
                return;
            }

            setShowEmojiPicker(false);

            const savedText = `${text}`;
            setValue('');

            if (!text) {
                return;
            }
            const chatMessage: ChatMessageCreate = {
                authorId: userId,
                chatId: chat.id ?? 0,
                text: text.trim(),
                fileIds: [],
                type: ChatMessageTypes.Default,
            };

            if (chatMessageReference?.id) {
                chatMessage.replyToMessageId = chatMessageReference.id;
            }

            try {
                if (!chatMessage.text?.length) {
                    return;
                }
                const { data } = await createChatMessage({
                    variables: {
                        chatMessage,
                    },
                });
                if (data?.createChatMessage?.id) {
                    onChatMessageCreated(data.createChatMessage);
                } else {
                    // restore value if message creation failed
                    setValue(savedText);
                }
            } catch (e) {
                logUtil.warn('handleSend: sending message failed');
                alert.error(t('screens.chat.errors.createMessageFailed'));
                createChatMessageReset();
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            onChatMessageCreated,
            createChatMessage,
            setValue,
            chat.id,
            userId,
            chatMessageReference,
        ],
    );

    const sendChatMessageWithDocument = useCallback(
        async (file: File) => {
            if (!singleFileUploadLoading && !createChatMessageLoading) {
                if (isApplicationType(file.type)) {
                    const fileInfo: FileInfo =
                        getFileInfoFromApplicationFile(file);
                    try {
                        const result = await singleFileUpload({
                            variables: {
                                file: file,
                                fileInfo,
                                skipVideoConversion: true,
                            },
                        });

                        if (result?.data?.singleFileUpload?.id) {
                            const chatMessage: ChatMessageCreate = {
                                authorId: userId,
                                chatId: chat.id ?? 0,
                                text: '',
                                fileIds: [result?.data?.singleFileUpload?.id],
                                type: ChatMessageTypes.Default,
                            };
                            if (chatMessage.fileIds?.length) {
                                const response = await createChatMessage({
                                    variables: {
                                        chatMessage,
                                    },
                                });
                                if (response?.data?.createChatMessage?.id) {
                                    onChatMessageCreated(
                                        response.data.createChatMessage,
                                    );
                                }
                            }
                        }
                    } catch (error) {
                        alert.error(
                            'screens.chat.errors.createMessageWithImageFailed',
                        );
                        logUtil.warn(error);
                    }
                }
            }
        },
        [
            userId,
            chat.id,
            singleFileUpload,
            singleFileUploadLoading,
            createChatMessageLoading,
            alert,
            createChatMessage,
            onChatMessageCreated,
        ],
    );

    useEffect(() => {
        const customers = chat.participants
            ?.filter(p => !!p.customer)
            .map(p => p.customer);
        if (customers) {
            setCustomerOptions(
                customers.map(customer => ({
                    value: customer?.id ?? 0,
                    label: buildNameFromUser(customer?.user),
                })),
            );
        }
    }, [chat]);

    const handleSendWithOrderAndAppointment = useCallback(
        async (form: AppointmentForm) => {
            // getting customerId for animal creator

            const customerId = form.customer;

            if (customerId === 0) {
                logUtil.warn('cannot determine customer');
                return;
            }

            if (loadingCreateAppointment) {
                logUtil.warn(
                    'already sending message with order and appointment',
                );
                return;
            }

            const appointment: AppointmentCreate = {
                companyId: chat.company?.id ?? 0,
                customerId: customerId,
                employeeId: form.consultant,
                from: form.begin,
                to: form.end,
                isEmergencyService: false,
                type: AppointmentTypes.VideoChat,
            };
            const order: OrderCreate = {
                amountToPayInEuroCents: toAmountInEuroCent(form.price),
                isAdvancePayment: form.isAdvancePayment,
                type: OrderTypes.Appointment,
                customerId: customerId,
                companyId: chat.company?.id ?? 0,
            };
            const chatMessage: ChatMessageCreate = {
                authorId: userId,
                chatId: chat.id ?? 0,
                text: '',
                type: ChatMessageTypes.Default,
            };
            const { data } = await createChatMessageWithOrderAndAppointment({
                variables: {
                    appointment,
                    chatMessage,
                    order,
                },
            });

            const createdAppointment =
                data?.createChatMessageWithOrderAndAppointment;
            if (createdAppointment?.id) {
                setValue('');
                setShowAppointmentForm(false);
                onChatMessageCreated(createdAppointment);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            onChatMessageCreated,
            createChatMessageWithOrderAndAppointment,
            chat,
            userId,
        ],
    );

    const handleShareVideoConsultationClick = useCallback(async () => {
        const isStripeConfigured =
            !!currentCompany?.stripeAccountId &&
            !!currentCompany?.isOnboardedInStripe;
        if (isStripeConfigured) {
            setShowAppointmentForm(true);
        } else {
            showModal({
                title: t(
                    'screens.chat.appointment.missingStripeOnboardingForVideoConsultations.title',
                ),
                text: t(
                    'screens.chat.appointment.missingStripeOnboardingForVideoConsultations.text',
                ),
                okButtonText: t('common.buttons.ok'),
                closeOnBackdropClick: true,
            });
        }
    }, [
        currentCompany?.stripeAccountId,
        currentCompany?.isOnboardedInStripe,
        setShowAppointmentForm,
        t,
    ]);

    const handleItemClick = useCallback(
        (option: Option) => {
            switch (option.value) {
                case chatInputActions.shareImage:
                    if (uploadInputRef?.current) {
                        uploadInputRef.current.accept = AcceptImageFilesString;
                        uploadInputRef.current.click();
                    }
                    break;
                case chatInputActions.shareVideo:
                    if (uploadInputRef?.current) {
                        uploadInputRef.current.accept = AcceptVideoFilesString;
                        uploadInputRef.current.click();
                    }
                    break;
                case chatInputActions.shareDocument:
                    if (uploadInputRef?.current) {
                        uploadInputRef.current.accept =
                            AcceptApplicationFilesString;
                        uploadInputRef.current.click();
                    }
                    break;
                case chatInputActions.offerAppointment:
                    handleShareVideoConsultationClick();
                    break;

                default:
                    break;
            }
        },
        [handleShareVideoConsultationClick, uploadInputRef],
    );

    let containerStyles = styles.container;
    if (showAppointmentForm) {
        containerStyles += ` ${styles.appointment}`;
    }

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        logUtil.log('handleFileChange');

        const tmpFiles: File[] = [];
        if (event.target.files?.length) {
            for (let i = 0; i < event.target.files.length; i += 1) {
                const file = event.target.files.item(i);
                if (file) {
                    tmpFiles.push(file);
                }
            }
        }

        logUtil.log('handleFileChange files:', tmpFiles);

        if (tmpFiles?.length === 1 && isApplicationType(tmpFiles[0].type)) {
            sendChatMessageWithDocument(tmpFiles[0]);
        } else {
            setFilesToUpload(tmpFiles);
        }
    };

    return (
        <>
            <div
                style={{
                    overflow: 'hidden',
                    height: showEmojiPicker ? '320px' : '0px',
                    transition: 'height 0.2s',
                }}>
                <Picker
                    groupNames={{
                        smileys_people: t('emojis.groups.smileys_people'),
                        animals_nature: t('emojis.groups.animals_nature'),
                        food_drink: t('emojis.groups.food_drink'),
                        travel_places: t('emojis.groups.travel_places'),
                        activities: t('emojis.groups.activities'),
                        objects: t('emojis.groups.objects'),
                        symbols: t('emojis.groups.symbols'),
                        flags: t('emojis.groups.flags'),
                        recently_used: t('emojis.groups.recently_used'),
                    }}
                    emojiUrl={emojiUrl}
                    preload={false}
                    onEmojiClick={onEmojiClick}
                    pickerStyle={{
                        width: '100%',
                    }}
                />
            </div>
            {!!filesToUpload?.length && (
                <ChatInputMedia
                    files={filesToUpload}
                    onClose={() => {
                        setFilesToUpload([]);
                        if (uploadInputRef.current) {
                            uploadInputRef.current.value = '';
                        }
                    }}
                    isLoading={isLoading => {
                        setIsLoadingMediaFiles(isLoading);
                    }}
                    setFiles={setFilesToUpload}
                    chatId={chat.id ?? 0}
                />
            )}
            <input
                style={{ visibility: 'hidden' }}
                type="file"
                accept={AcceptMediaString}
                multiple
                onChange={handleFileChange}
                ref={uploadInputRef}
                className={styles.fileInput}
            />
            <div className={containerStyles}>
                {!showAppointmentForm && (
                    <>
                        {showEmojiPicker ? (
                            <Icon
                                size={iconSize}
                                name="close-outline"
                                onClick={event => {
                                    event.preventDefault;
                                    setShowEmojiPicker(false);
                                }}
                            />
                        ) : (
                            <CtxMenu
                                offsets={{ left: 4, bottom: 40 }}
                                className={styles.contextMenu}
                                buttonClassName={styles.contextMenuButton}
                                dataCy={`chat-input-ctx-menu-${chat.id}`}
                                button={
                                    <Icon
                                        size={iconSize}
                                        name="add-circle"
                                        className={styles.filled}
                                    />
                                }>
                                {chatInputOptions.map(option => (
                                    <CtxMenuEntry
                                        key={option.label}
                                        dataCy={`chat-input-ctx-menu-${chat.id}-${option.value}`}
                                        text={t(option.label)}
                                        onClick={() => handleItemClick(option)}
                                    />
                                ))}
                            </CtxMenu>
                        )}
                        <Icon
                            size={iconSize}
                            name="happy-outline"
                            className={styles.emojiIcon}
                            onClick={event => {
                                event.preventDefault;
                                setShowEmojiPicker(show => !show);
                            }}
                        />
                        <Input
                            className={styles.input}
                            id={`chat-input-${chat?.id ?? 0}`}
                            value={value}
                            onChange={e => setValue(e.target.value)}
                            onChangeText={setValue}
                            placeholder={t(
                                'screens.chat.placeHolderMessageInput',
                            )}
                            multiline
                        />
                        {!isLoadingMediaFiles ? (
                            <>
                                {allowEmptyMessageText ||
                                    (value?.length > 0 && (
                                        <Icon
                                            onClick={() => handleSend(value)}
                                            size={iconSize}
                                            name="arrow-up-circle"
                                            className={styles.send}
                                        />
                                    ))}
                            </>
                        ) : (
                            <div
                                className={
                                    styles.loadingMediaFileMessagesSpinner
                                }
                                onClick={() => setIsLoadingMediaFiles(false)}>
                                {<FadeLoader color={'gray'} />}
                            </div>
                        )}
                    </>
                )}
                {showAppointmentForm && (
                    <ChatInputAppointment
                        onClose={() => setShowAppointmentForm(false)}
                        onSend={handleSendWithOrderAndAppointment}
                        customerOptions={customerOptions}
                    />
                )}
            </div>
        </>
    );
};

export default memo(ChatInput);

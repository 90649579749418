import {
    ApolloClient,
    ApolloLink,
    HttpLink,
    InMemoryCache,
    NormalizedCacheObject,
    split,
} from '@apollo/client';
import { NetworkError } from '@apollo/client/errors';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { withScalars } from 'apollo-link-scalars';
import { createUploadLink } from 'apollo-upload-client';
import fetch from 'cross-fetch';
import {
    buildClientSchema,
    GraphQLScalarType,
    IntrospectionQuery,
} from 'graphql';
import {
    API_BASE_URL,
    DASHBOARD_VERSION,
    WS_BASE_URL,
} from '../environment/env';
import { getAccessToken } from '../rxjs/RxAuthentication';
import RxUpdateBanner from '../rxjs/RxUpdateBanner';
import { RxUpdateNotice } from '../rxjs/RxUpdateNotice';
import { isServerParseError } from '../typeGuards/typeGuards';
import authenticationUtil from '../utils/authenticationUtil';
import logUtil from '../utils/logUtil';
import {
    ApiVersionErrorObject,
    apiVersionUtil,
    isApiVersionErrorObject,
    VersionHeaders,
} from '../__shared/common';
import { LogClient } from '../__shared/common/logging/logClient';
import { restartApplication } from '../__shared/electron';
import introspectionResult from '../__shared/graphql/graphql.schema.json';
import { SubscriptionClient } from 'subscriptions-transport-ws';

export const GRAPHQL_API = API_BASE_URL;
export const WS_GRAPHQL_API = WS_BASE_URL;
if (!GRAPHQL_API) {
    throw new Error(`Missing graphl api url: ${GRAPHQL_API}`);
}
if (!WS_GRAPHQL_API) {
    throw new Error(`Missing graphl websocket api url: ${WS_GRAPHQL_API}`);
}
logUtil.log('HTTP_GRAPHQL_API: ', GRAPHQL_API);
logUtil.log('WS_GRAPHQL_API: ', WS_GRAPHQL_API);
logUtil.log('DASHBOARD_VERSION: ', DASHBOARD_VERSION);
const LogPrefix = '[DashboardWeb.ApolloClient]';

let _dashboardShellVersion = '';
export const setDashboardShellVersion = (version?: string): void => {
    _dashboardShellVersion = version ?? '';
};

const dateScalar = new GraphQLScalarType({
    name: 'Date',
    parseValue(value) {
        try {
            return new Date(value as string);
        } catch (e) {
            throw Error(
                `Cannot parse string to Date because value is not a string: ${value} error: ${e}`,
            );
        }
    },
    serialize(value) {
        try {
            return new Date(value as Date).toISOString();
        } catch (e) {
            throw Error(
                `Cannot parse Date to string because value is not a date: ${value} error: ${e}`,
            );
        }
    },
});

const typesMap = {
    Date: dateScalar,
};

const schema = buildClientSchema(
    introspectionResult as unknown as IntrospectionQuery,
);

const httpLink = new HttpLink({
    uri: GRAPHQL_API,
    fetch,
});

const wsClient = new SubscriptionClient(WS_GRAPHQL_API, {
    reconnect: true,
    inactivityTimeout: Number.MAX_SAFE_INTEGER,
    lazy: true,
    connectionParams() {
        return {
            authToken: getAccessToken(),
            [VersionHeaders.AnimalchatDashboardVersion]: DASHBOARD_VERSION,
            [VersionHeaders.AnimalchatDashboardShellVersion]:
                _dashboardShellVersion,
        };
    },
});

wsClient.onConnected(() => {
    LogClient.debug('wsClient onConnected');
});
wsClient.onDisconnected(() => {
    LogClient.debug('wsClient onDisconnected');
});
wsClient.onReconnected(() => {
    LogClient.debug('wsClient onReconnected');
});
wsClient.onError(error => {
    LogClient.debug('wsClient onError', { error });
});

const wsLink = () => {
    LogClient.info(
        `${LogPrefix} Creating new instance of WebSocketLink with token: ${getAccessToken()}`,
    );
    return new WebSocketLink(wsClient);
};

const splitLink = () =>
    split(
        ({ query }) => {
            const definition = getMainDefinition(query);
            return (
                definition.kind === 'OperationDefinition' &&
                definition.operation === 'subscription'
            );
        },
        wsLink(),
        httpLink,
    );

const authMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => {
        const token = getAccessToken();
        return {
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : '',
                [VersionHeaders.AnimalchatDashboardVersion]: DASHBOARD_VERSION,
                [VersionHeaders.AnimalchatDashboardShellVersion]:
                    _dashboardShellVersion,
            },
        };
    });
    return forward(operation);
});

const isSufficientWebVersion = (error: ApiVersionErrorObject) => {
    const minWebVersion =
        error.supportedApiVersion[VersionHeaders.AnimalchatDashboardVersion];
    const isSufficient = apiVersionUtil.isSufficientVersion(
        DASHBOARD_VERSION,
        minWebVersion,
    );
    logUtil.log(
        `currentWebVersion: ${DASHBOARD_VERSION}, minWebVersion: ${minWebVersion}, isSufficient: ${isSufficient}`,
    );
    return isSufficient;
};

const isSufficientShellVersion = (error: ApiVersionErrorObject) => {
    if (!_dashboardShellVersion) {
        // no _dashboardShellVersion means we are in a web browser
        logUtil.log('No Shell version');
        return true;
    }

    const minShellVersion =
        error.supportedApiVersion[
            VersionHeaders.AnimalchatDashboardShellVersion
        ];
    const isSufficient = apiVersionUtil.isSufficientVersion(
        _dashboardShellVersion,
        minShellVersion,
    );
    logUtil.log(
        `currentShellVersion: ${_dashboardShellVersion}, minShellVersion: ${minShellVersion}, isSufficient: ${isSufficient}`,
    );

    return isSufficient;
};

let _handledNetworkErrorWithRestartOnce = false;
const handleOldApiVersion = (networkError: NetworkError) => {
    const error = JSON.parse(JSON.stringify(networkError));
    const apiVersionOutdatedError = error.result;
    if (isApiVersionErrorObject(apiVersionOutdatedError)) {
        if (!isSufficientShellVersion(apiVersionOutdatedError)) {
            logUtil.log(
                'OUTDATED SHELL, performing restart of the entire application',
            );
            if (!_handledNetworkErrorWithRestartOnce) {
                _handledNetworkErrorWithRestartOnce = true;
                authenticationUtil.logout(true);
                logUtil.log('restartApplication');
                restartApplication();
            }
            return;
        }

        if (!isSufficientWebVersion(apiVersionOutdatedError)) {
            logUtil.log(
                'OUTDATED WEB, performing reload to get the newest web version',
            );
            authenticationUtil.logout(true);
            RxUpdateBanner.showUpdateBanner('errors.UpdateRequired', 'error');
            return;
        }
        alert('OUTDATED WEB, performing reload to get the newest web version');
    }
    logUtil.warn('Unknown network error', error);
};

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (
        networkError &&
        isServerParseError(networkError) &&
        networkError.statusCode === 410
    ) {
        handleOldApiVersion(networkError);
    }
    if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
            logUtil.log(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            ),
        );
    if (networkError) {
        logUtil.log(`[Network error]: ${networkError}`);
    }
});

const versionLink = new ApolloLink((operation, forward) => {
    return forward(operation).map(response => {
        const context = operation.getContext();
        const headers = context?.response?.headers;

        if (!headers) {
            logUtil.log(
                'Missing version headers in server response, context:',
                context,
            );
            return response;
        }

        const latestVersionDashboardShellKey = `latest-${VersionHeaders.AnimalchatDashboardShellVersion}`;
        const latestVersionDashboardShell = headers.get(
            latestVersionDashboardShellKey,
        );
        if (latestVersionDashboardShell && _dashboardShellVersion) {
            LogClient.debug(
                `Comparing shell versions (actual: ${_dashboardShellVersion}, latest: ${latestVersionDashboardShell})`,
            );
            if (latestVersionDashboardShell !== _dashboardShellVersion) {
                const isLatestGreaterThanActual =
                    apiVersionUtil.isSufficientVersion(
                        latestVersionDashboardShell,
                        _dashboardShellVersion,
                    );
                LogClient.debug(
                    `isLatestGreaterThanActual: ${isLatestGreaterThanActual}`,
                );
                if (isLatestGreaterThanActual) {
                    RxUpdateNotice.showUpdateNotice(
                        {
                            header: 'updates.updateAvailable',
                            message: 'updates.downloadShellUpdateMessage',
                            buttonText: 'updates.downloadUpdate',
                        },
                        'shell',
                    );

                    return response;
                }
            }
        }

        const latestVersionDashboardKey = `latest-${VersionHeaders.AnimalchatDashboardVersion}`;
        const latestVersionDashboard = headers.get(latestVersionDashboardKey);

        const actualDashboardVersion = process.env.REACT_APP_DASHBOARD_VERSION;
        if (
            latestVersionDashboard &&
            latestVersionDashboard !== actualDashboardVersion
        ) {
            LogClient.debug(
                `Comparing web versions (actual: ${actualDashboardVersion}, latest: ${latestVersionDashboard})`,
            );
            const isLatestGreaterThanActual =
                apiVersionUtil.isSufficientVersion(
                    latestVersionDashboard,
                    actualDashboardVersion,
                );
            LogClient.debug(
                `isLatestGreaterThanActual: ${isLatestGreaterThanActual}`,
            );
            if (isLatestGreaterThanActual) {
                RxUpdateNotice.showUpdateNotice(
                    {
                        header: 'updates.updateAvailable',
                        message: 'updates.reloadForWebUpdate',
                        buttonText: 'common.buttons.refresh',
                    },
                    'web',
                );
            }
        }
        return response;
    });
});

const createApolloClient = (): ApolloClient<NormalizedCacheObject> => {
    return new ApolloClient({
        link: ApolloLink.from([
            errorLink,
            authMiddleware,
            versionLink,
            withScalars({ schema, typesMap }),
            splitLink(),
        ]),
        cache: new InMemoryCache(),
        defaultOptions: {
            watchQuery: { fetchPolicy: 'no-cache' },
            query: { fetchPolicy: 'no-cache' },
            mutate: { fetchPolicy: 'no-cache' },
        },
    });
};

export default createApolloClient;

export const uploadClient = new ApolloClient({
    link: ApolloLink.from([
        authMiddleware,
        createUploadLink({ uri: `${GRAPHQL_API}/upload` }),
    ]),
    cache: new InMemoryCache(),
    defaultOptions: {
        watchQuery: { fetchPolicy: 'no-cache' },
        query: { fetchPolicy: 'no-cache' },
        mutate: { fetchPolicy: 'no-cache' },
    },
});

import {
    ApolloQueryResult,
    LazyQueryResult,
    useLazyQuery,
    useMutation,
} from '@apollo/client';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Switch from '../../../../components/switch/switch';
import TextButton from '../../../../components/textButton/textButton';
import { useAlert } from '../../../../hooks/useAlert';
import { useObservable } from '../../../../hooks/useObservable';
import useUpdateCompany from '../../../../hooks/useUpdateCompany';
import RxCompany from '../../../../rxjs/RxCompany';
import { openUrlInBrowser } from '../../../../__shared/electron';
import {
    Company,
    CreateStripeConnectAccountForCompanyData,
    CreateStripeConnectAccountForCompanyMutation,
    GetCompanyData,
    GetCompanyQuery,
    GetStripeConnectOnboardingLinkForExistingAccountData,
    GetStripeConnectOnboardingLinkForExistingAccountQuery,
    GetStripeDashboardLinkData,
    GetStripeDashboardLinkQuery,
    MutationCreateStripeConnectAccountForCompanyArgs,
    QueryGetCompanyArgs,
    QueryGetStripeConnectDashboardLinkArgs,
    QueryGetStripeConnectOnboardingLinkForExistingAccountArgs,
} from '../../../../__shared/graphql';
import { mapCompanyToCompanyUpdate } from '../company/company';
import styles from './payment.module.scss';
import StripeAccountState from './stripeAccountState';

type OnboardingDashboardLinkResult =
    | ApolloQueryResult<GetStripeConnectOnboardingLinkForExistingAccountData>
    | LazyQueryResult<
          GetStripeConnectOnboardingLinkForExistingAccountData,
          QueryGetStripeConnectOnboardingLinkForExistingAccountArgs
      >;

const Stripe: React.FC = () => {
    const company = useObservable(RxCompany.currentCompany$);

    const { updateCompany } = useUpdateCompany();
    const { t } = useTranslation();
    const alert = useAlert();

    const [createStripeConnectAccountForCompany, { loading: createLoading }] =
        useMutation<
            CreateStripeConnectAccountForCompanyData,
            MutationCreateStripeConnectAccountForCompanyArgs
        >(CreateStripeConnectAccountForCompanyMutation.FullResponse);

    const [getCompany, { loading: getLoading }] = useLazyQuery<
        GetCompanyData,
        QueryGetCompanyArgs
    >(GetCompanyQuery.FullResponse);

    const [
        getStripeConnectOnboardingLinkForExistingAccount,
        { refetch, called },
    ] = useLazyQuery<
        GetStripeConnectOnboardingLinkForExistingAccountData,
        QueryGetStripeConnectOnboardingLinkForExistingAccountArgs
    >(GetStripeConnectOnboardingLinkForExistingAccountQuery.FullResponse);

    const [getStripeDashboardLink] = useLazyQuery<
        GetStripeDashboardLinkData,
        QueryGetStripeConnectDashboardLinkArgs
    >(GetStripeDashboardLinkQuery.FullResponse);

    const updateCompanyStore = async (company: Company) => {
        RxCompany.addCompany(company);
        RxCompany.setCurrentCompany(company);
    };

    const onRefresh = async () => {
        if (!company?.id) {
            return;
        }

        const response = await getCompany({
            variables: { companyId: company.id },
        });
        if (response.data?.getCompany) {
            updateCompanyStore(response.data.getCompany);
        }
    };

    const updateAdvancePayment = useCallback(
        newIsAdvancePayment => {
            if (!company) {
                return;
            }
            updateCompany({
                company: {
                    ...mapCompanyToCompanyUpdate(company),
                    defaultIsAdvancePayment: newIsAdvancePayment,
                },
            });
        },
        [updateCompany, company],
    );

    const onCreateAccount = async () => {
        if (!company?.id) {
            return;
        }
        try {
            const response = await createStripeConnectAccountForCompany({
                variables: {
                    companyId: company?.id,
                },
            });
            const url = response.data?.createStripeConnectAccountForCompany;
            if (url) {
                openUrlInBrowser(url);
            }
        } catch (error) {
            alert.error('common.alerts.defaultError');
            console.warn('error while creating account link', error);
        }
    };

    const onContinueOnboarding = async (accountId: string) => {
        try {
            let response: OnboardingDashboardLinkResult;
            if (called) {
                response = await refetch({ accountId });
            } else {
                response =
                    await getStripeConnectOnboardingLinkForExistingAccount({
                        variables: { accountId },
                    });
            }
            const url =
                response.data?.getStripeConnectOnboardingLinkForExistingAccount;
            if (url) {
                openUrlInBrowser(url);
            }
        } catch (e) {
            alert.error(t('common.alerts.defaultError'));
        }
    };

    const onOpenDashboard = async (accountId: string) => {
        try {
            const response = await getStripeDashboardLink({
                variables: { accountId },
            });
            const url = response.data?.getStripeConnectDashboardLink;
            if (url) {
                openUrlInBrowser(url);
            }
        } catch (e) {
            alert.error(t('common.alerts.defaultError'));
        }
    };

    const loading = getLoading || createLoading;

    return (
        <div className={styles.container}>
            <div className={styles.section}>
                <span className={styles.label}>
                    {t('screens.settings.stripe.sectionTitle')}
                </span>
                <div className={styles.row}>
                    <span className={styles.accountStateLabel}>
                        {t('screens.settings.stripe.accountState')}
                    </span>
                    {company && <StripeAccountState company={company} />}
                </div>
                <div className={styles.row}>
                    <div className={styles.refresh}>
                        <TextButton
                            style="primary"
                            disabled={getLoading}
                            text={t('common.buttons.refresh')}
                            onClick={onRefresh}
                        />
                    </div>
                    {company?.isOnboardedInStripe && (
                        <TextButton
                            disabled={loading}
                            text={t('screens.settings.stripe.toDashboard')}
                            onClick={() => {
                                if (company.stripeAccountId) {
                                    onOpenDashboard(company.stripeAccountId);
                                }
                            }}
                        />
                    )}
                </div>
                {company?.stripeAccountId && !company.isOnboardedInStripe && (
                    <div className={styles.additionalInfo}>
                        <p>
                            {t('screens.settings.stripe.finishOnboardingText')}
                        </p>
                        <TextButton
                            disabled={loading}
                            text={t(
                                'screens.settings.stripe.finishOnboardingButton',
                            )}
                            onClick={() => {
                                if (company.stripeAccountId) {
                                    onContinueOnboarding(
                                        company.stripeAccountId,
                                    );
                                }
                            }}
                        />
                    </div>
                )}
                {!!company && !company.stripeAccountId && (
                    <div className={styles.additionalInfo}>
                        <p>{t('screens.settings.stripe.noAccountText')}</p>
                        <TextButton
                            disabled={loading}
                            text={t('common.buttons.createAccount')}
                            onClick={onCreateAccount}
                        />
                    </div>
                )}
            </div>
            <div className={styles.section}>
                <span className={styles.label}>
                    {t('screens.settings.payment.sectionTitle')}
                </span>
                <div className={styles.row}>
                    <span className={styles.advancePaymentLabel}>
                        {t('screens.settings.payment.advancePayment')}
                    </span>
                    <Switch
                        handleToggle={newState => {
                            updateAdvancePayment(newState);
                        }}
                        id="company-advance-payment"
                        isOn={!!company?.defaultIsAdvancePayment}
                    />
                </div>
            </div>
        </div>
    );
};

export default Stripe;

import React from 'react';
import { User } from '../../__shared/graphql';
import ProfileIcon from '../profileIcon/profileIcon';
import styles from './userItem.module.scss';
import { buildNameFromUser } from '../../utils/nameUtils';
import { ClassNameProp } from '../../types/stylingProps';

interface UserItemProps extends ClassNameProp {
    user?: User;
}

const UserItem: React.FC<UserItemProps> = ({ user, className }) => {
    return (
        <div className={`${styles.user} ${className ?? ''}`.trim()}>
            <ProfileIcon
                fileDescription={user?.imageFile}
                colorBundle={user?.colorBundle}
            />
            <div className={styles.text}>
                <span className={styles.name}>{buildNameFromUser(user)}</span>
            </div>
        </div>
    );
};

export default UserItem;

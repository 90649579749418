import React, { ReactNode } from 'react';
import styles from './splitScreen.module.scss';

const SplitScreen: React.FC<{
    left: ReactNode;
    right: ReactNode;
}> = ({ left, right }) => {
    return (
        <div className={styles.container}>
            {left}
            {right}
        </div>
    );
};

export default SplitScreen;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Company } from '../../../../__shared/graphql';
import { ClassNameProp } from '../../../../types/stylingProps';
import styles from './stripeAccountState.module.scss';

interface StripeAccountStateProps extends ClassNameProp {
    company?: Company;
}

const StripeAccountState: React.FC<StripeAccountStateProps> = ({
    company,
    className,
}) => {
    const { t } = useTranslation();
    let containerClassName = styles.container;
    let text: '';
    if (!company?.stripeAccountId) {
        containerClassName += ` ${styles.missing}`;
        text = t('screens.settings.stripe.accountStates.missing');
    } else {
        if (company.isOnboardedInStripe) {
            containerClassName += ` ${styles.complete}`;
            text = t('screens.settings.stripe.accountStates.complete');
        } else {
            containerClassName += ` ${styles.incomplete}`;
            text = t('screens.settings.stripe.accountStates.incomplete');
        }
    }

    return (
        <div className={`${containerClassName} ${className ?? ''}`.trim()}>
            {text}
        </div>
    );
};

export default StripeAccountState;

import React from 'react';
import { AnimalContext } from '../../../../__shared/graphql';
import styles from './animalContextList.module.scss';
import AnimalContextItem from '../../../../components/animalContextItem/animalContextItem';
import logUtil from '../../../../utils/logUtil';

interface AnimalContextListProps {
    animalContexts?: AnimalContext[];
    chatId?: number;
    onAnimalContextClicked: (animalContext: AnimalContext) => void;
}

const AnimalContextList: React.FC<AnimalContextListProps> = ({
    animalContexts,
    onAnimalContextClicked,
}) => {
    return (
        <div className={styles.container}>
            <ul className={styles.animalContextList}>
                {animalContexts?.map(animalContext => {
                    return (
                        <li key={animalContext.id}>
                            <AnimalContextItem
                                animal={animalContext?.animal}
                                animalGroup={animalContext?.animalGroup}
                                onClick={() => {
                                    logUtil.log(
                                        'Clicked animal context item',
                                        animalContext.id,
                                    );
                                    onAnimalContextClicked(animalContext);
                                }}
                            />
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default AnimalContextList;

import { LogClient } from '../logging';

const DateFactors = {
    MillisecondsToMinutes: 60000,
    MillisecondsToSeconds: 1000,
};

const toHHMM = (date: Date): string => {
    date = new Date(date);
    return date ? `${getHours(date)}:${getMinutes(date)}` : '';
};

const toHHMMSS = (date: Date, separator = ':'): string => {
    date = new Date(date);
    return date
        ? `${getHours(date)}${separator}${getMinutes(
              date,
          )}${separator}${getSeconds(date)}`
        : '';
};

const toDDMMYYYY = (date: Date): string => {
    date = new Date(date);
    return date
        ? `${getDate(date)}.${getMonth(date)}.${date.getFullYear()}`
        : '';
};

const toDDMMYY = (date: Date): string => {
    date = new Date(date);
    return date
        ? `${getDate(date)}.${getMonth(date)}.${date
              .getFullYear()
              .toString()
              .substring(2, 4)}`
        : '';
};

const toDDMMYYYHHMM = (date: Date): string => {
    date = new Date(date);
    return date ? `${toDDMMYY(date)} - ${toHHMM(date)}` : '';
};

const toYYYYMMDD = (date: Date, separator = ''): string => {
    date = new Date(date);
    return date
        ? `${date.getFullYear()}${separator}${getMonth(
              date,
          )}${separator}${getDate(date)}`
        : '';
};

/**
 * @param separators different separators which will default to an empty string
 */
const toYYYYMMDDHHMM = (
    date: Date,
    separators?: {
        dateSeparator?: string;
        dateTimeSeparator?: string;
        timeSeparator?: string;
    },
): string => {
    if (!date) {
        return '';
    }
    date = new Date(date);
    const dateSeparator = separators?.dateSeparator ?? '';
    const dateTimeSeparator = separators?.dateTimeSeparator ?? '';
    const timeSeparator = separators?.timeSeparator ?? '';

    const yyyymmdd = toYYYYMMDD(date, dateSeparator);
    const hhmm = `${getHours(date)}${timeSeparator}${getMinutes(date)}`;
    return `${yyyymmdd}${dateTimeSeparator}${hhmm}`;
};

const millisecondsToMinutes = (ms: number): number => {
    return ms / DateFactors.MillisecondsToMinutes;
};

const getLastDayOfMonth = (date: Date): number => {
    date = new Date(date);
    return date
        ? new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()
        : -1;
};

const getSameOrLastDayDateOfNextMonth = (
    date: Date,
    dayOfMonth?: number,
): Date => {
    date = new Date(date);
    let expectedDayOfMonth = date.getDate();
    if (dayOfMonth !== undefined) {
        expectedDayOfMonth = dayOfMonth;
    }

    const result = new Date(date);
    result.setMonth(date.getMonth() + 1);
    if (expectedDayOfMonth !== result.getDate()) {
        // 31.01 -> 03.03 (or 02.03.)
        result.setDate(0); // --> sets it to the last day of the previous month
    }
    return result;
};

const getSameOrLastDayInXMonth = (
    date: Date,
    amountOfMonthsToSkip: number,
): Date => {
    date = new Date(date);
    const expectedDayOfMonth = date.getDate();
    const yearsToSkip = Math.floor(amountOfMonthsToSkip / 12);
    const monthsToSkip = amountOfMonthsToSkip % 12;
    let result = new Date(date);
    result.setFullYear(date.getFullYear() + yearsToSkip);
    for (let restAmount = monthsToSkip; restAmount > 0; restAmount -= 1) {
        result = getSameOrLastDayDateOfNextMonth(result, expectedDayOfMonth);
    }
    return result;
};

const toRNAddCalendarEventFormat = (d: Date): string => {
    d = new Date(d);
    return `${d.getFullYear()}-${getMonth(d)}-${getDate(d)}T${getHours(
        d,
    )}:${getMinutes(d)}:${getSeconds(d)}.${getMilliseconds(d)}Z`;
};

const getMMSSFromSeconds = (seconds: number): string => {
    const unfixedMinutesString = (seconds / 60).toFixed(2).toString();
    const [minutes, secondsInDecimalString] = unfixedMinutesString.split('.');
    const secondsInDecimal = Number(secondsInDecimalString);
    const finalSeconds = Math.floor((secondsInDecimal * 60) / 100);
    return `${minutes}:${getPaddedNumber(finalSeconds)}`;
};

const isLastDayOfMonth = (date: Date): boolean => {
    date = new Date(date);
    // day = 0 returns the last day of the previous month
    const compareDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    return (
        date.getFullYear() === compareDate.getFullYear() &&
        date.getMonth() === compareDate.getMonth() &&
        date.getDate() === compareDate.getDate()
    );
};

const getTimeDifferenceInMinutes = (d1: Date, d2: Date): number => {
    d1 = new Date(d1);
    d2 = new Date(d2);
    return Math.round(
        millisecondsToMinutes(new Date(d1).getTime() - new Date(d2).getTime()),
    );
};

const getAgeTranslationOptions = (
    d: Date,
): {
    key: string;
    options: { age: number };
} => {
    d = new Date(d);
    const now = new Date();
    const ageInWeeks = Math.round(
        (now.getTime() - new Date(d).getTime()) / MillisecondsToWeeks,
    );
    let age;
    switch (true) {
        case ageInWeeks > 26 && ageInWeeks < 53: {
            // TODO: calc in months via date function
            age = Math.round(ageInWeeks / 4);
            return {
                key: age === 1 ? 'common.age.monthOld' : 'common.age.monthsOld',
                options: {
                    age,
                },
            };
        }

        case ageInWeeks > 52: {
            // TODO: calc in years via date function
            age = Math.round(ageInWeeks / 52);
            return {
                key: age === 1 ? 'common.age.yearOld' : 'common.age.yearsOld',
                options: {
                    age,
                },
            };
        }

        default: {
            return {
                key:
                    ageInWeeks === 1
                        ? 'common.age.weekOld'
                        : 'common.age.weeksOld',
                options: {
                    age: ageInWeeks,
                },
            };
        }
    }
};

/**
 *
 * @param d1 earlier date
 * @param d2 later date
 */
const getTimeSpan = (d1: Date, d2: Date): TimeSpan => {
    let newer: Date;
    let older: Date;
    d1 = new Date(d1);
    d2 = new Date(d2);
    let d1IsFutureDate = false;
    if (d2.getTime() > d1.getTime()) {
        newer = d2;
        older = d1;
    } else {
        d1IsFutureDate = true;
        newer = d1;
        older = d2;
    }
    const diffInMinutes = getTimeDifferenceInMinutes(newer, older);

    if (diffInMinutes < 1 && !d1IsFutureDate) {
        return TimeSpan.WithinSeconds;
    }

    if (diffInMinutes < 60 && !d1IsFutureDate) {
        return TimeSpan.WithinMinutes;
    }

    if (newer.getDate() === older.getDate()) {
        return TimeSpan.SameDay;
    }

    //#region yesterday or tomorrow
    if (
        newer.getFullYear() - 1 === older.getFullYear() &&
        older.getMonth() === 11 &&
        older.getDate() === 31
    ) {
        // if not same year than yesterday is 31.12.YEAR-1
        // months are index based, december === 11
        return d1IsFutureDate ? TimeSpan.Tomorrow : TimeSpan.Yesterday;
    }

    if (newer.getFullYear() === older.getFullYear()) {
        // same year
        if (
            newer.getMonth() === older.getMonth() &&
            newer.getDate() - 1 === older.getDate()
        ) {
            // same month
            return d1IsFutureDate ? TimeSpan.Tomorrow : TimeSpan.Yesterday;
        }

        if (
            newer.getMonth() - 1 === older.getMonth() &&
            newer.getDate() === 1 &&
            isLastDayOfMonth(older)
        ) {
            return d1IsFutureDate ? TimeSpan.Tomorrow : TimeSpan.Yesterday;
        }
    }
    //#endregion

    //#region withinLastWeek or withinNextWeek
    if (newer.getFullYear() === older.getFullYear()) {
        if (
            newer.getMonth() === older.getMonth() &&
            newer.getDate() - older.getDate() < 7
        ) {
            return d1IsFutureDate
                ? TimeSpan.WithinNextWeek
                : TimeSpan.WithinLastWeek;
        }

        if (newer.getMonth() - 1 === older.getMonth()) {
            // get last day of d2 month
            const day = getLastDayOfMonth(older);
            const threshold = day - older.getDate();
            if (newer.getDate() + threshold < 7) {
                return d1IsFutureDate
                    ? TimeSpan.WithinNextWeek
                    : TimeSpan.WithinLastWeek;
            }
        }
    }

    if (newer.getFullYear() - 1 === older.getFullYear()) {
        const threshold = 31 - older.getDate();
        if (newer.getDate() + threshold < 7) {
            return d1IsFutureDate
                ? TimeSpan.WithinNextWeek
                : TimeSpan.WithinLastWeek;
        }
    }
    //#endregion

    return TimeSpan.Longer;
};

const toFileNameDateString = (date: Date): string => {
    date = new Date(date);
    return `${toYYYYMMDD(date, '')}${getHours(date)}${getMinutes(
        date,
    )}${getSeconds(date)}${date.getMilliseconds()}`;
};

const toDateAndTimeString = (date: Date): string => {
    return `${getDate(date)}.${getMonth(date)}.${date.getFullYear()}, ${toHHMM(
        date,
    )}`;
};

const getDate = (date: Date): string => {
    date = new Date(date);
    return getPaddedNumber(date.getDate());
};

const getSeconds = (date: Date): string => {
    date = new Date(date);
    return getPaddedNumber(date.getSeconds());
};

const getMinutes = (date: Date): string => {
    date = new Date(date);
    return getPaddedNumber(date.getMinutes());
};

const getMonth = (date: Date): string => {
    date = new Date(date);
    return getPaddedNumber(date.getMonth() + 1); // zero based
};

const getHours = (date: Date): string => {
    date = new Date(date);
    return getPaddedNumber(date.getHours());
};

const getMilliseconds = (date: Date): string => {
    date = new Date(date);
    const ms = date.getMilliseconds();
    let prefix = '';
    if (ms < 10) {
        prefix = '00';
    }
    if (ms < 100 && ms > 9) {
        prefix = '0';
    }

    return `${prefix}${ms}`;
};

const getPaddedNumber = (i: number): string => {
    return `${i > 9 ? '' : '0'}${i}`;
};

/**
 * @returns Now in format: YYYY-MM-DD HH:mm:ss.SSS
 */
const getTimeStamp = (): string => {
    const n = new Date();
    return `${n.getFullYear()}-${getMonth(n)}-${getDate(n)} ${getHours(
        n,
    )}:${getMinutes(n)}:${getSeconds(n)}.${getMilliseconds(n)}`;
};

const hasDST = (date: Date): boolean => {
    date = new Date(date);
    const january = new Date(
        new Date(date).getFullYear(),
        0,
        1,
    ).getTimezoneOffset();
    const july = new Date(
        new Date(date).getFullYear(),
        6,
        1,
    ).getTimezoneOffset();

    return Math.max(january, july) !== new Date(date).getTimezoneOffset();
};

export const MillisecondsToWeeks = 1000 * 60 * 60 * 24 * 7;

export enum TimeSpan {
    WithinSeconds,
    WithinMinutes,
    SameDay,
    Yesterday,
    WithinLastWeek,
    Longer,
    Tomorrow,
    WithinNextWeek,
}

const getPrettyDateOffsetText = (
    date: Date,
    translation: (key: string) => string,
): string => {
    date = new Date(date);
    let text = DateUtil.toDDMMYYYY(date);
    const timespan = DateUtil.getTimeSpan(date, new Date());
    switch (timespan) {
        case TimeSpan.Tomorrow: {
            return translation('common.tomorrow');
        }
        case TimeSpan.WithinSeconds: {
            return translation('common.today');
        }
        case TimeSpan.WithinMinutes: {
            return translation('common.today');
        }
        case TimeSpan.SameDay: {
            return translation('common.today');
        }
        case TimeSpan.Yesterday: {
            return translation('common.yesterday');
        }
        case TimeSpan.WithinLastWeek: {
            return translation(`common.days.${date.getDay()}`);
        }
    }
    return text;
};

const getPrettyDateAndTimeOffsetText = (
    date: Date,
    translation: (key: string) => string,
) => {
    let text = '';
    date = new Date(date);
    switch (DateUtil.getTimeSpan(date, new Date())) {
        case TimeSpan.WithinSeconds: {
            text = DateUtil.toHHMM(date);
            break;
        }
        case TimeSpan.WithinMinutes: {
            text = DateUtil.toHHMM(date);
            break;
        }
        case TimeSpan.SameDay: {
            text = DateUtil.toHHMM(date);
            break;
        }
        case TimeSpan.Yesterday: {
            text = translation('common.yesterday');
            break;
        }

        case TimeSpan.WithinLastWeek: {
            text = translation(`common.days.${date.getDay()}`);
            break;
        }

        default:
            text = DateUtil.toDDMMYY(date);
            break;
    }
    return text;
};

const checkIfStringIsIsoDateString = (string: string): boolean => {
    try {
        if (string && !isNaN(Date.parse(string))) {
            if (string == new Date(string)?.toISOString()) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    } catch (e) {
        LogClient.error(`Could not check ${string} for date`, e);
        return false;
    }
};

const parseDateStringsToDateInObject = (
    object: any,
    isFirstCall = true,
): any => {
    if (object && typeof object === 'object') {
        Object.keys(object).forEach(key => {
            if (key && typeof key === 'string') {
                const value = object[key];
                if (checkIfStringIsIsoDateString(value)) {
                    object[key] = new Date(value);
                } else if (object[key] && typeof object[key] === 'object') {
                    object[key] = value;
                    parseDateStringsToDateInObject(object[key], false);
                }
            }
        });
    }

    if (isFirstCall) {
        return object;
    }
};

export const DateUtil = {
    getAgeTranslationOptions,
    getLastDayOfMonth,
    getMMSSFromSeconds,
    getPrettyDateAndTimeOffsetText,
    getPrettyDateOffsetText,
    getSameOrLastDayDateOfNextMonth,
    getSameOrLastDayInXMonth,
    getTimeDifferenceInMinutes,
    getTimeSpan,
    getTimeStamp,
    hasDST,
    isLastDayOfMonth,
    millisecondsToMinutes,
    parseDateStringsToDateInObject,
    toDDMMYY,
    toDDMMYYYHHMM,
    toDDMMYYYY,
    toDateAndTimeString,
    toFileNameDateString,
    toHHMM,
    toHHMMSS,
    toRNAddCalendarEventFormat,
    toYYYYMMDD,
    toYYYYMMDDHHMM,
};

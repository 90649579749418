import { gql } from '@apollo/client';
import { Chat } from '../dto';
import {
    ChatMessageFragments,
    CompanyFragments,
    FileDescriptionFragments,
    TopicFragments,
} from '../fragments';
import { ChatToUserFragments } from '../fragments/chatToUserFragments';

export const FullResponse = gql`
    mutation CreateChat($chat: ChatCreate!) {
        createChat(chat: $chat) {
            id
            unreadMessageCount
            hasOpenRequest
            type
            animal {
                id
                name
                type
                breed
                specification
                description
                birthday
                isBirthdayEstimated
                createdAt
                updatedAt
                deletedAt
                deletedBy
                imageFile {
                    ...FullFileDescription
                }
                colorBundle {
                    hexColor
                    hexColorGradient
                }
                owners {
                    user {
                        id
                    }
                }
            }
            topic {
                ...FullTopic
            }
            animalGroup {
                id
                name
                type
                breed
                specification
                description
                averageBirthday
                groupSize
                isGroupSizeEstimated
                createdAt
                updatedAt
                deletedAt
                deletedBy
                imageFile {
                    ...FullFileDescription
                }
                colorBundle {
                    hexColor
                    hexColorGradient
                }
                owners {
                    user {
                        id
                    }
                }
            }
            company {
                ...FullCompany
            }
            lastMessage {
                ...FullChatMessage
            }
            chatToUser {
                ...FullChatToUser
            }
            createdAt
            deletedAt
            deletedBy
        }
    }
    ${ChatToUserFragments.FullChatToUser}
    ${ChatMessageFragments.FullResponse}
    ${CompanyFragments.FullCompany}
    ${TopicFragments.FullTopic}
    ${FileDescriptionFragments.FullResponse}
`;

export interface CreateChatData {
    createChat: Chat;
}

export const CreateChatMutation = {
    FullResponse,
};

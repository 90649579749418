import React, { useEffect, useState } from 'react';
import { useObservable } from '../../../hooks/useObservable';
import { useScreenHasLessWidthThan } from '../../../hooks/useScreenSize';
import RxChat from '../../../rxjs/RxChat';
import logUtil from '../../../utils/logUtil';
import { Chat } from '../../../__shared/graphql';
import styles from './chatSection.module.scss';
import ChatMain from './main/chatMain/chatMain';
import ChatMainPlaceholder from './main/chatMainPlaceholder/chatMainPlaceholder';

const ChatSection: React.FC = () => {
    const chats = useObservable(RxChat.chats$, []);
    const currentChatId = useObservable(RxChat.currentChatId$, 0);
    const loadedChatIds = useObservable(RxChat.loadedChatIds$, []);
    const [currentChat, setCurrentChat] = useState<Chat>();

    const [show, setShow] = useState(false);

    const [showEmptyPlaceholderChat, setShowEmptyPlaceholderChat] =
        useState<boolean>();

    const screenHasLessWidthThanXxs = useScreenHasLessWidthThan('xxs');

    useEffect(() => {
        if (
            currentChatId === 0 ||
            (!chats?.length && !screenHasLessWidthThanXxs)
        ) {
            setShowEmptyPlaceholderChat(true);
        }
    }, [
        currentChatId,
        chats?.length,
        setShowEmptyPlaceholderChat,
        screenHasLessWidthThanXxs,
    ]);

    // set current chat
    useEffect(() => {
        const currentChat = chats?.find(c => c.id === currentChatId);
        if (currentChat) {
            setCurrentChat(currentChat);
        }
        logUtil.log('chatSection currentChat:', currentChat);
    }, [currentChatId, chats]);

    useEffect(() => {
        if (currentChatId && screenHasLessWidthThanXxs) {
            setShow(true);
        } else {
            setShow(false);
        }
    }, [currentChatId, screenHasLessWidthThanXxs]);

    const mainStyle = screenHasLessWidthThanXxs
        ? show
            ? { left: '0px', width: '100vw' }
            : { left: '100vw' }
        : {};

    return (
        <section className={styles.main} style={mainStyle}>
            <>
                {chats?.length
                    ? chats?.map(chat => {
                          return (
                              <ChatMain
                                  key={chat.id}
                                  loaded={loadedChatIds?.includes(
                                      currentChatId,
                                  )}
                                  show={currentChatId === chat.id}
                                  chat={chat}
                                  hide={() => {
                                      RxChat.setCurrentChatId(0);
                                  }}
                              />
                          );
                      })
                    : null}
                {!screenHasLessWidthThanXxs && showEmptyPlaceholderChat && (
                    <ChatMainPlaceholder
                        isLoading={
                            currentChatId > 0 &&
                            (!currentChat || currentChatId !== currentChat.id)
                        }
                    />
                )}
            </>
        </section>
    );
};

export default ChatSection;

// import { createBrowserHistory } from 'history';
import { use } from 'i18next';
import { initReactI18next } from 'react-i18next';
import deTranslations from './assets/i18n/de.json';
import enTranslations from './assets/i18n/en.json';

// import React from 'react';
import { positions, Provider as AlertProvider } from 'react-alert';
// import 'react-datepicker/dist/react-datepicker.css';
// import { Redirect, Route, Router, Switch } from 'react-router-dom';
import AlertTemplate from './components/alertTemplate/alertTemplate';
// import LoginGuard from './components/loginGuard/loginGuard';
import Modal from './components/modal/modal';
import SystemBanner from './components/systemBanner/systemBanner';
import UpdateBanner from './components/updateBanner/updateBanner';
import './index.scss';
import {
    registerOpenChatListener,
    registerOpenSettingsListener,
} from './rxjs/onAnimalChatElectronSubject';
// import AnimalChatRoutes from './utils/routing';

// const history = createBrowserHistory();
use(initReactI18next).init({
    resources: {
        de: {
            translation: deTranslations,
        },
        en: {
            translation: enTranslations,
        },
    },
    lng: 'de',
    fallbackLng: 'de',
    interpolation: {
        escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
});

window.onload = () => {
    registerOpenSettingsListener();
    registerOpenChatListener();
};

import React from 'react';

import './app.module.scss';
import { BrowserRouter } from 'react-router-dom';
import Router from './pages/router';
import { NavigationBar } from './pages/business/components/navigationBar/navigationBar';
import ApolloWrapper from './api/apolloWrapper';

const App: React.FC = () => {
    return (
        <ApolloWrapper>
            <AlertProvider
                template={AlertTemplate}
                position={positions.BOTTOM_RIGHT}
                timeout={2000}
                containerStyle={{
                    zIndex: 999,
                }}>
                <SystemBanner />
                <UpdateBanner />
                <Modal />
                <BrowserRouter>
                    <NavigationBar />
                    <Router />
                </BrowserRouter>
            </AlertProvider>
        </ApolloWrapper>
    );
};

export default App;

import React, { useMemo } from 'react';
import Icon from '../../../../components/icon/icon';
import styles from './cardHeader.module.scss';

interface CardHeaderProps {
    title: string;
    onBack?: () => void;
}

const CardHeader: React.FC<CardHeaderProps> = ({ title, onBack }) => {
    const hasBackFunction = useMemo(() => typeof onBack === 'function', [
        onBack,
    ]);

    return (
        <div className={styles.header}>
            {hasBackFunction && (
                <div className={styles.backIcon} onClick={onBack}>
                    <Icon name="chevron-back-outline" size={32} />
                </div>
            )}
            <h4>{title}</h4>
        </div>
    );
};

export default CardHeader;

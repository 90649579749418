import { t } from 'i18next';
import React from 'react';
import ProfileIcon from '../../../../components/profileIcon/profileIcon';
import { useObservable } from '../../../../hooks/useObservable';
import RxCompany from '../../../../rxjs/RxCompany';
import styles from './companyHeader.module.scss';

interface CompanyHeaderProps {
    onShowChatCreation: () => void;
}

const CompanyHeader: React.FC<CompanyHeaderProps> = () => {
    const company = useObservable(RxCompany.currentCompany$);
    const responseTimeText = company?.medianResponseTimeInSeconds
        ? Math.floor(company?.medianResponseTimeInSeconds / 60) < 1
            ? '< 1'
            : Math.floor(company?.medianResponseTimeInSeconds / 60)
        : '-';

    const imageFile = company?.imageFile;
    const profileInProfileImageFile = company?.profileInProfileImageFile;

    return (
        <div className={styles.navHeader}>
            <ProfileIcon
                size={48}
                fileDescription={imageFile}
                colorBundle={company?.colorBundle}
                profileInProfileFileDescription={profileInProfileImageFile}
                profileInProfilePosition="right"
                profileInProfileSize={18}
            />
            <div className={styles.practice}>
                <p>{company?.name}</p>
                <div className={styles.subTitleContainer}>
                    <span className={styles.subTitle}>
                        {t('screens.chat.navigation.subtitle')}
                    </span>
                    {/* 
                        TODO: This component is not finished yet
                        it will be part of "create new internal chat story"
                    <div
                        className={styles.addIcon}
                        onClick={onShowChatCreation}>
                        <Icon size={20} name="add" />
                    </div> */}
                    {company?.medianResponseTimeInSeconds != null && (
                        <span
                            className={styles.medianResponseTimeBadgeContainer}>
                            <span
                                className={
                                    styles.medianResponseTimeBadge
                                }>{`${responseTimeText} ${'min'}`}</span>
                        </span>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CompanyHeader;

import React from 'react';
import { ClassNameProp } from '../../types/stylingProps';
import styles from './counterBadge.module.scss';

interface CounterBadgeProps {
    count: number;
    showOnZero?: boolean;
}

const CounterBadge: React.FC<CounterBadgeProps & ClassNameProp> = ({
    count,
    showOnZero = false,
    className,
}) => {
    return (
        <span className={`${styles.container} ${className ?? ''}`.trim()}>
            {count != null && (count > 0 || showOnZero) ? (
                <div className={styles.unreadMessageCountBubble}>
                    <div className={styles.unreadMessageCountBubbleText}>
                        {count}
                    </div>
                </div>
            ) : null}
        </span>
    );
};

export default CounterBadge;

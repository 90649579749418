import {
    AssignLicenceData,
    CancelLicenceData,
    Company,
    CompanyMutations,
    LicenceToCompany,
    MutationAssignLicenceArgs,
    MutationCancelLicenceArgs,
    MutationRemoveUserFromLicenceArgs,
    MutationRevokeLicenceCancellationArgs,
    MutationUpgradeLicenceArgs,
    RemoveUserFromLicenceData,
    RevokeLicenceCancellationData,
    UpgradeLicenceData,
} from '../../../../__shared/graphql';
import {
    CompanyLicenceTypes,
    DateUtil,
    isCompanyLicenceType,
    isUserLicenceType,
    LicenceType,
    LicenceTypes,
} from '../../../../__shared/common';
import { useMutation } from '@apollo/client';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/button/button';
import ProfileIcon from '../../../../components/profileIcon/profileIcon';
import Select from '../../../../components/select/select';
import Switch from '../../../../components/switch/switch';
import { useAlert } from '../../../../hooks/useAlert';
import useModal from '../../../../hooks/useModal';
import { useObservable } from '../../../../hooks/useObservable';
import useUpdateCompany from '../../../../hooks/useUpdateCompany';
import RxCompany from '../../../../rxjs/RxCompany';
import logUtil from '../../../../utils/logUtil';
import {
    buildNameFromEmployee,
    buildNameFromUser,
} from '../../../../utils/nameUtils';
import { Option } from '../../../../utils/option';
import { LicenceTypeTranslations } from './subscriptionCreate';
import styles from './subscriptionDetail.module.scss';
import { LicenceTypeListTranslations } from './subscriptionList';

interface SubscriptionDetailProps {
    subscription: LicenceToCompany;
    onUpdate: (subscription: LicenceToCompany) => void;
    onUpgrade: (
        oldSubscription: LicenceToCompany,
        newSubscription: LicenceToCompany,
        shouldReload?: boolean,
    ) => void;
}

const ProfileIconSize = 92;

let NotAssignedLabel = '';
const NotAssignedValue = 'not-assigned';
const getEmployeeOptions = (company?: Company): Option[] => {
    if (!company?.employees?.length) {
        return [];
    }
    const result = company.employees
        .filter(e => !!e.user?.id)
        .map(e => {
            return {
                label: buildNameFromEmployee(e),
                value: e.id.toString(),
            };
        });

    return [{ label: NotAssignedLabel, value: NotAssignedValue }, ...result];
};

const getUpgradesForLicence = (licenceType?: string): LicenceType[] => {
    if (!licenceType) {
        return [];
    }
    switch (licenceType) {
        case LicenceTypes.Company.Free:
            return [
                LicenceTypes.Company.StandardPerMonth,
                LicenceTypes.Company.StandardPerYear,
            ];
        case LicenceTypes.Company.StandardPerMonth:
            return [LicenceTypes.Company.StandardPerYear];

        case LicenceTypes.User.Member:
            return [LicenceTypes.User.Consultant];
        default:
            return [];
    }
};

const SubscriptionDetail: React.FC<SubscriptionDetailProps> = ({
    subscription,
    onUpdate,
    onUpgrade,
}) => {
    const { reloadCompany } = useUpdateCompany();
    const [licenceToCompany, setLicenceToUser] = useState(subscription);
    const [licenceUpgradeOptions, setLicenceUpgradeOptions] = useState<
        Option[]
    >([]);
    const [upgradeLicenceType, setUpgradeLicenceType] = useState<string>();
    const company = useObservable(RxCompany.currentCompany$);
    const { t } = useTranslation();

    useEffect(() => {
        logUtil.log('setLicenceToUser');
        setLicenceToUser(subscription);
    }, [subscription]);

    useEffect(() => {
        NotAssignedLabel = t('screens.settings.subscriptions.notAssigned');
    }, [t]);

    const alert = useAlert();
    const modal = useModal();
    const [employeeOptions, setEmployeeOptions] = useState<Option[]>([]);
    const [employeeId, setEmployeeId] = useState<string>();
    const [assignLicenceMutation] = useMutation<
        AssignLicenceData,
        MutationAssignLicenceArgs
    >(CompanyMutations.AssignLicenceMutations.AssignLicenceMutation);
    const [removeUserFromLicenceMutation] = useMutation<
        RemoveUserFromLicenceData,
        MutationRemoveUserFromLicenceArgs
    >(
        CompanyMutations.RemoveUserFromLicenceMutations
            .RemoveUserFromLicenceMutation,
    );
    const [cancelLicenceMutation] = useMutation<
        CancelLicenceData,
        MutationCancelLicenceArgs
    >(CompanyMutations.CancelLicenceMutations.CancelLicenceMutation);
    const [revokeLicenceCancellationMutation] = useMutation<
        RevokeLicenceCancellationData,
        MutationRevokeLicenceCancellationArgs
    >(
        CompanyMutations.RevokeLicenceCancellationMutations
            .RevokeLicenceCancellationMutation,
    );
    const [upgradeLicenceMutation] = useMutation<
        UpgradeLicenceData,
        MutationUpgradeLicenceArgs
    >(CompanyMutations.UpgradeLicenceMutations.UpgradeLicenceMutation);

    if (!licenceToCompany.licence) {
        throw new Error(
            `Missing licence in licenceToCompany with id ${licenceToCompany.id}`,
        );
    }

    useEffect(() => {
        if (!company) {
            return;
        }
        let options: Option[] = [];
        if (isUserLicenceType(licenceToCompany.licence?.type ?? '')) {
            options = getEmployeeOptions(company);
            setEmployeeId(options[0].value);
        }
        setEmployeeOptions(options);
    }, [licenceToCompany.licence, company]);

    const assignLicence = useCallback(
        async (licenceToCompanyId: number, employeeId: number) => {
            try {
                const response = await assignLicenceMutation({
                    variables: {
                        licenceToCompanyId,
                        employeeId,
                    },
                });
                if (response.data) {
                    onUpdate(response.data.assignLicence);
                    return;
                }
                // TODO: more specific alert
                alert.error('common.alerts.defaultError');
            } catch (e) {
                logUtil.warn('error while assigning licence', e);
                // TODO: more specific alert
                alert.error('common.alerts.defaultError');
            }
        },
        [assignLicenceMutation, onUpdate, alert],
    );

    const cancelLicence = useCallback(
        async (licenceToCompanyId: number) => {
            try {
                const response = await cancelLicenceMutation({
                    variables: {
                        licenceToCompanyId,
                    },
                });
                if (response.data) {
                    onUpdate(response.data.cancelLicence);
                    return;
                }
                // TODO: more specific alert
                alert.error('common.alerts.defaultError');
            } catch (e) {
                logUtil.warn('error while cancelling licence', e);
                // TODO: more specific alert
                alert.error('common.alerts.defaultError');
            }
        },
        [cancelLicenceMutation, onUpdate, alert],
    );

    const removeUserFromLicence = useCallback(
        async (licenceToCompanyId: number) => {
            try {
                const response = await removeUserFromLicenceMutation({
                    variables: {
                        licenceToCompanyId,
                    },
                });
                if (response.data) {
                    onUpdate(response.data.removeUserFromLicence);
                    return;
                }
                // TODO: more specific alert
                alert.error('common.alerts.defaultError');
            } catch (e) {
                logUtil.warn('error while cancelling licence', e);
                // TODO: more specific alert
                alert.error('common.alerts.defaultError');
            }
        },
        [removeUserFromLicenceMutation, onUpdate, alert],
    );

    const revokeLicenceCancellation = useCallback(
        async (licenceToCompanyId: number) => {
            try {
                const response = await revokeLicenceCancellationMutation({
                    variables: {
                        licenceToCompanyId,
                    },
                });
                if (response.data) {
                    onUpdate(response.data.revokeLicenceCancellation);
                    return;
                }
                // TODO: more specific alert
                alert.error('common.alerts.defaultError');
            } catch (e) {
                logUtil.warn('error while cancelling licence', e);
                // TODO: more specific alert
                alert.error('common.alerts.defaultError');
            }
        },
        [revokeLicenceCancellationMutation, onUpdate, alert],
    );

    const upgradeLicence = useCallback(
        async (licenceType?: string) => {
            logUtil.log(`upgrading licence: ${licenceType}`);
            if (!licenceType) {
                return;
            }
            const isUpgradeFromCompanyFree =
                subscription.licence?.type === CompanyLicenceTypes.Free;
            if (isUpgradeFromCompanyFree) {
                const proceed = await modal.show({
                    text: t(
                        'screens.settings.subscriptions.upgradeFreeCompanyLicenceUserWarning',
                    ),
                    title: t('screens.settings.subscriptions.licenceUpgrade'),
                });
                if (!proceed) {
                    return;
                }
            }

            try {
                const response = await upgradeLicenceMutation({
                    variables: {
                        licenceToCompanyId: subscription.id,
                        licenceType,
                    },
                });
                if (response.data) {
                    if (isCompanyLicenceType(licenceType)) {
                        reloadCompany(subscription.companyId);
                    }
                    onUpgrade(
                        subscription,
                        response.data.upgradeLicence,
                        isUpgradeFromCompanyFree,
                    );
                    return;
                }
                // TODO: more specific alert
                alert.error('common.alerts.defaultError');
            } catch (e) {
                logUtil.warn('error while upgrading licence', e);
                // TODO: more specific alert
                alert.error('common.alerts.defaultError');
            }
        },
        [
            upgradeLicenceMutation,
            onUpgrade,
            reloadCompany,
            subscription,
            alert,
            modal,
            t,
        ],
    );

    useEffect(() => {
        const licenceOptions = getUpgradesForLicence(
            licenceToCompany.licence?.type,
        ).map(lic => ({
            label: t(LicenceTypeTranslations[lic]),
            value: lic,
        }));
        setLicenceUpgradeOptions(licenceOptions);
        setUpgradeLicenceType(licenceOptions[0]?.value);
    }, [licenceToCompany.licence.type, t]);

    const renewalDate = DateUtil.toDDMMYYYY(
        DateUtil.getSameOrLastDayInXMonth(
            licenceToCompany.validSince,
            licenceToCompany.licence.durationInMonths,
        ),
    );

    const licenceCompany = licenceToCompany.company;
    const user = licenceToCompany.user;
    const isAssignedToUser = !!licenceToCompany.userId;
    const isUserLicence = isUserLicenceType(licenceToCompany.licence.type);
    const isCancelled =
        !!licenceToCompany.cancelledFor ||
        !!licenceToCompany.specialCancellationFor;

    return (
        <div className={styles.container}>
            <div>
                <h3>{t('screens.settings.subscriptions.subscription')}</h3>
                <div className={styles.licenceName}>
                    {t(
                        LicenceTypeListTranslations[
                            licenceToCompany.licence.type
                        ],
                    )}
                </div>

                <div className={styles.renewalContainer}>
                    <div className={styles.renewalText}>
                        <h3>
                            {t(
                                'screens.settings.subscriptions.automaticRenewal',
                            )}
                        </h3>
                        <h5 className={`${isCancelled ? styles.hide : ''}`}>
                            {t(
                                'screens.settings.subscriptions.willBeRenewedAt',
                                {
                                    renewalDate,
                                },
                            )}
                        </h5>
                        <h5
                            className={`${styles.cancelled} ${
                                isCancelled ? '' : styles.hide
                            }`.trim()}>
                            {t(
                                'screens.settings.subscriptions.validUntilDate',
                                {
                                    validUntil: DateUtil.toDDMMYYYY(
                                        licenceToCompany.cancelledFor ??
                                            new Date(),
                                    ),
                                },
                            )}
                        </h5>
                    </div>
                    <Switch
                        id={`subscription-switch-${licenceToCompany.id}`}
                        className={styles.switch}
                        handleToggle={newCancelled => {
                            if (newCancelled) {
                                revokeLicenceCancellation(licenceToCompany.id);
                            } else {
                                cancelLicence(licenceToCompany.id);
                            }
                        }}
                        isOn={!isCancelled}
                    />
                </div>
                <div
                    className={`${styles.upgradeContainer} ${
                        licenceUpgradeOptions.length ? '' : styles.hide
                    }`.trim()}>
                    <span className={styles.assignmentLabel}>
                        {t('screens.settings.subscriptions.upgradeLicence')}
                    </span>
                    <Select
                        options={licenceUpgradeOptions}
                        defaultValue={upgradeLicenceType}
                        onChange={option => {
                            setUpgradeLicenceType((option as Option).value);
                        }}
                    />
                    <Button
                        className={styles.buyButton}
                        onClick={() => upgradeLicence(upgradeLicenceType)}
                        text={t('screens.settings.subscriptions.buyLicence')}
                    />
                </div>
                <div className={styles.assignmentHeader}>
                    <span className={styles.assignmentLabel}>
                        {t('screens.settings.subscriptions.assignment')}
                    </span>
                    <div
                        className={`${styles.remove} ${
                            isUserLicence && isAssignedToUser ? '' : styles.hide
                        }`}
                        onClick={() => {
                            removeUserFromLicence(licenceToCompany.id);
                        }}>
                        {t('screens.settings.subscriptions.remove')}
                    </div>
                </div>
                {isUserLicence && !isAssignedToUser && (
                    <Select
                        options={employeeOptions}
                        defaultValue={employeeId}
                        onChange={option => {
                            const employeeId = employeeOptions.find(
                                opt => opt.value === (option as Option).value,
                            )?.value;
                            setEmployeeId(employeeId);
                            if (employeeId && employeeId !== NotAssignedValue) {
                                assignLicence(
                                    licenceToCompany.id,
                                    parseInt(employeeId, 10),
                                );
                            }
                        }}
                    />
                )}
                {isUserLicence && (
                    <div
                        className={`${styles.card} ${
                            isAssignedToUser ? '' : styles.hide
                        }`.trim()}>
                        <ProfileIcon
                            size={ProfileIconSize}
                            fileDescription={user?.imageFile}
                            colorBundle={user?.colorBundle}
                        />
                        <span className={styles.name}>
                            {buildNameFromUser(user)}
                        </span>
                    </div>
                )}
                {!isUserLicence && (
                    <div className={styles.card}>
                        <ProfileIcon
                            size={ProfileIconSize}
                            fileDescription={licenceCompany?.imageFile}
                            colorBundle={licenceCompany?.colorBundle}
                        />
                        <span className={styles.name}>
                            {licenceCompany?.name}
                        </span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SubscriptionDetail;

import {
    Address,
    AnimalContext,
    Chat,
    ChatMessage,
    Customer,
    FindAnimalContextsForUserData,
    FindAnimalContextsForUserQuery,
    GetCustomerForUserAndCompanyData,
    GetCustomerForUserAndCompanyQuery,
    QueryFindAnimalContextsForUserArgs,
    QueryGetCustomerForUserAndCompanyArgs,
} from '../../../../__shared/graphql';
import { useLazyQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PreviewItemMedia from '../../../../components/previewItemMedia/previewItemMedia';
import Icon from '../../../../components/icon/icon';
import ProfileIcon from '../../../../components/profileIcon/profileIcon';
import { useObservable } from '../../../../hooks/useObservable';
import RxChat from '../../../../rxjs/RxChat';
import { buildNameFromCustomer } from '../../../../utils/nameUtils';
import styles from './customerProfile.module.scss';
import { publishShowPreviewMessages } from '../../../../rxjs/onShowPreviewMessages';

export interface CustomerProfileProps {
    chatMessagesWithFiles: ChatMessage[];
    onShowMedia: () => void;
    onShowAnimalContexts: (animalContexts: AnimalContext[]) => void;
}

const CustomerProfile: React.FC<CustomerProfileProps> = ({
    chatMessagesWithFiles = [],
    onShowMedia,
    onShowAnimalContexts,
}) => {
    const currentChatId = useObservable(RxChat.currentChatId$, 0);
    const chats = useObservable(RxChat.chats$, []);
    const [chat, setChat] = useState<Chat>();
    const [animalContexts, setAnimalContexts] = useState<AnimalContext[]>([]);
    const [address, setAddress] = useState<Address | null | undefined>();
    const [customer, setCustomer] = useState<Customer>();
    const [getCustomerForUserAndCompany, { data: customerData }] = useLazyQuery<
        GetCustomerForUserAndCompanyData,
        QueryGetCustomerForUserAndCompanyArgs
    >(GetCustomerForUserAndCompanyQuery.FullResponse);

    const [findAnimalContextsForUser, { data: animalContextsData }] =
        useLazyQuery<
            FindAnimalContextsForUserData,
            QueryFindAnimalContextsForUserArgs
        >(FindAnimalContextsForUserQuery.FullResponse);

    // set current chat
    useEffect(() => {
        const currentChat = chats?.find(c => c.id === currentChatId);
        if (currentChat) {
            setChat(currentChat);
        }
    }, [currentChatId, chats]);

    // find animal creator for current chat and load the profile
    useEffect(() => {
        let userId = chat?.animal?.createdBy;
        if (!userId) {
            userId = chat?.animalGroup?.createdBy;
        }
        if (!userId) {
            userId = chat?.topic?.createdBy;
        }
        if (userId && chat?.company?.id) {
            getCustomerForUserAndCompany({
                variables: { userId, companyId: chat.company.id },
            });
            findAnimalContextsForUser({
                context: {},
                variables: { searchInput: '', userId },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        currentChatId,
        chat?.id,
        getCustomerForUserAndCompany,
        findAnimalContextsForUser,
    ]);

    // set current profile
    useEffect(() => {
        if (customerData?.getCustomerForUserAndCompany) {
            setCustomer(customerData.getCustomerForUserAndCompany);
            setAddress(
                customerData.getCustomerForUserAndCompany.user?.address ??
                    customerData.getCustomerForUserAndCompany.contact?.address,
            );
        }
    }, [customerData]);

    useEffect(() => {
        if (animalContextsData?.findAnimalContextsForUser) {
            setAnimalContexts(animalContextsData.findAnimalContextsForUser);
        }
    }, [animalContextsData]);

    const { t } = useTranslation();

    const customerFullName = buildNameFromCustomer(customer);

    const phoneNumber =
        customer?.contact?.phoneNumber ?? customer?.user?.phoneNumber;

    return (
        <div className={styles.container}>
            <div className={styles.profilePicture}>
                <div className={styles.profileIcon}>
                    <ProfileIcon
                        size={180}
                        fileDescription={customer?.user?.imageFile}
                        colorBundle={customer?.user?.colorBundle}
                    />
                </div>
                <div className={styles.profileName}>
                    <p>{customerFullName}</p>
                </div>
            </div>
            <div
                className={styles.animals}
                onClick={() => onShowAnimalContexts(animalContexts)}>
                <div className={styles.title}>
                    <span>{t('common.animals')}</span>
                    <Icon size={16} name="chevron-forward-outline" />
                </div>
                <div className={styles.profiles}>
                    {animalContexts.map(
                        (animalContext: AnimalContext, i: number) => (
                            <ProfileIcon
                                key={`animal-context-${i}`}
                                fileDescription={
                                    animalContext?.animal?.imageFile ??
                                    animalContext?.animalGroup?.imageFile
                                }
                                colorBundle={
                                    animalContext?.animal?.colorBundle ??
                                    animalContext?.animalGroup?.colorBundle
                                }
                                className={styles.profile}
                                style={{
                                    zIndex: animalContexts.length - i,
                                    background: `linear-gradient(${
                                        animalContext?.animal?.colorBundle
                                            ?.hexColor ??
                                        animalContext?.animalGroup?.colorBundle
                                            ?.hexColor
                                    }, ${
                                        animalContext?.animal?.colorBundle
                                            ?.hexColorGradient ??
                                        animalContext?.animalGroup?.colorBundle
                                            ?.hexColorGradient
                                    }`,
                                }}
                            />
                        ),
                    )}
                </div>
            </div>
            <div className={styles.media}>
                <div className={styles.title} onClick={onShowMedia}>
                    <span>{t('screens.chat.detail.customer.media')}</span>
                    <Icon size={16} name="chevron-forward-outline" />
                </div>
                <div className={styles.preview}>
                    {chatMessagesWithFiles.slice(0, 3).map(message => {
                        return (
                            <PreviewItemMedia
                                className={styles.previewItem}
                                key={message.id}
                                message={message}
                                onClick={() => {
                                    publishShowPreviewMessages(
                                        chatMessagesWithFiles,
                                        message,
                                    );
                                }}
                            />
                        );
                    })}
                </div>
            </div>
            <div className={styles.info}>
                <div className={styles.title}>
                    <span>{t('screens.chat.detail.customer.info')}</span>
                    {/* <Icon size={16} name="chevron-forward-outline" /> */}
                </div>
                <div className={styles.detail}>{phoneNumber}</div>
            </div>
            <div className={styles.address}>
                <div className={styles.title}>
                    <span>{t('screens.chat.detail.customer.address')}</span>
                    {/* <Icon size={16} name="chevron-forward-outline" /> */}
                </div>
                <div className={styles.detail}>
                    <span>
                        {address?.streetName} {address?.houseNumber}
                    </span>
                    <span>
                        {address?.zipCode} {address?.city}
                    </span>
                    <span>{address?.country}</span>
                </div>
            </div>
        </div>
    );
};

export default CustomerProfile;

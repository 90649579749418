import { Chat } from '../dto';
import { gql } from '@apollo/client';
import { ChatFragments } from '../fragments';

const FullResponse = gql`
    query GetChat($chatId: Int!) {
        getChat(chatId: $chatId) {
            ...FullChat
        }
    }
    ${ChatFragments.FullChat}
`;

export interface GetChatData {
    getChat: Chat;
}

export const GetChatQuery = { FullResponse };

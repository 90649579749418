import { ChatMessage } from '../dto';
import { gql } from '@apollo/client';
import { ChatMessageFragments } from '../fragments';

const FullResponse = gql`
    mutation createChatMessage($chatMessage: ChatMessageCreate!) {
        createChatMessage(chatMessage: $chatMessage) {
            ...FullChatMessage
        }
    }
    ${ChatMessageFragments.FullResponse}
`;

export interface CreateChatMessageData {
    createChatMessage: ChatMessage;
}

export const CreateChatMessageMutation = {
    FullResponse,
};

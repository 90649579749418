import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../components/button/button';
import Input from '../../../../../components/input/input';
import PhoneNumberInput from '../../../../../components/phoneNumberInput/phoneNumberInput';
import Select from '../../../../../components/select/select';
import { Limits } from '../../../../../utils/inputLimits';
import { CompanyTypeOptions, Option } from '../../../../../utils/option';
import Card from '../../../components/card/card';
import CardHeader from '../../../components/cardHeader/cardHeader';
import styles from './commonCompany.module.scss';
import { CommonCompanyData, isValidCommonData } from './createCompany';

interface CommonCompanyProps {
    formData: CommonCompanyData;
    onBack: (data: CommonCompanyData) => void;
    onNext: (data: CommonCompanyData) => void;
}

const CommonCompany: React.FC<CommonCompanyProps> = ({
    formData,
    onBack,
    onNext,
}) => {
    const [form, setForm] = useState<CommonCompanyData>(formData);
    const [isValid, setIsValid] = useState(false);

    useEffect(() => {
        setIsValid(isValidCommonData(form));
    }, [form]);

    const { t } = useTranslation();

    const onChange = (key: string, value: string) =>
        setForm(prev => ({ ...prev, [key]: value }));

    return (
        <Card>
            <CardHeader
                title={t('screens.business.company.common.title')}
                onBack={() => onBack(form)}
            />
            <div className={styles.row}>
                <Input
                    value={form.name}
                    onChange={e => onChange('name', e.target.value)}
                    placeholder={`${t(
                        'screens.business.company.common.name',
                    )}*`}
                    id="create-company-name-input"
                    dataCy="create-company-name-input"
                    limit={Limits.CompanyName}
                />
            </div>
            <div className={styles.row}>
                <Input
                    value={form.email}
                    onChange={e => onChange('email', e.target.value)}
                    placeholder={`${t(
                        'screens.business.company.common.email',
                    )}*`}
                    id="create-company-email-input"
                    dataCy="create-company-email-input"
                    limit={Limits.Email}
                />
            </div>
            <div className={styles.row}>
                <Select
                    isRequired
                    defaultValue={form.type}
                    options={CompanyTypeOptions.map(o => ({
                        label: t(o.label),
                        value: o.value,
                    }))}
                    onChange={option => {
                        onChange('type', (option as Option).value);
                    }}
                />
            </div>
            <div className={styles.row}>
                <Input
                    value={form.nametag}
                    onChange={e => onChange('nametag', e.target.value)}
                    placeholder={t('screens.business.company.common.nametag')}
                    id="create-company-nametag-input"
                    dataCy="create-company-nametag-input"
                    limit={Limits.CompanyNameTag}
                />
            </div>
            <div className={styles.row}>
                <Input
                    value={form.description}
                    onChange={e => onChange('description', e.target.value)}
                    placeholder={t(
                        'screens.business.company.common.description',
                    )}
                    id="create-company-description-input"
                    dataCy="create-company-description-input"
                    limit={Limits.CompanyDescription}
                />
            </div>
            <div className={styles.row}>
                <PhoneNumberInput
                    value={form.phoneNumber}
                    onChange={value => onChange('phoneNumber', value)}
                    id="create-company-phoneNumber-input"
                    dataCy="create-company-phoneNumber-input"
                    errorClassName={styles.phoneNumberError}
                    inputProps={{
                        limit: Limits.PhoneNumber,
                        placeholder: `${t(
                            'screens.business.company.common.phoneNumber',
                        )}*`,
                    }}
                />
            </div>
            <div className={styles.row}>
                <PhoneNumberInput
                    value={form.emergencyServicePhoneNumber}
                    onChange={value =>
                        onChange('emergencyServicePhoneNumber', value)
                    }
                    id="create-company-emergencyServicePhoneNumber-input"
                    dataCy="create-company-emergencyServicePhoneNumber-input"
                    errorClassName={styles.phoneNumberError}
                    inputProps={{
                        limit: Limits.PhoneNumber,
                        placeholder: t(
                            'screens.business.company.common.emergencyServicePhoneNumber',
                        ),
                    }}
                />
            </div>
            <div className={styles.row}>
                <Input
                    value={form.website}
                    onChange={e => onChange('website', e.target.value)}
                    placeholder={t('screens.business.company.common.website')}
                    id="create-company-website-input"
                    dataCy="create-company-website-input"
                    limit={Limits.Website}
                />
            </div>
            <div className={styles.row}>
                {/* <p className={styles.switchLabel}>
                    {t('screens.business.company.common.isSearchable')}
                </p>
                <Switch
                    isOn={!!form.isSearchable}
                    id="company-is-searchable"
                    handleToggle={isSearchable =>
                        setForm(prev => ({ ...prev, isSearchable }))
                    }
                /> */}
                <p className={styles.changeDetailsLater}>
                    {t('screens.business.company.changeDetailsLater')}
                </p>
            </div>
            <Button
                text={t('common.buttons.next')}
                onClick={() => onNext(form)}
                disabled={!isValid}
                className={styles.nextButton}
            />
            <p className={styles.legend}>
                {t('screens.business.requiredField')}
            </p>
        </Card>
    );
};

export default CommonCompany;

import React, { useEffect, useState } from 'react';
import { FadeLoader } from 'react-spinners';
import ApiImage from '../../../../../../components/apiImage/apiImage';
import ApiVideoPlayer from '../../../../../../components/apiVideoPlayer/apiVideoPlayer';
import Icon from '../../../../../../components/icon/icon';
import { useObservable } from '../../../../../../hooks/useObservable';
import RxFileCache from '../../../../../../rxjs/RxFileCache';
import { isImageType, isVideoType } from '../../../../../../utils/fileUtils';
import { DateUtil, getDetailPicture } from '../../../../../../__shared/common';
import { FileDescription } from '../../../../../../__shared/graphql';
import styles from './chatMessageMediaFilePreview.module.scss';

export interface ChatMessageMediaFilePreviewProps {
    fileDescription: FileDescription;
    onShowImage: () => void;
}

const ChatMessageMediaFilePreview: React.FC<
    ChatMessageMediaFilePreviewProps
> = ({ fileDescription, onShowImage }) => {
    const [optimalImage, setOptimalImage] = useState<FileDescription>();
    const updatedFileDescription = useObservable(
        RxFileCache.getCacheUpdateObservableForFileUuid(fileDescription.uuid),
    );
    const [loading, setLoading] = useState<boolean>();

    useEffect(() => {
        const _optimal = getDetailPicture(
            updatedFileDescription ?? fileDescription,
        );
        setOptimalImage(_optimal);
    }, [updatedFileDescription, fileDescription]);

    useEffect(() => {
        const _optimal = getDetailPicture(fileDescription);
        setOptimalImage(_optimal);
    }, [fileDescription]);

    if (isImageType(fileDescription?.fileType)) {
        const imageFile = fileDescription;
        if (!imageFile) {
            throw new Error(
                'Cannot display a file chat message without a file',
            );
        }
        return (
            <div className={styles.imageContainer}>
                {loading && <FadeLoader color={'gray'} />}
                <ApiImage
                    onLoadingChanged={setLoading}
                    nativeProps={{
                        onClick: onShowImage,
                        style: loading ? { display: 'none' } : {},
                    }}
                    fileDescription={imageFile}
                    fileDescriptionWithOptimalResolution={optimalImage}
                />
            </div>
        );
    }

    if (isVideoType(fileDescription?.fileType)) {
        return (
            <div
                className={styles.videoWrapper}
                onClick={e => {
                    // dont play video, open in preview mode
                    e.stopPropagation();
                    e.preventDefault();
                    onShowImage();
                }}>
                {loading ? (
                    <FadeLoader color={'gray'} />
                ) : (
                    <Icon
                        size={60}
                        name="play-circle-outline"
                        className={styles.playIcon}
                    />
                )}
                <ApiVideoPlayer
                    fileDescription={fileDescription}
                    onLoadingChanged={setLoading}
                    nativeProps={{
                        pip: false,
                        height: 'auto',
                        width: 'auto',
                    }}
                />

                {!!fileDescription.duration && (
                    <div className={styles.videoInfo}>
                        <Icon size={14} name="videocam" />
                        <span>
                            {DateUtil.getMMSSFromSeconds(
                                fileDescription.duration,
                            )}
                        </span>
                    </div>
                )}
            </div>
        );
    }

    console.warn(`unknown file type: ${fileDescription.fileType}`);
    return null;
};

export default ChatMessageMediaFilePreview;

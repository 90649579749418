import { ApolloProvider } from '@apollo/client';
import React, { useMemo } from 'react';
import { LogClient } from '../__shared/common/logging/logClient';
import createApolloClient from './apolloClient';

const LogPrefix = '[ApolloWrapper]';

const ApolloWrapper: React.FC = ({ children }) => {
    const client = useMemo(() => {
        LogClient.info(`${LogPrefix} creating apollo client`);
        return createApolloClient();
    }, []);

    LogClient.info(`${LogPrefix} Rendering apollo wrapper/provider`);

    return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloWrapper;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import NavBackButton from '../../../../components/navBackButton/navBackButton';
import { useObservable } from '../../../../hooks/useObservable';
import { useScreenHasLessWidthThan } from '../../../../hooks/useScreenSize';
import RxElectron from '../../../../rxjs/RxElectron';
import { isElectron } from '../../../../__shared/electron';
import SplitScreen from '../main/splitScreen';
import styles from './serviceTimes.module.scss';
import ServiceTimeDetails from './serviceTimesDetails';
import TimeTable from './timeTable';

const ServiceTimes: React.FC = () => {
    const { t } = useTranslation();
    const isWindows = useObservable(RxElectron.isWindows$, true);
    const screenHasLessWidthThanXxs = useScreenHasLessWidthThan('xxs');
    const screenHasLessWidthThanSm = useScreenHasLessWidthThan('sm');
    const [show, setShow] = useState(false);

    const mainStyle = screenHasLessWidthThanSm
        ? show
            ? { left: '0px', width: '100%' }
            : { left: '100vw' }
        : {};

    const editFieldWrapper = (
        <div className={styles.splitScreenRightContainer} style={mainStyle}>
            <div className={styles.header}>
                {screenHasLessWidthThanSm && (
                    <>
                        <NavBackButton
                            position={{
                                top: 14,
                                left:
                                    screenHasLessWidthThanXxs &&
                                    isElectron() &&
                                    !isWindows
                                        ? 74
                                        : 14,
                            }}
                            onClick={() => setShow(false)}
                        />
                        <div
                            style={
                                screenHasLessWidthThanXxs &&
                                isElectron() &&
                                !isWindows
                                    ? { paddingLeft: '60px' }
                                    : {}
                            }>
                            {t(
                                'screens.settings.serviceTimes.changeServiceTimes',
                            )}
                        </div>
                    </>
                )}
            </div>
            <div className={styles.content}>{<ServiceTimeDetails />}</div>
        </div>
    );

    return (
        <SplitScreen
            left={
                <TimeTable
                    title={t('screens.settings.serviceTimes.openingTimes')}
                    onEdit={() => setShow(true)}
                />
            }
            right={editFieldWrapper}
        />
    );
};

export default ServiceTimes;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from '../../../../components/button/button';
import AnimalChatRoutes from '../../../../utils/routing';
import styles from './pleaseLoginScreen.module.scss';

const PleaseLoginScreen: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <div className={styles.container}>
            <div
                style={{
                    width: '100%',
                    textAlign: 'center',
                    fontSize: '16px',
                }}>{`Sie sind nicht eingeloggt oder keinen Unternehmen zugewiesen`}</div>
            <div className={styles.buttons}>
                <Button
                    text={t('screens.business.goToLogin')}
                    onClick={() => {
                        navigate(AnimalChatRoutes.businessSubs.login);
                    }}
                />
                <Button
                    text={t('screens.business.goToRegistration')}
                    onClick={() => {
                        navigate(
                            AnimalChatRoutes.businessSubs.firstStepsSubs
                                .termsOfUse,
                        );
                    }}
                />
            </div>
        </div>
    );
};

export default PleaseLoginScreen;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DetailHeader from './detailHeader';
import {
    AnimalContext,
    ChatMessage,
    FindMessagesData,
    FindMessagesQuery,
    QueryFindChatMessagesArgs,
} from '../../../../__shared/graphql';

import styles from './chatDetail.module.scss';
import AnimalContextProfile from './animalContextProfile';
import ChatMediaList from './chatMediaList';
import { useLazyQuery } from '@apollo/client';
import { filterMessagesWithImageAndVideo } from '../../../../utils/fileUtils';
import { useScreenHasMoreWidthThan } from '../../../../hooks/useScreenSize';

interface AnimalContextDetailProps {
    hideDetail: () => void;
    animalContext: AnimalContext;
    show: boolean;
}

const AnimalContextDetail: React.FC<AnimalContextDetailProps> = ({
    hideDetail,
    animalContext,
    show,
}) => {
    const screenHasMoreWidthThanMd = useScreenHasMoreWidthThan('md');

    const [initial, setInitial] = useState(true);
    const [showMedia, setShowMedia] = useState(false);

    const [detailStyle, setDetailStyle] = useState(
        `${styles.detail} ${styles.closed}`,
    );

    useEffect(() => {
        setInitial(true);
    }, [screenHasMoreWidthThanMd]);

    useEffect(() => {
        if (screenHasMoreWidthThanMd) {
            if (show) {
                setDetailStyle(`${styles.detail} ${styles.shown}`);
            } else {
                setDetailStyle(`${styles.detail} ${styles.hidden}`);
            }

            return;
        }

        if (show) {
            if (initial) {
                setDetailStyle(`${styles.detail} ${styles.shown}`);
                setInitial(false);
                return;
            }
            setDetailStyle(`${styles.detail} ${styles.show}`);
            return;
        }
        if (initial) {
            setDetailStyle(`${styles.detail}`);
            setInitial(false);
            return;
        }
        setDetailStyle(`${styles.detail} ${styles.hide}`);
        // we don't want to trigger this function when the initial state changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show, screenHasMoreWidthThanMd]);

    const { t } = useTranslation();

    const [
        chatMessagesWithFilesForAnimalContext,
        setChatMessagesWithFilesForAnimalContext,
    ] = useState<ChatMessage[]>([]);

    const findChatMessagesVariables: QueryFindChatMessagesArgs = {
        searchInput: '',
        onlyFiles: true,
    };

    if (animalContext?.animal?.id) {
        findChatMessagesVariables.animalId = animalContext.animal?.id;
    } else if (animalContext?.animalGroup?.id) {
        findChatMessagesVariables.animalGroupId = animalContext.animalGroup?.id;
    }

    const [
        findChatMessages,
        { loading: findChatMessagesLoading, data: findChatMessagesData },
    ] = useLazyQuery<FindMessagesData, QueryFindChatMessagesArgs>(
        FindMessagesQuery.MediaResponse,
        {
            variables: findChatMessagesVariables,
        },
    );

    useEffect(() => {
        if (animalContext?.animal || animalContext?.animalGroup) {
            findChatMessages();
        }
    }, [animalContext?.animal, animalContext?.animalGroup, findChatMessages]);

    useEffect(() => {
        if (
            !findChatMessagesLoading &&
            findChatMessagesData?.findChatMessages
        ) {
            setChatMessagesWithFilesForAnimalContext(
                findChatMessagesData?.findChatMessages,
            );
        } else if (!findChatMessagesLoading) {
            setChatMessagesWithFilesForAnimalContext([]);
        }
    }, [
        findChatMessagesLoading,
        findChatMessagesData,
        animalContext?.animal?.id,
        animalContext?.animalGroup?.id,
    ]);

    if (
        !animalContext ||
        (!animalContext.animal && !animalContext.animalGroup)
    ) {
        return null;
    }

    return (
        <section
            className={detailStyle}
            onAnimationEnd={() => {
                setDetailStyle(
                    `${styles.detail} ${show ? styles.shown : styles.hidden}`,
                );
            }}>
            <div className={styles.container}>
                <DetailHeader
                    onClose={hideDetail}
                    title={
                        animalContext?.animalGroup
                            ? t('common.animalGroup')
                            : t('common.animalProfile')
                    }
                />
                <AnimalContextProfile
                    animalContext={animalContext}
                    chatMessagesWithFiles={filterMessagesWithImageAndVideo(
                        chatMessagesWithFilesForAnimalContext,
                    )}
                    onShowMedia={() => setShowMedia(true)}
                />

                <div
                    className={`${
                        showMedia ? styles.subPage : styles.subPageHidden
                    }`}>
                    <DetailHeader
                        iconName="arrow-back-outline"
                        title={t('screens.chat.detail.animalContext.media')}
                        onClose={() => setShowMedia(false)}
                    />
                    <ChatMediaList
                        messages={filterMessagesWithImageAndVideo(
                            chatMessagesWithFilesForAnimalContext,
                        )}
                        chatId={0}
                    />
                </div>
            </div>
        </section>
    );
};

export default AnimalContextDetail;

export const VersionHeaders = {
    AnimalchatAppiOSVersion: 'animalchat-app-ios-version',
    AnimalchatAppAndroidVersion: 'animalchat-app-android-version',
    AnimalchatDashboardVersion: 'animalchat-dashboard-version',
    AnimalchatDashboardShellVersion: 'animalchat-dashboard-shell-version',
    AnimalchatProAppiOSVersion: 'animalchat-pro-app-ios-version',
    AnimalchatProAppAndroidVersion: 'animalchat-pro-app-android-version',
    AnimalchatWidgetVersion: 'animalchat-widget-version',
};

export const LatestVersionHeaderKeys = {
    LatestAnimalchatAppiOSVersion: 'LatestAnimalchatAppiOSVersion',
    LatestAnimalchatAppAndroidVersion: 'LatestAnimalchatAppAndroidVersion',
    LatestAnimalchatDashboardVersion: 'LatestAnimalchatDashboardVersion',
    LatestAnimalchatDashboardShellVersion:
        'LatestAnimalchatDashboardShellVersion',
    LatestAnimalchatProAppiOSVersion: 'LatestAnimalchatProAppiOSVersion',
    LatestAnimalchatProAppAndroidVersion:
        'LatestAnimalchatProAppAndroidVersion',
    LatestAnimalchatWidgetVersion: 'LatestAnimalchatWidgetVersion',
};

export const LatestVersionHeaders = {
    LatestAnimalchatAppiOSVersion: 'latest-animalchat-app-ios-version',
    LatestAnimalchatAppAndroidVersion: 'latest-animalchat-app-android-version',
    LatestAnimalchatDashboardVersion: 'latest-animalchat-dashboard-version',
    LatestAnimalchatDashboardShellVersion:
        'latest-animalchat-dashboard-shell-version',
    LatestAnimalchatProAppiOSVersion: 'latest-animalchat-pro-app-ios-version',
    LatestAnimalchatProAppAndroidVersion:
        'latest-animalchat-pro-app-android-version',

    LatestAnimalchatWidgetVersion: 'latest-animalchat-widget-version',
};

import { useMutation } from '@apollo/client';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Logo from '../../../../../assets/images/animalchat_app_icon.png';
import Button from '../../../../../components/button/button';
import {
    CompanyMutations,
    CreateStarterCompanyAsOwnerWithAcceptedLicenceAndOrderProcessingAgreementsData,
} from '../../../../../graphql/mutations/companyMutations';
import { useAlert } from '../../../../../hooks/useAlert';
import { useErrorAlert } from '../../../../../hooks/useErrorAlert';
import RxCompany from '../../../../../rxjs/RxCompany';
import { Limits } from '../../../../../utils/inputLimits';
import logUtil from '../../../../../utils/logUtil';
import {
    CountryCodeGermany,
    isValidEmailAddress,
    isValidPhoneNumberWithCountryCodePrefixAndLimit,
    isValidRequiredPhoneNumberWithCountryCodePrefixAndLimit,
    isValidRequiredValueWithLimit,
    isValidValueWithLimit,
} from '../../../../../utils/validationUtil';
import {
    CreateAddressData,
    CreateAddressMutation,
    MutationCreateAddressArgs,
    MutationCreateStarterCompanyAsOwnerWithAcceptedLicenceAndOrderProcessingAgreementsArgs,
} from '../../../../../__shared/graphql';
import Card from '../../../components/card/card';
import CommonCompany from './commonCompany';
import styles from './createCompany.module.scss';
import CreateCompanyAddress from './createCompanyAddress';
import SummaryCreateCompany from './summaryCreateCompany';


// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LogPrefix = '[createCompany]';

const ZipCodeRegex = /^(?!01000|99999)(0[1-9]\d{3}|[1-9]\d{4})$/;

export type CreateCompanyPage =
    | ''
    | 'common'
    | 'address'
    | 'summary'
    | 'openingHours';

export interface Address {
    streetName: string;
    houseNumber: string;
    zipCode: string;
    city: string;
    country: string;
}

interface ServiceHour {
    from?: Date;
    to?: Date;
}

export interface ServiceHours {
    0: ServiceHour[];
    1: ServiceHour[];
    2: ServiceHour[];
    3: ServiceHour[];
    4: ServiceHour[];
    5: ServiceHour[];
    6: ServiceHour[];
}

export interface CommonCompanyData {
    name: string;
    email: string;
    type: string;
    nametag: string;
    description: string;
    phoneNumber: string;
    emergencyServicePhoneNumber: string;
    website: string;
    isSearchable: boolean;
}

export interface NewCompanyForm {
    common: CommonCompanyData;
    address: Address;
    serviceHours: ServiceHours;
}

export const isValidAddress = (address: Address): boolean => {
    return (
        isValidRequiredValueWithLimit(Limits.StreetName, address.streetName) &&
        isValidRequiredValueWithLimit(
            Limits.HouseNumber,
            address.houseNumber,
        ) &&
        isValidRequiredValueWithLimit(Limits.City, address.city) &&
        isValidRequiredValueWithLimit(Limits.Country, address.country) &&
        !!ZipCodeRegex.test(address.zipCode)
    );
};

export const isValidCommonData = (form: CommonCompanyData): boolean => {
    return (
        !!form.type.length &&
        isValidRequiredValueWithLimit(Limits.CompanyName, form.name) &&
        isValidRequiredPhoneNumberWithCountryCodePrefixAndLimit(
            Limits.PhoneNumber,
            form.phoneNumber,
        ) &&
        isValidEmailAddress({ limit: Limits.Email, email: form.email }) &&
        isValidValueWithLimit(Limits.CompanyDescription, form.description) &&
        isValidValueWithLimit(Limits.Website, form.website) &&
        isValidPhoneNumberWithCountryCodePrefixAndLimit(
            Limits.PhoneNumber,
            form.emergencyServicePhoneNumber,
        ) &&
        isValidValueWithLimit(Limits.CompanyNameTag, form.nametag)
    );
};

const isValidForm = (form: NewCompanyForm): boolean => {
    return isValidCommonData(form.common) && isValidAddress(form.address);
};

const initialFormData: NewCompanyForm = {
    common: {
        name: '',
        email: '',
        type: '',
        nametag: '',
        description: '',
        phoneNumber: '',
        emergencyServicePhoneNumber: '',
        website: '',
        isSearchable: false,
    },
    address: {
        streetName: '',
        houseNumber: '',
        zipCode: '',
        city: '',
        country: '',
    },
    serviceHours: {
        '0': [],
        '1': [],
        '2': [],
        '3': [],
        '4': [],
        '5': [],
        '6': [],
    },
};

export interface CreateCompanyProps {
    onPageChanged: (page: CreateCompanyPage) => void;
}

const CreateCompany: React.FC<CreateCompanyProps> = ({ onPageChanged }) => {
    const { t } = useTranslation();
    const [page, setPage] = useState<CreateCompanyPage>('');
    const [formData, setFormData] = useState<NewCompanyForm>(initialFormData);
    const { alertGraphQlError, alertError } = useErrorAlert();
    const alert = useAlert();

    const handleCreateCompany = () => {
        setPage('common');
    };

    useEffect(() => {
        onPageChanged(page);
    }, [page, onPageChanged]);

    const [createAddressMutation, { loading: addressLoading }] = useMutation<
        CreateAddressData,
        MutationCreateAddressArgs
    >(CreateAddressMutation.FullResponse);

    const [
        createCompanyAsOwnerWithAgreementsMutation,
        { loading: companyLoading },
    ] = useMutation<
        CreateStarterCompanyAsOwnerWithAcceptedLicenceAndOrderProcessingAgreementsData,
        MutationCreateStarterCompanyAsOwnerWithAcceptedLicenceAndOrderProcessingAgreementsArgs
    >(
        CompanyMutations.CreateStarterCompanyAsOwnerWithAcceptedLicenceAndOrderProcessingAgreements,
    );

    const handleCreate = useCallback(
        async (_formData: NewCompanyForm) => {
            try {
                const addressResponse = await createAddressMutation({
                    variables: {
                        address: _formData.address,
                    },
                });
                if (addressResponse.errors?.length) {
                    alertGraphQlError(addressResponse.errors);
                    return;
                }

                const addressId = addressResponse.data?.createAddress.id;

                if (!addressId) {
                    alert && alert.error('common.alerts.defaultError');
                    return;
                }

                const description = _formData.common.description.length
                    ? _formData.common.description
                    : undefined;
                const emergencyServicePhoneNumber =
                    _formData.common.emergencyServicePhoneNumber.length &&
                    ![CountryCodeGermany].includes(
                        _formData.common.emergencyServicePhoneNumber,
                    )
                        ? _formData.common.emergencyServicePhoneNumber
                        : undefined;
                const website = _formData.common.website.length
                    ? _formData.common.website
                    : undefined;
                const nametag = _formData.common.nametag.length
                    ? _formData.common.nametag
                    : _formData.common.name.replaceAll(' ', '.');
                const companyResponse =
                    await createCompanyAsOwnerWithAgreementsMutation({
                        variables: {
                            company: {
                                addressId,
                                email: _formData.common.email,
                                name: _formData.common.name,
                                type: _formData.common.type,
                                nametag,
                                phoneNumber: _formData.common.phoneNumber,
                                description,
                                emergencyServicePhoneNumber,
                                website,
                            },
                        },
                    });
                if (companyResponse.errors?.length) {
                    alertGraphQlError(companyResponse.errors);
                    return;
                }
                const newCompany =
                    companyResponse.data
                        ?.createStarterCompanyAsOwnerWithAcceptedLicenceAndOrderProcessingAgreements;
                if (newCompany) {
                    alert.success(t('common.alerts.savedChanges'));
                    RxCompany.addCompany(newCompany);
                    RxCompany.setCurrentCompany(newCompany);
                }
            } catch (error) {
                logUtil.log('Error while updating user', error);
                alertError(error);
            }
        },
        [
            createCompanyAsOwnerWithAgreementsMutation,
            createAddressMutation,
            t,
            alert,
            alertError,
            alertGraphQlError,
        ],
    );

    return (
        <div className={styles.container}>
            {page === '' && (
                <Card className={styles.card}>
                    <img className={styles.logo} src={Logo} />
                    <p>{t('screens.business.company.createNewCompany')}</p>
                    <Button
                        text={t('screens.business.company.createCompany')}
                        onClick={handleCreateCompany}
                    />
                </Card>
            )}
            {page === 'common' && (
                <CommonCompany
                    formData={formData.common}
                    onBack={commonData => {
                        setFormData(prev => ({ ...prev, common: commonData }));
                        setPage('');
                    }}
                    onNext={commonData => {
                        setFormData(prev => ({ ...prev, common: commonData }));
                        setPage('address');
                    }}
                />
            )}
            {page === 'address' && (
                <CreateCompanyAddress
                    formData={formData.address}
                    onBack={addressData => {
                        setFormData(prev => ({
                            ...prev,
                            address: addressData,
                        }));
                        setPage('common');
                    }}
                    onNext={addressData => {
                        setFormData(prev => ({
                            ...prev,
                            address: addressData,
                        }));
                        setPage('summary');
                    }}
                />
            )}
            {page === 'summary' && (
                <SummaryCreateCompany
                    onBack={() => setPage('address')}
                    onNext={() => {
                        if (isValidForm(formData)) {
                            handleCreate(formData);
                        }
                    }}
                    formData={formData}
                    isLoading={addressLoading || companyLoading}
                />
            )}
        </div>
    );
};

export default CreateCompany;

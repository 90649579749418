import { FileDescription } from '../__shared/graphql';
import { VersionHeaders } from '../__shared/common';
import { DASHBOARD_VERSION } from '../environment/env';
import { LogClient } from '../__shared/common/logging/logClient';
import { TestLoggerMessages } from '../__shared/common/logging/testConstants';

interface FileDescriptionLoaderJobResult {
    objectUrl?: string;
    error?: unknown;
}

class FileDescriptionWebLoaderClass {
    private readonly fileDescriptionUuidToObjectUrlPromiseMap = new Map<
        string,
        string
    >();
    private readonly processingQueue = new Map<
        string,
        Promise<FileDescriptionLoaderJobResult>
    >();

    private async loadObjectFromUrl(
        src: string,
        uuid: string,
        accessToken: string,
    ): Promise<FileDescriptionLoaderJobResult> {
        let result: FileDescriptionLoaderJobResult = {};
        try {
            const response = await fetch(src, {
                mode: 'cors',
                headers: {
                    authorization: `Bearer ${accessToken}`,
                    [VersionHeaders.AnimalchatDashboardVersion]:
                        DASHBOARD_VERSION,
                },
            });
            const blob = await response.blob();
            result.objectUrl = URL.createObjectURL(blob);
            this.fileDescriptionUuidToObjectUrlPromiseMap.set(
                uuid,
                result.objectUrl,
            );
        } catch (error) {
            LogClient.error(
                `Could not load file description for uuid: ${uuid}`,
                error,
            );
            result = {
                error,
            };
        } finally {
            this.processingQueue.delete(uuid);
        }

        return result;
    }

    getObjectUrl(
        fileDescription: FileDescription,
        accessToken: string,
    ): Promise<FileDescriptionLoaderJobResult> {
        const { uuid, name, safeUrl } = fileDescription;

        LogClient.debug(
            `Try downloading file with name ${name} (uuid: ${uuid})`,
        );

        const existingObjectUrl =
            this.fileDescriptionUuidToObjectUrlPromiseMap.get(uuid);

        if (existingObjectUrl) {
            LogClient.debug(`Found existing object url for file ${name}`);
            return Promise.resolve({ objectUrl: existingObjectUrl });
        }
        let loadObjectJob = this.processingQueue.get(uuid);
        if (loadObjectJob) {
            LogClient.debug(`Found existing download job for file ${name}`);
            return loadObjectJob;
        }

        if (!safeUrl) {
            LogClient.error(
                `Cannot download file (${name}, ${uuid}) with safeUrl: ${safeUrl}`,
            );
            return Promise.resolve({
                error: new Error(
                    `Cannot load file description for uuid ${uuid} with safeUrl ${safeUrl}`,
                ),
            });
        }
        LogClient.debug(`Downloading file ${name}`);
        LogClient.test(TestLoggerMessages.LoadingFileDescriptionFromUrl, {
            fileDescription,
        });

        loadObjectJob = this.loadObjectFromUrl(safeUrl, uuid, accessToken);
        this.processingQueue.set(uuid, loadObjectJob);
        return loadObjectJob;
    }
}

export const FileDescriptionWebLoader = new FileDescriptionWebLoaderClass();

export const Limits = {
    CompanyName: 200,
    CompanyDescription: 250,
    CompanyNameTag: 50,
    Email: 100,
    Firstname: 100,
    Lastname: 100,
    Password: 50,
    PhoneNumber: 30,
    Title: 100,
    Username: 100,
    Website: 100,
    StreetName: 80,
    HouseNumber: 20,
    City: 80,
    ZipCode: 10,
    Country: 80,
};

import { gql } from '@apollo/client';

const FullLicence = gql`
    fragment FullLicence on Licence {
        id
        type
        durationInMonths
        periodOfNoticeInMonths
        isUniquePerCompany
        priceInEuroCents
        createdAt
        createdBy
        updatedAt
        updatedBy
    }
`;

export const LicenceFragments = {
    FullLicence,
};

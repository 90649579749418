import { gql } from '@apollo/client';
import { FileDescriptionFragments } from './fileDescriptionFragments';

const FullAnimal = gql`
    fragment FullAnimal on Animal {
        id
        name
        type
        breed
        specification
        description
        birthday
        isBirthdayEstimated
        createdAt
        createdBy
        updatedAt
        deletedAt
        deletedBy
        colorBundle {
            hexColor
            hexColorGradient
        }
        imageFile {
            ...FullFileDescription
        }
        owners {
            user {
                id
            }
        }
    }
    ${FileDescriptionFragments.FullResponse}
`;

export const AnimalFragments = { FullAnimal };

import React from 'react';
import Icon from '../../../../components/icon/icon';
import styles from './detailHeader.module.scss';

export interface DetailHeaderProps {
    title?: string;
    iconName?: string;
    onClose: () => void;
}

const DetailHeader: React.FC<DetailHeaderProps> = ({
    title,
    iconName,
    onClose,
}) => {
    // const isWindows = useObservable(RxElectron.isWindows$, true);
    // const screenHasLessWidthThanXxs = useScreenHasLessWidthThan('xxs');

    return (
        <div className={styles.container}>
            {/* {!screenHasLessWidthThanXxs && ( */}
            <Icon
                className="closeIcon"
                name={iconName ?? 'close-outline'}
                onClick={onClose}
            />
            {/* )} */}
            {title && (
                <span className={styles.title}>
                    {/* {screenHasLessWidthThanXxs && (
            <NavBackButton
              position={{
                top: 14,
                left: isElectron() && !isWindows ? 74 : 14,
              }}
              onClick={() => onClose()}
            />
          )} */}
                    {title}
                </span>
            )}
        </div>
    );
};

export default DetailHeader;

import {
    MutationUpdateEmailArgs,
    MutationUpdatePhoneNumberArgs,
    MutationUpdateUserArgs,
    UpdateUserData,
    User,
} from '../../../../__shared/graphql';
import { useMutation } from '@apollo/client';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormField from '../../../../components/form/formField';
import TextButton from '../../../../components/textButton/textButton';
import UploadProfileIcon from '../../../../components/uploadProfileIcon/uploadProfileIcon';
import { useAlert } from '../../../../hooks/useAlert';
import { useObservable } from '../../../../hooks/useObservable';
import RxUserProfile from '../../../../rxjs/RxUserProfile';
import { SalutationOptions } from '../../../../utils/option';
import styles from './userProfileForm.module.scss';
import {
    isValidEmailAddress,
    isValidRequiredPhoneNumberWithCountryCodePrefixAndLimit,
} from '../../../../utils/validationUtil';
import { Limits } from '../../../../utils/inputLimits';
import EmailActivationCodeForm from './emailActivationCodeForm/emailActivationCodeForm';
import SmsActivationCodeForm from './smsActivationCodeForm/smsActivationCodeForm';
import { useErrorAlert } from '../../../../hooks/useErrorAlert';
import logUtil from '../../../../utils/logUtil';
import {
    UpdateEmailData,
    UpdatePhoneNumberData,
    UserMutations,
} from '../../../../graphql/mutations/userMutations';

interface MainFormData {
    email: string;
    username: string;
    gender: string;
    title: string;
    firstname: string;
    lastname: string;
}

const initialForm = {
    email: '',
    username: '',
    gender: '',
    title: '',
    firstname: '',
    lastname: '',
};

const UserProfileForm: React.FC = () => {
    const { t } = useTranslation();
    const [form, setForm] = useState<MainFormData>(initialForm);
    const [email, setEmail] = useState<string>('');
    const [phoneNumber, setPhoneNumber] = useState<string>('');

    const alert = useAlert();
    const { alertGraphQlError, alertError } = useErrorAlert();
    const user = useObservable(RxUserProfile.userProfile$);
    const [updateLoading, setLoading] = useState(false);

    const [showEmailActivationCodeArea, setShowEmailActivationCodeArea] =
        useState<boolean>(false);

    const [showSmsActivationCodeArea, setShowSmsActivationCodeArea] =
        useState<boolean>(false);

    const resetForm = useCallback((resetUser: User | undefined) => {
        setForm({
            email: resetUser?.email ?? '',
            username: resetUser?.username ?? '',
            gender: resetUser?.gender ?? '',
            firstname: resetUser?.firstname ?? '',
            lastname: resetUser?.lastname ?? '',
            title: resetUser?.title ?? '',
        });
    }, []);

    const resetEmail = useCallback(() => {
        setEmail(user?.email ?? '');
    }, [user]);

    const resetPhoneNumber = useCallback(() => {
        setPhoneNumber(user?.phoneNumber ?? '');
    }, [user]);

    useEffect(() => {
        resetForm(user);
        resetEmail();
        resetPhoneNumber();
    }, [user, resetForm, resetEmail, resetPhoneNumber]);

    const updateValue = useCallback((field: string, value: string) => {
        setForm(old => ({ ...old, [field]: value }));
    }, []);

    const onReset = () => {
        resetForm(user);
    };

    const validEmailAddressInput = isValidEmailAddress({
        email: email,
        limit: Limits.Email,
    });

    const validPhoneNumberInput =
        isValidRequiredPhoneNumberWithCountryCodePrefixAndLimit(
            Limits.PhoneNumber,
            phoneNumber,
        );

    const hasEmailChanged = !!user?.email && user?.email !== email;
    const isEmailConfirmed = hasEmailChanged
        ? undefined
        : !!user?.emailConfirmedAt;

    const hasPhoneNumberChanged =
        !!user?.phoneNumber && user?.phoneNumber !== phoneNumber;
    const isPhoneNumberConfirmed = hasPhoneNumberChanged
        ? undefined
        : !!user?.phoneNumberConfirmedAt;

    const isValidInput =
        validEmailAddressInput &&
        validPhoneNumberInput &&
        isEmailConfirmed &&
        !hasEmailChanged;

    useEffect(() => {
        setShowEmailActivationCodeArea(hasEmailChanged);
    }, [hasEmailChanged, setShowEmailActivationCodeArea]);

    useEffect(() => {
        setShowSmsActivationCodeArea(hasPhoneNumberChanged);
    }, [hasPhoneNumberChanged, setShowSmsActivationCodeArea]);

    const [updateUserMutation] = useMutation<
        UpdateUserData,
        MutationUpdateUserArgs
    >(UserMutations.UpdateUser);

    const [updateEmailMutation] = useMutation<
        UpdateEmailData,
        MutationUpdateEmailArgs
    >(UserMutations.UpdateEmail);

    const [updatePhoneNumberMutation] = useMutation<
        UpdatePhoneNumberData,
        MutationUpdatePhoneNumberArgs
    >(UserMutations.UpdatePhoneNumber);

    const isValidForm = (form: MainFormData): boolean => {
        return (
            !!form.username?.trim().length &&
            !!form.firstname?.trim().length &&
            !!form.lastname?.trim().length
        );
    };

    const handleSave = async (form: MainFormData) => {
        if (!user?.id) {
            return;
        }
        const _form = {
            id: user.id,
            ...form,
        };
        if (!isValidForm(_form)) {
            alert.error('screens.userProfile.pleaseFillOutRequiredFields');
            return;
        }
        setLoading(true);
        try {
            const response = await updateUserMutation({
                variables: {
                    user: {
                        id: user.id,
                        username: _form.username?.trim() ?? undefined,
                        gender: _form.gender?.trim() ?? undefined,
                        title: _form.title?.trim() ?? undefined,
                        firstname: _form.firstname?.trim() ?? undefined,
                        lastname: _form.lastname?.trim() ?? undefined,
                    },
                },
            });
            const { data, errors } = response;
            if (errors?.length) {
                alertGraphQlError(errors);
                return;
            }
            if (data?.updateUser) {
                alert.success('common.alerts.savedChanges');
                RxUserProfile.setUser(data.updateUser);
            }
        } catch (error) {
            logUtil.log('Error while updating user', error);
            alertError(error);
        } finally {
            setLoading(false);
        }
    };

    const handleSaveEmail = async (email: string, activationCode: string) => {
        setLoading(true);
        const trimmedEmail = email?.trim() ?? '';
        try {
            const response = await updateEmailMutation({
                variables: {
                    activationCode,
                    email: trimmedEmail,
                },
            });
            const { data, errors } = response;
            if (errors?.length) {
                alertGraphQlError(errors);
                return;
            }
            if (data?.updateEmail) {
                alert.success('common.alerts.savedChanges');
                RxUserProfile.setUser(data.updateEmail);
            }
        } catch (error) {
            logUtil.log('Error while updating email', error);
            alertError(error);
        } finally {
            setLoading(false);
        }
    };

    const handleSavePhoneNumber = async (
        phoneNumber: string,
        activationCode: string,
    ) => {
        setLoading(true);
        const trimmedPhoneNumber = phoneNumber?.trim() ?? '';
        try {
            const response = await updatePhoneNumberMutation({
                variables: {
                    activationCode,
                    phoneNumber: trimmedPhoneNumber,
                },
            });
            const { data, errors } = response;
            if (errors?.length) {
                alertGraphQlError(errors);
                return;
            }
            if (data?.updatePhoneNumber) {
                alert.success('common.alerts.savedChanges');
                RxUserProfile.setUser(data.updatePhoneNumber);
            }
        } catch (error) {
            logUtil.log('Error while updating phone number', error);
            alertError(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={styles.container}>
            <UploadProfileIcon
                user={user}
                onUserUpdated={RxUserProfile.setUser}
            />
            <div>
                <div className={styles.formFields}>
                    <FormField
                        id="user-profile-form-email"
                        name={'email'}
                        value={email}
                        label={t(
                            'screens.userProfile.profile.form.fields.email',
                        )}
                        updateValue={(field: string, value: string | number) =>
                            setEmail(value as string)
                        }
                        required
                        confirmable={true}
                        confirmed={isEmailConfirmed}
                        limit={Limits.Email}
                    />
                </div>

                {!showEmailActivationCodeArea && (
                    <TextButton
                        style="primary"
                        text={t('screens.userProfile.changeEmail')}
                        onClick={() =>
                            setShowEmailActivationCodeArea(show => !show)
                        }
                        disabled={updateLoading}
                    />
                )}

                {showEmailActivationCodeArea && (
                    <EmailActivationCodeForm
                        email={email}
                        onClose={() => {
                            resetEmail();
                            setShowEmailActivationCodeArea(false);
                        }}
                        onSave={(email, activationCode) =>
                            handleSaveEmail(email, activationCode)
                        }
                    />
                )}
            </div>
            <div>
                <div className={styles.formFields}>
                    <FormField
                        id="user-profile-form-phonenumber"
                        name={'phoneNumber'}
                        value={phoneNumber}
                        type={'phoneNumber'}
                        label={t(
                            'screens.userProfile.profile.form.fields.phoneNumber',
                        )}
                        updateValue={(field: string, value: string | number) =>
                            setPhoneNumber(value as string)
                        }
                        confirmable={true}
                        confirmed={isPhoneNumberConfirmed}
                        limit={Limits.PhoneNumber}
                    />
                </div>

                {!showSmsActivationCodeArea && (
                    <TextButton
                        style="primary"
                        text={t('screens.userProfile.changePhoneNumber')}
                        onClick={() => {
                            setShowSmsActivationCodeArea(show => !show);
                        }}
                        disabled={updateLoading}
                    />
                )}

                {showSmsActivationCodeArea && (
                    <SmsActivationCodeForm
                        phoneNumber={phoneNumber}
                        onClose={() => {
                            resetPhoneNumber();
                            setShowSmsActivationCodeArea(false);
                        }}
                        onSave={(phoneNumber, activationCode) =>
                            handleSavePhoneNumber(phoneNumber, activationCode)
                        }
                    />
                )}
            </div>

            <div className={styles.formFields}>
                <FormField
                    id="user-profile-form-username"
                    name={'username'}
                    value={form.username}
                    label={t(
                        'screens.userProfile.profile.form.fields.username',
                    )}
                    updateValue={(field: string, value: string | number) =>
                        updateValue(field, value as string)
                    }
                    required
                    limit={Limits.Username}
                />
                <FormField
                    id="user-profile-form-gender"
                    name={'gender'}
                    value={form.gender}
                    type="select"
                    options={SalutationOptions.map(option => ({
                        label: t(option.label),
                        value: option.value,
                    }))}
                    label={t('screens.userProfile.profile.form.fields.gender')}
                    updateValue={(field: string, value: string | number) =>
                        updateValue(field, value as string)
                    }
                />

                <FormField
                    id="user-profile-form-title"
                    name={'title'}
                    value={form.title}
                    label={t('screens.userProfile.profile.form.fields.title')}
                    updateValue={(field: string, value: string | number) =>
                        updateValue(field, value as string)
                    }
                    limit={Limits.Title}
                />

                <FormField
                    id="user-profile-form-firstname"
                    name={'firstname'}
                    value={form.firstname}
                    label={t(
                        'screens.userProfile.profile.form.fields.firstname',
                    )}
                    updateValue={(field: string, value: string | number) =>
                        updateValue(field, value as string)
                    }
                    required
                    limit={Limits.Firstname}
                />
                <FormField
                    id="user-profile-form-lastname"
                    name={'lastname'}
                    value={form.lastname}
                    label={t(
                        'screens.userProfile.profile.form.fields.lastname',
                    )}
                    updateValue={(field: string, value: string | number) =>
                        updateValue(field, value as string)
                    }
                    required
                    limit={Limits.Lastname}
                />

                <div className={styles.actions}>
                    <TextButton
                        style="secondary"
                        text={t('common.buttons.cancel')}
                        onClick={onReset}
                        disabled={updateLoading}
                    />
                    <TextButton
                        style="primary"
                        text={t('common.buttons.apply')}
                        onClick={() => handleSave(form)}
                        disabled={updateLoading || !isValidInput}
                    />
                </div>
            </div>
        </div>
    );
};

export default UserProfileForm;

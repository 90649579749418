import { gql } from '@apollo/client';
import { FileDescription } from './dto';
import { FileDescriptionFragments } from './fragments';

const SingleFileUploadWithFastResponseFullFileDescription = gql`
    mutation SingleFileUploadWithFastResponse(
        $file: Upload!
        $fileInfo: FileInfo
        $uuid: String
        $resolutions: [ImageResolution!]
        $skipVideoConversion: Boolean
    ) {
        singleFileUploadWithFastResponse(
            file: $file
            fileInfo: $fileInfo
            uuid: $uuid
            resolutions: $resolutions
            skipVideoConversion: $skipVideoConversion
        ) {
            ...FullFileDescription
        }
    }
    ${FileDescriptionFragments.FullResponse}
`;

export const GraphQLFileDescription = {
    Queries: {},
    Mutations: {
        SingleFileUploadWithFastResponse: {
            FullFileDescription:
                SingleFileUploadWithFastResponseFullFileDescription,
        },
    },
};

export interface SingleFileUploadWithFastResponseData {
    singleFileUploadWithFastResponse?: FileDescription | null;
}

import { ServiceHours } from '../dto';
import { gql } from '@apollo/client';
import { ServiceHoursFragments } from '../fragments';

const FullResponse = gql`
    mutation updateServiceHours($serviceHours: ServiceHoursUpdate!) {
        updateServiceHours(serviceHours: $serviceHours) {
            ...FullServiceHours
        }
    }
    ${ServiceHoursFragments.FullServiceHours}
`;

export interface UpdateServiceHoursData {
    updateServiceHours: ServiceHours;
}

export const UpdateServiceHoursMutation = {
    FullResponse,
};

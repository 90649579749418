import { gql } from '@apollo/client';
import { FileDescriptionFragments } from './fileDescriptionFragments';

const FullTopic = gql`
    fragment FullTopic on Topic {
        id
        name
        createdAt
        updatedAt
        createdBy
        updatedBy
        imageFile {
            ...FullFileDescription
        }
    }
    ${FileDescriptionFragments.FullResponse}
`;

export const TopicFragments = { FullTopic };

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import ProfileIcon from '../../../../components/profileIcon/profileIcon';
import Submenu from '../../../../components/submenu/submenu';
import SubmenuEntry from '../../../../components/submenu/submenuEntry';
import { useObservable } from '../../../../hooks/useObservable';
import RxUserProfile from '../../../../rxjs/RxUserProfile';
import { buildNameFromUser } from '../../../../utils/nameUtils';
import AnimalChatRoutes from '../../../../utils/routing';
import styles from './userProfileNavigation.module.scss';

const menuEntries: {
    translationKey: string;
    iconName: string;
    route: string;
}[] = [
    {
        translationKey: 'screens.userProfile.navigation.entries.profile',
        iconName: 'person-circle-outline',
        route: AnimalChatRoutes.userProfileSub.profile,
    },
    {
        translationKey: 'screens.userProfile.navigation.entries.changePassword',
        iconName: 'key-outline',
        route: AnimalChatRoutes.userProfileSub.changePassword,
    },
];

interface UserProfileNavigationProps {
    activeSubRoute: string;
    onNavigate: (subRoute: string) => void;
}

const UserProfileNavigation: React.FC<UserProfileNavigationProps> = ({
    activeSubRoute,
    onNavigate,
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const user = useObservable(RxUserProfile.userProfile$);

    return (
        <div className={styles.container}>
            <div key="header" className={styles.header}>
                <ProfileIcon
                    size={48}
                    fileDescription={user?.imageFile}
                    colorBundle={user?.colorBundle}
                />
                <div className={styles.userName}>
                    <p>{buildNameFromUser(user)}</p>
                    <span>{t('screens.userProfile.navigation.subtitle')}</span>
                </div>
            </div>
            <div className={styles.menuContainer}>
                <Submenu
                    className={styles.menu}
                    entries={menuEntries}
                    onNavigate={onNavigate}
                    activeSubRoute={activeSubRoute}
                />
                <div className={styles.logout}>
                    <SubmenuEntry
                        isActive={false}
                        iconName="log-out-outline"
                        translationKey="common.buttons.logout"
                        onClick={() => {
                            navigate(AnimalChatRoutes.login);
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default UserProfileNavigation;

import { ServerParseError } from "@apollo/client";

export const isServerParseError = (
  error: unknown
): error is ServerParseError => {
  if (!error) {
    return false;
  }
  const serverParseError = error as ServerParseError;
  if (
    serverParseError?.message &&
    serverParseError?.name &&
    serverParseError?.response &&
    serverParseError?.statusCode
  ) {
    return true;
  }
  return false;
};

import { gql } from '@apollo/client';
import { Company } from '../dto';
import { CompanyFragments } from '../fragments';

const FullResponse = gql`
    query GetCompaniesForUser {
        getCompaniesForUser {
            ...FullCompany
        }
    }
    ${CompanyFragments.FullCompany}
`;

export interface GetCompaniesForUserData {
    getCompaniesForUser: Company[];
}

export const GetCompaniesForUserQuery = {
    FullResponse,
};

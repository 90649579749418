import {
    Chat,
    ChatUpdate,
    MutationUpdateChatArgs,
    UpdateChatData,
    UpdateChatMutation,
} from '../__shared/graphql';
import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import RxChat from '../rxjs/RxChat';

interface UseCloseInquiryHook {
    closeInquiry: (chat: Chat) => void;
    loading: boolean;
}

const useCloseInquiry = (): UseCloseInquiryHook => {
    const [updateChat, { loading }] = useMutation<
        UpdateChatData,
        MutationUpdateChatArgs
    >(UpdateChatMutation.FullResponse);

    const closeInquiry = useCallback(
        async (chat: Chat) => {
            if (!loading) {
                const update: ChatUpdate = {
                    id: chat.id ?? 0,
                    companyId: chat.company?.id ?? 0,
                    hasOpenRequest: false,
                };
                if (chat.animal?.id) {
                    update.animalId = chat.animal.id;
                }
                if (chat.animalGroup?.id) {
                    update.animalGroupId = chat.animalGroup.id;
                }
                if (chat.topic?.id) {
                    update.topicId = chat.topic.id;
                }

                try {
                    const result = await updateChat({
                        variables: {
                            chat: update,
                        },
                    });
                    if (result.data?.updateChat) {
                        RxChat.addChats([result.data.updateChat]);
                    }
                } catch (e) {
                    console.warn('error while closing request', e);
                }
            }
        },
        [updateChat, loading],
    );

    return { closeInquiry, loading };
};

export default useCloseInquiry;

import React, { useMemo } from 'react';
import { Employee } from '../../../../__shared/graphql';
import ProfileIcon from '../../../../components/profileIcon/profileIcon';
import styles from './employeeListItem.module.scss';
import { buildNameFromEmployee } from '../../../../utils/nameUtils';
import Icon from '../../../../components/icon/icon';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import CtxMenu from '../../../../components/ctxMenu/ctxMenu';
import CtxMenuEntry from '../../../../components/ctxMenu/ctxMenuEntry';

interface EmployeeListItemProps {
    onEdit: (employee: Employee) => void;
    onInviteUserToBecomeEmployee: (employee: Employee) => void;
    onRemoveInvitationForUserToBecomeEmployee: (employee: Employee) => void;
    employee?: Employee;
    active?: boolean;
}

const inviteUserToBecomeEmployeeTextKey =
    'screens.settings.employees.inviteUserToBecomeEmployee';

const removeInvitationForUserToBecomeEmployeeTextKey =
    'screens.settings.employees.removeInvitationToBecomeEmployee';

const canAcceptText = 'screens.settings.employees.invitationSent';
const cannotAcceptText = 'screens.settings.employees.cannotAcceptInvitation';

const canAcceptIcon = 'mail-outline';
const cannotAcceptIcon = 'mail-unread-outline';

const EmployeeListItem: React.FC<EmployeeListItemProps> = ({
    onEdit,
    onInviteUserToBecomeEmployee,
    onRemoveInvitationForUserToBecomeEmployee,
    employee,
    active = false,
}) => {
    const { t } = useTranslation();
    const tooltipId = `employee-invitation-${employee?.id}`;

    const invitations = employee?.invitations?.filter(
        i => i.employeeId === employee.id,
    );
    const hasInvitation = !!invitations?.length;
    const canAccept = invitations?.some(i => i.canAccept);

    const isConnected = employee?.user;

    const hasOptionToInvite = !isConnected;
    const hasOptionToReject = !isConnected;

    const hasOptions = hasOptionToInvite || hasOptionToReject;

    const memorizedEmployeeItemOptions = useMemo(() => {
        return !hasOptions ? null : (
            <CtxMenu
                offsets={{ right: 4, top: 14 }}
                button={<Icon size={20} name="chevron-down-outline" />}
                className={styles.contextMenu}>
                {!hasInvitation && hasOptionToInvite && (
                    <CtxMenuEntry
                        icon={{
                            iconName: 'mail-outline',
                        }}
                        text={t(inviteUserToBecomeEmployeeTextKey)}
                        onClick={() => {
                            if (employee) {
                                onInviteUserToBecomeEmployee(employee);
                            }
                        }}
                    />
                )}
                {hasInvitation && hasOptionToReject && (
                    <CtxMenuEntry
                        icon={{
                            iconName: 'trash-outline',
                        }}
                        text={t(removeInvitationForUserToBecomeEmployeeTextKey)}
                        onClick={() => {
                            if (employee) {
                                onRemoveInvitationForUserToBecomeEmployee(
                                    employee,
                                );
                            }
                        }}
                    />
                )}
            </CtxMenu>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        hasOptions,
        hasInvitation,
        hasOptionToInvite,
        hasOptionToReject,
        onInviteUserToBecomeEmployee,
        onRemoveInvitationForUserToBecomeEmployee,
    ]);

    return (
        <div
            className={`${styles.employee} ${
                active ? styles.active : ''
            }`.trim()}
            onClick={() => {
                if (employee) {
                    onEdit(employee);
                }
            }}>
            <ProfileIcon
                fileDescription={employee?.user?.imageFile}
                colorBundle={employee?.user?.colorBundle}
            />
            <div className={styles.text}>
                <span className={styles.name}>
                    {buildNameFromEmployee(employee)}
                </span>
                <span className={styles.jobTitle}>{employee?.title ?? ''}</span>
            </div>
            {hasInvitation && (
                <div
                    className={styles.invitationIcon}
                    data-tip
                    data-for={tooltipId}>
                    <ReactTooltip
                        id={tooltipId}
                        effect="solid"
                        place="bottom"
                        getContent={() =>
                            t(canAccept ? canAcceptText : cannotAcceptText)
                        }
                    />
                    <Icon name={canAccept ? canAcceptIcon : cannotAcceptIcon} />
                </div>
            )}
            {memorizedEmployeeItemOptions}
        </div>
    );
};

export default EmployeeListItem;

import { Permissions } from '../__shared/graphql';

export const hasPermission = (
    actionName: string,
    permissions?: Permissions | null,
): boolean => {
    if (!actionName || !permissions?.actions?.length) {
        return false;
    }
    return (
        permissions.actions.find(action => action.key === actionName)?.value ??
        false
    );
};

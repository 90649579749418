import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Input from '../input/input';
import PhoneNumberInput from '../phoneNumberInput/phoneNumberInput';
import TextButton from '../textButton/textButton';
import styles from './detailTextField.module.scss';

export interface DetailTextFieldProps {
    title: string;
    value: string;
    limit?: number;
    isPhoneNumber?: boolean;
    onSave: (newValue: string) => void;
    onValidate?: (value: string) => boolean;
    onCancel?: () => void;
}

const DetailTextField: React.FC<DetailTextFieldProps> = ({
    title,
    value,
    limit,
    isPhoneNumber,
    onSave,
    onValidate,
    onCancel,
}) => {
    const { t } = useTranslation();
    const [newValue, setValue] = useState<string>(value);
    const [canSave, setCanSave] = useState(true);

    useEffect(() => {
        setValue(value);
    }, [value, onValidate]);

    const handleChange = (_value: string) => {
        if (typeof onValidate === 'function') {
            setCanSave(onValidate(_value));
        }
        setValue(_value);
    };

    return (
        <div className={styles.container}>
            <p className={styles.title}>{title}</p>
            {isPhoneNumber && (
                <PhoneNumberInput
                    id={title}
                    onChange={handleChange}
                    inputProps={{ limit }}
                    value={newValue}
                />
            )}
            {!isPhoneNumber && (
                <Input
                    id={title}
                    limit={limit}
                    textarea
                    className={styles.input}
                    onChange={e => handleChange(e.target.value)}
                    value={newValue}
                />
            )}
            <div className={styles.actions}>
                <TextButton
                    style="secondary"
                    text={t('common.buttons.cancel')}
                    onClick={() => {
                        setValue(value);
                        if (onCancel) {
                            onCancel();
                        }
                    }}
                />
                <TextButton
                    style="primary"
                    text={t('common.buttons.apply')}
                    disabled={!canSave}
                    onClick={() => onSave(newValue)}
                />
            </div>
        </div>
    );
};

export default DetailTextField;

import {
    Contact,
    CreateEmployeeMutation,
    CreateUserAndContactAndEmployeeData,
    Employee,
    EmployeeMutations,
    MutationCreateUserAndContactAndEmployeeAndAddToChatsWithCustomersArgs,
    MutationSoftDeleteEmployeeArgs,
    MutationUpdateContactArgs,
    MutationUpdateEmployeeArgs,
    SoftDeleteEmployeeData,
    SoftDeleteEmployeeMutation,
    UpdateContactData,
    UpdateContactMutation,
    UpdateEmployeeData,
    UserCreate,
} from '../../../../__shared/graphql';
import { useMutation } from '@apollo/client';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormField from '../../../../components/form/formField';
import TextButton from '../../../../components/textButton/textButton';
import { useAlert } from '../../../../hooks/useAlert';
import { useErrorAlert } from '../../../../hooks/useErrorAlert';
import useModal from '../../../../hooks/useModal';
import logUtil from '../../../../utils/logUtil';
import { buildNameFromEmployee } from '../../../../utils/nameUtils';
import { SalutationOptions } from '../../../../utils/option';
import styles from './employeeDetail.module.scss';
interface EmployeeDetailProps {
    employee?: Employee;
    createMode?: boolean;
    numberOfEmployees: number;
    companyId: number;
    onSaved: (employee: Employee) => void;
    onDeleted?: () => void;
    onCanceled?: () => void;
}

interface EmployeeForm {
    email: string;
    username: string;
    gender: string;
    firstname: string;
    lastname: string;
    title: string;
    job: string;
    phoneNumber: string;
    password?: string;
    password2?: string;
}

const initialForm = {
    email: '',
    username: '',
    gender: '',
    firstname: '',
    lastname: '',
    title: '',
    job: '',
    phoneNumber: '',
};

const EmployeeDetail: React.FC<EmployeeDetailProps> = ({
    employee,
    onSaved,
    createMode = false,
    companyId,
    onDeleted,
    onCanceled,
    numberOfEmployees,
}) => {
    const { t } = useTranslation();
    const alert = useAlert();
    const { alertError, alertGraphQlError } = useErrorAlert();
    const modal = useModal();
    const [employeeForm, setEmployeeForm] = useState<EmployeeForm>(initialForm);
    const [create] = useMutation<
        CreateUserAndContactAndEmployeeData,
        MutationCreateUserAndContactAndEmployeeAndAddToChatsWithCustomersArgs
    >(CreateEmployeeMutation.FullResponse);
    const [update] = useMutation<UpdateContactData, MutationUpdateContactArgs>(
        UpdateContactMutation.FullResponse,
    );

    const [updateEmployeeMutation] = useMutation<
        UpdateEmployeeData,
        MutationUpdateEmployeeArgs
    >(EmployeeMutations.UpdateEmployee);

    const [softDeleteEmployee] = useMutation<
        SoftDeleteEmployeeData,
        MutationSoftDeleteEmployeeArgs
    >(SoftDeleteEmployeeMutation.FullResponse);

    const reset = useCallback(() => {
        if (createMode) {
            setEmployeeForm({ ...initialForm, password: '', password2: '' });
            return;
        }
        setEmployeeForm({
            email: employee?.contact?.email ?? '',
            username: employee?.user?.username ?? '',
            gender: employee?.contact?.gender ?? employee?.user?.gender ?? '',
            firstname:
                employee?.contact?.firstname ?? employee?.user?.firstname ?? '',
            lastname:
                employee?.contact?.lastname ?? employee?.user?.lastname ?? '',
            title: employee?.contact?.title ?? employee?.user?.title ?? '',
            job: employee?.title ?? '',
            phoneNumber:
                employee?.contact?.phoneNumber ??
                employee?.user?.phoneNumber ??
                '',
        });
    }, [employee, createMode]);

    const resetAndCancel = useCallback(() => {
        reset();
        if (typeof onCanceled === 'function') {
            onCanceled();
        }
    }, [reset, onCanceled]);

    useEffect(() => {
        reset();
    }, [reset, employee, createMode]);

    const validate = useCallback(
        (form: EmployeeForm): boolean => {
            if (
                !form.gender?.length ||
                !form.firstname?.length ||
                !form.lastname?.length ||
                (createMode && !form.password?.length)
            ) {
                alert.error('screens.settings.employees.missingRequiredField');
                return false;
            }
            if (createMode && form.password !== form.password2) {
                alert.error('screens.settings.employees.passwordsDontMatch');
                return false;
            }
            return true;
        },
        [createMode, alert],
    );

    const onSave = async (form: EmployeeForm) => {
        if (!validate(form)) {
            return;
        }
        if (createMode) {
            try {
                const result = await create({
                    variables: {
                        employee: {
                            companyId,
                            title: form.job,
                        },
                        user: mapFormToUserCreate(form),
                    },
                });
                if (
                    result?.data
                        ?.createUserAndContactAndEmployeeAndAddToChatsWithCustomers
                        ?.id
                ) {
                    alert.success('common.alerts.savedChanges');
                    onSaved(
                        result.data
                            .createUserAndContactAndEmployeeAndAddToChatsWithCustomers,
                    );
                } else {
                    alertGraphQlError(result.errors);
                    logUtil.warn('could not employee');
                }
            } catch (e) {
                alertError(e);
                logUtil.warn('error while creating employee', e);
            }
            return;
        }
        try {
            const employeeResult = await updateEmployeeMutation({
                variables: {
                    employee: {
                        id: employee?.id ?? 0,
                        companyId: employee?.company?.id,
                        contactId: employee?.contact?.id,
                        roleKey: employee?.role?.key,
                        title: form.job,
                        userId: employee?.user?.id,
                    },
                },
            });
            if (!employeeResult.data?.updateEmployee.id) {
                alertGraphQlError(employeeResult.errors);
                logUtil.warn('Could not update contact/employee');
                return;
            }

            const result = await update({
                variables: {
                    contact: {
                        ...mapFormToContact(form),
                        id: employee?.contact?.id ?? 0,
                    },
                },
            });
            if (result?.data?.updateContact?.id) {
                alert.success('common.alerts.savedChanges');
                onSaved(employeeResult.data.updateEmployee);
                return;
            }
            alertGraphQlError(result.errors);
            logUtil.warn('Could not update contact/employee');
        } catch (e) {
            logUtil.warn('error while updating employee', e);
            alertError(e);
        }
    };

    const onDelete = async () => {
        const shouldContinue = await modal.show({
            title: t('screens.settings.employees.removeEmployeeDialogTitle'),
            text: t('screens.settings.employees.removeEmployeeDialogText', {
                name: buildNameFromEmployee(employee),
            }),
        });
        if (shouldContinue) {
            try {
                const response = await softDeleteEmployee({
                    variables: {
                        employeeId: employee?.id ?? 0,
                    },
                });
                if (response.data?.softDeleteEmployee) {
                    alert.success(
                        'screens.settings.employees.employeeHasBeenRemoved',
                    );
                    if (typeof onDeleted === 'function') {
                        onDeleted();
                    }
                }
            } catch (e) {
                logUtil.warn('error while deleting employee', e);
                alertError(e);
            }
        }
    };

    const loading = false;

    const canDeleteEmployee = numberOfEmployees && numberOfEmployees > 1;

    return (
        <div className={styles.container}>
            <FormField
                id="employee-detail-email"
                name={'email'}
                value={employeeForm.email}
                label={t('screens.settings.employees.fields.email')}
                updateValue={(field: string, value: string | number) =>
                    setEmployeeForm(prev => ({
                        ...prev,
                        [field]: value as string,
                    }))
                }
            />
            <FormField
                id="employee-detail-username"
                name={'username'}
                value={employeeForm.username}
                label={t('screens.settings.employees.fields.username')}
                updateValue={(field: string, value: string | number) =>
                    setEmployeeForm(prev => ({
                        ...prev,
                        [field]: value as string,
                    }))
                }
                disabled={!createMode}
            />
            <FormField
                id="employee-detail-gender"
                name={'gender'}
                value={employeeForm.gender}
                type="select"
                options={SalutationOptions.map(option => ({
                    label: t(option.label),
                    value: option.value,
                }))}
                required
                label={t('screens.settings.employees.fields.gender')}
                updateValue={(field: string, value: string | number) =>
                    setEmployeeForm(prev => ({
                        ...prev,
                        [field]: value as string,
                    }))
                }
            />
            <FormField
                id="employee-detail-firstname"
                name={'firstname'}
                value={employeeForm.firstname}
                label={t('screens.settings.employees.fields.firstname')}
                updateValue={(field: string, value: string | number) =>
                    setEmployeeForm(prev => ({
                        ...prev,
                        [field]: value as string,
                    }))
                }
                required
            />
            <FormField
                id="employee-detail-lastname"
                name={'lastname'}
                value={employeeForm.lastname}
                label={t('screens.settings.employees.fields.lastname')}
                updateValue={(field: string, value: string | number) =>
                    setEmployeeForm(prev => ({
                        ...prev,
                        [field]: value as string,
                    }))
                }
                required
            />
            <FormField
                id="employee-detail-title"
                name={'title'}
                value={employeeForm.title}
                label={t('screens.settings.employees.fields.title')}
                updateValue={(field: string, value: string | number) =>
                    setEmployeeForm(prev => ({
                        ...prev,
                        [field]: value as string,
                    }))
                }
            />
            <FormField
                id="employee-detail-job"
                name={'job'}
                value={employeeForm.job}
                label={t('screens.settings.employees.fields.job')}
                updateValue={(field: string, value: string | number) =>
                    setEmployeeForm(prev => ({
                        ...prev,
                        [field]: value as string,
                    }))
                }
            />
            <FormField
                id="employee-detail-phonenumber"
                name={'phoneNumber'}
                type="phoneNumber"
                value={employeeForm.phoneNumber}
                label={t('screens.settings.employees.fields.phoneNumber')}
                updateValue={(field: string, value: string | number) =>
                    setEmployeeForm(prev => ({
                        ...prev,
                        [field]: value as string,
                    }))
                }
                required={false}
            />
            {createMode && (
                <>
                    <FormField
                        id="employee-detail-password"
                        name={'password'}
                        type="password"
                        value={employeeForm.password}
                        label={t('screens.settings.employees.fields.password')}
                        updateValue={(field: string, value: string | number) =>
                            setEmployeeForm(prev => ({
                                ...prev,
                                [field]: value as string,
                            }))
                        }
                        required
                    />
                    <FormField
                        id="employee-detail-password2"
                        name={'password2'}
                        type="password"
                        value={employeeForm.password2}
                        label={t('screens.settings.employees.fields.password2')}
                        updateValue={(field: string, value: string | number) =>
                            setEmployeeForm(prev => ({
                                ...prev,
                                [field]: value as string,
                            }))
                        }
                        required
                    />
                </>
            )}
            <div className={styles.actions}>
                <TextButton
                    style="secondary"
                    text={t('common.buttons.cancel')}
                    onClick={resetAndCancel}
                    disabled={loading}
                />
                <TextButton
                    style="primary"
                    text={t(
                        createMode
                            ? 'common.buttons.save'
                            : 'common.buttons.apply',
                    )}
                    onClick={() => onSave(employeeForm)}
                    disabled={loading}
                />
            </div>
            {canDeleteEmployee && !createMode && (
                <div className={styles.deleteButtonContainer}>
                    <TextButton
                        className={styles.deleteButton}
                        style="primary"
                        text={t('screens.settings.employees.removeEmployee')}
                        onClick={onDelete}
                        disabled={loading}
                    />
                </div>
            )}
        </div>
    );
};

export default EmployeeDetail;

const mapFormToUserCreate = (form: EmployeeForm): UserCreate => {
    let username = form?.username;
    if (!username) {
        username = form.email;
    }
    const result: UserCreate = {
        firstname: (form?.firstname ?? '') as string,
        username: username as string,
        lastname: (form?.lastname ?? '') as string,
        email: (form?.email ?? '') as string,
        title: (form?.title ?? '') as string,
        phoneNumber: (form?.phoneNumber ?? '') as string,
        gender: (form?.gender ?? '') as string,
        password: form?.password as string,
    };
    return result;
};

const mapFormToContact = (form: EmployeeForm): Contact => {
    const result: Contact = {
        firstname: (form?.firstname ?? '') as string,
        lastname: (form?.lastname ?? '') as string,
        email: (form?.email ?? '') as string,
        title: (form?.title ?? '') as string,
        phoneNumber: (form?.phoneNumber ?? '') as string,
        gender: (form?.gender ?? '') as string,
    };
    return result;
};

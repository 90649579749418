import { gql } from '@apollo/client';
import { Company } from '../dto';
import { CompanyFragments } from '../fragments';

const FullResponse = gql`
    mutation UpdateCompany(
        $company: CompanyUpdate!
        $imageResolutions: [ImageResolution!]
    ) {
        updateCompany(company: $company, imageResolutions: $imageResolutions) {
            ...FullCompany
        }
    }
    ${CompanyFragments.FullCompany}
`;

export interface UpdateCompanyData {
    updateCompany: Company;
}

export const UpdateCompanyMutation = {
    FullResponse,
};

import React from 'react';
import UpdateNotice from '../../../../components/updateNotice/updateNotice';
import { useObservable } from '../../../../hooks/useObservable';
import { RxUpdateNotice } from '../../../../rxjs/RxUpdateNotice';
import logUtil from '../../../../utils/logUtil';
import styles from './chatNavigation.module.scss';
import Chats from './chats';
import CompanyHeader from './companyHeader';

const ChatNavigation: React.FC = () => {
    // const [showChatCreation, setShowChatCreation] = useState(false);
    const updateProps = useObservable(RxUpdateNotice.updateNoticeProps$);

    return (
        <section className={styles.navigation}>
            <CompanyHeader
                // onShowChatCreation={() => setShowChatCreation(true)}
                onShowChatCreation={() => {
                    logUtil.log('Set show chat creation to true');
                }}
            />
            {updateProps?.visible && (
                <div style={{ paddingLeft: '90px' }}>
                    <UpdateNotice {...updateProps} />
                </div>
            )}
            <Chats />
            {/* 
                TODO: This component is not finished yet
                it will be part of "create new internal chat story"
                <InternalChatCreation
                    show={showChatCreation}
                    onHide={() => setShowChatCreation(false)} /> 
            */}
        </section>
    );
};

export default ChatNavigation;

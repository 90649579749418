import { gql } from '@apollo/client';

const FullAddress = gql`
    fragment FullAddress on Address {
        id
        streetName
        houseNumber
        zipCode
        city
        country
    }
`;

export const AddressFragments = {
    FullAddress,
};

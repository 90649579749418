import React, { useEffect, useState } from 'react';
import { useScreenHasLessWidthThan } from '../../../hooks/useScreenSize';
import AnimalChatRoutes from '../../../utils/routing';
import SettingsMain from './main/settingsMain';
import SettingsNavigation from './navigation/settingsNavigation';
import styles from './settingsLayout.module.scss';

interface SettingsLayoutProps {
    show: boolean;
}

const SettingsLayout: React.FC<SettingsLayoutProps> = ({ show }) => {
    const [initial, setInitial] = useState(true);
    const [activeSubRoute, setActiveSubRoute] = useState(
        AnimalChatRoutes.settingsSub.company,
    );
    const [containerClassName, setContainerClassName] = useState(
        styles.navigation,
    );

    useEffect(() => {
        if (show) {
            setContainerClassName(`${styles.container} ${styles.show}`);
        } else {
            if (!initial) {
                setContainerClassName(`${styles.container} ${styles.hide}`);
            } else {
                setContainerClassName(`${styles.container}`);
                setInitial(false);
            }
        }
        // we don't want to trigger this function when the initial state changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    const screenHasLessWidthThanXxs = useScreenHasLessWidthThan('xxs');

    const [showMain, setShowMain] = useState(false);

    const mainStyle = screenHasLessWidthThanXxs
        ? showMain
            ? { left: '0px', width: '100vw' }
            : { left: '100vw' }
        : {};

    return (
        <div className={containerClassName}>
            <section
                className={styles.navigation}
                onAnimationEnd={() => {
                    setContainerClassName(
                        `${styles.container} ${
                            show ? styles.shown : styles.hidden
                        }`,
                    );
                }}>
                <SettingsNavigation
                    activeSubRoute={activeSubRoute}
                    onNavigate={value => {
                        setShowMain(true);
                        setActiveSubRoute(value);
                    }}
                />
            </section>
            <section className={styles.main} style={mainStyle}>
                <SettingsMain
                    subRoute={activeSubRoute}
                    setShowMain={show => setShowMain(show)}
                />
            </section>
        </div>
    );
};

export default SettingsLayout;

import { ChatMessageFragments } from './chatMessageFragments';
import { AnimalFragments } from './animalFragments';
import { gql } from '@apollo/client';
import { CustomerFragments } from './customerFragments';
import { FileDescriptionFragments } from './fileDescriptionFragments';
import { TopicFragments } from './topicFragments';
import { UserFragments } from './userFragments';
import { AnimalGroupFragments } from './animalGroupFragments';
import { ChatToUserFragments } from './chatToUserFragments';

const FullChat = gql`
    fragment FullChat on Chat {
        id
        unreadMessageCount
        hasOpenRequest
        type
        updatedAt
        updatedBy
        createdAt
        createdBy
        animal {
            ...FullAnimal
            colorBundle {
                hexColor
                hexColorGradient
            }
            imageFile {
                ...FullFileDescription
            }
            owners {
                user {
                    id
                    imageFile {
                        ...FullFileDescription
                    }
                }
            }
        }
        animalGroup {
            ...FullAnimalGroup
            colorBundle {
                hexColor
                hexColorGradient
            }
            imageFile {
                ...FullFileDescription
            }
            owners {
                user {
                    id
                    imageFile {
                        ...FullFileDescription
                    }
                }
            }
        }
        topic {
            ...FullTopic
        }
        company {
            id
            name
            type
            isActive
            colorBundle {
                hexColor
                hexColorGradient
            }
            imageFile {
                ...FullFileDescription
            }
        }
        customers {
            id
            contact {
                id
                title
                firstname
                lastname
                email
            }
            company {
                id
                name
            }
        }
        participants {
            user {
                ...FullUserPrivateOnUser
            }
            customer {
                ...CustomerWithContact
            }
            employee {
                id
                title
                contact {
                    id
                    title
                    firstname
                    lastname
                    email
                }
                role {
                    key
                }
            }
        }
        lastMessage {
            ...FullChatMessage
        }
        chatToUser {
            ...FullChatToUser
        }
    }
    ${UserFragments.FullUserPrivateOnUser}
    ${ChatToUserFragments.FullChatToUser}
    ${ChatMessageFragments.FullResponse}
    ${TopicFragments.FullTopic}
    ${CustomerFragments.CustomerWithContact}
    ${FileDescriptionFragments.FullResponse}
    ${AnimalFragments.FullAnimal}
    ${AnimalGroupFragments.FullAnimalGroup}
`;

export const ChatFragments = { FullChat };

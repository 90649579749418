import { FileInfo } from '../../graphql';

export const AcceptImageFilesString = 'image/*';
export const AcceptVideoFilesString = 'video/*';
export const AcceptApplicationFilesString = 'application/*';
export const AcceptMediaString = [
    AcceptImageFilesString,
    AcceptVideoFilesString,
    AcceptApplicationFilesString,
].join(',');

const video = 'video';
export const isVideoType = (type?: string | null): boolean =>
    type?.toLowerCase().indexOf(video) !== -1;

const image = 'image';
export const isImageType = (type?: string | null): boolean =>
    type?.toLowerCase().indexOf(image) !== -1;

const application = 'application';
export const isApplicationType = (type?: string | null): boolean =>
    type?.toLowerCase().indexOf(application) !== -1;

export const getFileInfoFromImage = (file: File): FileInfo => {
    return {
        fileName: file.name,
        fileSize: file.size,
        type: file.type,
    };
};

export const getFileInfoFromApplicationFile = (file: File): FileInfo => {
    return {
        fileName: file.name,
        fileSize: file.size,
        type: file.type,
    };
};

export const getPrettyFileTypeTextFromFileType = (
    fileType?: string | null,
): string => {
    if (!fileType) {
        return '';
    }
    const fileTypeParts = fileType.split('/') ?? [];
    const prettyFileTypeText = fileTypeParts.length
        ? fileTypeParts[fileTypeParts.length - 1].toUpperCase()
        : '';

    return prettyFileTypeText;
};

export const getPrettyFileSizeTextFromNumber = (
    sizeInBytes?: number | null,
): string => {
    if (!sizeInBytes || sizeInBytes < 1) {
        return '';
    }

    let size = sizeInBytes / 1024;
    // less than 1 kB -> 0.123 kB
    if (size < 1) {
        return `${size.toFixed(3)} kB`;
    }

    // less than 1 MB --> 1023 kB
    if (size < 1024) {
        return `${size.toFixed(0)} kB`;
    }

    // more than 1 MB -> 123.4 MB
    size = size / 1024;
    return `${size.toFixed(1)} MB`;
};

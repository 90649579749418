import {
    MutationUpdateActivationCodeSmsArgs,
    MutationUpdatePhoneNumberArgs,
} from '../../../../__shared/graphql';
import { useMutation } from '@apollo/client';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
// import { useNavigate } from 'react-router-dom';
import Verify from './verify';
import {
    UpdateActivationCodeSmsData,
    UpdatePhoneNumberData,
    UserMutations,
} from '../../../../graphql/mutations/userMutations';
import { useAlert } from '../../../../hooks/useAlert';
import { useErrorAlert } from '../../../../hooks/useErrorAlert';
import { useObservable } from '../../../../hooks/useObservable';
import { RxCreateAccount } from '../../../../rxjs/RxCreateAccount';
import RxUserProfile from '../../../../rxjs/RxUserProfile';
import logUtil from '../../../../utils/logUtil';
import AnimalChatRoutes from '../../../../utils/routing';

const VerifyPhone: React.FC = () => {
    const phoneNumberForVerification = useObservable<string>(
        RxCreateAccount.phoneNumberForVerification$,
        '',
    );

    const [phoneNumber, setPhoneNumber] = useState<string>('');

    useEffect(() => {
        setPhoneNumber(phoneNumberForVerification);
    }, [phoneNumberForVerification]);

    const [isLoading, setIsLoading] = useState(false);
    const [activationCode, setActivationCode] = useState('');
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { alertError, alertGraphQlError } = useErrorAlert();
    const alert = useAlert();

    const [updatePhoneNumberMutation] = useMutation<
        UpdatePhoneNumberData,
        MutationUpdatePhoneNumberArgs
    >(UserMutations.UpdatePhoneNumber);

    const [updateActivationCodeSmsMutation] = useMutation<
        UpdateActivationCodeSmsData,
        MutationUpdateActivationCodeSmsArgs
    >(UserMutations.UpdateActivationCodeSms);

    const handleSendSmsActivationCode = async () => {
        setIsLoading(true);
        try {
            const response = await updateActivationCodeSmsMutation({
                variables: {
                    phoneNumber,
                },
            });
            const { data, errors } = response;
            if (errors?.length) {
                alertGraphQlError(errors);
                return;
            }
            if (data?.updateActivationCodeSms) {
                alert.success(
                    'screens.business.userProfile.smsCodeSendMessage',
                );
            }
        } catch (error) {
            logUtil.log('Error while updating email activation code', error);
            alertError(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleNext = useCallback(
        async (_phoneNumber: string, _activationCode: string) => {
            setIsLoading(true);
            try {
                const { data, errors } = await updatePhoneNumberMutation({
                    variables: {
                        phoneNumber: _phoneNumber,
                        activationCode: _activationCode,
                    },
                });
                if (errors?.length) {
                    alertGraphQlError(errors);
                    return;
                }
                if (data?.updatePhoneNumber) {
                    RxUserProfile.setUser(data.updatePhoneNumber);
                    alert.success(
                        'screens.business.userProfile.profileCreated',
                    );
                    // Navigte to first steps so user sees what to do next
                    navigate(AnimalChatRoutes.businessSubs.firstSteps);
                }
            } catch (e) {
                logUtil.error(e);
                alertError(e);
            } finally {
                setIsLoading(false);
            }
        },
        [
            alertError,
            alertGraphQlError,
            updatePhoneNumberMutation,
            navigate,
            alert,
        ],
    );

    return (
        <Verify
            title={t('screens.business.verifyPhone.title')}
            text={t('screens.business.verifyPhone.text')}
            placeholder={t('screens.business.verifyPhone.placeholder')}
            code={activationCode}
            isLoading={isLoading}
            onBack={() =>
                navigate(AnimalChatRoutes.businessSubs.firstStepsSubs.phone)
            }
            onNext={() => handleNext(phoneNumber, activationCode)}
            onChange={setActivationCode}
            onResendCode={handleSendSmsActivationCode}
        />
    );
};

export default VerifyPhone;

import { useMutation } from '@apollo/client';
import { useCallback, useEffect } from 'react';
import {
    ChatMessage,
    ChatMessageStateUpdate,
    MutationUpdateChatMessagesToUserArgs,
    UpdateChatMessagesToUserData,
    UpdateChatMessagesToUserMutation,
} from '../__shared/graphql';
import { logMutation } from './../utils/loggingUtil';
interface ChatMessageManager {
    markMessagesAsReceived: (messages: ChatMessage[]) => Promise<ChatMessage[]>;
    markMessagesAsViewed: (messages: ChatMessage[]) => Promise<ChatMessage[]>;
    loading: boolean;
}

const useChatMessageManager = (): ChatMessageManager => {
    const [
        updateChatMessagesToUser,
        { loading: updateMessagesLoading, error },
    ] = useMutation<
        UpdateChatMessagesToUserData,
        MutationUpdateChatMessagesToUserArgs
    >(UpdateChatMessagesToUserMutation.FullResponseWithChatMessage);

    useEffect(() => {
        if (error) {
            console.warn('error while marking message as received', error);
        }
    }, [error]);

    const markMessages = useCallback(
        async (
            messages: ChatMessage[],
            property: keyof ChatMessageStateUpdate,
        ): Promise<ChatMessage[]> => {
            if (!messages?.length) {
                return [];
            }

            const messageIds = messages.map(msg => msg.id ?? 0);

            if (!messageIds?.length) {
                return [];
            }
            const now = new Date();
            try {
                logMutation(
                    `updateChatMessagesToUser [${property}, [${messageIds.join(
                        ',',
                    )}]]`,
                    messageIds,
                );
                const response = await updateChatMessagesToUser({
                    variables: {
                        chatMessageIds: messageIds.filter(id => id > 0),
                        chatMessageStateUpdate: {
                            [property]: now,
                        },
                    },
                });
                if (response.errors) {
                    console.warn(response.errors);
                }
                const result: ChatMessage[] = [];
                response.data?.updateChatMessagesToUser.forEach(msg => {
                    if (msg.chatMessage) {
                        result.push(msg.chatMessage);
                    }
                });
                return result;
            } catch (e) {
                console.warn('Error while marking message as received', e);
            }
            return [];
        },
        [updateChatMessagesToUser],
    );

    const markMessagesAsReceived = useCallback(
        (messages: ChatMessage[]) => {
            return markMessages(
                messages.filter(msg => !msg.isReceivedByMe),
                'receivedAt',
            );
        },
        [markMessages],
    );

    const markMessagesAsViewed = useCallback(
        (messages: ChatMessage[]) => {
            return markMessages(
                messages.filter(msg => !msg.isViewedByMe),
                'viewedAt',
            );
        },
        [markMessages],
    );

    return {
        markMessagesAsReceived,
        markMessagesAsViewed,
        loading: updateMessagesLoading,
    };
};

export default useChatMessageManager;

import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Icon from '../../../../components/icon/icon';
import AnimalChatRoutes from '../../../../utils/routing';
import styles from './navigationBar.module.scss';
import { useObservable } from '../../../../hooks/useObservable';
import RxAuthentication from '../../../../rxjs/RxAuthentication';
import AuthenticationUtil from '../../../../utils/authenticationUtil';
import { RxDrawerMenu } from '../../../../rxjs/RxDrawerMenu';
import RxUserProfile from '../../../../rxjs/RxUserProfile';
import ProfileIcon from '../../../../components/profileIcon/profileIcon';
import RxCompany from '../../../../rxjs/RxCompany';
import { isElectron } from '../../../../__shared/electron';

export const NavigationBar = (): JSX.Element => {
    const userId = useObservable(RxAuthentication.userId$, 0);
    const user = useObservable(RxUserProfile.userProfile$);

    const navigate = useNavigate();
    const location = useLocation();

    const showDrawerMenu = useObservable(RxDrawerMenu.showDrawerMenu$);

    const company = useObservable(RxCompany.currentCompany$);

    const isProfileComplete =
        !!user?.emailConfirmedAt && !!user.phoneNumberConfirmedAt;

    const isStripeConfigured =
        !!company?.stripeAccountId && !!company.isOnboardedInStripe;

    const isAllowedToGoToApp =
        isElectron() && isProfileComplete && isStripeConfigured;

    if (!location.pathname.includes(AnimalChatRoutes.business)) {
        return <></>;
    }

    return (
        <div className={styles.container}>
            <header className={styles.header}>
                <span
                    className={styles.menuToggle}
                    onClick={() => {
                        RxDrawerMenu.setShowDrawerMenu(!showDrawerMenu);
                    }}>
                    <div
                        style={{
                            pointerEvents: 'none', // important for mobile view!
                        }}>
                        <Icon name="menu-outline" size={28} />
                    </div>
                </span>
                <span
                    style={{ maxHeight: '30px' }}
                    onClick={() => {
                        navigate(
                            isAllowedToGoToApp
                                ? AnimalChatRoutes.app
                                : AnimalChatRoutes.businessSubs.firstSteps,
                        );
                    }}>
                    <img
                        src="/images/AnimalchatLogo.png"
                        className={styles.logo}
                    />
                </span>
                {location.pathname !== AnimalChatRoutes.businessSubs.login && (
                    <>
                        <span className={styles.link}>
                            <div
                                onClick={() => {
                                    navigate(
                                        AnimalChatRoutes.businessSubs.login,
                                    );
                                    AuthenticationUtil.logout(true);
                                }}>
                                {userId ? 'Logout' : 'Login'}
                            </div>
                        </span>
                        {user?.imageFile?.safeUrl ? (
                            <span
                                onClick={() =>
                                    navigate(
                                        AnimalChatRoutes.businessSubs.profile,
                                    )
                                }
                                className={styles.profileIcon}>
                                <ProfileIcon
                                    size={34}
                                    fileDescription={user?.imageFile}
                                    colorBundle={user?.colorBundle}
                                />{' '}
                            </span>
                        ) : (
                            <span
                                onClick={() => {
                                    if (!userId) {
                                        navigate(
                                            AnimalChatRoutes.businessSubs
                                                .firstStepsSubs.termsOfUse,
                                        );
                                    } else {
                                        navigate(
                                            AnimalChatRoutes.businessSubs
                                                .profile,
                                        );
                                    }
                                }}
                                className={`${styles.profileIcon} ${
                                    !userId ? styles.profileIconDisabled : ''
                                }`.trim()}>
                                <div
                                    style={{
                                        pointerEvents: 'none', // important for mobile view!
                                    }}>
                                    <Icon
                                        name="person-circle-outline"
                                        size={32}
                                    />
                                </div>
                            </span>
                        )}
                    </>
                )}
            </header>
        </div>
    );
};

export default NavigationBar;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/button/button';
import Input from '../../../../components/input/input';
import {
    isValidEmailAddress,
    isValidRequiredValueWithLimit,
} from '../../../../utils/validationUtil';
import styles from './createAccount.module.scss';
import RegisterCard from '../../components/registerCard/registerCard';
import CardHeader from '../../components/cardHeader/cardHeader';
import { useObservable } from '../../../../hooks/useObservable';
import RxAuthentication from '../../../../rxjs/RxAuthentication';
import { Limits } from '../../../../utils/inputLimits';
import { useNavigate } from 'react-router-dom';
import AnimalChatRoutes from '../../../../utils/routing';
import { RxCreateAccount } from '../../../../rxjs/RxCreateAccount';

export type RegisterBusinessFormTextKey =
    | 'email'
    | 'email2'
    | 'password'
    | 'password2'
    | 'gender'
    | 'title'
    | 'firstname'
    | 'lastname'
    | 'phone';

export interface RegisterFormData {
    email: string;
    email2: string;
    password: string;
    password2: string;
    gender: string;
    title: string;
    firstname: string;
    lastname: string;
    isEmailActivated: boolean;
    phone: string;
    isPhoneActivated: boolean;
}

const initialFormData = {
    email: '',
    email2: '',
    password: '',
    password2: '',
    gender: '',
    title: '',
    firstname: '',
    lastname: '',
    isEmailActivated: false,
    phone: '',
    isPhoneActivated: false,
};

const CreateAccount: React.FC = () => {
    const createAccountForm = useObservable<RegisterFormData | undefined>(
        RxCreateAccount.createAccountForm$,
    );

    const [form, setForm] = useState<RegisterFormData>(initialFormData);

    useEffect(() => {
        if (createAccountForm) {
            setForm(createAccountForm);
        }
    }, [createAccountForm]);

    const onUpdateValue = (key: RegisterBusinessFormTextKey, value: string) => {
        RxCreateAccount.setCreateAccountForm({
            ...form,
            [key]: value,
        });
    };

    const accessToken = useObservable(RxAuthentication.accessToken$);
    const [isValid, setIsValid] = useState(false);
    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        const { email, email2, password, password2 } = form;
        if (
            !isValidEmailAddress({ email, limit: Limits.Email }) ||
            !isValidEmailAddress({ email: email2, limit: Limits.Email }) ||
            !isValidRequiredValueWithLimit(Limits.Password, password) ||
            !isValidRequiredValueWithLimit(Limits.Password, password2)
        ) {
            setIsValid(false);
            return;
        }
        if (email !== email2) {
            setIsValid(false);
            return;
        }
        if (password !== password2) {
            setIsValid(false);
            return;
        }
        setIsValid(true);
    }, [form]);

    const nextStep = () => {
        navigate(AnimalChatRoutes.businessSubs.firstStepsSubs.person);
    };

    return (
        <RegisterCard>
            <CardHeader
                title={t('screens.business.createAccount.title')}
                onBack={() => {
                    navigate(
                        AnimalChatRoutes.businessSubs.firstStepsSubs.termsOfUse,
                    );
                }}
            />
            <div className={styles.form}>
                <Input
                    className={styles.input}
                    id="register-email"
                    limit={Limits.Email}
                    disabled={!!accessToken?.length}
                    value={form.email}
                    placeholder={t('screens.business.createAccount.email')}
                    onChange={e => onUpdateValue('email', e.target.value)}
                />
                <Input
                    className={styles.input}
                    disabled={!!accessToken?.length}
                    limit={Limits.Email}
                    id="register-email2"
                    value={form.email2}
                    placeholder={t('screens.business.createAccount.email2')}
                    onChange={e => onUpdateValue('email2', e.target.value)}
                />
                <Input
                    className={styles.input}
                    id="register-email"
                    disabled={!!accessToken?.length}
                    value={form.password}
                    limit={Limits.Password}
                    type="password"
                    placeholder={t('screens.business.createAccount.password')}
                    onChange={e => onUpdateValue('password', e.target.value)}
                />
                <Input
                    className={styles.input}
                    id="register-email"
                    disabled={!!accessToken?.length}
                    value={form.password2}
                    limit={Limits.Password}
                    type="password"
                    placeholder={t('screens.business.createAccount.password2')}
                    onChange={e => onUpdateValue('password2', e.target.value)}
                />
            </div>
            <Button
                text={t('common.buttons.next')}
                disabled={!isValid}
                className={styles.button}
                onClick={() => nextStep()}
            />
        </RegisterCard>
    );
};

export default CreateAccount;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomerProfile from './customerProfile';
import DetailHeader from './detailHeader';
import {
    AnimalContext,
    Chat,
    ChatMessage,
    FindMessagesData,
    FindMessagesQuery,
    QueryFindChatMessagesArgs,
} from '../../../../__shared/graphql';

import styles from './chatDetail.module.scss';
import { ChatTypes } from '../../../../__shared/common';
import CompanyProfile from './companyProfile';
import ChatMediaList from './chatMediaList';
import { useLazyQuery } from '@apollo/client';
import AnimalContextList from './animalContextList';
import AnimalContextProfile from './animalContextProfile';
import { filterMessagesWithImageAndVideo } from '../../../../utils/fileUtils';
import { useScreenHasMoreWidthThan } from '../../../../hooks/useScreenSize';

interface ChatDetailProps {
    hideDetail: () => void;
    showDetail: () => void;
    chatMessagesWithFiles: ChatMessage[];
    show: boolean;
    chat?: Chat;
}

const ChatDetail: React.FC<ChatDetailProps> = ({
    hideDetail,
    show,
    chat,
    chatMessagesWithFiles = [],
}) => {
    const screenHasMoreWidthThanMd = useScreenHasMoreWidthThan('md');
    const [initial, setInitial] = useState(true);
    const [showMedia, setShowMedia] = useState(false);
    const [showMediaForAnimalContext, setShowMediaForAnimalContext] =
        useState(false);

    const [currentAnimalContexts, setAnimalContexts] = useState<
        AnimalContext[]
    >([]);
    const [currentAnimalContext, setCurrentAnimalContext] =
        useState<AnimalContext>();
    const [showAnimalContextProfile, setShowAnimalContextProfile] =
        useState<boolean>(false);
    const [showAnimalContexts, setShowAnimalContexts] = useState(false);

    const [
        chatMessagesWithFilesForAnimalContext,
        setChatMessagesWithFilesForAnimalContext,
    ] = useState<ChatMessage[]>([]);

    const findChatMessagesVariables: QueryFindChatMessagesArgs = {
        searchInput: '',
        onlyFiles: true,
    };

    if (currentAnimalContext?.animal?.id) {
        findChatMessagesVariables.animalId = currentAnimalContext.animal?.id;
    } else if (currentAnimalContext?.animalGroup?.id) {
        findChatMessagesVariables.animalGroupId =
            currentAnimalContext.animalGroup?.id;
    }

    const [
        findChatMessages,
        { loading: findChatMessagesLoading, data: findChatMessagesData },
    ] = useLazyQuery<FindMessagesData, QueryFindChatMessagesArgs>(
        FindMessagesQuery.MediaResponse,
        {
            variables: findChatMessagesVariables,
        },
    );

    useEffect(() => {
        if (
            !findChatMessagesLoading &&
            findChatMessagesData?.findChatMessages
        ) {
            setChatMessagesWithFilesForAnimalContext(
                findChatMessagesData?.findChatMessages,
            );
        } else if (!findChatMessagesLoading) {
            setChatMessagesWithFilesForAnimalContext([]);
        }
    }, [
        findChatMessagesLoading,
        findChatMessagesData,
        currentAnimalContext?.animal?.id,
        currentAnimalContext?.animalGroup?.id,
    ]);

    useEffect(() => {
        if (currentAnimalContext?.animal || currentAnimalContext?.animalGroup) {
            findChatMessages();
        }
    }, [
        currentAnimalContext?.animal,
        currentAnimalContext?.animalGroup,
        findChatMessages,
    ]);

    const [detailStyle, setDetailStyle] = useState(
        `${styles.detail} ${styles.closed}`,
    );

    useEffect(() => {
        setInitial(true);
    }, [screenHasMoreWidthThanMd]);

    useEffect(() => {
        if (screenHasMoreWidthThanMd) {
            if (show) {
                setDetailStyle(`${styles.detail} ${styles.shown}`);
            } else {
                setDetailStyle(`${styles.detail} ${styles.hidden}`);
            }

            return;
        }

        if (show) {
            if (initial) {
                setDetailStyle(`${styles.detail} ${styles.shown}`);
                setInitial(false);
                return;
            }
            setDetailStyle(`${styles.detail} ${styles.show}`);
            return;
        }
        if (initial) {
            setDetailStyle(`${styles.detail}`);
            setInitial(false);
            return;
        }
        setDetailStyle(`${styles.detail} ${styles.hide}`);
        // we don't want to trigger this function when the initial state changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show, screenHasMoreWidthThanMd]);

    const { t } = useTranslation();

    const title =
        chat?.type === ChatTypes.Internal
            ? t('screens.chat.detail.group.header')
            : t('screens.chat.detail.customer.header');

    return (
        <section
            className={detailStyle}
            onAnimationEnd={() => {
                setDetailStyle(
                    `${styles.detail} ${show ? styles.shown : styles.hidden}`,
                );
            }}>
            <div className={styles.container}>
                <DetailHeader title={title} onClose={hideDetail} />
                {chat?.type !== ChatTypes.Internal && (
                    <CustomerProfile
                        chatMessagesWithFiles={chatMessagesWithFiles}
                        onShowMedia={() => setShowMedia(true)}
                        onShowAnimalContexts={(
                            currentAnimalContexts: AnimalContext[],
                        ) => {
                            setAnimalContexts(currentAnimalContexts);
                            setShowAnimalContexts(true);
                        }}
                    />
                )}
                {chat?.type === ChatTypes.Internal && (
                    <CompanyProfile
                        chat={chat ?? undefined}
                        chatMessagesWithFiles={chatMessagesWithFiles}
                        onShowMedia={() => setShowMedia(true)}
                    />
                )}

                <div
                    className={`${
                        showMedia ? styles.subPage : styles.subPageHidden
                    }`}>
                    <DetailHeader
                        iconName="arrow-back-outline"
                        title={t('screens.chat.detail.customer.media')}
                        onClose={() => setShowMedia(false)}
                    />
                    <ChatMediaList
                        messages={filterMessagesWithImageAndVideo(
                            chatMessagesWithFiles,
                        )}
                        chatId={chat?.id ?? 0}
                    />
                </div>
                <div
                    className={`${
                        showAnimalContexts
                            ? styles.subPage
                            : styles.subPageHidden
                    }`}>
                    <DetailHeader
                        iconName="arrow-back-outline"
                        title={t('common.animalProfiles')}
                        onClose={() => setShowAnimalContexts(false)}
                    />
                    <AnimalContextList
                        animalContexts={currentAnimalContexts}
                        onAnimalContextClicked={(
                            currentAnimalContext: AnimalContext,
                        ) => {
                            setCurrentAnimalContext(currentAnimalContext);
                            setShowAnimalContextProfile(true);
                        }}
                    />
                </div>

                <div
                    className={`${
                        showAnimalContextProfile
                            ? styles.subPage
                            : styles.subPageHidden
                    }`}>
                    <>
                        {((showAnimalContextProfile &&
                            currentAnimalContext?.animal) ||
                            currentAnimalContext?.animalGroup) && (
                            <>
                                <DetailHeader
                                    iconName="arrow-back-outline"
                                    title={
                                        currentAnimalContext?.animalGroup
                                            ? t('common.animalGroup')
                                            : t('common.animalProfile')
                                    }
                                    onClose={() =>
                                        setShowAnimalContextProfile(false)
                                    }
                                />
                                <AnimalContextProfile
                                    chatMessagesWithFiles={filterMessagesWithImageAndVideo(
                                        chatMessagesWithFilesForAnimalContext,
                                    )}
                                    animalContext={currentAnimalContext}
                                    onShowMedia={() =>
                                        setShowMediaForAnimalContext(true)
                                    }
                                />
                            </>
                        )}
                    </>
                </div>
                <div
                    className={`${
                        showMediaForAnimalContext
                            ? styles.subPage
                            : styles.subPageHidden
                    }`}>
                    <DetailHeader
                        iconName="arrow-back-outline"
                        title={t('screens.chat.detail.customer.media')}
                        onClose={() => setShowMediaForAnimalContext(false)}
                    />
                    <ChatMediaList
                        messages={filterMessagesWithImageAndVideo(
                            chatMessagesWithFilesForAnimalContext,
                        )}
                        chatId={0}
                    />
                </div>
            </div>
        </section>
    );
};

export default ChatDetail;

import { gql } from '@apollo/client';
import { Licence } from '../dto';
import { LicenceFragments } from '../fragments';

const GetLicences = gql`
    query GetLicences {
        getLicences {
            ...FullLicence
        }
    }
    ${LicenceFragments.FullLicence}
`;

export interface GetLicencesData {
    getLicences: Licence[];
}

const GetLicencesQueries = {
    GetLicences,
};

export const LicenceQueries = {
    GetLicencesQueries,
};

import { Employee } from '../dto';
import { gql } from '@apollo/client';
import { EmployeeFragments } from '../fragments';

const FullResponse = gql`
    mutation CreateUserAndContactAndEmployeeAndAddToChatsWithCustomers(
        $employee: EmployeePreCreate!
        $user: UserCreate!
    ) {
        createUserAndContactAndEmployeeAndAddToChatsWithCustomers(
            employee: $employee
            user: $user
        ) {
            ...FullEmployee
        }
    }
    ${EmployeeFragments.FullEmployee}
`;

export interface CreateUserAndContactAndEmployeeData {
    createUserAndContactAndEmployeeAndAddToChatsWithCustomers: Employee;
}

export const CreateEmployeeMutation = {
    FullResponse,
};

import { Autolinker } from 'autolinker';
import GraphemeSplitter from 'grapheme-splitter';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { fixPriceValue } from '../../../../../../utils/currencyUtils';
import { DateUtil } from '../../../../../../__shared/common';
import { ChatMessage } from '../../../../../../__shared/graphql';
import styles from './chatMessageText.module.scss';

export interface ChatMessageTextProps {
    message: ChatMessage;
    showOrderDetails?: boolean;
}

const ChatMessageText: React.FC<ChatMessageTextProps> = ({
    message,
    showOrderDetails = true,
}) => {
    const { t } = useTranslation();

    const order = message.order;
    const appointment = message.order?.appointment;

    const linkedText = Autolinker.link(message.text ?? '');

    let textStyle = styles.text;
    if (message.text?.length) {
        const MaxNumberOfBigEmojis = 5;

        const splitter = new GraphemeSplitter();
        const splittedMessageTextByCharacterOrEmoji = message.text?.length
            ? splitter.splitGraphemes(message.text)
            : [];
        const characterCount = splittedMessageTextByCharacterOrEmoji.length;

        if (characterCount > 0 && characterCount <= MaxNumberOfBigEmojis) {
            const emojiRegex = /\p{Extended_Pictographic}/gu;

            const emojiCount = splittedMessageTextByCharacterOrEmoji.filter(
                x => {
                    // Note: can contain multiple charactor for the presentation of a single emoji
                    const isEmoji = x.match(emojiRegex)?.length;
                    // logUtil.log(
                    //     `ChatMessage character: ${x} isEmoji: ${isEmoji}`,
                    // );
                    return isEmoji;
                },
            ).length;

            if (emojiCount === characterCount) {
                textStyle = styles.bigText;
            }
        }
    }

    if (!order) {
        return (
            <div
                className={textStyle}
                dangerouslySetInnerHTML={{ __html: linkedText }}></div>
        );
    }

    const isClosed = !!order.closedAt;

    const isCancelled = !!order.canceledAt;
    const isCancelledByCustomer = order.canceledBy === order.customer?.user?.id;

    const cancelationText = isCancelledByCustomer
        ? t('screens.chat.appointment.cancelledByCustomer')
        : t('screens.chat.appointment.cancelledByCompany');
    const confirmationText = order.appointment?.confirmedAt
        ? t('screens.chat.appointment.confirmed')
        : t('screens.chat.appointment.unconfirmed');
    const advancePaymentText = order.isAdvancePayment
        ? t('common.yes')
        : t('common.no');

    if (appointment && typeof order?.amountToPayInEuroCents === 'number') {
        const price = fixPriceValue(
            (order.amountToPayInEuroCents / 100).toFixed(2).replace('.', ','),
        );

        let messageText = t('screens.chat.appointment.videoCallOffer');
        let paymentStatusShortText = !isCancelled
            ? t('screens.chat.payment.pending')
            : t('screens.chat.payment.dropped');

        if (order.paidAt) {
            messageText = t('screens.chat.appointment.videoCallPending');
            paymentStatusShortText = t('screens.chat.payment.paid');
        }

        const consultationStatusShortText = !isClosed
            ? t('screens.chat.appointment.pending')
            : t('screens.chat.appointment.closed');

        const linkedTextForOrder = Autolinker.link(messageText ?? '');

        return (
            <div>
                <div
                    className={styles.text}
                    dangerouslySetInnerHTML={{
                        __html: linkedTextForOrder,
                    }}></div>
                <div className={styles.text}>
                    <div className={styles.timeSlot}>
                        {DateUtil.toDDMMYYYHHMM(appointment.from)}
                    </div>
                    <div className={styles.price}>{price}</div>
                    {!isCancelled && showOrderDetails && (
                        <div>
                            <div className={styles.statusText}>
                                <span>{`${t(
                                    'screens.chat.appointment.advancePayment',
                                )}: `}</span>
                                <span className={styles.statusValue}>
                                    {advancePaymentText}
                                </span>
                            </div>
                            <div className={styles.statusText}>
                                <span>{`${t(
                                    'screens.chat.appointment.appointment',
                                )}: `}</span>
                                <span
                                    className={`${styles.statusValue} ${
                                        order.appointment?.confirmedAt
                                            ? styles.paid
                                            : styles.pending
                                    }`}>
                                    {confirmationText}
                                </span>
                            </div>
                            <div className={styles.statusText}>
                                <span>{`${t(
                                    'screens.chat.payment.paymentStatus',
                                )}: `}</span>
                                <span
                                    className={`${styles.statusValue} ${
                                        order.paidAt
                                            ? styles.paid
                                            : styles.pending
                                    }`}>
                                    {paymentStatusShortText}
                                </span>
                            </div>
                            <div className={styles.statusText}>
                                <span>{`${t(
                                    'screens.chat.appointment.consultationStatus',
                                )}: `}</span>
                                <span
                                    className={`${styles.statusValue} ${
                                        order.closedAt
                                            ? styles.paid
                                            : styles.pending
                                    }`}>
                                    {consultationStatusShortText}
                                </span>
                            </div>
                        </div>
                    )}
                    {isCancelled && (
                        <div className={styles.cancelled}>
                            {cancelationText}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    // TODO: to be defined when new order/appointment types are defined
    return null;
};

export default ChatMessageText;

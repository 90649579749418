import React from 'react';
import ReactTooltip from 'react-tooltip';
import { useObservable } from '../../../../../../hooks/useObservable';
import RxAuthentication from '../../../../../../rxjs/RxAuthentication';
import {
    getPrettyFileSizeText,
    getPrettyFileTypeText,
} from '../../../../../../__shared/common';
import { saveFileAs } from '../../../../../../__shared/electron';
import { FileDescription } from '../../../../../../__shared/graphql';
import styles from './chatMessageApplicationFilePreview.module.scss';

export interface ChatMessageApplicationFilePreviewProps {
    fileDescription: FileDescription;
}

const ChatMessageApplicationFilePreview: React.FC<
    ChatMessageApplicationFilePreviewProps
> = ({ fileDescription }) => {
    const accessToken = useObservable(RxAuthentication.accessToken$);
    const prettyFileTypeText = getPrettyFileTypeText(fileDescription);
    const prettyFileSizeText = getPrettyFileSizeText(fileDescription);
    return (
        <div
            className={styles.container}
            data-tip
            data-for={`download-file-${fileDescription.id}`}
            onClick={() => {
                if (
                    fileDescription?.safeUrl &&
                    fileDescription?.fileType &&
                    fileDescription.name &&
                    accessToken
                ) {
                    saveFileAs({
                        url: fileDescription.safeUrl,
                        fileType: fileDescription.fileType,
                        fileName: fileDescription.name,
                        accessToken,
                    });
                }
            }}>
            <div className={styles.fileTypeSymbol}>
                <div className={styles.fileTypeText}>📄</div>
            </div>
            <div className={styles.fileInfoContainer}>
                <div className={styles.fileName}>{fileDescription.name}</div>
                <div className={styles.fileInfo}>
                    {`${prettyFileTypeText} | ${prettyFileSizeText}`}
                </div>
            </div>
            <ReactTooltip
                className={styles.tooltip}
                id={`download-file-${fileDescription.id}`}
                effect="solid"
                place="bottom"
                getContent={() => fileDescription.name}
            />
        </div>
    );
};

export default ChatMessageApplicationFilePreview;

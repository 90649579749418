import {
    AssignLicenceData,
    BuyLicenceData,
    Company,
    CompanyMutations,
    GetLicencesData,
    Licence,
    LicenceQueries,
    MutationAssignLicenceArgs,
    MutationBuyLicenceArgs,
} from '../../../../__shared/graphql';
import {
    CompanyLicenceTypes,
    filterPurchasableUserLicencesForAnyStandardCompany,
    filterPurchasableUserLicencesForFreeCompany,
    isCompanyLicenceType,
    isUserLicenceType,
    LicenceTypes,
} from '../../../../__shared/common';
import { useMutation, useQuery } from '@apollo/client';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import Button from '../../../../components/button/button';
import Select from '../../../../components/select/select';
import { useAlert } from '../../../../hooks/useAlert';
import { useObservable } from '../../../../hooks/useObservable';
import useUpdateCompany from '../../../../hooks/useUpdateCompany';
import RxCompany from '../../../../rxjs/RxCompany';
import logUtil from '../../../../utils/logUtil';
import { buildNameFromEmployee } from '../../../../utils/nameUtils';
import { Option } from '../../../../utils/option';
import styles from './subscriptionCreate.module.scss';

export const LicenceTypeTranslations: { [key: string]: string } = {
    Unknown: 'screens.settings.subscriptions.types.unknown',
    [LicenceTypes.User.Starter]: 'screens.settings.subscriptions.types.starter',
    [LicenceTypes.User.Member]: 'screens.settings.subscriptions.types.member',
    [LicenceTypes.User.Consultant]:
        'screens.settings.subscriptions.types.consultant',
    [LicenceTypes.Company.Free]: 'screens.settings.subscriptions.types.free',
    [LicenceTypes.Company.StandardPerMonth]:
        'screens.settings.subscriptions.types.standardPerMonth',
    [LicenceTypes.Company.StandardPerYear]:
        'screens.settings.subscriptions.types.standardPerYear',
};

let NotAssignedLabel = '';
const NotAssignedValue = 'not-assigend';
const getEmployeeOptions = (company?: Company): Option[] => {
    if (!company?.employees?.length) {
        return [];
    }
    const result = company.employees.map(e => {
        return {
            label: buildNameFromEmployee(e),
            value: e.id.toString(),
        };
    });

    return [{ label: NotAssignedLabel, value: NotAssignedValue }, ...result];
};

const getCompanyOptions = (company?: Company): Option[] => {
    if (!company) {
        return [];
    }
    return [{ label: company.name ?? '', value: company.id.toString() }];
};

interface SubscriptionCreateProps {
    onLicenceAdded: () => void;
}

const mapLicenceToOption = (licence: Licence, t: TFunction): Option => ({
    label: t(LicenceTypeTranslations[licence.type]),
    value: licence.type,
});

const getPurchasableLicencesForCompanyLicenceType = (
    licences: Licence[],
    licenceType?: string,
): Licence[] => {
    if (!licenceType) {
        return licences.filter(lic => isCompanyLicenceType(lic.type));
    }

    switch (licenceType) {
        case CompanyLicenceTypes.Free:
            return filterPurchasableUserLicencesForFreeCompany(licences);
        case CompanyLicenceTypes.StandardPerMonth:
            return filterPurchasableUserLicencesForAnyStandardCompany(licences);
        case CompanyLicenceTypes.StandardPerYear:
            return filterPurchasableUserLicencesForAnyStandardCompany(licences);

        default:
            return [];
    }
};

const getPurchasableLicenceOptionsForCompany = (
    licences: Licence[],
    t: TFunction,
    company?: Company,
): Option[] => {
    if (!company) {
        return [];
    }

    const companyLicenceType = company.licencesToCompany?.find(lic =>
        isCompanyLicenceType(lic.licence?.type),
    )?.licence?.type;

    return getPurchasableLicencesForCompanyLicenceType(
        licences,
        companyLicenceType,
    ).map(lic => mapLicenceToOption(lic, t));
};

const SubscriptionCreate: React.FC<SubscriptionCreateProps> = ({
    onLicenceAdded,
}) => {
    const { t } = useTranslation();
    const alert = useAlert();
    const company = useObservable(RxCompany.currentCompany$);
    const licencesRef = useRef<Licence[]>([]);
    const [assignmentOptions, setAssignmentOptions] = useState<Option[]>([]);
    const [assignment, setAssignment] = useState<string>();
    const [licenceOptions, setLicenceOptions] = useState<Option[]>([]);
    const [licenceType, setLicenceType] = useState<Licence | undefined>();
    // const [isAutomaticeRenewal, setIsAutomaticeRenewal] = useState(true);

    const { data } = useQuery<GetLicencesData>(
        LicenceQueries.GetLicencesQueries.GetLicences,
    );

    useEffect(() => {
        NotAssignedLabel = t('screens.settings.subscriptions.notAssigned');
    }, [t]);

    useEffect(() => {
        if (data?.getLicences?.length) {
            logUtil.log(`Got company`, company?.licencesToCompany);
            if (!company) {
                return;
            }
            licencesRef.current = data.getLicences;
            const options = getPurchasableLicenceOptionsForCompany(
                data.getLicences,
                t,
                company,
            );
            if (options.length) {
                setLicenceType(
                    data.getLicences.find(lic => lic.type === options[0].value),
                );
            }
            setLicenceOptions(options);
        }
    }, [company, data, t]);

    useEffect(() => {
        logUtil.log(`licetyp: ${licenceType?.type}`);
    }, [licenceType]);

    useEffect(() => {
        let options: Option[] = [];
        if (!company) {
            return;
        }
        if (isCompanyLicenceType(licenceType?.type ?? '')) {
            options = getCompanyOptions(company);
            if(options?.length){
                setAssignment(options[0].value);
            }
        }
        if (isUserLicenceType(licenceType?.type ?? '')) {
            options = getEmployeeOptions(company);
            if(options?.length){
                setAssignment(options[0].value);
            }
        }
        setAssignmentOptions(options);
    }, [licenceType, company]);

    const [buyLicenceMutation, { loading: buyLoading }] = useMutation<
        BuyLicenceData,
        MutationBuyLicenceArgs
    >(CompanyMutations.BuyLicenceMutations.BuyLicenceMutation);

    const [assignLicenceMutation, { loading: assignLoading }] = useMutation<
        AssignLicenceData,
        MutationAssignLicenceArgs
    >(CompanyMutations.AssignLicenceMutations.AssignLicenceMutation);

    const { reloadCompany } = useUpdateCompany();

    const buyLicence = useCallback(
        async (companyId: number, licenceType: string, employeeId?: number) => {
            try {
                const buyResponse = await buyLicenceMutation({
                    variables: {
                        companyId,
                        licenceType,
                    },
                });
                const newLicence = buyResponse.data?.buyLicence;
                if (!newLicence || buyResponse.errors) {
                    // TODO: more specific alert
                    alert.error('common.alerts.defaultError');
                    return;
                }
                if (employeeId && isUserLicenceType(licenceType)) {
                    const assignLicenceResponse = await assignLicenceMutation({
                        variables: {
                            licenceToCompanyId: newLicence.id,
                            employeeId,
                        },
                    });
                    const updatedLicence =
                        assignLicenceResponse.data?.assignLicence;
                    if (!updatedLicence || assignLicenceResponse.errors) {
                        // TODO: more specific alert
                        alert.error('common.alerts.defaultError');
                        onLicenceAdded();
                        return;
                    }
                } else if (isCompanyLicenceType(licenceType)) {
                    reloadCompany(companyId);
                }
                alert.success('common.alerts.savedChanges');
                onLicenceAdded();
            } catch (e) {
                logUtil.warn('Buying licence failed', e);
                // TODO: more specific alert
                alert.error('common.alerts.defaultError');
            }
        },
        [
            buyLicenceMutation,
            assignLicenceMutation,
            onLicenceAdded,
            alert,
            reloadCompany,
        ],
    );

    const onBuy = () => {
        if (!company?.id || !licenceType?.type) {
            return;
        }
        let userId: number | undefined;
        if (assignment && assignment !== NotAssignedValue) {
            userId = parseInt(assignment, 10);
        }
        buyLicence(company.id, licenceType.type, userId);
    };

    // const renewalDate = new Date();
    // if (licenceType) {
    //     renewalDate.setMonth(
    //         renewalDate.getMonth() + licenceType.durationInMonths,
    //     );
    // }

    return (
        <div className={styles.container}>
            <h3>{t('screens.settings.subscriptions.subscription')}</h3>
            <Select
                options={licenceOptions}
                defaultValue={licenceType?.type}
                onChange={option => {
                    setLicenceType(
                        licencesRef.current.find(
                            lic => lic.type === (option as Option).value,
                        ),
                    );
                }}
            />

            {/* <div className={styles.renewalContainer}>
                <div className={styles.renewalText}>
                    <h3>
                        {t('screens.settings.subscriptions.automaticRenewal')}
                    </h3>
                    <h5>
                        {t('screens.settings.subscriptions.willBeRenewedAt', {
                            renewalDate: DateUtil.toDDMMYYYY(renewalDate),
                        })}
                    </h5>
                </div>
                <Switch
                    id={`new-subscription-switch-${licenceType?.id}`}
                    className={styles.switch}
                    handleToggle={setIsAutomaticeRenewal}
                    isOn={isAutomaticeRenewal}
                />
            </div> */}
            <div className={styles.assignment}>
                <span>{t('screens.settings.subscriptions.assignment')}</span>
                <Select
                    options={assignmentOptions}
                    defaultValue={assignment}
                    onChange={option => {
                        setAssignment(
                            assignmentOptions.find(
                                opt => opt.value === (option as Option).value,
                            )?.value,
                        );
                    }}
                />
            </div>
            <Button
                disabled={buyLoading || assignLoading}
                className={styles.buyButton}
                onClick={onBuy}
                text={t('screens.settings.subscriptions.buyLicence')}
            />
        </div>
    );
};

export default SubscriptionCreate;

import React from 'react';
import NotFound from './NotFound';
import { Navigate, Route, Routes } from 'react-router';
import BusinessLoginScreen from './business/views/login/loginScreen';
import BusinessForgotPasswordScreen from './business/views/forgotPassword/forgotPassword';
import AnimalChatRoutes from '../utils/routing';
import ForgotPassword from './forgotPassword/forgotPassword';
import CreateAccount from './business/views/firstSteps/createAccount';
import Person from './business/views/firstSteps/person';
import Phone from './business/views/firstSteps/phone';
import TermsOfUse from './business/views/firstSteps/termsOfUse';
import VerifyEmail from './business/views/firstSteps/verifyEmail';
import VerifyPhone from './business/views/firstSteps/verifyPhone';
import Welcome from './business/views/firstSteps/welcome';
import CompanyView from './business/views/company/companyView';
import Stripe from './business/views/payment/payment';
import Downloads from './business/views/downloads/downloads';
import MainBusinessLayout from './business/MainBusinessLayout';
import LoginScreen from './login/loginScreen';
import PleaseLoginScreen from './business/views/pleaseLogin/pleaseLoginScreen';
import AppLayout from './AppLayout';
import ChangePassword from './app/userProfileLayout/changePassword/changePassword';
import UserProfileForm from './app/userProfileLayout/userProfileForm/userProfileForm';
import Employees from './business/views/employees/employees';
import ServiceTimes from './business/views/serviceTimes/serviceTimes';
import Subscriptions from './business/views/subscriptions/subscriptions';

const Router = (): JSX.Element => {
    return (
        <Routes>
            <Route
                path="/"
                element={
                    <Navigate to={AnimalChatRoutes.businessSubs.firstSteps} />
                }
            />
            <Route path={AnimalChatRoutes.app} element={<AppLayout />} />
            <Route path={AnimalChatRoutes.login} element={<LoginScreen />} />
            <Route
                path={AnimalChatRoutes.forgotPassword}
                element={<ForgotPassword />}
            />
            x
            <Route
                path={AnimalChatRoutes.business}
                element={<MainBusinessLayout />}>
                <Route
                    path={AnimalChatRoutes.business + '/'}
                    element={
                        <Navigate
                            to={AnimalChatRoutes.businessSubs.firstSteps}
                        />
                    }
                />
                <Route
                    path={AnimalChatRoutes.businessSubs.login}
                    element={<BusinessLoginScreen />}
                />
                <Route
                    path={AnimalChatRoutes.businessSubs.forgotPassword}
                    element={<BusinessForgotPasswordScreen />}
                />
                <Route
                    path={AnimalChatRoutes.businessSubs.firstSteps}
                    element={<Welcome />}
                />
                <Route
                    path={AnimalChatRoutes.businessSubs.company}
                    element={<CompanyView />}
                />
                <Route
                    path={AnimalChatRoutes.businessSubs.profile}
                    element={<UserProfileForm />}
                />
                <Route
                    path={AnimalChatRoutes.businessSubs.changePassword}
                    element={<ChangePassword />}
                />
                <Route
                    path={AnimalChatRoutes.businessSubs.downloads}
                    element={<Downloads />}
                />
                <Route
                    path={AnimalChatRoutes.businessSubs.payment}
                    element={<Stripe />}
                />
                <Route
                    path={AnimalChatRoutes.businessSubs.employees}
                    element={<Employees />}
                />
                <Route
                    path={AnimalChatRoutes.businessSubs.serviceTimes}
                    element={<ServiceTimes />}
                />
                <Route
                    path={AnimalChatRoutes.businessSubs.subscriptions}
                    element={<Subscriptions />}
                />
                <Route
                    path={
                        AnimalChatRoutes.businessSubs.firstStepsSubs.termsOfUse
                    }
                    element={<TermsOfUse />}></Route>
                <Route
                    path={
                        AnimalChatRoutes.businessSubs.firstStepsSubs
                            .createAccount
                    }
                    element={<CreateAccount />}></Route>
                <Route
                    path={AnimalChatRoutes.businessSubs.firstStepsSubs.person}
                    element={<Person />}></Route>
                <Route
                    path={
                        AnimalChatRoutes.businessSubs.firstStepsSubs.verifyEmail
                    }
                    element={<VerifyEmail />}></Route>
                <Route
                    path={AnimalChatRoutes.businessSubs.firstStepsSubs.phone}
                    element={<Phone />}></Route>
                <Route
                    path={
                        AnimalChatRoutes.businessSubs.firstStepsSubs.verifyPhone
                    }
                    element={<VerifyPhone />}></Route>

                <Route
                    path={AnimalChatRoutes.businessSubs.pleaseLogin}
                    element={<PleaseLoginScreen />}
                />
                <Route path="*" element={<NotFound />} />
            </Route>
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
};
export default Router;

import { DateUtil } from '../../../../__shared/common';
import React, { useEffect, useState } from 'react';
import Icon from '../../../../components/icon/icon';
import { useObservable } from '../../../../hooks/useObservable';
import RxCompany from '../../../../rxjs/RxCompany';
import styles from './trafficLightBadge.module.scss';

interface TrafficLightBadgeProps {
    messageDate: Date;
    onCloseInquiry: () => void;
    roundCorners?: boolean;
}

const UpdateTimerInMilliSeconds = 15 * 1000;

const TrafficLightBadge: React.FC<TrafficLightBadgeProps> = ({
    messageDate,
    onCloseInquiry,
    roundCorners = true,
}) => {
    const company = useObservable(RxCompany.currentCompany$);
    const [timeDiffInMinutes, setTimeDiffInMinutes] = useState(() =>
        DateUtil.getTimeDifferenceInMinutes(new Date(), messageDate),
    );

    useEffect(() => {
        setTimeDiffInMinutes(
            DateUtil.getTimeDifferenceInMinutes(new Date(), messageDate),
        );

        const interval = setInterval(() => {
            setTimeDiffInMinutes(
                DateUtil.getTimeDifferenceInMinutes(new Date(), messageDate),
            );
        }, UpdateTimerInMilliSeconds);

        return () => {
            clearInterval(interval);
        };
    }, [messageDate, setTimeDiffInMinutes]);

    let badgClasses = styles.pendingTime;
    if (
        company?.secondTimePeriodToAnswerInMinutes &&
        timeDiffInMinutes > company?.secondTimePeriodToAnswerInMinutes
    ) {
        badgClasses += ` ${styles.yellow}`;
    }
    if (
        company?.thirdTimePeriodToAnswerInMinutes &&
        timeDiffInMinutes > company?.thirdTimePeriodToAnswerInMinutes
    ) {
        badgClasses += ` ${styles.red}`;
    }

    if (roundCorners) {
        badgClasses += ` ${styles.roundCorners}`;
    }

    const handleCloseInquiry = (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    ) => {
        e.stopPropagation();
        e.preventDefault();
        onCloseInquiry();
    };

    return (
        <div className={badgClasses} onClick={handleCloseInquiry}>
            {timeDiffInMinutes < 1 ? '< 1' : timeDiffInMinutes} min
            <div className={styles.icon}>
                <Icon name="close-outline" size={18} />
            </div>
        </div>
    );
};

export default TrafficLightBadge;

import React, { useEffect, useState } from 'react';
import { ChatMessage } from '../../../../__shared/graphql';
import PreviewItemMedia from '../../../../components/previewItemMedia/previewItemMedia';
import styles from './chatMediaList.module.scss';
import { publishShowPreviewMessages } from '../../../../rxjs/onShowPreviewMessages';

interface ChatMediaListProps {
    messages?: ChatMessage[];
    chatId?: number;
}

const ChatMediaList: React.FC<ChatMediaListProps> = props => {
    const [messages, setMessages] = useState<ChatMessage[]>([]);
    const [_, setChatId] = useState(0);

    useEffect(() => {
        if (props) {
            setMessages(props.messages ?? []);
            setChatId(props.chatId ?? 0);
        }
    }, [props]);

    return (
        <div className={styles.container}>
            <ul className={styles.mediaList}>
                {messages.map(message => {
                    return (
                        <li key={message.id}>
                            <PreviewItemMedia
                                className={styles.item}
                                message={message}
                                onClick={() =>
                                    publishShowPreviewMessages(
                                        messages,
                                        message,
                                    )
                                }
                            />
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default ChatMediaList;

import { isElectron } from './electronUtils';

export const NotificationEvent = 'AC_NOTIFICATION';

export interface NotificationPayload {
    title?: string;
    body?: {
        chatId: number;
        message: string;
    };
    icon?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let ipcRenderer: any = undefined;

export const sendNotification = (payload: NotificationPayload): void => {
    if (isElectron() && window) {
        ipcRenderer = window.require('electron').ipcRenderer;
        ipcRenderer.send(NotificationEvent, payload);
    }
    // console.log('sendNotification', NotificationEvent, payload);
};

export const OpenChatEvent = 'AC_OPEN_CHAT';
export interface OpenChatPayload {
    chatId?: number;
}

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Employee } from '../../../../__shared/graphql';
import Icon from '../../../../components/icon/icon';
import styles from './employeesList.module.scss';
import EmployeeListItem from './employeeListItem';

interface EmployeesListProps {
    onCreate: () => void;
    onEdit: (employee: Employee) => void;
    onInviteUserToBecomeEmployee: (employee: Employee) => void;
    onRemoveInvitationForUserToBecomeEmployee: (employee: Employee) => void;
    employees?: Employee[];
    currentEmployeeId?: number;
}

const EmployeesList: React.FC<EmployeesListProps> = ({
    onCreate,
    onEdit,
    onInviteUserToBecomeEmployee,
    onRemoveInvitationForUserToBecomeEmployee,
    employees = [],
    currentEmployeeId = undefined,
}) => {
    const { t } = useTranslation();

    const addEmployeeStyleClass = `${styles.addEmployee} ${
        currentEmployeeId === undefined ? styles.active : ''
    }`.trim();

    return (
        <div className={styles.container}>
            <div className={addEmployeeStyleClass} onClick={onCreate}>
                <span>{t('screens.settings.employees.addEmployee')}</span>
                <Icon name="person-add" size={20} />
            </div>
            {employees.map(e => {
                return (
                    <EmployeeListItem
                        active={e.id == currentEmployeeId}
                        key={e.id}
                        employee={e}
                        onEdit={onEdit}
                        onInviteUserToBecomeEmployee={
                            onInviteUserToBecomeEmployee
                        }
                        onRemoveInvitationForUserToBecomeEmployee={
                            onRemoveInvitationForUserToBecomeEmployee
                        }
                    />
                );
            })}
        </div>
    );
};

export default EmployeesList;

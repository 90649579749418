import React from 'react';
import { useTranslation } from 'react-i18next';
import ProfileIcon from '../../../../components/profileIcon/profileIcon';
import Submenu from '../../../../components/submenu/submenu';
import { useObservable } from '../../../../hooks/useObservable';
import RxCompany from '../../../../rxjs/RxCompany';
import AnimalChatRoutes from '../../../../utils/routing';
import styles from './settingsNavigation.module.scss';

const menuEntries: {
    translationKey: string;
    iconName: string;
    route: string;
}[] = [
    {
        translationKey: 'screens.settings.navigation.entries.company',
        iconName: 'business',
        route: AnimalChatRoutes.settingsSub.company,
    },
    {
        translationKey: 'screens.settings.navigation.entries.employees',
        iconName: 'people-circle-outline',
        route: AnimalChatRoutes.settingsSub.employees,
    },
    {
        translationKey: 'screens.settings.navigation.entries.payments',
        iconName: 'cash-outline',
        route: AnimalChatRoutes.settingsSub.stripe,
    },
    {
        translationKey: 'screens.settings.navigation.entries.serviceTimes',
        iconName: 'time-outline',
        route: AnimalChatRoutes.settingsSub.serviceTimes,
    },
    // {
    //   textKey: 'screens.settings.navigation.entries.widget',
    //   icon: 'laptop',
    //   route: AnimalChatRoutes.settingsSub.widget,
    // },
    {
        translationKey: 'screens.settings.navigation.entries.support',
        iconName: 'help-circle-outline',
        route: AnimalChatRoutes.settingsSub.support,
    },
    {
        translationKey: 'screens.settings.navigation.entries.subscriptions',
        iconName: 'card-outline',
        route: AnimalChatRoutes.settingsSub.subscriptions,
    },
];

interface SettingsNavigationProps {
    activeSubRoute: string;
    onNavigate: (subRoute: string) => void;
}

const SettingsNavigation: React.FC<SettingsNavigationProps> = ({
    activeSubRoute,
    onNavigate,
}) => {
    const { t } = useTranslation();
    const company = useObservable(RxCompany.currentCompany$);

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <ProfileIcon
                    size={48}
                    fileDescription={company?.imageFile}
                    colorBundle={company?.colorBundle}
                    profileInProfileFileDescription={
                        company?.profileInProfileImageFile
                    }
                    profileInProfilePosition="right"
                    profileInProfileSize={18}
                />
                <div className={styles.practice}>
                    <p>{company?.name}</p>
                    <span>{t('screens.settings.navigation.subtitle')}</span>
                </div>
            </div>
            <Submenu
                className={styles.menu}
                entries={menuEntries}
                onNavigate={onNavigate}
                activeSubRoute={activeSubRoute}
            />
        </div>
    );
};

export default SettingsNavigation;

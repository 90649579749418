import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import React, { useCallback, useEffect, useState } from 'react';
import {
    AcceptInvitationToBecomeEmployeeData,
    RejectInvitationToBecomeEmployeeData,
    UserMutations,
} from '../../../../graphql/mutations/userMutations';
import {
    GetUserData,
    UserQueries,
} from '../../../../graphql/queries/userQueries';
import { useAlert } from '../../../../hooks/useAlert';
import { useErrorAlert } from '../../../../hooks/useErrorAlert';
import { useObservable } from '../../../../hooks/useObservable';
import useUpdateCompany from '../../../../hooks/useUpdateCompany';
import RxAuthentication from '../../../../rxjs/RxAuthentication';
import RxUserProfile from '../../../../rxjs/RxUserProfile';
import logUtil from '../../../../utils/logUtil';
import {
    GetCompanyData,
    GetCompanyQuery,
    Invitation,
    MutationAcceptInvitationToBecomeEmployeeArgs,
    MutationRejectInvitationToBecomeEmployeeArgs,
    QueryGetCompanyArgs,
    QueryGetUserArgs,
} from '../../../../__shared/graphql';
import BusinessCompany from './company';

const BusinessCompanyLayout: React.FC = () => {
    const userId = useObservable(RxAuthentication.userId$, 0);
    const user = useObservable(RxUserProfile.userProfile$);
    const [isLoading, setIsLoading] = useState(false);
    const { alertError, alertGraphQlError } = useErrorAlert();
    const alert = useAlert();
    const { setCurrentCompany } = useUpdateCompany();

    const { data: userData } = useQuery<GetUserData, QueryGetUserArgs>(
        UserQueries.GetUser.Full,
        {
            variables: {
                userId,
            },
            skip: !userId || !!user,
        },
    );

    const [getCompany] = useLazyQuery<GetCompanyData, QueryGetCompanyArgs>(
        GetCompanyQuery.FullResponse,
    );

    const [acceptInvitationToBecomeEmployeeMutation] = useMutation<
        AcceptInvitationToBecomeEmployeeData,
        MutationAcceptInvitationToBecomeEmployeeArgs
    >(UserMutations.AcceptInvitationToBecomeEmployee);

    const [rejectInvitationToBecomeEmployeeMutation] = useMutation<
        RejectInvitationToBecomeEmployeeData,
        MutationRejectInvitationToBecomeEmployeeArgs
    >(UserMutations.RejectInvitationToBecomeEmployee);

    useEffect(() => {
        if (userData?.getUser) {
            RxUserProfile.setUser(userData.getUser);
        }
    }, [userData]);

    const handleAcceptInvitationToBecomeEmployee = useCallback(
        async (invitation: Invitation) => {
            const { userId: _userId, employeeId } = invitation;
            if (!_userId || !employeeId) {
                return;
            }

            setIsLoading(true);
            try {
                const { data, errors } =
                    await acceptInvitationToBecomeEmployeeMutation({
                        variables: {
                            userId: _userId,
                            employeeId,
                        },
                    });
                if (errors?.length) {
                    alertGraphQlError(errors);
                    return;
                }
                if (data?.acceptInvitationToBecomeEmployee) {
                    alert.success('common.alerts.savedChanges');
                    RxUserProfile.setUser(
                        data.acceptInvitationToBecomeEmployee,
                    );

                    const companyId = invitation?.employee?.company?.id;
                    if (companyId) {
                        const { data } = await getCompany({
                            variables: {
                                companyId,
                            },
                        });
                        if (data?.getCompany) {
                            setCurrentCompany(data?.getCompany);
                        }
                    }
                }
            } catch (e) {
                logUtil.error(e);
                alertError(e);
            } finally {
                setIsLoading(false);
            }
        },
        [
            acceptInvitationToBecomeEmployeeMutation,
            alert,
            alertError,
            alertGraphQlError,
            getCompany,
            setCurrentCompany,
        ],
    );

    const handleRejectInvitationToBecomeEmployee = useCallback(
        async (invitation: Invitation) => {
            const { userId: _userId, employeeId } = invitation;
            if (!_userId || !employeeId) {
                return;
            }

            setIsLoading(true);
            try {
                const { data, errors } =
                    await rejectInvitationToBecomeEmployeeMutation({
                        variables: {
                            userId: _userId,
                            employeeId,
                        },
                    });
                if (errors?.length) {
                    alertGraphQlError(errors);
                    return;
                }
                if (data?.rejectInvitationToBecomeEmployee) {
                    alert.success('common.alerts.savedChanges');
                    RxUserProfile.setUser(
                        data.rejectInvitationToBecomeEmployee,
                    );
                }
            } catch (e) {
                logUtil.error(e);
                alertError(e);
            } finally {
                setIsLoading(false);
            }
        },
        [
            rejectInvitationToBecomeEmployeeMutation,
            alert,
            alertError,
            alertGraphQlError,
        ],
    );

    return (
        <BusinessCompany
            invitationsProps={{
                user: user,
                onAcceptInvitationToBecomeEmployee:
                    handleAcceptInvitationToBecomeEmployee,
                onRejectInvitationToBecomeEmployee:
                    handleRejectInvitationToBecomeEmployee,
                isLoading: isLoading,
            }}
        />
    );
};

export default BusinessCompanyLayout;

import React, { useCallback, useEffect, useState } from 'react';
import { useIsMounted } from '../../hooks/useIsMounted';
import DefaultHTMLImageProps from '../../types/defaultHTMLImageProps';
// import styles from './localFileImage.module.scss'

interface LocalFileImageProps {
    file: File;
    nativeProps?: DefaultHTMLImageProps;
    onLoadingChanged?: (loadingState: boolean) => void;
    onError?: (error: unknown) => void;
}

const LocalFileImage: React.FC<LocalFileImageProps> = ({
    file,
    nativeProps,
    onLoadingChanged,
    onError,
}) => {
    const isMounted = useIsMounted();
    const [imageData, setImageData] = useState<string | undefined>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<unknown | undefined>(undefined);

    const loadFromLocalFile = useCallback(
        (file: File) => {
            setLoading(true);
            setError(undefined);
            const fr = new FileReader();
            fr.onload = () => {
                if (isMounted()) {
                    setLoading(false);
                    setImageData(fr.result as string);
                }
            };
            fr.readAsDataURL(file);
            fr.onerror = error => {
                console.warn(
                    'Error while loading image from file',
                    file,
                    error,
                );
                if (isMounted()) {
                    setError(error);
                    setLoading(false);
                }
            };
        },
        [isMounted],
    );

    useEffect(() => {
        if (typeof onLoadingChanged === 'function') {
            onLoadingChanged(loading);
        }
    }, [onLoadingChanged, loading]);

    useEffect(() => {
        if (typeof onError === 'function') {
            onError(error);
        }
    }, [onError, error]);

    useEffect(() => {
        // electron files from fileDialog do have paths
        // whereas browser files from fileDialog do have uris
        // so we have to test the file here
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const path = (file as any).path;
        if (!path) {
            loadFromLocalFile(file);
        } else {
            setImageData(`acupload://${path}`);
        }
    }, [loadFromLocalFile, file]);

    return <img {...nativeProps} src={imageData} />;
};

export default LocalFileImage;

import React, { useCallback, useMemo } from 'react';
import { ColorBundle, FileDescription } from '../../__shared/graphql';
import ApiImage from '../apiImage/apiImage';
import styles from './profileIcon.module.scss';
import { getProfilePicture, getThumbnail } from '../../__shared/common';

export interface ProfileIconProps {
    fileDescription?: FileDescription | null;
    colorBundle?: ColorBundle | null;
    className?: string;
    size?: number;
    style?: { [key: string]: unknown };
    profileInProfileFileDescription?: FileDescription | null;
    profileInProfileSize?: number;
    profileInProfilePosition?: 'left' | 'right';
    onClick?: () => void;
}

const defaultSize = 48;

const ProfileIcon: React.FC<ProfileIconProps> = ({
    fileDescription,
    colorBundle,
    className,
    size,
    profileInProfileFileDescription,
    profileInProfileSize,
    profileInProfilePosition,
    style,
    onClick,
}) => {
    let containerClass = styles.container;
    if (className) {
        containerClass += ` ${className}`;
    }
    if (typeof onClick === 'function') {
        containerClass += ` ${styles.clickable}`;
    }

    let profileInProfileClasses = styles.profileInProfile;
    if (profileInProfilePosition === 'left') {
        profileInProfileClasses += ` ${styles.left}`;
    }

    const placeholderBackgroundStyle = useMemo(() => {
        return {
            background: `linear-gradient(${
                colorBundle?.hexColor ?? '#98c776'
            }, ${colorBundle?.hexColorGradient ?? '#77b54a'})`,
            borderRadius: '50%',
        };
    }, [colorBundle]);

    const profileStyle = useMemo(
        () =>
            size
                ? {
                      height: `${size}px`,
                      width: `${size}px`,
                      minHeight: `${size}px`,
                      minWidth: `${size ?? defaultSize}px`,
                      ...placeholderBackgroundStyle,
                      ...style,
                  }
                : { ...placeholderBackgroundStyle, ...style },
        [placeholderBackgroundStyle, size, style],
    );

    const profileInProfileStyle = useMemo(
        () =>
            profileInProfileSize
                ? {
                      height: `${profileInProfileSize}px`,
                      width: `${profileInProfileSize}px`,
                  }
                : {},
        [profileInProfileSize],
    );

    const handleClick = useCallback(() => {
        if (onClick) {
            onClick();
        }
    }, [onClick]);

    const memorizedProfileIcon = useMemo(() => {
        // logUtil.log('memorizedChatEntry changed');
        return (
            <div
                className={containerClass}
                style={profileStyle}
                onClick={handleClick}>
                {fileDescription ? (
                    <ApiImage
                        nativeProps={{
                            className: styles.profile,
                            style: { minWidth: `${size ?? defaultSize}px` },
                        }}
                        fileDescription={fileDescription}
                        fileDescriptionWithOptimalResolution={getProfilePicture(
                            fileDescription,
                        )}
                    />
                ) : (
                    // placeholder background
                    <div className={styles.profile}></div>
                )}

                {profileInProfileFileDescription && (
                    <ApiImage
                        fileDescription={profileInProfileFileDescription}
                        fileDescriptionWithOptimalResolution={getThumbnail(
                            profileInProfileFileDescription,
                        )}
                        nativeProps={{
                            style: profileInProfileStyle,
                            className: profileInProfileClasses,
                        }}
                    />
                )}
            </div>
        );
    }, [
        handleClick,
        profileInProfileClasses,
        profileInProfileFileDescription,
        profileInProfileStyle,
        profileStyle,
        size,
        containerClass,
        fileDescription,
    ]);

    return memorizedProfileIcon;
};

export default ProfileIcon;

import { ApiVersionErrorObject } from '../errors';

export const isApiVersionErrorObject = (
    error: any,
): error is ApiVersionErrorObject => {
    if (!error) {
        return false;
    }
    if (
        typeof error.message !== 'string' ||
        typeof error.errorCode !== 'string'
    ) {
        return false;
    }
    const supportedVersion = error.supportedApiVersion;
    if (!supportedVersion) {
        return false;
    }
    let isCorrectObject = true;
    Object.values(supportedVersion).forEach(value => {
        if (typeof value !== 'string' && value !== undefined) {
            isCorrectObject = false;
        }
    });
    return isCorrectObject;
};

import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import NavBackButton from '../../../../components/navBackButton/navBackButton';
import { useAlert } from '../../../../hooks/useAlert';
import { useObservable } from '../../../../hooks/useObservable';
import { useScreenHasLessWidthThan } from '../../../../hooks/useScreenSize';
import RxCompany from '../../../../rxjs/RxCompany';
import RxElectron from '../../../../rxjs/RxElectron';
import { buildNameFromEmployee } from '../../../../utils/nameUtils';
import { isElectron } from '../../../../__shared/electron';
import {
    Company,
    Employee,
    GetCompanyData,
    GetCompanyQuery,
    InviteUserToBecomeEmployeeData,
    InviteUserToBecomeEmployeeMutation,
    MutationInviteUserToBecomeEmployeeArgs,
    MutationRemoveInvitationForUserToBecomeEmployeeArgs,
    QueryGetCompanyArgs,
    RemoveInvitationForUserToBecomeEmployeeData,
    RemoveInvitationForUserToBecomeEmployeeMutation,
} from '../../../../__shared/graphql';
import SplitScreen from '../main/splitScreen';
import EmployeeDetail from './employeeDetail';
import styles from './employees.module.scss';
import EmployeesList from './employeesList';

const Employees: React.FC = () => {
    const updatedIdRef = useRef(0);
    const isWindows = useObservable(RxElectron.isWindows$, true);
    const [employee, setEmployee] = useState<Employee>();
    const [employees, setEmployees] = useState<Employee[]>([]);
    const company = useObservable(RxCompany.currentCompany$);
    const companies = useObservable(RxCompany.companies$, []);
    const [createMode, setCreateMode] = useState(false);
    const [getCompany] = useLazyQuery<GetCompanyData, QueryGetCompanyArgs>(
        GetCompanyQuery.FullResponse,
    );
    const alert = useAlert();

    const screenHasLessWidthThanXxs = useScreenHasLessWidthThan('xxs');
    const screenHasLessWidthThanSm = useScreenHasLessWidthThan('sm');

    useEffect(() => {
        const newEmployees = company?.employees;
        if (newEmployees?.length) {
            setEmployees(newEmployees);
        }
        if (updatedIdRef.current) {
            setEmployee(newEmployees?.find(e => e.id === updatedIdRef.current));
        }
    }, [company]);

    const updateCompanyInStore = (comp: Company) => {
        RxCompany.setCurrentCompany(comp);
        const updatedCompanies = [...companies];
        const updateIndex = updatedCompanies.findIndex(c => c.id === comp.id);
        updatedCompanies[updateIndex] = comp;
        RxCompany.setCompanies(updatedCompanies);
    };

    const [inviteUserToBecomeEmployee] = useMutation<
        InviteUserToBecomeEmployeeData,
        MutationInviteUserToBecomeEmployeeArgs
    >(InviteUserToBecomeEmployeeMutation.FullResponse);

    const [removeInvitationForUserToBecomeEmployee] = useMutation<
        RemoveInvitationForUserToBecomeEmployeeData,
        MutationRemoveInvitationForUserToBecomeEmployeeArgs
    >(RemoveInvitationForUserToBecomeEmployeeMutation.FullResponse);

    const onInviteUserToBecomeEmployee = useCallback(
        async (employee: Employee) => {
            const success = await inviteUserToBecomeEmployee({
                variables: {
                    employeeId: employee.id,
                },
            });
            if (success) {
                alert.success('common.alerts.savedChanges');
            } else {
                alert.error('common.alerts.defaultError');
            }
        },
        [inviteUserToBecomeEmployee, alert],
    );

    const onRemoveInvitationForUserToBecomeEmployee = useCallback(
        async (employee: Employee) => {
            const success = await removeInvitationForUserToBecomeEmployee({
                variables: {
                    employeeId: employee.id,
                },
            });
            if (success) {
                alert.success('common.alerts.savedChanges');
            } else {
                alert.error('common.alerts.defaultError');
            }
        },
        [removeInvitationForUserToBecomeEmployee, alert],
    );

    const onSaved = (newEmployee: Employee) => {
        if (screenHasLessWidthThanSm) {
            setShow(false);
        }
        reload(newEmployee);
    };

    const reload = async (newEmployee: Employee) => {
        const getCompanyResult = await getCompany({
            variables: { companyId: company?.id ?? 0 },
        });
        if (getCompanyResult?.data?.getCompany) {
            updateCompanyInStore(getCompanyResult?.data?.getCompany);
            updatedIdRef.current = newEmployee.id;
            setCreateMode(false);
        }
    };

    const onDeleted = async () => {
        const getCompanyResult = await getCompany({
            variables: { companyId: company?.id ?? 0 },
        });
        if (getCompanyResult?.data?.getCompany) {
            updateCompanyInStore(getCompanyResult?.data?.getCompany);
            setCreateMode(false);
            setShow(false);
        }
    };

    const [show, setShow] = useState(false);

    const mainStyle = screenHasLessWidthThanSm
        ? show
            ? { left: '0px', width: '100%' }
            : { left: '100vw' }
        : {};

    const isWidePadding =
        screenHasLessWidthThanXxs && isElectron() && !isWindows;

    const editFieldWrapper = (
        <div className={styles.splitScreenRightContainer} style={mainStyle}>
            <div className={styles.header}>
                {screenHasLessWidthThanSm && (
                    <>
                        <NavBackButton
                            position={{
                                top: 14,
                                left: isWidePadding ? 74 : 14,
                            }}
                            onClick={() => setShow(false)}
                        />
                        <div
                            style={
                                isWidePadding ? { paddingLeft: '60px' } : {}
                            }>
                            {employee
                                ? buildNameFromEmployee(employee)
                                : 'Mitarbeiter hinzufügen'}
                        </div>
                    </>
                )}
            </div>
            <div className={styles.content}>
                {(createMode || employee) && (
                    <EmployeeDetail
                        onSaved={onSaved}
                        onDeleted={onDeleted}
                        onCanceled={() => {
                            setEmployee(undefined);
                            setShow(false);
                            setCreateMode(false);
                            updatedIdRef.current = 0;
                        }}
                        companyId={company?.id ?? 0}
                        employee={employee}
                        createMode={createMode}
                        numberOfEmployees={company?.employees?.length ?? 0}
                    />
                )}
            </div>
        </div>
    );

    return (
        <SplitScreen
            left={
                <EmployeesList
                    employees={employees}
                    currentEmployeeId={employee?.id}
                    onCreate={() => {
                        setEmployee(undefined);
                        setCreateMode(true);
                        setShow(true);
                        updatedIdRef.current = 0;
                    }}
                    onEdit={editEmployee => {
                        setEmployee(editEmployee);
                        setCreateMode(false);
                        setShow(true);
                        updatedIdRef.current = editEmployee.id;
                    }}
                    onInviteUserToBecomeEmployee={onInviteUserToBecomeEmployee}
                    onRemoveInvitationForUserToBecomeEmployee={
                        onRemoveInvitationForUserToBecomeEmployee
                    }
                />
            }
            right={editFieldWrapper}
        />
    );
};

export default Employees;

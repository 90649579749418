import { Order } from '../dto';
import { gql } from '@apollo/client';
import { OrderFragments } from '../fragments';

const FullResponse = gql`
    mutation CloseOrder($orderId: Int!) {
        closeOrder(orderId: $orderId) {
            ...FullOrder
        }
    }
    ${OrderFragments.FullOrder}
`;

export interface CloseOrderData {
    closeOrder: Order;
}

export const CloseOrderMutation = { FullResponse };

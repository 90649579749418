import {
    Company,
    GetCompanyData,
    GetCompanyQuery,
    MutationUpdateCompanyArgs,
    QueryGetCompanyArgs,
    UpdateCompanyData,
    UpdateCompanyMutation,
} from '../__shared/graphql';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useCallback, useEffect } from 'react';
import RxAuthentication from '../rxjs/RxAuthentication';
import RxCompany from '../rxjs/RxCompany';
import { updateCache } from '../utils/imageFileUtil';
import { useAlert } from './useAlert';
import { useObservable } from './useObservable';

interface UseUpdateCompanyHook {
    updateCompany: (companyUpdate: MutationUpdateCompanyArgs) => Promise<void>;
    reloadCompany: (companyId: number) => Promise<void>;
    setCurrentCompany: (company: Company) => Promise<void>;
}

const useUpdateCompany = (): UseUpdateCompanyHook => {
    const alert = useAlert();
    const companies = useObservable(RxCompany.companies$, []);
    const accessToken = useObservable(RxAuthentication.accessToken$);

    const [getCompany] = useLazyQuery<GetCompanyData, QueryGetCompanyArgs>(
        GetCompanyQuery.FullResponse,
    );
    const [
        updateCompanyMutation,
        { loading: updateCompanyLoading, data: updateCompanyData },
    ] = useMutation<UpdateCompanyData, MutationUpdateCompanyArgs>(
        UpdateCompanyMutation.FullResponse,
    );

    const updateCompanyInStore = useCallback(
        (company: Company) => {
            RxCompany.setCurrentCompany(company);
            const updatedCompanies = [...companies];
            const updateIndex = updatedCompanies.findIndex(
                c => c.id === company.id,
            );
            updatedCompanies[updateIndex] = company;
            RxCompany.setCompanies(updatedCompanies);
        },
        [companies],
    );

    useEffect(() => {
        if (!updateCompanyLoading) {
            const imageFile = updateCompanyData?.updateCompany.imageFile;
            if (imageFile) {
                updateCache(imageFile);
            }
        }
    }, [updateCompanyLoading, updateCompanyData, accessToken]);

    const setCurrentCompany = async (company: Company) => {
        updateCompanyInStore(company);
    };

    const reloadCompany = async (companyId: number) => {
        const getCompanyResult = await getCompany({
            variables: { companyId },
        });
        if (getCompanyResult?.data?.getCompany) {
            updateCompanyInStore(getCompanyResult?.data?.getCompany);
        }
    };

    const updateCompany = useCallback(
        async (companyUpdate: MutationUpdateCompanyArgs) => {
            const result = await updateCompanyMutation({
                variables: companyUpdate,
            });
            if (result.data?.updateCompany?.id) {
                alert.success('common.alerts.savedChanges');
                updateCompanyInStore(result.data.updateCompany);
            }
        },
        [updateCompanyMutation, updateCompanyInStore, alert],
    );

    return {
        updateCompany,
        reloadCompany,
        setCurrentCompany,
    };
};

export default useUpdateCompany;

import React from 'react';
import Input from '../input/input';
import Select from '../select/select';
import styles from './formField.module.scss';
import { Option } from '../../utils/option';
import PhoneNumberInput from '../phoneNumberInput/phoneNumberInput';
import Icon from '../icon/icon';

type FormFieldType =
    | 'text'
    | 'password'
    | 'select'
    | 'multiselect'
    | 'phoneNumber';

interface FormFieldProps {
    id: string;
    name: string;
    value?: string | number;
    updateValue: (name: string, value: string | number) => void;
    required?: boolean;
    type?: FormFieldType;
    disabled?: boolean;
    label?: string;
    options?: Option[];
    limit?: number;
    confirmable?: boolean;
    confirmed?: boolean;
    iconSize?: number;
}

const FormField: React.FC<FormFieldProps> = ({
    id,
    value = '',
    name,
    updateValue,
    label,
    required = false,
    type = 'text',
    disabled = false,
    options = [],
    limit,
    confirmable = false,
    confirmed,
    iconSize = 22,
}) => {
    const labelComponent = label ? (
        <div className={styles.label}>
            <span>{label}</span>
            {required && <span> {required && '*'}</span>}

            {confirmable && confirmed !== undefined && (
                <span
                    className={
                        confirmed ? styles.checkmark : styles.exclamationMark
                    }>
                    <Icon
                        name={confirmed ? 'checkmark-outline' : 'alert-outline'}
                        size={iconSize}
                    />
                </span>
            )}
        </div>
    ) : null;

    if (type === 'select') {
        return (
            <>
                {labelComponent}
                <Select
                    name={name}
                    options={options}
                    defaultValue={value}
                    onChange={option => {
                        if (option) {
                            // since this is no multi select we can treat this as a single option
                            updateValue(name, (option as Option).value);
                        }
                    }}
                />
            </>
        );
    }

    if (type === 'phoneNumber') {
        return (
            <>
                {labelComponent}
                <PhoneNumberInput
                    value={value as string}
                    onChange={(value: string) =>
                        updateValue(name, value as string)
                    }
                    id={id}
                    inputProps={{
                        className: styles.input,
                        limit: limit,
                    }}
                />
            </>
        );
    }

    return (
        <>
            {labelComponent}
            <Input
                className={styles.input}
                id={id}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                type={type as any}
                disabled={disabled}
                value={value}
                limit={limit}
                onChange={event => updateValue(name, event.target.value)}
            />
        </>
    );
};

export default FormField;

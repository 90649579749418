import React from 'react';
import { ChatMessage } from '../../__shared/graphql';
import { ClassNameProp } from '../../types/stylingProps';
import { isVideoType } from '../../utils/fileUtils';
import PreviewItemImage from '../previewItemImage/previewItemImage';
import PreviewItemVideo from '../previewItemVideo/previewItemVideo';

export interface PreviewItemMediaProps extends ClassNameProp {
    message: ChatMessage;
    onClick?: (message: ChatMessage) => void;
}

const CustomerProfileMediaPreviewItem: React.FC<
    PreviewItemMediaProps
> = props => {
    const file = props.message?.files?.length
        ? props.message?.files[0]
        : undefined;

    const isVideo = isVideoType(file?.fileType);

    return isVideo ? (
        <PreviewItemVideo {...props} />
    ) : (
        <PreviewItemImage {...props} />
    );
};

export default CustomerProfileMediaPreviewItem;

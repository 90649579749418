import React, { MutableRefObject, useRef } from 'react';
import PreviewItemMedia from '../../../../../../components/previewItemMedia/previewItemMedia';
import {
    isApplicationType,
    isImageType,
    isVideoType,
} from '../../../../../../__shared/common';
import { ChatMessage as ChatMessageType } from '../../../../../../__shared/graphql';
import ChatMessageApplicationFilePreview from '../chatMessageApplicationFilePreview/chatMessageApplicationFilePreview';
import ChatMessageText from '../chatMessageText/chatMessageText';
import ChatMessageTitle from '../chatMessageTitle/chatMessageTitle';
import styles from './chatMessageReference.module.scss';

export interface ChatMessageReferenceProps {
    message: ChatMessageType;
    userId: number;
    id: string;
}

const ChatMessageReference: React.FC<ChatMessageReferenceProps> = ({
    message,
    userId,
    id,
}) => {
    const ref = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;

    let messageClass = styles.message;
    const isOwnMessage = message.author?.id === userId;
    if (isOwnMessage) {
        messageClass += ` ${styles.ownMessage}`;
    }

    const fileDescription = message?.files?.length ? message.files[0] : null;
    const hasFile = !!fileDescription;
    const hasImage = hasFile && isImageType(fileDescription?.fileType);
    const hasVideo = hasFile && isVideoType(fileDescription?.fileType);
    const hasDocument = hasFile && isApplicationType(fileDescription?.fileType);

    let messageTextContainerStyle = styles.messageTextContainerWithMedia;

    if (!hasImage && !hasVideo) {
        messageTextContainerStyle = styles.messageTextContainer;
    }

    return (
        <div id={id} className={messageClass} ref={ref}>
            <div className={styles.messageContentContainer}>
                <div className={messageTextContainerStyle}>
                    <ChatMessageTitle message={message} />
                    <ChatMessageText
                        message={message}
                        showOrderDetails={false}
                    />
                    {fileDescription && hasDocument && (
                        <ChatMessageApplicationFilePreview
                            fileDescription={fileDescription}
                        />
                    )}
                </div>

                {fileDescription && (hasImage || hasVideo) && (
                    <PreviewItemMedia
                        className={styles.mediaMessage}
                        key={message.id}
                        message={message}
                    />
                )}
            </div>
        </div>
    );
};

export default ChatMessageReference;

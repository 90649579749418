import { gql } from '@apollo/client';
import { Contact } from '../dto';
import { ContactFragments } from '../fragments';

const FullResponse = gql`
    query GetContacts {
        getContacts {
            ...FullContact
        }
    }
    ${ContactFragments.FullContact}
`;

export interface GetContactsData {
    getContacts: Contact[];
}

export const GetContactsQuery = {
    FullResponse,
};

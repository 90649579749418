import {
    GenerateRandomPasswordData,
    GenerateRandomPasswordQuery,
    QueryGenerateRandomPasswordArgs,
} from '../../../../__shared/graphql';
import { ApolloError, useLazyQuery } from '@apollo/client';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FadeLoader } from 'react-spinners';
import Button from '../../../../components/button/button';
import Input from '../../../../components/input/input';
import { useAlert } from '../../../../hooks/useAlert';
import AnimalChatRoutes from '../../../../utils/routing';
import styles from './forgotPassword.module.scss';

const BusinessForgotPassword: React.FC = () => {
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const alert = useAlert();

    const handleError = (error: ApolloError) => {
        if (!error) {
            return;
        }
        if (error.message?.indexOf('Cannot find user') !== -1) {
            alert.error('screens.forgotPassword.noUserWithMail');
            return;
        }
        alert.error('errors.default');
    };

    const [
        generateRandomPassword,
        { data: generateData, loading: generateLoading },
    ] = useLazyQuery<
        GenerateRandomPasswordData,
        QueryGenerateRandomPasswordArgs
    >(GenerateRandomPasswordQuery.FullResponse, {
        onError: handleError,
    });

    const onGeneratePassword = async () => {
        try {
            await generateRandomPassword({
                variables: {
                    email,
                },
            });
        } catch (error) {
            console.warn(error);
        }
    };

    if (generateData?.generateRandomPassword) {
        return (
            <div className={styles.container}>
                <form className={styles.form}>
                    <p>{t('screens.forgotPassword.resetSuccess')}</p>
                    <Link to={AnimalChatRoutes.businessSubs.login}>
                        {t('screens.forgotPassword.backToLogin')}
                    </Link>
                </form>
            </div>
        );
    }

    return (
        <div className={styles.container}>
            <form className={styles.form}>
                {generateLoading && (
                    <div
                        className={`${styles.formOverlay} ${styles.loadingSpinner}`}>
                        <FadeLoader />
                    </div>
                )}
                <div className={styles.input}>
                    <label htmlFor="username">{t('common.email')}</label>
                    <Input
                        type="text"
                        id="username"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                    />
                </div>
                <div className={styles.buttons}>
                    <Button
                        disabled={generateLoading || !email?.length}
                        text={t('screens.forgotPassword.resetPassword')}
                        onClick={e => {
                            e.preventDefault();
                            onGeneratePassword();
                        }}
                    />
                </div>
                <div className={styles.passwordActions}>
                    <Link to={AnimalChatRoutes.businessSubs.login}>
                        {t('screens.forgotPassword.toLogin')}
                    </Link>
                </div>
            </form>
        </div>
    );
};

export default BusinessForgotPassword;

import { LogLevel, LogLineCreate, LogSource } from './loggingTypes';

let _fetchImpl: (url: RequestInfo, init?: RequestInit) => Promise<Response>;

// http://localhost:4000/
let logApiUrl: string | undefined;
let instanceId: string;
let client: LogSource;
let logLevel: LogLevel = 'info';
let isTestLoggingEnabled = false;
let isConsoleLoggingEnabled = false;
let isHttpDisabled = false;
const headers = {
    'Content-Type': 'application/json',
};

interface LogClientPreparationParams {
    baseUrl?: string;
    instanceId: string;
    client: LogSource;
    logLevel?: LogLevel;
    isTestLoggingEnabled?: boolean;
    isConsoleLoggingEnabled?: boolean;
    isHttpDisabled?: boolean;
    fetchImpl?: (url: RequestInfo, init?: RequestInit) => Promise<Response>;
}

const prepare = (params: LogClientPreparationParams): void => {
    setBaseUrl(params.baseUrl);
    instanceId = params.instanceId;
    client = params.client;
    logLevel = params.logLevel ?? 'info';
    isTestLoggingEnabled = !!params.isTestLoggingEnabled;
    isConsoleLoggingEnabled = !!params.isConsoleLoggingEnabled;
    isHttpDisabled = !!params.isHttpDisabled;
    _fetchImpl = params.fetchImpl ?? fetch; // electron has it's own fetch implementation
};

const setBaseUrl = (baseUrl?: string): void => {
    if (!baseUrl?.length) {
        return;
    }
    logApiUrl = `${baseUrl}/logs`;
};

const setLogLevel = (level: LogLevel): void => {
    logLevel = level;
};

const LogPrefixes = {
    debug: '[DEBUG]',
    info: '[INFO] ',
    test: '[TEST] ',
    warn: '[WARN] ',
    error: '[ERROR]',
    trace: '[TRACE]',
};

const isSufficientLogLevel = (level: LogLevel): boolean => {
    switch (level) {
        case 'debug':
            return logLevel === 'debug';
        case 'info':
            return logLevel === 'info' || logLevel === 'debug';
        case 'warn':
            return (
                logLevel === 'info' ||
                logLevel === 'debug' ||
                logLevel === 'warn'
            );
        case 'error':
            return (
                logLevel === 'info' ||
                logLevel === 'debug' ||
                logLevel === 'warn' ||
                logLevel === 'trace' ||
                logLevel === 'error'
            );
        case 'trace':
            return logLevel === 'trace';
        case 'test':
            return true;

        default:
            return false;
    }
};

const log = (message: string, level: LogLevel, data?: unknown) => {
    if (!isSufficientLogLevel(level)) {
        return;
    }

    if (isConsoleLoggingEnabled && level !== 'off') {
        const _message = `${LogPrefixes[level]}\t${message}`;
        if (data) {
            console.log(_message, data);
        } else {
            console.log(_message);
        }
    }

    if (isHttpDisabled) {
        return;
    }

    if (!logApiUrl) {
        console.log(
            `${LogPrefixes.error}\tIncomplete LogClient configuration. Missing api url: ${logApiUrl}`,
        );
        return;
    }

    const payload: LogLineCreate = {
        client,
        instanceId,
        message,
        level,
        data,
    };

    try {
        _fetchImpl(logApiUrl, {
            method: 'POST',
            headers,
            body: JSON.stringify(payload),
        });
    } catch (e) {
        console.warn(
            `${LogPrefixes.warn}\tError while sending log to server`,
            e,
        );
    }
};

const test = (message: string, data?: unknown): void => {
    if (!isTestLoggingEnabled) {
        return;
    }
    log(message, 'test', data);
};

const debug = (message: string, data?: unknown): void => {
    log(message, 'debug', data);
};

const info = (message: string, data?: unknown): void => {
    log(message, 'info', data);
};

const warn = (message: string, data?: unknown): void => {
    log(message, 'warn', data);
};

const trace = (message: string, data?: unknown): void => {
    log(message, 'trace', data);
};

const error = (message: string, data?: unknown): void => {
    log(message, 'error', data);
};

export const parseLogLevel = (level?: string | null): LogLevel | undefined => {
    if (!level) {
        return undefined;
    }
    switch (level) {
        case 'debug':
            return 'debug';
        case 'info':
            return 'info';
        case 'warn':
            return 'warn';
        case 'error':
            return 'error';
        case 'trace':
            return 'trace';
        case 'test':
            return 'test';
        case 'off':
            return 'off';

        default:
            return undefined;
    }
};
export const LogClient = {
    prepare,
    debug,
    info,
    warn,
    trace,
    error,
    test,
    setLogLevel,
};

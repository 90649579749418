import React from 'react';
import { ClassNameProp } from '../../types/stylingProps';
import styles from './switch.module.scss';

interface SwitchProps {
    handleToggle: (newState: boolean) => void;
    isOn: boolean;
    id: string;
    disabled?: boolean;
}
const Switch: React.FC<SwitchProps & ClassNameProp> = ({
    isOn,
    handleToggle,
    id,
    disabled,
    className,
}) => {
    const onToggle = (newState: boolean) => {
        if (!disabled) {
            handleToggle(newState);
        }
    };

    return (
        <label
            className={`${styles.label} ${isOn ? styles.checked : ''} ${
                disabled ? styles.disabled : ''
            } ${className || ''}`.trim()}
            htmlFor={id}>
            <span
                className={`${styles.button} ${
                    isOn ? styles.checked : ''
                }`.trim()}
            />
            <input
                checked={isOn}
                onChange={e => {
                    onToggle(e.target.checked);
                }}
                className={styles.checkbox}
                id={id}
                type="checkbox"
            />
        </label>
    );
};

export default Switch;

import { useCallback } from 'react';
import {
    AlertInstance,
    AlertCustomOptions,
    useAlert as useReactAlert,
} from 'react-alert';
import { useTranslation } from 'react-i18next';

interface UseAlertResult {
    error: (messageKey: string) => AlertInstance;
    success: (messageKey: string) => AlertInstance;
    info: (messageKey: string) => AlertInstance;
}

export const useAlert = (): UseAlertResult => {
    const alert = useReactAlert();
    const { t } = useTranslation();

    const error = useCallback(
        (message: string, options?: AlertCustomOptions) =>
            alert && alert.error(t(message), { ...options, timeout: 4000 }),
        [alert, t],
    );
    const success = useCallback(
        (message: string, options?: AlertCustomOptions) =>
            alert && alert.success(t(message), options),
        [alert, t],
    );
    const info = useCallback(
        (message: string, options?: AlertCustomOptions) =>
            alert && alert.info(t(message), options),
        [alert, t],
    );

    return {
        error,
        success,
        info,
    };

    // const alertInstance: AlertInstance = {
    //     id: 0,
    //     close: () => {
    //         return;
    //     },
    //     message: 'Dummy Alert Message',
    //     options: {},
    // };

    // const result: UseAlertResult = {
    //     error: (messageKey: string) => alertInstance,
    //     success: (messageKey: string) => alertInstance,
    //     info: (messageKey: string) => alertInstance,
    // };
    // return result;
};

import { gql } from '@apollo/client';
import { CompanyFragments } from '../fragments';
import { Company } from '../dto';

const PublicCompany = gql`
    query GetCompanyByUuid($uuid: String!) {
        getCompanyByUuid(uuid: $uuid) {
            ...PublicCompany
        }
    }
    ${CompanyFragments.PublicCompany}
`;

export type GetCompanyByUuidData = {
    getCompanyByUuid: Company;
};

export const GetCompanyByUuid = {
    PublicCompany,
};

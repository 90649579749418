import React from 'react';
import { Company } from '../../__shared/graphql';
import ProfileIcon from '../profileIcon/profileIcon';
import styles from './companyItem.module.scss';
import { ClassNameProp } from '../../types/stylingProps';

interface CompanyItemProps extends ClassNameProp {
    company?: Company;
}

const CompanyItem: React.FC<CompanyItemProps> = ({ company, className }) => {
    return (
        <div className={`${styles.company} ${className ?? ''}`.trim()}>
            <ProfileIcon
                fileDescription={company?.imageFile}
                colorBundle={company?.colorBundle}
            />
            <div className={styles.text}>
                <span className={styles.name}>{company?.name ?? ''}</span>
            </div>
        </div>
    );
};

export default CompanyItem;

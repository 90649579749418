import { gql } from '@apollo/client';

const LoginWithEmailOrUsername = gql`
    mutation LoginWithEmailOrUsername($login: LoginOptions!) {
        loginWithEmailOrUsername(login: $login)
    }
`;

// const Login = gql`
//     mutation Login($email: String!, $password: String!) {
//         login(email: $email, password: $password)
//     }
// `;

export interface LoginWithEmailOrUsernameData {
    loginWithEmailOrUsername: string;
}

// export interface LoginData {
//     login: string;
// }

export const UserMutations = {
    LoginWithEmailOrUsername,
    // Login,
};

import React from 'react';
import DetailTextField from '../../../../components/detailTextfield/detailTextfield';
import logUtil from '../../../../utils/logUtil';
import SplitScreen from '../main/splitScreen';
import WidgetSettings from './widgetSettings';

const Widget: React.FC = () => {
    return (
        <SplitScreen
            left={<WidgetSettings />}
            right={
                <DetailTextField
                    title="Test"
                    value="More tests"
                    onSave={() => {
                        logUtil.log('save..');
                    }}
                />
            }
        />
    );
};

export default Widget;

import {
    MutationSingleFileUploadArgs,
    MutationUpdateUserArgs,
    SingleFileUploadData,
    SingleFileUploadMutation,
    User,
} from '../../__shared/graphql';
import {
    AcceptImageFilesString,
    PredefinedImageResolutionsProfilePictureOnly,
    PredefinedImageResolutionsThumbnailAndDetail,
} from '../../__shared/common';
import { useMutation } from '@apollo/client';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FadeLoader } from 'react-spinners';
import { uploadClient } from '../../api/apolloClient';
import {
    UpdateUserData,
    UserMutations,
} from '../../graphql/mutations/userMutations';
import { useAlert } from '../../hooks/useAlert';
import { useObservable } from '../../hooks/useObservable';
import RxAuthentication from '../../rxjs/RxAuthentication';
import { ClassNameProp } from '../../types/stylingProps';
import { getFileInfoFromImage } from '../../utils/fileUtils';
import { updateCache } from '../../utils/imageFileUtil';
import logUtil from '../../utils/logUtil';
import ProfileIcon from '../profileIcon/profileIcon';
import styles from './uploadProfileIcon.module.scss';

interface UploadProfileIconProps {
    user?: User;
    loading?: boolean;
    onUserUpdated: (user: User) => void;
}

const UploadProfileIcon: React.FC<UploadProfileIconProps & ClassNameProp> = ({
    user,
    loading = false,
    onUserUpdated,
    className,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const uploadInputRef = useRef<HTMLInputElement>(null);
    const alert = useAlert();
    const { t } = useTranslation();
    const accessToken = useObservable(RxAuthentication.accessToken$);

    const [
        silentUpdateUserMutation,
        {
            loading: silentUpdateLoading,
            data: silentUpdateData,
            reset: resetSilentUpload,
        },
    ] = useMutation<UpdateUserData, MutationUpdateUserArgs>(
        UserMutations.UpdateUser,
    );

    const [singleFileUpload, { loading: uploadLoading, reset: resetUpload }] =
        useMutation<SingleFileUploadData, MutationSingleFileUploadArgs>(
            SingleFileUploadMutation.FullResponse,
            { client: uploadClient },
        );

    const [
        updateUserMutation,
        { loading: updateLoading, reset: resetUserUpdate },
    ] = useMutation<UpdateUserData, MutationUpdateUserArgs>(
        UserMutations.UpdateUser,
        {
            notifyOnNetworkStatusChange: true,
        },
    );

    useEffect(() => {
        if (!silentUpdateLoading) {
            const imageFile = silentUpdateData?.updateUser.imageFile;
            if (imageFile) {
                updateCache(imageFile, accessToken);
            }
        }
    }, [silentUpdateData, silentUpdateLoading, accessToken]);

    // updated loading state
    useEffect(() => {
        setIsLoading(loading || uploadLoading || updateLoading);
    }, [loading, uploadLoading, updateLoading]);

    const onChange = async (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files?.length) {
            return;
        }
        const file = event.target.files[0];
        if (!file) {
            return;
        }
        let _userId: number | undefined;
        const fileInfo = getFileInfoFromImage(file);
        try {
            const result = await singleFileUpload({
                variables: {
                    file,
                    fileInfo,
                    resolutions: Object.values(
                        PredefinedImageResolutionsProfilePictureOnly,
                    ),
                },
            });
            if (!result.data?.singleFileUpload?.id) {
                return;
            }
            const response = await updateUserMutation({
                variables: {
                    user: {
                        id: user?.id ?? 0,
                        imageFileId: result.data.singleFileUpload.id,
                    },
                },
            });
            const updatedUser = response.data?.updateUser;
            if (!updatedUser?.id) {
                return;
            }
            _userId = updatedUser.id;
            alert.success('common.alerts.savedChanges');
            onUserUpdated(updatedUser);
        } catch (e) {
            // TODO: more specific alert
            alert.error('common.alerts.defaultError');
            resetUpload();
            resetUserUpdate();
        }

        if (!_userId) {
            return;
        }
        try {
            await silentUpdateUserMutation({
                variables: {
                    user: {
                        id: _userId,
                    },
                    imageResolutions: Object.values(
                        PredefinedImageResolutionsThumbnailAndDetail,
                    ),
                },
            });
        } catch (e) {
            // TODO: fix this error
            logUtil.log('SilentUpdate failed', e);
            resetSilentUpload();
        }
    };

    return (
        <div className={`${styles.profileContainer} ${className ?? ''}`.trim()}>
            <input
                type="file"
                accept={AcceptImageFilesString}
                onChange={onChange}
                ref={uploadInputRef}
                className={styles.fileInput}
            />
            <div
                className={styles.profile}
                onClick={() => {
                    if (uploadInputRef.current && !isLoading) {
                        uploadInputRef.current.click();
                    }
                }}>
                <>
                    <ProfileIcon
                        size={200}
                        fileDescription={user?.imageFile}
                        colorBundle={user?.colorBundle}
                    />
                    {/* <img src={company.imageFile?.safeUrl ?? ''} /> */}
                    {!isLoading && (
                        <span className={styles.text}>
                            {t('common.buttons.uploadNewImage')}
                        </span>
                    )}
                </>
                {isLoading && (
                    <div className={styles.spinner}>
                        <FadeLoader />
                    </div>
                )}
            </div>
        </div>
    );
};

export default UploadProfileIcon;

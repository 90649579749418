import { gql } from '@apollo/client';
import { AnimalType } from '../dto';

const GetAnimalTypes = gql`
    query GetAnimalTypes {
        getAnimalTypes {
            typeName
            createdAt
            updatedAt
        }
    }
`;

export interface GetAnimalTypesData {
    getAnimalTypes: AnimalType[];
}

const GetAnimalTypesQueries = {
    GetAnimalTypes,
};

export const AnimalTypeQueries = {
    GetAnimalTypesQueries,
};

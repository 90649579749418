import { BehaviorSubject, first, Subject } from 'rxjs';
import { mergeArraysById } from '../utils/arrayUtils';
import { Chat, ChatMessage } from '../__shared/graphql';

const chatsSubject = new BehaviorSubject<Chat[]>([]);
const chats$ = chatsSubject.asObservable();

const unreadMessageCountSubject = new BehaviorSubject(0);
const unreadMessageCount$ = unreadMessageCountSubject.asObservable();

const loadedChatIdsSubject = new BehaviorSubject<number[]>([]);
const loadedChatIds$ = loadedChatIdsSubject.asObservable();

const currentChatIdSubject = new BehaviorSubject(0);
const currentChatId$ = currentChatIdSubject.asObservable();

const onChatUpdateForUserSubject = new Subject<Chat>();
const onChatUpdateForUser$ = onChatUpdateForUserSubject.asObservable();

const onChatMessageAddedSubject = new Subject<ChatMessage>();
const onChatMessageAdded$ = onChatMessageAddedSubject.asObservable();

const onChatMessageEditedSubject = new Subject<ChatMessage>();
const onChatMessageEdited$ = onChatMessageEditedSubject.asObservable();

const onChatMessagesEditedSubject = new Subject<ChatMessage[]>();
const onChatMessagesEdited$ = onChatMessagesEditedSubject.asObservable();

const onChatUpdateForUser = (chat: Chat): void => {
    onChatUpdateForUserSubject.next(chat);
};

const onChatMessageAdded = (chatMessage: ChatMessage): void => {
    onChatMessageAddedSubject.next(chatMessage);
};

const onChatMessageEdited = (chatMessage: ChatMessage): void => {
    onChatMessageEditedSubject.next(chatMessage);
};

const onChatMessagesEdited = (chatMessages: ChatMessage[]): void => {
    onChatMessagesEditedSubject.next(chatMessages);
};

export type ScrollEnd = 'bottom' | 'top';
export type ScrollBehaviour = 'smooth' | 'auto';

interface ScrollChatToEnd {
    chatId: number;
    scrollEnd: ScrollEnd;
    behavior: ScrollBehaviour;
}

interface ScrollChatToMessage {
    chatId: number;
    chatMessageUuid: string;
    behavior: ScrollBehaviour;
}

const scrollChatToEndSubject = new Subject<ScrollChatToEnd>();
const scrollChatToEnd$ = scrollChatToEndSubject.asObservable();

const scrollChatToMessageSubject = new Subject<ScrollChatToMessage>();
const scrollChatToMessage$ = scrollChatToMessageSubject.asObservable();

const scrollChatToEnd = (scrollForChat: ScrollChatToEnd): void => {
    scrollChatToEndSubject.next(scrollForChat);
};

const scrollChatToMessage = (
    scrollForChatToMessage: ScrollChatToMessage,
): void => {
    scrollChatToMessageSubject.next(scrollForChatToMessage);
};

const countUnreadMessages = (chats?: Chat[]): number => {
    if (!chats?.length) {
        return 0;
    }
    return chats.reduce(
        (previousCount, currentChat) =>
            previousCount + (currentChat?.unreadMessageCount || 0),
        0,
    );
};

const setUnreadMessageCount = (count: number) => {
    unreadMessageCount$.pipe(first()).subscribe(currentCount => {
        // don't updated if not necessary
        if (count !== currentCount) {
            unreadMessageCountSubject.next(count);
        }
    });
};

const chatSortFunc = (a: Chat, b: Chat): number =>
    b.lastMessage?.createdAt - a.lastMessage?.createdAt;

const addChats = (chats: Chat[]): void => {
    if (!chats.length) {
        return;
    }
    chats$.pipe(first()).subscribe(oldChats => {
        const newChats = [...mergeArraysById<Chat>(oldChats, chats)].sort(
            chatSortFunc,
        );
        chatsSubject.next(newChats);
        setUnreadMessageCount(countUnreadMessages(newChats));
    });
};

const addLoadedChatId = (chatId: number): void => {
    loadedChatIds$.pipe(first()).subscribe(loadedChats => {
        if (!loadedChats.includes(chatId)) {
            loadedChatIdsSubject.next([...loadedChats, chatId]);
        }
    });
};

const setCurrentChatId = (chatId: number): void => {
    currentChatId$.pipe(first()).subscribe(currentId => {
        if (currentId !== chatId) {
            currentChatIdSubject.next(chatId);
        }
        addLoadedChatId(chatId);
    });
};

const clear = (): void => {
    chatsSubject.next([]);
    unreadMessageCountSubject.next(0);
    loadedChatIdsSubject.next([]);
    currentChatIdSubject.next(0);
};

const RxChat = {
    addChats,
    setCurrentChatId,
    clear,
    scrollChatToEnd,
    scrollChatToMessage,
    chats$,
    currentChatId$,
    loadedChatIds$,
    unreadMessageCount$,
    scrollChatToEnd$,
    scrollChatToMessage$,
    onChatUpdateForUser$,
    onChatMessageAdded$,
    onChatMessageEdited$,
    onChatMessagesEdited$,
    onChatUpdateForUser,
    onChatMessageAdded,
    onChatMessageEdited,
    onChatMessagesEdited,
};

export default RxChat;

import { Animal, AnimalGroup } from '../../__shared/graphql';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultColorBundle } from '../../constants';
import ProfileIcon from '../profileIcon/profileIcon';
import styles from './animalContextItem.module.scss';

const millisecondsToWeeks = 1000 * 60 * 60 * 24 * 7;

interface AnimalContextItemProps {
    animal?: Animal | null;
    animalGroup?: AnimalGroup | null;
    onClick: () => void;
}

const AnimalContextItem: React.FC<AnimalContextItemProps> = ({
    animal,
    animalGroup,
    onClick,
}) => {
    const { t } = useTranslation();

    const hasAnimalContext = animal || animalGroup;

    if (!hasAnimalContext) {
        return null;
    }

    const now = new Date();

    const birthday = animal?.birthday ?? animalGroup?.averageBirthday;
    const animalType = animal?.type ?? animalGroup?.type;
    const breed = animal?.breed ?? animalGroup?.breed;
    const name = animal?.name ?? animalGroup?.name ?? 'Unbenanntes Tierprofil';

    const ageInWeeks = birthday
        ? Math.round((now.getTime() - birthday.getTime()) / millisecondsToWeeks)
        : 0;

    let ageText: string;
    let age: number;
    switch (true) {
        case ageInWeeks > 26 && ageInWeeks < 53:
            // TODO: calc in months via date function
            age = Math.round(ageInWeeks / 4);
            ageText = t(
                age === 1 ? 'screens.chat.monthOld' : 'screens.chat.monthsOld',
                {
                    age,
                },
            );
            break;

        // case ageInWeeks > 52:
        // TODO: calc in years via date function

        default:
            age = Math.round(ageInWeeks / 52);
            ageText = t(
                age === 1 ? 'screens.chat.yearOld' : 'screens.chat.yearsOld',
                {
                    age,
                },
            );
            break;
    }

    let infoText = animalType ? t(`common.animalTypes.${animalType}`) : '';

    if (breed) {
        infoText += `, ${breed}`;
    }
    infoText += `, ${ageText}`;

    const imageFile = animal?.imageFile ?? animalGroup?.imageFile;
    return (
        <div
            className={`${styles.container} ${
                !hasAnimalContext ? styles.hidden : ''
            }`.trim()}
            onClick={onClick}>
            <div className={styles.animalInfo}>
                <ProfileIcon
                    fileDescription={imageFile}
                    colorBundle={
                        animal?.colorBundle ?? animalGroup?.colorBundle
                    }
                    style={{
                        background: `linear-gradient(${
                            animal?.colorBundle?.hexColor ??
                            animalGroup?.colorBundle?.hexColor ??
                            DefaultColorBundle.hexColor
                        }, ${
                            animal?.colorBundle?.hexColorGradient ??
                            animalGroup?.colorBundle?.hexColorGradient ??
                            DefaultColorBundle.hexColorGradient
                        }`,
                    }}
                />
                <div className={styles.animalDetail}>
                    <span className={styles.name}>{name}</span>
                    <span className={styles.details}>{infoText}</span>
                </div>
            </div>
            <div />
        </div>
    );
};

export default AnimalContextItem;

import { gql } from '@apollo/client';

const FullResponse = gql`
    fragment FullFileDescription on FileDescription {
        id
        uuid
        name
        url
        safeUrl
        fileType
        duration
        height
        width
        fileSize
        createdAt
        updatedAt
        children {
            id
            uuid
            name
            url
            safeUrl
            fileType
            duration
            height
            width
            fileSize
            createdAt
            updatedAt
        }
    }
`;

export const FileDescriptionFragments = {
    FullResponse,
};

import { LicenceToCompany } from '../../__shared/graphql';
import { gql } from '@apollo/client';
import { LicenceToCompanyFragments } from '../fragments/licenceToCompanyFragments';

const GetLicencesToCompanies = gql`
    query GetLicencesToCompanies($filter: LicenceToCompanyFilter!) {
        getLicencesToCompanies(filter: $filter) {
            ...FullLicenceToCompanyFragment
        }
    }
    ${LicenceToCompanyFragments.FullLicenceToCompanyFragment}
`;
//#endregion graphql

export const LicencesToCompaniesQueries = { GetLicencesToCompanies };

export interface GetLicencesToCompaniesData {
    getLicencesToCompanies: LicenceToCompany[];
}

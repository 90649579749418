import React, { useEffect, useState } from 'react';
import styles from './systemBanner.module.scss';

const ProducationUrls = [
    'https://pro.animalchat.net',
    'http://pro.animalchat.net',
    'https://www.pro.animalchat.net',
    'http://www.pro.animalchat.net',
];

const DevelopmentUrls = [
    'https://dev.pro.animalchat.net',
    'http://dev.pro.animalchat.net',
    'https://www.dev.pro.animalchat.net',
    'http://www.dev.pro.animalchat.net',
];

type System = 'Prod' | 'Dev' | 'local';

const SystemBanner: React.FC = () => {
    const [system, setSystem] = useState<System>('Prod');

    useEffect(() => {
        const DashboardUrl = process?.env?.REACT_APP_DASHBOARD_URL;
        if (ProducationUrls.some(url => DashboardUrl?.startsWith(url))) {
            setSystem('Prod');
            return;
        }
        if (DevelopmentUrls.some(url => DashboardUrl?.startsWith(url))) {
            setSystem('Dev');
            return;
        }
        setSystem('local');
    }, []);

    if (
        process?.env?.REACT_APP_SYSTEM_BANNER !== 'enabled' ||
        system === 'Prod'
    ) {
        return null;
    }
    return <div className={styles.container}>{system}</div>;
};

export default SystemBanner;

import { ChatMessageToUser } from '../dto';
import { gql } from '@apollo/client';
import { ChatMessageToUserFragments } from '../fragments';

const FullResponse = gql`
    mutation updateChatMessagesToUser(
        $chatMessageStateUpdate: ChatMessageStateUpdate!
        $chatMessageIds: [Int!]!
    ) {
        updateChatMessagesToUser(
            chatMessageStateUpdate: $chatMessageStateUpdate
            chatMessageIds: $chatMessageIds
        ) {
            ...FullChatMessageToUser
        }
    }
    ${ChatMessageToUserFragments.FullChatMessageToUser}
`;

const FullResponseWithChatMessage = gql`
    mutation updateChatMessagesToUser(
        $chatMessageStateUpdate: ChatMessageStateUpdate!
        $chatMessageIds: [Int!]!
    ) {
        updateChatMessagesToUser(
            chatMessageStateUpdate: $chatMessageStateUpdate
            chatMessageIds: $chatMessageIds
        ) {
            ...ChatMessageToUserIdWithFullChatMessage
        }
    }
    ${ChatMessageToUserFragments.ChatMessageToUserIdWithFullChatMessage}
`;

export type UpdateChatMessagesToUserData = {
    updateChatMessagesToUser: ChatMessageToUser[];
};

export const UpdateChatMessagesToUserMutation = {
    FullResponse,
    FullResponseWithChatMessage,
};

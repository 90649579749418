import React, { useEffect, useState } from 'react';
import { useObservable } from '../../../../hooks/useObservable';
import RxCompany from '../../../../rxjs/RxCompany';
import Company from '../../../app/settingsLayout/company/company';
import CreateCompany, {
    CreateCompanyPage,
} from './createCompany/createCompany';
import Invitations, { InvitationsProps } from './invitations/invitations';

import styles from './company.module.scss';
import logUtil from '../../../../utils/logUtil';
import { FadeLoader } from 'react-spinners';
interface BusinessCompanyProps {
    invitationsProps: InvitationsProps;
}

const BusinessCompany: React.FC<BusinessCompanyProps> = ({
    invitationsProps,
}) => {
    const company = useObservable(RxCompany.currentCompany$);
    const [hasCompany, setHasCompany] = useState<boolean | undefined>(
        undefined,
    );
    const [createCompanyPage, setCreateCompanyPage] =
        useState<CreateCompanyPage>('');

    useEffect(() => {
        setHasCompany(company !== null);
    }, [company]);

    const hasInvitations = invitationsProps.user?.invitations?.some(
        i => !!i.employee?.company,
    );

    logUtil.log('Company', company);

    if (company === undefined) {
        return (
            <div className={styles.spinner}>
                <FadeLoader />
            </div>
        );
    }

    return (
        <div className={styles.container}>
            {hasCompany ? (
                <Company />
            ) : (
                <div>
                    {createCompanyPage === '' && (
                        <Invitations {...invitationsProps} />
                    )}
                    {!hasInvitations && (
                        <CreateCompany
                            onPageChanged={page => {
                                setCreateCompanyPage(page);
                            }}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export default BusinessCompany;

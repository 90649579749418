import { gql } from '@apollo/client';
import { FileDescriptionFragments } from './fileDescriptionFragments';
import { WherebyMeetingFragments } from './wherebyMeetingFragments';

const FullOrder = gql`
    fragment FullOrder on Order {
        id
        type
        amountToPayInEuroCents
        paidAmountInEuroCents
        paidAt
        paidBy
        stripePaymentIntent
        closedAt
        closedBy
        canceledAt
        canceledBy
        createdAt
        createdBy
        updatedAt
        updatedBy
        isAdvancePayment
        company {
            id
            name
            allowsDelayedPaymentMethods
        }
        customer {
            id
            contact {
                id
                title
                firstname
                lastname
                email
            }
            user {
                id
                title
                firstname
                lastname
                email
                colorBundle {
                    hexColor
                    hexColorGradient
                }
            }
        }
        appointment {
            id
            type
            from
            to
            isEmergencyService
            wherebyMeeting {
                ...FullWherebyMeeting
            }
            confirmedAt
            confirmedBy
            canceledAt
            canceledBy
            createdAt
            createdBy
            updatedAt
            updatedBy
            company {
                id
                name
            }
            customer {
                id
                contact {
                    id
                    title
                    firstname
                    lastname
                    email
                }
                user {
                    id
                    title
                    firstname
                    lastname
                    email
                    colorBundle {
                        hexColor
                        hexColorGradient
                    }
                }
            }
            employeeId
            employee {
                id
                title
                contact {
                    id
                    title
                    firstname
                    lastname
                    email
                }
                user {
                    id
                    title
                    firstname
                    lastname
                    email
                    colorBundle {
                        hexColor
                        hexColorGradient
                    }
                    imageFile {
                        ...FullFileDescription
                    }
                }
                role {
                    key
                }
            }
            parent {
                id
                type
                from
                to
                isEmergencyService
                confirmedAt
                confirmedBy
                canceledAt
                canceledBy
                createdAt
                createdBy
                updatedAt
                updatedBy
            }
            permissions {
                actions {
                    key
                    value
                }
            }
        }
        timeSlots {
            id
            from
            to
        }
        permissions {
            actions {
                key
                value
            }
        }
    }
    ${WherebyMeetingFragments.FullWherebyMeeting}
    ${FileDescriptionFragments.FullResponse}
`;

export const OrderFragments = { FullOrder };

import { Chat } from '../dto';
import { gql } from '@apollo/client';
import { ChatFragments } from '../fragments';

const FullResponse = gql`
    query GetChatsForUserUpdatedSince($lastUpdatedAt: Date!) {
        getChatsForUserUpdatedSince(lastUpdatedAt: $lastUpdatedAt) {
            ...FullChat
        }
    }
    ${ChatFragments.FullChat}
`;

export interface GetChatsForUserUpdatedSinceData {
    getChatsForUserUpdatedSince: Chat[];
}

export const GetChatsForUserUpdatedSinceQuery = { FullResponse };

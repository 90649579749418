import { gql } from '@apollo/client';
import { Employee } from '../dto';
import { EmployeeFragments } from '../fragments';

const UpdateEmployee = gql`
    mutation updateEmployee($employee: EmployeeUpdate!) {
        updateEmployee(employee: $employee) {
            ...FullEmployee
        }
    }
    ${EmployeeFragments.FullEmployee}
`;

export interface UpdateEmployeeData {
    updateEmployee: Employee;
}

export const EmployeeMutations = {
    UpdateEmployee,
};

import {
    ColorBundle,
    FileDescription,
    LicenceToCompany,
} from '../../../../__shared/graphql';
import {
    DateUtil,
    isCompanyLicenceType,
    isUserLicenceType,
    LicenceTypes,
} from '../../../../__shared/common';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ProfileIcon from '../../../../components/profileIcon/profileIcon';
import logUtil from '../../../../utils/logUtil';
import styles from './subscriptionList.module.scss';

interface SubscriptionListProps {
    subscriptions: LicenceToCompany[];
    loading: boolean;
    onClick: (licenceToCompany?: LicenceToCompany) => void;
    subscription?: LicenceToCompany;
}

const HideCss = styles.hide;
const ShowCss = '';

const emptyEntries = [1, 2, 3, 4, 5, 6];

export const LicenceTypeListTranslations: { [key: string]: string } = {
    Unknown: 'screens.settings.subscriptions.listTypes.unknown',
    [LicenceTypes.User.Starter]:
        'screens.settings.subscriptions.listTypes.starter',
    [LicenceTypes.User.Member]:
        'screens.settings.subscriptions.listTypes.member',
    [LicenceTypes.User.Consultant]:
        'screens.settings.subscriptions.listTypes.consultant',
    [LicenceTypes.Company.Free]:
        'screens.settings.subscriptions.listTypes.free',
    [LicenceTypes.Company.StandardPerMonth]:
        'screens.settings.subscriptions.listTypes.standardPerMonth',
    [LicenceTypes.Company.StandardPerYear]:
        'screens.settings.subscriptions.listTypes.standardPerYear',
};

const SubscriptionList: React.FC<SubscriptionListProps> = ({
    subscriptions,
    loading,
    onClick,
    subscription,
}) => {
    const [hideClass, setHideClass] = useState<string>(HideCss);
    const timeoutRef = useRef<number>();
    const { t } = useTranslation();

    useEffect(() => {
        if (loading) {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
            setHideClass(HideCss);
        } else {
            timeoutRef.current = window.setTimeout(
                () => setHideClass(ShowCss),
                200,
            );
        }
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [loading]);

    return (
        <ul className={styles.list}>
            <li className={styles.addEntry} onClick={() => onClick(undefined)}>
                {t('screens.settings.subscriptions.addSubscription')}
            </li>
            {!!hideClass &&
                emptyEntries.map(e => {
                    return (
                        <li key={e} className={styles.emptyEntry}>
                            <div className={styles.text}>
                                <div className={styles.title}></div>
                                <div className={styles.subTitle}></div>
                            </div>
                            <div className={styles.profile}>
                                <div className={styles.image}></div>
                            </div>
                        </li>
                    );
                })}
            {subscriptions.map(sub => {
                const licence = sub.licence;
                let imageFile: FileDescription | undefined | null;
                let colorBundle: ColorBundle | undefined | null;

                if (isUserLicenceType(licence?.type)) {
                    imageFile = sub.user?.imageFile;
                    colorBundle = sub.user?.colorBundle;
                }
                if (isCompanyLicenceType(licence?.type)) {
                    imageFile = sub.company?.imageFile;
                    colorBundle = sub.company?.colorBundle;
                }

                if (!licence) {
                    logUtil.warn(
                        `Missing licence for licenceToCompanyId ${sub.id}}`,
                    );
                    return null;
                }
                let validUntil = t(
                    'screens.settings.subscriptions.willBeRenewedAutomatically',
                );
                if (sub.cancelledFor) {
                    validUntil = t(
                        'screens.settings.subscriptions.validUntilDate',
                        { validUntil: DateUtil.toDDMMYYYY(sub.cancelledFor) },
                    );
                }

                return (
                    <li
                        onClick={() => onClick(sub)}
                        key={sub.id}
                        className={`${styles.entry} ${
                            sub.id === subscription?.id ? styles.active : ''
                        } ${hideClass}`.trim()}>
                        <div className={styles.text}>
                            <div className={styles.title}>
                                {t(LicenceTypeListTranslations[licence.type])}
                            </div>
                            <div
                                className={`${styles.subTitle} ${
                                    sub.cancelledFor ? styles.cancelled : ''
                                }`.trim()}>
                                {validUntil}
                            </div>
                        </div>
                        <div className={styles.profile}>
                            <ProfileIcon
                                size={60}
                                fileDescription={imageFile}
                                colorBundle={colorBundle}
                            />
                            {/* <div className={styles.image}></div> */}
                        </div>
                    </li>
                );
            })}
        </ul>
    );
};

export default SubscriptionList;

import { gql } from '@apollo/client';
import { User } from '../dto';
import { UserFragments } from '../fragments';

const FullResponse = gql`
    query getUser($userId: Int!) {
        getUser(userId: $userId) {
            ...FullUser
        }
    }
    ${UserFragments.FullUser}
`;

const FullResponseInvitationAndLicences = gql`
    query getUser($userId: Int!) {
        getUser(userId: $userId) {
            ...FullUserWithInvitationAndLicences
        }
    }
    ${UserFragments.FullUserWithInvitationAndLicences}
`;

export const GetUserQuery = {
    FullResponse,
    FullResponseInvitationAndLicences,
};

import { gql } from '@apollo/client';

const FullResponse = gql`
    mutation DeleteEmployee($employeeId: Int!) {
        deleteEmployee(employeeId: $employeeId)
    }
`;

export interface DeleteEmployeeData {
    deleteEmployee: number;
}

export const DeleteEmployeeMutation = {
    FullResponse,
};

import RxAuthentication from '../rxjs/RxAuthentication';
import RxChat from '../rxjs/RxChat';
import RxCompany from '../rxjs/RxCompany';
import { RxCreateAccount } from '../rxjs/RxCreateAccount';
import RxLicenceToCompanies from '../rxjs/RxLicenceToCompanies';
import RxUserProfile from '../rxjs/RxUserProfile';

const TokenKey = 'AnimalChatAccessToken';

const logout = (skipRefresh = false): void => {
    clearToken();
    storeAccessToken('');
    RxChat.clear();
    RxAuthentication.logout();
    RxCompany.clear();
    RxUserProfile.clear();
    RxLicenceToCompanies.clear();
    RxCreateAccount.setCreateAccountForm(undefined);
    RxCreateAccount.setEmailForVerification('');
    RxCreateAccount.setPhoneNumberForVerification('');

    if (!skipRefresh) {
        //  window.location.reload();
    }
};

const clearToken = (): void => {
    window.localStorage.removeItem(TokenKey);
};

const loadAccessToken = (): string | null => {
    return window.localStorage.getItem(TokenKey);
};

const storeAccessToken = (token: string): void => {
    window.localStorage.setItem(TokenKey, token);
};

const AuthenticationUtil = {
    logout,
    storeAccessToken,
    loadAccessToken,
};

export default AuthenticationUtil;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Company } from '../../../../../__shared/graphql';
import Select from '../../../../../components/select/select';
import TextButton from '../../../../../components/textButton/textButton';
import { CompanyTypeOptions, Option } from '../../../../../utils/option';
import styles from './editCompanyType.module.scss';

interface EditCompanyTypeProps {
    company: Company;
    onSave: (newCompanyType: string) => void;
    onCancel: () => void;
}

const EditCompanyType: React.FC<EditCompanyTypeProps> = ({
    company,
    onSave,
    onCancel,
}) => {
    const [value, setValue] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        setValue(company.type ?? '');
    }, [company]);

    return (
        <div className={styles.container}>
            <p className={styles.title}>
                {t('screens.settings.company.fields.type')}
            </p>
            <Select
                defaultValue={value}
                options={CompanyTypeOptions.map(o => ({
                    label: t(o.label),
                    value: o.value,
                }))}
                onChange={option => {
                    setValue((option as Option).value);
                }}
            />
            <div className={styles.actions}>
                <TextButton
                    style="secondary"
                    text={t('common.buttons.cancel')}
                    onClick={() => {
                        setValue(company.type ?? '');
                        onCancel();
                    }}
                />
                <TextButton
                    style="primary"
                    text={t('common.buttons.apply')}
                    onClick={() => onSave(value)}
                />
            </div>
        </div>
    );
};

export default EditCompanyType;

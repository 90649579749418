import { LicenceToCompany } from '../__shared/graphql';
import { BehaviorSubject } from 'rxjs';

const licenceToCompaniesSubject = new BehaviorSubject<
    LicenceToCompany[] | undefined
>(undefined);
const licenceToCompanies$ = licenceToCompaniesSubject.asObservable();

const setLicenceToCompanies = (
    licenceToCompanies: LicenceToCompany[],
): void => {
    licenceToCompaniesSubject.next(licenceToCompanies);
};

const clear = (): void => {
    licenceToCompaniesSubject.next(undefined);
};

const RxLicenceToCompanies = {
    licenceToCompanies$,
    clear,
    setLicenceToCompanies,
};

export default RxLicenceToCompanies;

import { Order } from '../dto';
import { gql } from '@apollo/client';
import { OrderFragments } from '../fragments';

const FullResponse = gql`
    mutation CancelOrder($orderId: Int!) {
        cancelOrder(orderId: $orderId) {
            ...FullOrder
        }
    }
    ${OrderFragments.FullOrder}
`;

export interface CancelOrderData {
    cancelOrder: Order;
}

export const CancelOrderMutation = { FullResponse };

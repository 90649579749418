import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DetailTextField from '../../../../components/detailTextfield/detailTextfield';
import NavBackButton from '../../../../components/navBackButton/navBackButton';
import { useObservable } from '../../../../hooks/useObservable';
import { useScreenHasLessWidthThan } from '../../../../hooks/useScreenSize';
import useUpdateCompany from '../../../../hooks/useUpdateCompany';
import RxCompany from '../../../../rxjs/RxCompany';
import RxElectron from '../../../../rxjs/RxElectron';
import { Limits } from '../../../../utils/inputLimits';
import logUtil from '../../../../utils/logUtil';
import {
    isValidPhoneNumberWithCountryCodePrefixAndLimit,
    isValidRequiredPhoneNumberWithCountryCodePrefixAndLimit,
    isValidRequiredValueWithLimit,
    isValidValueWithLimit,
} from '../../../../utils/validationUtil';
import { isElectron } from '../../../../__shared/electron';
import {
    Address,
    Company as CompanyType,
    CompanyUpdate,
} from '../../../../__shared/graphql';
import SplitScreen from '../main/splitScreen';
import AddressForm from './addressForm/addressForm';
import styles from './company.module.scss';
import CompanySettings from './companySettings';
import EditCompanyProfilePicture from './editCompanyProfilePicture/editCompanyProfilePicture';
import EditCompanyType from './editCompanyType/editCompanyType';

export const mapCompanyToCompanyUpdate = (
    company: CompanyType,
): CompanyUpdate => {
    const result: CompanyUpdate = {
        id: company.id ?? 0,
        name: company.name,
        nametag: company.nametag,
        type: company.type,
        email: company.email,
        imageFileId: company.imageFileId,
        description: company.description,
        website: company.website,
        addressId: company.addressId,
        isWidgetActive: company.isWidgetActive,
        widgetPosition: company.widgetPosition,
        widgetGreeting: company.widgetGreeting,
        phoneNumber: company.phoneNumber,
        emergencyServicePhoneNumber: company.emergencyServicePhoneNumber,
        stripeAccountId: company.stripeAccountId,
        createdAt: company.createdAt,
        updatedAt: company.updatedAt,
        defaultVideoChatTimeSlotInMinutes:
            company.defaultVideoChatTimeSlotInMinutes,
        defaultVideoChatPricePerTimeSlotInEuroCents:
            company.defaultVideoChatPricePerTimeSlotInEuroCents,
        allowsDelayedPaymentMethods: company.allowsDelayedPaymentMethods,
        isSearchable: company.isSearchable,
        isActive: company.isActive,
    };
    if (company.addressId) {
        result.addressId = company.addressId;
    }
    return result;
};

export type CompanyField = keyof CompanyType;

interface CompanyProps {
    breakpoints?: {
        verySmall: number;
        small: number;
    };
    splitScreenClassName?: string;
}

const getFieldLimit = (field: string): number | undefined => {
    if (!field) {
        return undefined;
    }
    switch (field as keyof CompanyType) {
        case 'name':
            return Limits.CompanyName;
        case 'nametag':
            return Limits.CompanyNameTag;
        case 'description':
            return Limits.CompanyDescription;
        case 'phoneNumber':
            return Limits.PhoneNumber;
        case 'emergencyServicePhoneNumber':
            return Limits.PhoneNumber;
        case 'website':
            return Limits.Website;

        default:
            return undefined;
    }
};

type ValidatorFunc = (value: string) => boolean;

const getValidatorFuncForField = (
    fieldName: string,
): ValidatorFunc | undefined => {
    switch (fieldName as keyof CompanyType) {
        case 'name':
            return value =>
                isValidRequiredValueWithLimit(Limits.CompanyName, value);
        case 'nametag':
            return value => isValidValueWithLimit(Limits.CompanyNameTag, value);
        case 'description':
            return value =>
                isValidValueWithLimit(Limits.CompanyDescription, value);
        case 'phoneNumber':
            return value =>
                isValidRequiredPhoneNumberWithCountryCodePrefixAndLimit(
                    Limits.PhoneNumber,
                    value,
                );
        case 'emergencyServicePhoneNumber':
            return value =>
                isValidPhoneNumberWithCountryCodePrefixAndLimit(
                    Limits.PhoneNumber,
                    value,
                );
        case 'website':
            return value => isValidValueWithLimit(Limits.Website, value);

        default:
            return undefined;
    }
};

const Company: React.FC<CompanyProps> = () => {
    const screenHasLessWidthThanXxs = useScreenHasLessWidthThan('xxs');
    const screenHasLessWidthThanSm = useScreenHasLessWidthThan('sm');

    const isWindows = useObservable(RxElectron.isWindows$, true);
    const company = useObservable(RxCompany.currentCompany$);
    const [field, setField] = useState<CompanyField | undefined>(undefined);
    const { t } = useTranslation();
    const { updateCompany, reloadCompany } = useUpdateCompany();

    const isEditProfile = field === 'imageFile';
    const isEditAddress = field === 'address';
    const isEditCompanyType = field === 'type';
    const isPhoneField =
        field === 'phoneNumber' || field === 'emergencyServicePhoneNumber';
    const isTextField = !isEditAddress && !isEditProfile && !!field;

    const [show, setShow] = useState(false);

    useEffect(() => {
        logUtil.log('Company field:', field);
    }, [field]);

    useEffect(() => {
        logUtil.log('Company show:', show);
    }, [show]);

    useEffect(() => {
        logUtil.log(
            'Company screenHasLessWidthThanXs:',
            screenHasLessWidthThanSm,
        );
    }, [screenHasLessWidthThanSm]);

    useEffect(() => {
        logUtil.log(
            'Company screenHasLessWidthThanXxs:',
            screenHasLessWidthThanXxs,
        );
    }, [screenHasLessWidthThanXxs]);

    const onCompanyTypeSave = useCallback(
        (companyType: string) => {
            if (!company) {
                return;
            }
            updateCompany({
                company: {
                    ...mapCompanyToCompanyUpdate(company),
                    type: companyType,
                },
            });
            setShow(false);
            setField(undefined);
        },
        [updateCompany, company],
    );

    if (!company) {
        return null;
    }

    const onAddressSaved = (address: Address) => {
        if (!address || !company.id) {
            return;
        }
        if (company.address?.id === address.id) {
            reloadCompany(company.id);
        } else {
            updateCompany({
                company: {
                    ...mapCompanyToCompanyUpdate(company),
                    addressId: address.id,
                },
            });
        }
        setShow(false);
        setField(undefined);
    };

    const onImageSaved = (imageFileId: number) => {
        updateCompany({
            company: { ...mapCompanyToCompanyUpdate(company), imageFileId },
        });
        setShow(false);
        setField(undefined);
    };

    const onCancel = () => {
        setShow(false);
        setField(undefined);
    };

    let editField = null;
    if (isTextField && field) {
        const fieldValue = company[field];
        const fieldLimit = getFieldLimit(field);
        editField = (
            <DetailTextField
                title={t(`screens.settings.company.fields.${field}`)}
                value={fieldValue ?? ''}
                limit={fieldLimit}
                isPhoneNumber={isPhoneField}
                onValidate={getValidatorFuncForField(field)}
                onSave={(updatedValue: string) => {
                    if (company.id) {
                        updateCompany({
                            company: {
                                ...mapCompanyToCompanyUpdate(company),
                                [field]: updatedValue,
                            },
                        });
                        setShow(false);
                        setField(undefined);
                    }
                }}
                onCancel={onCancel}
            />
        );
    }

    if (isEditAddress) {
        editField = (
            <AddressForm
                onSave={onAddressSaved}
                onCancel={onCancel}
                address={company.address}
            />
        );
    }
    if (isEditProfile) {
        editField = (
            <EditCompanyProfilePicture
                onSaved={onImageSaved}
                company={company}
            />
        );
    }
    if (isEditCompanyType) {
        editField = (
            <EditCompanyType
                onSave={onCompanyTypeSave}
                onCancel={onCancel}
                company={company}
            />
        );
    }

    const mainStyle = screenHasLessWidthThanSm
        ? show
            ? { left: '0px', width: '100%' }
            : { left: '100vw' }
        : {};

    const editFieldWrapper = (
        <div className={styles.splitScreenRightContainer} style={mainStyle}>
            <div className={styles.header}>
                {screenHasLessWidthThanSm && (
                    <>
                        <NavBackButton
                            position={{
                                top: 14,
                                left:
                                    screenHasLessWidthThanXxs &&
                                    isElectron() &&
                                    !isWindows
                                        ? 74
                                        : 14,
                            }}
                            onClick={() => setShow(false)}
                        />
                        <div
                            style={
                                screenHasLessWidthThanXxs &&
                                isElectron() &&
                                !isWindows
                                    ? { paddingLeft: '60px' }
                                    : {}
                            }>
                            <div className={styles.text}>
                                {field &&
                                    t(
                                        `screens.settings.company.fields.${field}`,
                                    )}
                            </div>
                        </div>
                    </>
                )}
            </div>
            <div className={styles.content}>{editField}</div>
        </div>
    );

    return (
        <SplitScreen
            left={
                <CompanySettings
                    canEdit={true}
                    onEdit={value => {
                        setField(value);
                        setShow(true);
                    }}
                    company={company}
                    onSave={companyUpdate => {
                        if (companyUpdate) {
                            updateCompany({
                                company: companyUpdate,
                            });
                        }
                    }}
                />
            }
            right={editFieldWrapper}
        />
    );
};

export default Company;

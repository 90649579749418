import { gql } from '@apollo/client';
import { ContactFragments } from './contactFragments';
import { UserFragments } from './userFragments';

const FullEmployee = gql`
    fragment FullEmployee on Employee {
        id
        title
        createdAt
        updatedAt
        contact {
            ...FullContact
        }
        user {
            ...FullUser
        }
    }
    ${ContactFragments.FullContact}
    ${UserFragments.FullUser}
`;

export const EmployeeFragments = { FullEmployee };

import { AnimalContext, ChatMessage } from '../../../../__shared/graphql';
import { DateUtil } from '../../../../__shared/common';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../../../../components/icon/icon';
import PreviewItemMedia from '../../../../components/previewItemMedia/previewItemMedia';
import ProfileIcon from '../../../../components/profileIcon/profileIcon';
import { publishShowPreviewMessages } from '../../../../rxjs/onShowPreviewMessages';
import { filterMessagesWithImageAndVideo } from '../../../../utils/fileUtils';
import styles from './customerProfile.module.scss';

export interface AnimalContextProfileProps {
    animalContext: AnimalContext;
    chatMessagesWithFiles: ChatMessage[];
    onShowMedia?: () => void;
}

const AnimalContextProfile: React.FC<AnimalContextProfileProps> = ({
    animalContext,
    chatMessagesWithFiles,
    onShowMedia,
}) => {
    const { t } = useTranslation();

    const chatMessagesWithImageAndVideoFiles = useMemo(
        () => filterMessagesWithImageAndVideo(chatMessagesWithFiles),
        [chatMessagesWithFiles],
    );

    const animalOrAnimalGroup =
        animalContext.animal ?? animalContext.animalGroup;

    if (!animalOrAnimalGroup) {
        return null;
    }

    let averageAgeText = '';
    if (animalContext.animalGroup?.averageBirthday) {
        const translationArgs = DateUtil.getAgeTranslationOptions(
            new Date(animalContext.animalGroup?.averageBirthday),
        );
        averageAgeText = t(translationArgs.key, translationArgs.options);
    }

    let ageText = '';
    if (animalContext.animal?.birthday) {
        const translationArgs = DateUtil.getAgeTranslationOptions(
            new Date(animalContext.animal?.birthday),
        );
        ageText = t(translationArgs.key, translationArgs.options);
    }

    return (
        <div className={styles.container}>
            <div className={styles.profilePicture}>
                <div className={styles.profileIcon}>
                    <ProfileIcon
                        size={180}
                        fileDescription={animalOrAnimalGroup.imageFile}
                        colorBundle={animalOrAnimalGroup.colorBundle}
                    />
                </div>
                <div className={styles.profileName}>
                    <p>{animalOrAnimalGroup.name}</p>
                </div>
            </div>
            <div className={styles.media}>
                <div className={styles.title} onClick={onShowMedia}>
                    <span>{t('screens.chat.detail.animalContext.media')}</span>
                    <Icon size={16} name="chevron-forward-outline" />
                </div>
                <div className={styles.preview}>
                    {!chatMessagesWithImageAndVideoFiles ||
                    chatMessagesWithImageAndVideoFiles.length === 0 ? (
                        <div className={styles.detail}>
                            <span>
                                {t(`screens.chat.detail.notMediaAvailable`)}
                            </span>
                        </div>
                    ) : (
                        chatMessagesWithImageAndVideoFiles
                            .slice(0, 3)
                            .map(message => {
                                return (
                                    <PreviewItemMedia
                                        className={styles.previewItem}
                                        key={message.id}
                                        message={message}
                                        onClick={() =>
                                            publishShowPreviewMessages(
                                                chatMessagesWithImageAndVideoFiles,
                                                message,
                                            )
                                        }
                                    />
                                );
                            })
                    )}
                </div>
            </div>

            <div className={styles.address}>
                <div className={styles.title}>
                    <span>{t('screens.chat.detail.animalContext.type')}</span>
                </div>
                <div className={styles.detail}>
                    <span>
                        {t(`common.animalTypes.${animalOrAnimalGroup.type}`)}
                    </span>
                </div>
            </div>
            <div className={styles.info}>
                <div className={styles.title}>
                    <span>{t('screens.chat.detail.animalContext.breed')}</span>
                </div>
                {animalOrAnimalGroup.breed
                    ? animalOrAnimalGroup.breed
                    : t('common.undefined')}
            </div>
            <div className={styles.info}>
                <div className={styles.title}>
                    <span>
                        {t('screens.chat.detail.animalContext.specification')}
                    </span>
                </div>
                <div className={styles.detail}>
                    {animalOrAnimalGroup.specification
                        ? animalOrAnimalGroup.specification
                        : t('common.undefined')}
                </div>
            </div>

            {animalContext.animal && (
                <>
                    <div className={styles.info}>
                        <div className={styles.title}>
                            <span>
                                {t(
                                    'screens.chat.detail.animalContext.birthday',
                                )}
                            </span>
                        </div>
                        <div className={styles.detail}>
                            {animalContext.animal.birthday
                                ? DateUtil.toDDMMYYYY(
                                      animalContext.animal.birthday,
                                  )
                                : t('common.undefined')}
                        </div>
                    </div>
                    <div className={styles.info}>
                        <div className={styles.title}>
                            <span>
                                {t('screens.chat.detail.animalContext.age')}
                            </span>
                        </div>
                        <div className={styles.detail}>
                            {animalContext.animal.birthday
                                ? ageText
                                : t('common.undefined')}
                        </div>
                    </div>
                    {animalContext.animal.birthday && (
                        <div className={styles.info}>
                            <div className={styles.title}>
                                <span>
                                    {t(
                                        'screens.chat.detail.animalContext.ageIsEstimated',
                                    )}
                                </span>
                            </div>
                            <div className={styles.detail}>
                                {animalContext.animal.isBirthdayEstimated
                                    ? t('common.yes')
                                    : t('common.no')}
                            </div>
                        </div>
                    )}
                </>
            )}
            {animalContext.animalGroup && (
                <>
                    <div className={styles.info}>
                        <div className={styles.title}>
                            <span>
                                {`ø-${t(
                                    'screens.chat.detail.animalContext.birthday',
                                )}`}
                            </span>
                        </div>
                        <div className={styles.detail}>
                            {animalContext.animalGroup.averageBirthday
                                ? DateUtil.toDDMMYYYY(
                                      animalContext.animalGroup.averageBirthday,
                                  )
                                : t('common.undefined')}
                        </div>
                    </div>
                    <div className={styles.info}>
                        <div className={styles.title}>
                            <span>
                                {`ø-${t(
                                    'screens.chat.detail.animalContext.age',
                                )}`}
                            </span>
                        </div>
                        <div className={styles.detail}>
                            {animalContext.animalGroup.averageBirthday
                                ? averageAgeText
                                : t('common.undefined')}
                        </div>
                    </div>
                    <div className={styles.info}>
                        <div className={styles.title}>
                            <span>
                                {t(
                                    'screens.chat.detail.animalContext.groupSize',
                                )}
                            </span>{' '}
                        </div>
                        <div className={styles.detail}>
                            {animalContext.animalGroup.groupSize
                                ? animalContext.animalGroup.groupSize
                                : t('common.undefined')}
                        </div>
                    </div>
                    {animalContext.animalGroup.groupSize != null && (
                        <div className={styles.info}>
                            <div className={styles.title}>
                                <span>
                                    {t(
                                        'screens.chat.detail.animalContext.groupSizeIsEstimated',
                                    )}
                                </span>{' '}
                            </div>
                            <div className={styles.detail}>
                                {animalContext.animalGroup.isGroupSizeEstimated
                                    ? t('common.yes')
                                    : t('common.no')}
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default AnimalContextProfile;

import { gql } from '@apollo/client';
import { ChatMessage } from '../dto';
import { ChatMessageFragments } from '../fragments';

const FullResponse = gql`
    mutation UpdateChatMessage(
        $chatMessage: ChatMessageUpdate!
        $imageResolutions: [ImageResolution!]
    ) {
        updateChatMessage(
            chatMessage: $chatMessage
            imageResolutions: $imageResolutions
        ) {
            ...FullChatMessage
        }
    }
    ${ChatMessageFragments.FullResponse}
`;

export interface UpdateChatMessageData {
    updateChatMessage: ChatMessage;
}

export const UpdateChatMessageMutation = {
    FullResponse,
};

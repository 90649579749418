import { useCallback, useEffect, useState } from 'react';
import RxAuthentication from '../rxjs/RxAuthentication';
import { FileDescriptionWebLoader } from '../utils/fileDescriptionWebLoader';
import { LogClient } from '../__shared/common/logging/logClient';
import {
    getFilepathAndStoreToCache,
    getFilepathFromCache,
    isElectron,
} from '../__shared/electron';
import { FileDescription } from '../__shared/graphql';
import { useObservable } from '../__shared/react';
import { useIsMounted } from './useIsMounted';

interface UseLoadSourceFromApiUrl {
    loading: boolean;
    error: unknown | undefined;
    optimalUrl: string | undefined;
    fallbackUrl: string | undefined;
}

export const useLoadSourceFromApiUrl = (
    fileDescription: FileDescription,
    fileDescriptionWithOptimalResolution?: FileDescription | null,
): UseLoadSourceFromApiUrl => {
    const accessToken = useObservable(RxAuthentication.accessToken$);
    const isMounted = useIsMounted();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<unknown | undefined>(undefined);
    const [optimalUrl, setOptimalUrl] = useState<string | undefined>();
    const [fallbackUrl, setFallbackUrl] = useState<string | undefined>();

    const loadFileDescriptionWithWebLoader = useCallback(
        async (
            accessToken: string,
            _fileDescription: FileDescription,
            isFileWithOptimalResolution: boolean,
        ) => {
            LogClient.debug('Loading file with FileDescriptionWebLoader');
            if (!isMounted()) {
                return;
            }
            setLoading(true);
            setError(undefined);
            const imageLoaderJobResult = FileDescriptionWebLoader.getObjectUrl(
                _fileDescription,
                accessToken,
            );
            imageLoaderJobResult
                .then(result => {
                    if (result.error) {
                        setError(result.error);
                        return;
                    }
                    if (!result.objectUrl) {
                        return;
                    }
                    if (isFileWithOptimalResolution) {
                        setOptimalUrl(result.objectUrl);
                    } else {
                        setFallbackUrl(result.objectUrl);
                    }
                })
                .catch(error => setError(error))
                .finally(() => setLoading(false));
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    const loadFileDescriptionFromDisk = useCallback(
        async (
            accessToken: string,
            _fileDescription: FileDescription,
            _fileDescriptionWithOptimalResolution?: FileDescription | null,
        ) => {
            LogClient.debug('Loading file from disk');

            // Expected behaviour:
            // 1. try finding the optimal file in cache
            // 2. if no optimal file was found try finding some file in cache as fallback
            // 3. if no fallback was found load the smallest image and set as fallback
            // 4. try loading the optimal image
            // 5. store the rest of the file descriptions in cache

            let _optimalUrl: string | undefined;
            let _fallbackUrl: string | undefined;

            setLoading(true);
            setError(undefined);

            let fileDescriptionsToLoad = [_fileDescription];
            if (_fileDescription.children?.length) {
                fileDescriptionsToLoad.push(..._fileDescription.children);
            }

            // 1. try finding optimal file in cache
            if (_fileDescriptionWithOptimalResolution) {
                _optimalUrl = await getFilepathFromCache(
                    _fileDescriptionWithOptimalResolution,
                );
                if (_optimalUrl) {
                    setOptimalUrl(_optimalUrl);
                }
            }

            // 2. try finding some file as fallback in cache
            if (!_optimalUrl) {
                // finding cached fallback
                for (const fileDesc of fileDescriptionsToLoad) {
                    const cachedUrl = await getFilepathFromCache(fileDesc);
                    if (cachedUrl) {
                        _fallbackUrl = cachedUrl;
                        setFallbackUrl(_fallbackUrl);
                        fileDescriptionsToLoad = fileDescriptionsToLoad.filter(
                            fd => fd.id !== fileDesc.id,
                        );
                        break;
                    }
                }
            }

            // 3. load smallest resolution if no fallback or optimal file was set and set as fallback
            if (!_optimalUrl && !_fallbackUrl) {
                const smallestResolution = fileDescriptionsToLoad.reduce(
                    (previous, current) => {
                        if (previous.fileSize && current.fileSize) {
                            return previous.fileSize < current.fileSize
                                ? previous
                                : current;
                        }
                        if (previous.fileSize) {
                            return previous;
                        }
                        return current;
                    },
                );
                if (smallestResolution) {
                    _fallbackUrl = await getFilepathAndStoreToCache(
                        smallestResolution,
                        accessToken,
                    );
                    if (_fallbackUrl) {
                        // smallest might be optimal
                        if (
                            smallestResolution.id ===
                            _fileDescriptionWithOptimalResolution?.id
                        ) {
                            _optimalUrl = _fallbackUrl;
                            setOptimalUrl(_optimalUrl);
                        }
                        setFallbackUrl(_fallbackUrl);
                        fileDescriptionsToLoad = fileDescriptionsToLoad.filter(
                            fd => fd.id !== smallestResolution.id,
                        );
                    }
                }
            }

            // 4. load optimal resolution if not yet set but file description is provided
            if (!_optimalUrl && _fileDescriptionWithOptimalResolution) {
                _optimalUrl = await getFilepathAndStoreToCache(
                    _fileDescriptionWithOptimalResolution,
                    accessToken,
                );
                if (_optimalUrl) {
                    setOptimalUrl(_optimalUrl);
                }
            }

            // at this point either optimal or fallback url is set, so we can stop loading
            // the rest is fire and forget
            setLoading(false);

            // 5. store the rest of the files in cache
            fileDescriptionsToLoad.map(fd =>
                getFilepathAndStoreToCache(fd, accessToken),
            );
        },
        [],
    );

    useEffect(() => {
        if (!accessToken) {
            return;
        }
        if (!isElectron()) {
            loadFileDescriptionWithWebLoader(
                accessToken,
                fileDescriptionWithOptimalResolution ?? fileDescription,
                !!fileDescriptionWithOptimalResolution,
            );
            return;
        }

        loadFileDescriptionFromDisk(
            accessToken,
            fileDescription,
            fileDescriptionWithOptimalResolution,
        );
    }, [
        accessToken,
        fileDescription,
        fileDescriptionWithOptimalResolution,
        loadFileDescriptionWithWebLoader,
        loadFileDescriptionFromDisk,
    ]);

    return {
        loading,
        error,
        optimalUrl,
        fallbackUrl,
    };
};

import React, { useRef } from 'react';
import { ClassNameProp } from '../../types/stylingProps';
import Icon from '../icon/icon';
import styles from './checkBox.module.scss';

interface CheckBoxProps {
    checked: boolean;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const size = 16;

const CheckBox: React.FC<CheckBoxProps & ClassNameProp> = ({
    checked,
    onChange,
    className,
}) => {
    const inputRef = useRef<HTMLInputElement>(null);

    return (
        <div
            className={`${styles.container} ${checked ? styles.checked : ''} ${
                className ?? ''
            }`.trim()}
            onClick={() => inputRef.current?.click()}
            style={{ height: `${size}px`, width: `${size}px` }}>
            <input
                type="checkbox"
                checked={checked}
                onChange={onChange}
                ref={inputRef}
            />
            <div className={styles.checkmark}>
                {checked && (
                    <Icon
                        name="checkmark-outline"
                        size={size}
                        className={styles.icon}
                    />
                )}
            </div>
        </div>
    );
};

export default CheckBox;

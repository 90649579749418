import { Appointment } from '../dto';
import { gql } from '@apollo/client';

const FullResponse = gql`
    mutation ConfirmAppointment($appointmentId: Int!) {
        confirmAppointment(appointmentId: $appointmentId) {
            id
            type
            companyId
            customerId
            employeeId
            from
            to
            isEmergencyService
            createdBy
            canceledAt
            canceledBy
            confirmedAt
            permissions {
                actions {
                    key
                    value
                }
            }
        }
    }
`;

export interface ConfirmAppointmentData {
    confirmAppointment: Appointment;
}

export const ConfirmAppointmentMutation = { FullResponse };

import { Subject } from 'rxjs';

const showDrawerMenuSubject = new Subject<boolean>();
const showDrawerMenu$ = showDrawerMenuSubject.asObservable();

const setShowDrawerMenu = (show: boolean): void => {
    showDrawerMenuSubject.next(show);
};

export const RxDrawerMenu = {
    showDrawerMenu$,
    setShowDrawerMenu,
};

import React from 'react';
import Icon from '../../../../../../components/icon/icon';
import { ChatMessage } from '../../../../../../__shared/graphql';
import styles from './chatMessageCheckmark.module.scss';

interface ChatMessageCheckmarkProps {
    message: ChatMessage;
}

const ChatMessageCheckmark: React.FC<ChatMessageCheckmarkProps> = ({
    message,
}) => {
    const isReceived = message.isReceived || message.isViewed;
    const isViewed = message.isViewed;

    let containerStyle = styles.container;
    let iconName = 'checkmark-outline';

    if (isViewed) {
        containerStyle += ` ${styles.viewed}`;
    }
    if (isReceived) {
        iconName = 'checkmark-done-outline';
    }
    return (
        <div className={containerStyle}>
            <Icon name={iconName} size={17} />
        </div>
    );
};

export default ChatMessageCheckmark;

import React from 'react';
import { Invitation, User } from '../../../../../__shared/graphql';
import Card from '../../../components/card/card';
import InvitationCard from '../invitationCard/invitationCard';
import styles from './invitations.module.scss';

export interface InvitationsProps {
    user?: User;
    isLoading: boolean;
    onAcceptInvitationToBecomeEmployee: (invitation: Invitation) => void;
    onRejectInvitationToBecomeEmployee: (invitation: Invitation) => void;
}

const Invitations: React.FC<InvitationsProps> = ({
    user,
    isLoading,
    onAcceptInvitationToBecomeEmployee,
    onRejectInvitationToBecomeEmployee,
}) => {
    return (
        <div className={styles.cardContainer}>
            <h2>Einladungen</h2>
            {user?.invitations?.length ? (
                user?.invitations
                    ?.filter(i => !!i.employee?.company)
                    ?.map(i => (
                        <InvitationCard
                            className={styles.card}
                            key={`${i.userId}-${i.employeeId}`}
                            // company is set, it has been filtered before
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            company={i.employee!.company!}
                            onAccept={() =>
                                onAcceptInvitationToBecomeEmployee(i)
                            }
                            onReject={() =>
                                onRejectInvitationToBecomeEmployee(i)
                            }
                            canAccept={!!i.canAccept}
                            isLoading={isLoading}
                        />
                    ))
            ) : (
                <Card>
                    Sie haben zur Zeit keine Einladung zu einem Unternehmen
                </Card>
            )}
        </div>
    );
};

export default Invitations;

import { Company, CompanyUpdate } from '../../../../__shared/graphql';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AddressLine from '../../../../components/addressLine/addressLine';
import Icon from '../../../../components/icon/icon';
import ProfileIcon from '../../../../components/profileIcon/profileIcon';
import Switch from '../../../../components/switch/switch';
import { CompanyField, mapCompanyToCompanyUpdate } from './company';
import styles from './companySettings.module.scss';

interface CompanySettingsProps {
    company: Company;
    onEdit: (field: CompanyField) => void;
    canEdit: boolean;
    onSave: (companyUpdate: CompanyUpdate) => void;
}

const CompanySettings: React.FC<CompanySettingsProps> = ({
    company,
    onEdit,
    canEdit,
    onSave,
}) => {
    const { t } = useTranslation();

    return (
        <div className={styles.container}>
            <div className={styles.row}>
                <div className={styles.field}>
                    <ProfileIcon
                        size={72}
                        fileDescription={company?.imageFile}
                        colorBundle={company?.colorBundle}
                        profileInProfileFileDescription={
                            company?.profileInProfileImageFile
                        }
                        profileInProfilePosition="right"
                    />
                </div>
                {canEdit && (
                    <Icon
                        name="pencil-outline"
                        className={styles.editIcon}
                        onClick={() => onEdit('imageFile')}
                    />
                )}
            </div>
            <div className={styles.row}>
                <p className={`${styles.field} ${styles.companyName}`}>
                    {company.name}
                </p>
            </div>
            <div className={styles.row}>
                <div className={styles.field}>
                    <p className={styles.name}>
                        {t(
                            'screens.settings.company.fields.visibleForCustomers',
                        )}
                    </p>
                    <div>
                        <Switch
                            disabled={!canEdit}
                            isOn={!!company.isSearchable}
                            id="company-is-searchable"
                            handleToggle={newIsSearchable => {
                                onSave(
                                    mapCompanyToCompanyUpdate({
                                        ...company,
                                        isSearchable: newIsSearchable,
                                    }),
                                );
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.row}>
                <div className={styles.field}>
                    <p className={styles.name}>
                        {t('screens.settings.company.fields.nametag')}
                    </p>
                    <p className={styles.value}>{company.nametag}</p>
                </div>
                {canEdit && (
                    <div className={styles.editIconContainer}>
                        <Icon
                            name="pencil-outline"
                            className={styles.editIcon}
                            onClick={() => onEdit('nametag')}
                        />
                    </div>
                )}
            </div>

            <div className={styles.row}>
                <div className={styles.field}>
                    <p className={styles.name}>
                        {t('screens.settings.company.fields.type')}
                    </p>
                    <p className={styles.value}>
                        {company?.type
                            ? t(
                                  `screens.settings.company.types.${company.type}`,
                              )
                            : ''}
                    </p>
                </div>
                {canEdit && (
                    <div className={styles.editIconContainer}>
                        <Icon
                            name="pencil-outline"
                            className={styles.editIcon}
                            onClick={() => onEdit('type')}
                        />
                    </div>
                )}
            </div>
            <div className={styles.row}>
                <div className={styles.field}>
                    <p className={styles.name}>
                        {t('screens.settings.company.fields.name')}
                    </p>
                    <p className={styles.value}>{company.name}</p>
                </div>
                {canEdit && (
                    <div className={styles.editIconContainer}>
                        <Icon
                            name="pencil-outline"
                            className={styles.editIcon}
                            onClick={() => onEdit('name')}
                        />
                    </div>
                )}
            </div>
            <div className={styles.row}>
                <div className={styles.field}>
                    <p className={styles.name}>
                        {t('screens.settings.company.fields.description')}
                    </p>
                    <p className={styles.value}>{company.description}</p>
                </div>
                {canEdit && (
                    <div className={styles.editIconContainer}>
                        <Icon
                            name="pencil-outline"
                            className={styles.editIcon}
                            onClick={() => onEdit('description')}
                        />
                    </div>
                )}
            </div>
            <div className={styles.row}>
                <div className={styles.field}>
                    <p className={styles.name}>
                        {t('screens.settings.company.fields.phoneNumber')}
                    </p>
                    <p className={styles.value}>{company.phoneNumber}</p>
                </div>
                {canEdit && (
                    <div className={styles.editIconContainer}>
                        <Icon
                            name="pencil-outline"
                            className={styles.editIcon}
                            onClick={() => onEdit('phoneNumber')}
                        />
                    </div>
                )}
            </div>
            <div className={styles.row}>
                <div className={styles.field}>
                    <p className={styles.name}>
                        {t(
                            'screens.settings.company.fields.emergencyServicePhoneNumber',
                        )}
                    </p>
                    <p className={styles.value}>
                        {company.emergencyServicePhoneNumber}
                    </p>
                </div>
                {canEdit && (
                    <div className={styles.editIconContainer}>
                        <Icon
                            name="pencil-outline"
                            className={styles.editIcon}
                            onClick={() =>
                                onEdit('emergencyServicePhoneNumber')
                            }
                        />
                    </div>
                )}
            </div>
            <div className={styles.row}>
                <div className={styles.field}>
                    <p className={styles.name}>
                        {t('screens.settings.company.fields.address')}
                    </p>
                    <AddressLine
                        address={company.address}
                        className={styles.value}
                    />
                </div>
                {canEdit && (
                    <div className={styles.editIconContainer}>
                        <Icon
                            name="pencil-outline"
                            className={styles.editIcon}
                            onClick={() => onEdit('address')}
                        />
                    </div>
                )}
            </div>
            <div className={styles.row}>
                <div className={styles.field}>
                    <p className={styles.name}>
                        {t('screens.settings.company.fields.website')}
                    </p>
                    <p className={styles.value}>{company.website}</p>
                </div>
                {canEdit && (
                    <div className={styles.editIconContainer}>
                        <Icon
                            name="pencil-outline"
                            className={styles.editIcon}
                            onClick={() => onEdit('website')}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default CompanySettings;

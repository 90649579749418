export const isDefined = <T>(val: T | undefined | null): val is T => {
    return val !== undefined && val !== null;
};

export const isNullOrUndefined = (o: unknown): Boolean =>
    o === null || o === undefined;

export const isBoolean = (o: unknown): o is Boolean => {
    return typeof o === 'boolean';
};

export const isString = (s: unknown): s is string => {
    return typeof s === 'string';
};

export const isNumber = (n: unknown): n is number => {
    return typeof n === 'number';
};

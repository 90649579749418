import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from '../../../../components/button/button';
import CompanyItem from '../../../../components/companyItem/companyItem';
import Icon from '../../../../components/icon/icon';
import UserItem from '../../../../components/userItem/userItem';
import { useAlert } from '../../../../hooks/useAlert';
import { useObservable } from '../../../../hooks/useObservable';
import RxAuthentication from '../../../../rxjs/RxAuthentication';
import RxCompany from '../../../../rxjs/RxCompany';
import RxUserProfile from '../../../../rxjs/RxUserProfile';
import AuthenticationUtil from '../../../../utils/authenticationUtil';
import AnimalChatRoutes from '../../../../utils/routing';
import { isElectron } from '../../../../__shared/electron';
import styles from './welcome.module.scss';

interface WelcomeProps {}

const Welcome: React.FC<WelcomeProps> = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const userId = useObservable(RxAuthentication.userId$);
    const user = useObservable(RxUserProfile.userProfile$);
    const company = useObservable(RxCompany.currentCompany$);
    const alert = useAlert();

    const isProfileComplete =
        !!user?.emailConfirmedAt && !!user.phoneNumberConfirmedAt;

    const isStripeConfigured =
        !!company?.stripeAccountId && !!company.isOnboardedInStripe;

    return (
        <div className={styles.container}>
            <div className={styles.stepContainer}>
                <h3>{t('screens.business.welcome')}</h3>
                <h4>{t('screens.business.animalChatBusinessAccount')}</h4>
                <p>{t('screens.business.threeStepsToStart')}</p>
                <div className={styles.stepRow}>
                    <span className={styles.step}>
                        {t('screens.business.step1')}
                    </span>
                    &nbsp;
                    <span className={styles.text}>
                        {t('screens.business.createUserProfile')}
                    </span>
                    {!!userId && userId > 0 && (
                        <div className={styles.checkmark}>
                            <Icon name="checkmark-outline" size={16} />
                        </div>
                    )}
                </div>
                {!user && (
                    <Button
                        disabled={false}
                        onClick={() => {
                            navigate(
                                AnimalChatRoutes.businessSubs.firstStepsSubs
                                    .termsOfUse,
                            );
                        }}
                        text={t('screens.business.createUserProfile')}
                        className={styles.button}
                    />
                )}
                {!user && (
                    <>
                        <p>{t('screens.business.alreadyHaveAProfile')}</p>
                        <Button
                            disabled={false}
                            onClick={() => {
                                navigate(AnimalChatRoutes.businessSubs.login);
                                AuthenticationUtil.logout(true);
                            }}
                            text={t('screens.business.login')}
                            style="secondary"
                            className={`${styles.button} ${styles.secondaryButton}`}
                        />
                    </>
                )}
                <div className={styles.stepRow}>
                    <span className={styles.step}>
                        {t('screens.business.step2')}
                    </span>
                    &nbsp;
                    <span className={styles.text}>
                        {t('screens.business.completeUserProfile')}
                    </span>
                    {!!user && (
                        <>
                            <div
                                className={
                                    isProfileComplete
                                        ? styles.checkmark
                                        : styles.exclamationMark
                                }>
                                <Icon
                                    name={
                                        isProfileComplete
                                            ? 'checkmark-outline'
                                            : 'alert-outline'
                                    }
                                    size={16}
                                />
                            </div>
                        </>
                    )}
                </div>
                {!user || (!user.firstname && !user.lastname) ? (
                    <Button
                        disabled={!userId || isProfileComplete}
                        onClick={() => {
                            navigate(AnimalChatRoutes.businessSubs.profile);
                        }}
                        text={t('screens.business.completeUserProfile')}
                        style="secondary"
                        className={`${styles.button} ${styles.secondaryButton}`}
                    />
                ) : (
                    <div
                        className={styles.listItem}
                        onClick={() =>
                            navigate(AnimalChatRoutes.businessSubs.profile)
                        }>
                        <UserItem user={{ ...user }} />
                    </div>
                )}
                <div className={styles.stepRow}>
                    <span className={styles.step}>
                        {t('screens.business.step3')}
                    </span>
                    &nbsp;
                    <span className={styles.text}>
                        {t('screens.business.assignCompany')}
                    </span>
                    {!!company && (
                        <div className={styles.checkmark}>
                            <Icon name="checkmark-outline" size={16} />
                        </div>
                    )}
                </div>
                {!!company && company.name ? (
                    <div
                        className={styles.listItem}
                        onClick={() => {
                            if (isProfileComplete) {
                                navigate(AnimalChatRoutes.businessSubs.company);
                            } else {
                                alert.error(
                                    'common.alerts.pendingAuthentication',
                                );
                            }
                        }}>
                        <CompanyItem company={company} />
                    </div>
                ) : (
                    <>
                        <Button
                            onClick={() => {
                                navigate(AnimalChatRoutes.businessSubs.company);
                            }}
                            disabled={
                                !userId ||
                                !user?.emailConfirmedAt ||
                                !user.phoneNumberConfirmedAt ||
                                !!company
                            }
                            text={t('screens.business.createCompany')}
                            style="secondary"
                            className={`${styles.button} ${styles.secondaryButton}`}
                        />
                        <p>{t('screens.business.or')}</p>
                        <Button
                            onClick={() => {
                                navigate(AnimalChatRoutes.businessSubs.company);
                            }}
                            disabled={
                                !userId ||
                                !user?.emailConfirmedAt ||
                                !user.phoneNumberConfirmedAt ||
                                !!company
                            }
                            text={t('screens.business.acceptInvitation')}
                            style="secondary"
                            className={`${styles.button} ${styles.secondaryButton}`}
                        />
                    </>
                )}
                <div className={styles.stepRow}>
                    <span className={styles.step}>
                        {t('screens.business.step4')}
                    </span>
                    &nbsp;
                    <span className={styles.text}>
                        <span>{t('screens.business.installDashboard')}</span>
                    </span>
                </div>
                <Button
                    onClick={() => {
                        navigate(AnimalChatRoutes.businessSubs.downloads);
                    }}
                    text={t('screens.business.downloadSetup')}
                    style="secondary"
                    className={`${styles.button} ${styles.secondaryButton}`}
                />
                <div className={styles.stepRow}>
                    <span className={styles.step}>
                        {t('screens.business.step5')}
                    </span>
                    &nbsp;
                    <span className={styles.text}>
                        <span>
                            {t('screens.business.configurePaymentOptional')}
                        </span>
                    </span>
                    {!!company && (
                        <div
                            className={
                                isStripeConfigured
                                    ? styles.checkmark
                                    : styles.exclamationMark
                            }>
                            <Icon
                                name={
                                    isStripeConfigured
                                        ? 'checkmark-outline'
                                        : 'alert-outline'
                                }
                                size={16}
                            />
                        </div>
                    )}
                </div>
                <Button
                    onClick={() => {
                        if (isProfileComplete) {
                            navigate(AnimalChatRoutes.businessSubs.payment);
                        } else {
                            alert.error('common.alerts.pendingAuthentication');
                        }
                    }}
                    text={t('screens.business.configurePayment')}
                    style="secondary"
                    disabled={!company}
                    className={`${styles.button} ${styles.secondaryButton}`}
                />
                {!isElectron() && isProfileComplete && !!company && (
                    <>
                        <div className={styles.stepRow}>
                            <span className={styles.step}>
                                {t('screens.business.finishedConfiguration')}
                            </span>
                            &nbsp;
                        </div>
                        <span className={styles.text}>
                            {t(
                                'screens.business.enableVisibilityForCustomersIfReady',
                            )}
                        </span>
                    </>
                )}
                {isElectron() && isProfileComplete && (
                    <>
                        <div className={styles.stepRow}>
                            <span className={styles.step}>
                                {t('screens.business.goToApp')}
                            </span>
                        </div>
                        <Button
                            onClick={() => {
                                navigate(AnimalChatRoutes.app);
                            }}
                            text={t(
                                'screens.business.youCanOpenTheMessengerAppNow',
                            )}
                            style="primary"
                            className={`${styles.button} ${styles.secondaryButton}`}
                        />
                    </>
                )}
            </div>
            <div className={styles.imgContainer}>
                <img src="/images/vet.svg" alt="vet logo" />
                <img
                    src="/images/pet.svg"
                    alt="pet logo"
                    className={styles.petImg}
                />
            </div>
        </div>
    );
};

export default Welcome;

import { Customer, Employee, User, UserPrivate } from '../__shared/graphql';

const TwoOrMoreConsecutiveSpacesRegex = /(\s{2,})/g;

export const buildNameFromUser = (user?: User | UserPrivate | null): string => {
    return buildName(user?.title, user?.firstname, user?.lastname);
};

export const buildNameFromEmployee = (employee?: Employee | null): string =>
    buildName(
        employee?.contact?.title,
        employee?.contact?.firstname,
        employee?.contact?.lastname,
    );

export const buildNameFromCustomer = (customer?: Customer | null): string => {
    return buildName(
        customer?.contact?.title ?? customer?.user?.title,
        customer?.contact?.firstname ?? customer?.user?.firstname,
        customer?.contact?.lastname ?? customer?.user?.lastname,
    );
};

export const buildNameFromUserCustomerOrEmployee = (args: {
    user?: User | null;
    employee?: Employee | null;
    customer?: Customer | null;
}): string => {
    let name = buildNameFromUser(args.user);
    if (!name && args.customer) {
        name = buildNameFromCustomer(args.customer);
    }
    if (!name && args.employee) {
        name = buildNameFromEmployee(args.employee);
    }
    return name;
};

export const buildName = (
    title?: string | null,
    firstname?: string | null,
    lastname?: string | null,
): string =>
    `${title ?? ''} ${firstname ?? ''} ${lastname ?? ''}`
        .replace(TwoOrMoreConsecutiveSpacesRegex, ' ')
        .trim();

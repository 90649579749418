import { MutationUpdateActivationCodeSmsArgs } from '../../../../__shared/graphql';
import { useMutation } from '@apollo/client';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from '../../../../components/button/button';
import PhoneNumberInput from '../../../../components/phoneNumberInput/phoneNumberInput';
import {
    UpdateActivationCodeSmsData,
    UserMutations,
} from '../../../../graphql/mutations/userMutations';
import { useErrorAlert } from '../../../../hooks/useErrorAlert';
import { useObservable } from '../../../../hooks/useObservable';
import { RxCreateAccount } from '../../../../rxjs/RxCreateAccount';
import RxUserProfile from '../../../../rxjs/RxUserProfile';
import { Limits } from '../../../../utils/inputLimits';
import logUtil from '../../../../utils/logUtil';
import AnimalChatRoutes from '../../../../utils/routing';
import { isValidRequiredPhoneNumberWithCountryCodePrefixAndLimit } from '../../../../utils/validationUtil';
import CardHeader from '../../components/cardHeader/cardHeader';
import RegisterCard from '../../components/registerCard/registerCard';
import styles from './phone.module.scss';

const Phone: React.FC = () => {
    const user = useObservable(RxUserProfile.userProfile$);
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { alertError, alertGraphQlError } = useErrorAlert();

    const phoneNumberForVerification = useObservable<string>(
        RxCreateAccount.phoneNumberForVerification$,
        '',
    );

    const [phoneNumber, setPhoneNumber] = useState<string>('');

    useEffect(() => {
        setPhoneNumber(phoneNumberForVerification);
    }, [phoneNumberForVerification]);

    const onPhoneNumberChanged = (value: string) => {
        RxCreateAccount.setPhoneNumberForVerification(value);
    };

    const [updateActivationCodeSmsMutation] = useMutation<
        UpdateActivationCodeSmsData,
        MutationUpdateActivationCodeSmsArgs
    >(UserMutations.UpdateActivationCodeSms);

    const handleNext = useCallback(
        async (_phoneNumber: string) => {
            setIsLoading(true);
            try {
                const { data, errors } = await updateActivationCodeSmsMutation({
                    variables: {
                        phoneNumber: _phoneNumber,
                    },
                });
                if (errors?.length) {
                    alertGraphQlError(errors);
                    return;
                }
                if (data?.updateActivationCodeSms) {
                    navigate(
                        AnimalChatRoutes.businessSubs.firstStepsSubs
                            .verifyPhone,
                    );
                }
            } catch (e) {
                logUtil.error(e);
                alertError(e);
            } finally {
                setIsLoading(false);
            }
        },
        [
            navigate,
            alertError,
            alertGraphQlError,
            updateActivationCodeSmsMutation,
        ],
    );

    const handleBack = () => {
        if (user?.isActive) {
            navigate(AnimalChatRoutes.businessSubs.firstStepsSubs.person);
            return;
        }
        navigate(AnimalChatRoutes.businessSubs.firstStepsSubs.verifyEmail);
    };

    return (
        <RegisterCard>
            <CardHeader
                title={t('screens.business.phone.title')}
                onBack={handleBack}
            />
            <p className={styles.text}>{t('screens.business.phone.text')}</p>
            <div className={styles.form}>
                <PhoneNumberInput
                    id="business-verify-email"
                    value={phoneNumber}
                    onChange={onPhoneNumberChanged}
                    inputProps={{
                        disabled: isLoading,
                        placeholder: t('screens.business.phone.placeholder'),
                        className: styles.inputContainer,
                        limit: Limits.PhoneNumber,
                    }}
                />
            </div>
            <Button
                disabled={
                    !isValidRequiredPhoneNumberWithCountryCodePrefixAndLimit(
                        Limits.PhoneNumber,
                        phoneNumber,
                    ) || isLoading
                }
                className={styles.button}
                text={t('common.buttons.send')}
                onClick={() => handleNext(phoneNumber)}
            />
        </RegisterCard>
    );
};

export default Phone;

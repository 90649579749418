export type ChatType =
    | 'personal'
    | 'internal'
    | 'internal-group'
    | 'open'
    | 'broadcast';

interface IChatTypes {
    Personal: 'personal';
    Internal: 'internal';
    InternalGroup: 'internal-group';
    Open: 'open';
    Broadcast: 'broadcast';
}
export const ChatTypes: IChatTypes = {
    Personal: 'personal',
    Internal: 'internal',
    InternalGroup: 'internal-group',
    Open: 'open',
    Broadcast: 'broadcast',
};

import { User, UserFragments } from '../../__shared/graphql';
import { gql } from '@apollo/client';

const GetUserFull = gql`
    query getUser($userId: Int!) {
        getUser(userId: $userId) {
            ...FullUserWithInvitationAndLicences
        }
    }
    ${UserFragments.FullUserWithInvitationAndLicences}
`;

export const UserQueries = {
    GetUser: {
        Full: GetUserFull,
    },
};

export interface GetUserData {
    getUser: User | null;
}

import {
    MutationCreateUserArgs,
    MutationLoginWithEmailOrUsernameArgs,
    MutationSendActivationCodeEmailArgs,
    MutationUpdateUserArgs,
} from '../../../../__shared/graphql';
import { useMutation } from '@apollo/client';
import jwt_decode from 'jwt-decode';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from '../../../../components/button/button';
import Input from '../../../../components/input/input';
import Select from '../../../../components/select/select';
import {
    CreateUserData,
    LoginWithEmailOrUsernameData,
    SendActivationCodeEmailData,
    UpdateUserData,
    UserMutations,
} from '../../../../graphql/mutations/userMutations';
import { useErrorAlert } from '../../../../hooks/useErrorAlert';
import { useObservable } from '../../../../hooks/useObservable';
import RxAuthentication, {
    DecodedJwt,
} from '../../../../rxjs/RxAuthentication';
import { RxCreateAccount } from '../../../../rxjs/RxCreateAccount';
import RxUserProfile from '../../../../rxjs/RxUserProfile';
import { Limits } from '../../../../utils/inputLimits';
import logUtil from '../../../../utils/logUtil';
import { Option, SalutationOptions } from '../../../../utils/option';
import AnimalChatRoutes from '../../../../utils/routing';
import {
    isValidRequiredValueWithLimit,
    isValidValueWithLimit,
} from '../../../../utils/validationUtil';
import CardHeader from '../../components/cardHeader/cardHeader';
import RegisterCard from '../../components/registerCard/registerCard';
import { RegisterBusinessFormTextKey, RegisterFormData } from './createAccount';
import styles from './person.module.scss';

const Person: React.FC = () => {
    const createAccountForm = useObservable<RegisterFormData | undefined>(
        RxCreateAccount.createAccountForm$,
    );

    const [form, setForm] = useState<RegisterFormData>({
        email: '',
        email2: '',
        password: '',
        password2: '',
        gender: '',
        title: '',
        firstname: '',
        lastname: '',
        isEmailActivated: false,
        phone: '',
        isPhoneActivated: false,
    });

    useEffect(() => {
        if (createAccountForm) {
            setForm(createAccountForm);
        }
    }, [createAccountForm]);

    const onUpdateValue = (key: RegisterBusinessFormTextKey, value: string) => {
        RxCreateAccount.setCreateAccountForm({
            ...form,
            [key]: value,
        });
    };

    const { t } = useTranslation();
    const navigate = useNavigate();
    const userId = useObservable(RxAuthentication.userId$);
    const [isLoading, setIsLoading] = useState(false);
    const { alertError, alertGraphQlError } = useErrorAlert();

    const [createUserMutation] = useMutation<
        CreateUserData,
        MutationCreateUserArgs
    >(UserMutations.CreateUser);

    const [updateUserMutation] = useMutation<
        UpdateUserData,
        MutationUpdateUserArgs
    >(UserMutations.UpdateUser);

    const [loginMutation] = useMutation<
        LoginWithEmailOrUsernameData,
        MutationLoginWithEmailOrUsernameArgs
    >(UserMutations.LoginWithEmailOrUsername);

    const [sendEmailCodeMutation] = useMutation<
        SendActivationCodeEmailData,
        MutationSendActivationCodeEmailArgs
    >(UserMutations.SendActivationCodeEmail);

    const createUser = useCallback(
        async (form: RegisterFormData) => {
            setIsLoading(true);
            try {
                const createResult = await createUserMutation({
                    variables: {
                        user: {
                            firstname: form.firstname,
                            lastname: form.lastname,
                            username: form.email,
                            email: form.email,
                            title: form.title,
                            password: form.password,
                            gender: form.gender,
                        },
                    },
                });

                const { data, errors } = createResult;
                if (errors?.length) {
                    alertGraphQlError(errors);
                    return;
                }
                if (data?.createUser) {
                    RxUserProfile.setUser(data.createUser);
                }

                const loginResult = await loginMutation({
                    variables: {
                        login: {
                            emailOrUsername: form.email,
                            password: form.password,
                        },
                    },
                });
                if (loginResult.data?.loginWithEmailOrUsername) {
                    const accessToken =
                        loginResult.data.loginWithEmailOrUsername;
                    const decodedToken: DecodedJwt = jwt_decode(accessToken);
                    RxAuthentication.setAccessToken(accessToken);
                    await sendEmailCodeMutation({
                        variables: {
                            userId: decodedToken.userId,
                        },
                    });
                }
                if (loginResult.errors?.length) {
                    alertGraphQlError(loginResult.errors);
                    return;
                }

                navigate(
                    AnimalChatRoutes.businessSubs.firstStepsSubs.verifyEmail,
                );
            } catch (e) {
                logUtil.error(e);
                alertError(e);
            } finally {
                setIsLoading(false);
            }
        },
        [
            createUserMutation,
            loginMutation,
            alertError,
            alertGraphQlError,
            sendEmailCodeMutation,
            navigate,
        ],
    );

    const updateUser = useCallback(
        async (form: RegisterFormData, _userId: number) => {
            setIsLoading(true);
            try {
                const { data, errors } = await updateUserMutation({
                    variables: {
                        user: {
                            id: _userId,
                            gender: form.gender,
                            firstname: form.firstname,
                            lastname: form.lastname,
                        },
                    },
                });
                if (errors?.length) {
                    alertGraphQlError(errors);
                    return;
                }
                if (data?.updateUser) {
                    RxUserProfile.setUser(data.updateUser);
                }
                if (data?.updateUser.isActive) {
                    navigate(
                        AnimalChatRoutes.businessSubs.firstStepsSubs.phone,
                    );
                    return;
                }
                navigate(
                    AnimalChatRoutes.businessSubs.firstStepsSubs.verifyEmail,
                );
            } catch (e) {
                logUtil.error(e);
                alertError(e);
            } finally {
                setIsLoading(false);
            }
        },
        [alertError, updateUserMutation, alertGraphQlError, navigate],
    );

    const onNext = () => {
        if (userId) {
            updateUser(form, userId);
            return;
        }
        createUser(form);
    };

    const onBack = () => {
        if (userId) {
            navigate(AnimalChatRoutes.businessSubs.firstStepsSubs.termsOfUse);
            return;
        }
        navigate(AnimalChatRoutes.businessSubs.firstStepsSubs.createAccount);
    };

    return (
        <RegisterCard>
            <CardHeader
                title={t('screens.business.person.header')}
                onBack={onBack}
            />
            <div className={styles.form}>
                <Select
                    name={'gender'}
                    options={SalutationOptions.map(option => ({
                        label: t(option.label),
                        value: option.value,
                    }))}
                    isRequired
                    defaultValue={form.gender}
                    onChange={option => {
                        if (option) {
                            // since this is no multi select we can treat this as a single option
                            onUpdateValue('gender', (option as Option).value);
                        }
                    }}
                />
                <Input
                    className={styles.input}
                    id="register-title"
                    value={form.title}
                    placeholder={t('screens.business.person.title')}
                    onChange={e => onUpdateValue('title', e.target.value)}
                    limit={Limits.Title}
                />
                <Input
                    className={styles.input}
                    id="register-firstname"
                    value={form.firstname}
                    placeholder={`${t('screens.business.person.firstname')}*`}
                    onChange={e => onUpdateValue('firstname', e.target.value)}
                    limit={Limits.Firstname}
                />
                <Input
                    className={styles.input}
                    id="register-lastname"
                    value={form.lastname}
                    placeholder={`${t('screens.business.person.lastname')}*`}
                    limit={Limits.Lastname}
                    onChange={e => onUpdateValue('lastname', e.target.value)}
                />
            </div>
            <Button
                text={t('common.buttons.next')}
                disabled={
                    !isValidRequiredValueWithLimit(
                        Limits.Firstname,
                        form.firstname,
                    ) ||
                    !isValidRequiredValueWithLimit(
                        Limits.Lastname,
                        form.lastname,
                    ) ||
                    !isValidValueWithLimit(Limits.Title, form.title) ||
                    !form.gender.length ||
                    isLoading
                }
                className={styles.button}
                onClick={() => onNext()}
            />
            <p className={styles.legend}>
                {t('screens.business.requiredField')}
            </p>
        </RegisterCard>
    );
};

export default Person;

import { isNumber } from '../__shared/common';

export const PhoneNumberWithCountryCodeRegex = /(\+49){1}[0-9]+$/;
export const CountryCodeGermany = '+49';
export const EmailRegex =
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;

export const isValidValueWithLimit = (
    limit: number,
    value?: string | null,
): boolean => {
    if (!value) {
        return true;
    }
    return value.length <= limit;
};

export const isValidRequiredValueWithLimit = (
    limit: number,
    value: string,
): boolean => {
    if (!value.length) {
        return false;
    }
    return value.length <= limit;
};

export const isValidPhoneNumberWithCountryCodePrefixAndLimit = (
    limit: number,
    value: string,
): boolean => {
    if (value === '') {
        return true;
    }
    if (value === CountryCodeGermany) {
        return true;
    }
    if (value.length > limit + CountryCodeGermany.length) {
        return false;
    }
    return PhoneNumberWithCountryCodeRegex.test(value);
};

export const isValidRequiredPhoneNumberWithCountryCodePrefixAndLimit = (
    limit: number,
    value: string,
): boolean => {
    if (value.length > limit + CountryCodeGermany.length) {
        return false;
    }
    return PhoneNumberWithCountryCodeRegex.test(value);
};

export const isValidEmailAddress = ({
    email,
    limit,
}: {
    email?: string | null;
    limit?: number;
}): boolean => {
    if (!email) {
        return false;
    }
    if (isNumber(limit) && email.length > limit) {
        return false;
    }
    return EmailRegex.test(email);
};

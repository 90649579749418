import { gql } from '@apollo/client';
import { ChatMessage } from '../dto';
import { ChatMessageFragments } from '../fragments';

const FullResponse = gql`
    query GetChatMessage($chatMessageId: Int!) {
        getChatMessage(chatMessageId: $chatMessageId) {
            ...FullChatMessage
        }
    }
    ${ChatMessageFragments.FullResponse}
`;

export interface GetChatMessageData {
    getChatMessage: ChatMessage;
}

export const GetChatMessageQuery = { FullResponse };

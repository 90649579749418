import { gql } from '@apollo/client';
import { ChatFragments } from '../fragments/chatFragments';

const ChatForChatListEntry = gql`
    query FindChatsForUser(
        $searchInput: String!
        $sortOrder: String
        $pageSize: Int
        $idCursor: Int
        $animalId: Int
        $animalGroupId: Int
        $companyId: Int
        $topicId: Int
        $userId: Int
        $type: String
    ) {
        findChatsForUser(
            searchInput: $searchInput
            sortOrder: $sortOrder
            pageSize: $pageSize
            idCursor: $idCursor
            animalId: $animalId
            animalGroupId: $animalGroupId
            companyId: $companyId
            topicId: $topicId
            userId: $userId
            type: $type
        ) {
            ...ChatForChatListEntryFragment
        }
    }
    ${ChatFragments.ChatForChatListEntryFragment}
`;
//#endregion graphql

export const FindChatsForUserQueries = { ChatForChatListEntry };

import { ServiceHours } from '../dto';
import { gql } from '@apollo/client';
import { ServiceHoursFragments } from '../fragments';

const FullResponse = gql`
    mutation createServiceHours($serviceHours: ServiceHoursCreate!) {
        createServiceHours(serviceHours: $serviceHours) {
            ...FullServiceHours
        }
    }
    ${ServiceHoursFragments.FullServiceHours}
`;

export interface CreateServiceHoursData {
    createServiceHours: ServiceHours;
}

export const CreateServiceHoursMutation = {
    FullResponse,
};

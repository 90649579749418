import { gql } from '@apollo/client';

const FullResponse = gql`
    query GetStripeConnectDashboardLink($accountId: String!) {
        getStripeConnectDashboardLink(accountId: $accountId)
    }
`;

export interface GetStripeDashboardLinkData {
    getStripeConnectDashboardLink: string | null | undefined;
}

export const GetStripeDashboardLinkQuery = { FullResponse };

import { gql } from '@apollo/client';
import { Customer } from '../dto';
import { CustomerFragments } from '../fragments';

export interface GetCustomerForUserAndCompanyData {
    getCustomerForUserAndCompany: Customer;
}

const FullResponse = gql`
    query GetCustomerForUserAndCompany($userId: Int!, $companyId: Int!) {
        getCustomerForUserAndCompany(userId: $userId, companyId: $companyId) {
            ...CustomerWithUserAndContactAndAddressAndCompany
        }
    }
    ${CustomerFragments.CustomerWithUserAndContactAndAddressAndCompany}
`;

export const GetCustomerForUserAndCompanyQuery = {
    FullResponse,
};

import { Subject } from 'rxjs';
import { ModalProps } from './../components/modal/modal';

const onShowModalSubject = new Subject<ModalProps>();

export const showModal = (props: ModalProps): void => {
    onShowModalSubject.next(props);
};

export const onShowModal$ = onShowModalSubject.asObservable();

const onCloseModalSubject = new Subject<boolean>();
export const closeModal = (response: boolean): void => {
    onCloseModalSubject.next(response);
};

export const onCloseModal$ = onCloseModalSubject.asObservable();

import { FileDescription } from '../__shared/graphql';
import { BehaviorSubject, Observable } from 'rxjs';

type Rx = {
    subject: BehaviorSubject<FileDescription | undefined>;
    observable$: Observable<FileDescription | undefined>;
};

const rxMap = new Map<string, Rx>();

const addRxForUuid = (uuid: string): Rx => {
    const subject = new BehaviorSubject<FileDescription | undefined>(undefined);
    const observable$ = subject.asObservable();
    const rx = { subject, observable$ };
    rxMap.set(uuid, rx);
    return rx;
};

const getCacheUpdateObservableForFileUuid = (
    uuid: string,
): Observable<FileDescription | undefined> => {
    let rx = rxMap.get(uuid);
    if (!rx) {
        rx = addRxForUuid(uuid);
    }
    return rx.observable$;
};

const triggerUpdateForFileUuid = (fileDesc: FileDescription): void => {
    let rx = rxMap.get(fileDesc.uuid);
    if (!rx) {
        rx = addRxForUuid(fileDesc.uuid);
    }
    rx.subject.next(fileDesc);
};

const RxFileCache = {
    getCacheUpdateObservableForFileUuid,
    triggerUpdateForFileUuid,
};

export default RxFileCache;

import React, { ReactNode } from 'react';
import { CssInterface } from '../../constants';

import styles from './menuEntry.module.scss';

interface MenuEntryProps {
  title?: string;
  icon: ReactNode;
  active?: boolean;
  messageCount?: number;
  onClick?: () => void;
  className?: CssInterface;
}

const MenuEntry: React.FC<MenuEntryProps> = ({
  title,
  icon,
  active,
  messageCount,
  onClick,
  className,
}) => {
  let cssStyles: string = styles.menuEntry;
  if (active) {
    cssStyles += ` ${styles.active}`;
  }
  if (className) {
    cssStyles += ` ${className}`;
  }

  const messageCountBubble = (
    <div className={styles.messageCount}>{messageCount}</div>
  );

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <div className={cssStyles} onClick={handleClick}>
      {!!messageCount && messageCountBubble}
      {icon}
      {title && <span>{title}</span>}
    </div>
  );
};

export default MenuEntry;

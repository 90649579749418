import { gql } from '@apollo/client';
import { AnimalContext } from '../dto';
import { AnimalFragments, AnimalGroupFragments } from '../fragments';

export const ON_ANIMALCONTEXT_UPDATE_FOR_USER = gql`
    subscription OnAnimalContextUpdateForUser($userId: Int!) {
        onAnimalContextUpdateForUser(userId: $userId) {
            id
            animal {
                ...Animal
            }
            animalGroup {
                ...AnimalContext
            }
            createdAt
        }
    }
    ${AnimalFragments.FullAnimal}
    ${AnimalGroupFragments.FullAnimalGroup}
`;

export interface OnAnimalContextUpdateForUserData {
    onAnimalContextUpdateForUser: AnimalContext;
}

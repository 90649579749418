import { FileDescription } from '../dto';
import { gql } from '@apollo/client';
import { FileDescriptionFragments } from '../fragments';

const FullResponse = gql`
    mutation CreateResolutionsForFileDescription(
        $id: Int!
        $resolutions: [ImageResolution!]
    ) {
        createResolutionsForFileDescription(
            id: $id
            resolutions: $resolutions
        ) {
            ...FullFileDescription
        }
    }
    ${FileDescriptionFragments.FullResponse}
`;

export interface CreateResolutionsForFileDescriptionData {
    createResolutionsForFileDescription: FileDescription;
}

export const CreateResolutionsForFileDescriptionMutation = {
    FullResponse,
};

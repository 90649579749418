import { BehaviorSubject } from 'rxjs';

interface UpdateNoticeTexts {
    header: string;
    message: string;
    buttonText: string;
}

type UpdateNoticeType = 'shell' | 'web';

export interface UpdateNoticeProps {
    visible: boolean;
    translationKeys: UpdateNoticeTexts;
    updateType?: UpdateNoticeType;
}

const initialProps: UpdateNoticeProps = {
    visible: false,
    translationKeys: {
        buttonText: '',
        header: '',
        message: '',
    },
};

const updateNoticeSubject = new BehaviorSubject<UpdateNoticeProps>(
    initialProps,
);
const updateNoticeProps$ = updateNoticeSubject.asObservable();

const showUpdateNotice = (
    translationKeys: UpdateNoticeTexts,
    updateType: UpdateNoticeType,
): void => {
    updateNoticeSubject.next({ visible: true, translationKeys, updateType });
};

const hideUpdateNotice = (): void => {
    updateNoticeSubject.next(initialProps);
};

export const RxUpdateNotice = {
    showUpdateNotice,
    hideUpdateNotice,
    updateNoticeProps$,
};

import { Address } from '../dto';
import { gql } from '@apollo/client';

const FullResponse = gql`
    query GetAddress($addressId: Int!) {
        getAddress(addressId: $addressId) {
            streetName
            houseNumber
            zipCode
            city
            country
            createdAt
            updatedAt
        }
    }
`;

export interface GetAddressData {
    getAddress: Address;
}

export const GetAddressQuery = {
    FullResponse: FullResponse,
};

import { useCallback } from 'react';
import { first } from 'rxjs';
import { ModalProps } from './../components/modal/modal';
import { onCloseModal$, showModal } from './../rxjs/onShowModalSubject';

interface UseModalHook {
  show: (modalProps: ModalProps) => Promise<boolean>;
}

// TODO: we might introduce a stack to display multiple modals at once
const useModal = (): UseModalHook => {
  const show = useCallback(async (modalProps: ModalProps): Promise<boolean> => {
    return new Promise((resolve) => {
      // the first operator automatically unsubscribes after the first value is emitted
      onCloseModal$.pipe(first()).subscribe((result) => resolve(result));
      showModal(modalProps);
    });
  }, []);

  return { show };
};

export default useModal;

import { gql } from '@apollo/client';
import { LicenceToCompanyFragments } from '../fragments';
import { LicenceToCompany } from './../dto';

const BuyLicenceMutation = gql`
    mutation buyLicence($companyId: Int!, $licenceType: String!) {
        buyLicence(companyId: $companyId, licenceType: $licenceType) {
            ...LicenceToCompanyWithLicenceAndUser
        }
    }
    ${LicenceToCompanyFragments.LicenceToCompanyWithLicenceAndUser}
`;

const CancelLicenceMutation = gql`
    mutation CancelLicence($licenceToCompanyId: Int!) {
        cancelLicence(licenceToCompanyId: $licenceToCompanyId) {
            ...LicenceToCompanyWithLicenceAndUser
        }
    }
    ${LicenceToCompanyFragments.LicenceToCompanyWithLicenceAndUser}
`;

const AssignLicenceMutation = gql`
    mutation AssignLicence($licenceToCompanyId: Int!, $employeeId: Int!) {
        assignLicence(
            licenceToCompanyId: $licenceToCompanyId
            employeeId: $employeeId
        ) {
            ...LicenceToCompanyWithLicenceAndUser
        }
    }
    ${LicenceToCompanyFragments.LicenceToCompanyWithLicenceAndUser}
`;

const RemoveUserFromLicenceMutation = gql`
    mutation RemoveUserFromLicence($licenceToCompanyId: Int!) {
        removeUserFromLicence(licenceToCompanyId: $licenceToCompanyId) {
            ...LicenceToCompanyWithLicenceAndUser
        }
    }
    ${LicenceToCompanyFragments.LicenceToCompanyWithLicenceAndUser}
`;

const RevokeLicenceCancellationMutation = gql`
    mutation RevokeLicenceCancellation($licenceToCompanyId: Int!) {
        revokeLicenceCancellation(licenceToCompanyId: $licenceToCompanyId) {
            ...LicenceToCompanyWithLicenceAndUser
        }
    }
    ${LicenceToCompanyFragments.LicenceToCompanyWithLicenceAndUser}
`;

const UpgradeLicenceMutation = gql`
    mutation upgradeLicence($licenceToCompanyId: Int!, $licenceType: String!) {
        upgradeLicence(
            licenceToCompanyId: $licenceToCompanyId
            licenceType: $licenceType
        ) {
            ...LicenceToCompanyWithLicenceAndUser
        }
    }
    ${LicenceToCompanyFragments.LicenceToCompanyWithLicenceAndUser}
`;

export interface AssignLicenceData {
    assignLicence: LicenceToCompany;
}

export interface BuyLicenceData {
    buyLicence: LicenceToCompany;
}

export interface CancelLicenceData {
    cancelLicence: LicenceToCompany;
}

export interface RevokeLicenceCancellationData {
    revokeLicenceCancellation: LicenceToCompany;
}

export interface RemoveUserFromLicenceData {
    removeUserFromLicence: LicenceToCompany;
}

export interface UpgradeLicenceData {
    upgradeLicence: LicenceToCompany;
}

const AssignLicenceMutations = {
    AssignLicenceMutation,
};

const BuyLicenceMutations = {
    BuyLicenceMutation,
};

const CancelLicenceMutations = {
    CancelLicenceMutation,
};

const RemoveUserFromLicenceMutations = {
    RemoveUserFromLicenceMutation,
};

const RevokeLicenceCancellationMutations = {
    RevokeLicenceCancellationMutation,
};

const UpgradeLicenceMutations = {
    UpgradeLicenceMutation,
};

export const CompanyMutations = {
    AssignLicenceMutations,
    BuyLicenceMutations,
    CancelLicenceMutations,
    RemoveUserFromLicenceMutations,
    RevokeLicenceCancellationMutations,
    UpgradeLicenceMutations,
};

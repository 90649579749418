import RxFileCache from '../rxjs/RxFileCache';
import { getFilepathAndStoreToCache } from '../__shared/electron';
import { FileDescription } from '../__shared/graphql';
import { isImageType } from './fileUtils';

export const updateCache = async (
    fileDescription: FileDescription,
    accessToken = '',
): Promise<void> => {
    if (isImageType(fileDescription.fileType) && fileDescription.children) {
        await Promise.all(
            fileDescription.children.map(child =>
                getFilepathAndStoreToCache(child, accessToken),
            ),
        );
        RxFileCache.triggerUpdateForFileUuid(fileDescription);
    }
};

export interface Option<T = string> {
    label: string;
    value: T;
}

export const SalutationOptions: Option<string>[] = [
    // {
    //     label: 'common.pleaseSelect',
    //     value: '',
    // },
    {
        label: 'common.salutations.male',
        value: 'male',
    },
    {
        label: 'common.salutations.female',
        value: 'female',
    },
];

export const CompanyTypeOptions: Option<string>[] = [
    {
        label: 'screens.settings.company.types.vet-practice',
        value: 'vet-practice',
    },
    {
        label: 'screens.settings.company.types.vet-clinic',
        value: 'vet-clinic',
    },
    {
        label: 'screens.settings.company.types.dog-groomer',
        value: 'dog-groomer',
    },
    {
        label: 'screens.settings.company.types.other',
        value: 'other',
    },
];

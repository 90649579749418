import {
    MutationUpdatePasswordArgs,
    UpdatePasswordData,
    UpdatePasswordMutation,
} from '../../../../__shared/graphql';
import { useMutation } from '@apollo/client';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormField from '../../../../components/form/formField';
import TextButton from '../../../../components/textButton/textButton';
import errorCodes from '../../../../errors/errorCodes';
import { useAlert } from '../../../../hooks/useAlert';
import { Limits } from '../../../../utils/inputLimits';
import styles from './changePassword.module.scss';

interface ChangePasswordForm {
    oldPassword: string;
    newPassword: string;
    newPassword2: string;
}

const initialForm = {
    oldPassword: '',
    newPassword: '',
    newPassword2: '',
};

const MinPasswordLength = 4;

const ChangePassword: React.FC = () => {
    const [form, setForm] = useState<ChangePasswordForm>(initialForm);
    const alert = useAlert();
    const { t } = useTranslation();
    const [
        updatePassword,
        { loading: updateLoading, error: updateError, reset },
    ] = useMutation<UpdatePasswordData, MutationUpdatePasswordArgs>(
        UpdatePasswordMutation.FullUpdateUser,
    );

    const onCancel = () => {
        setForm(initialForm);
    };

    const onSave = useCallback(
        async (formValues: ChangePasswordForm) => {
            if (formValues.newPassword?.length < MinPasswordLength) {
                alert.error(
                    t(
                        'screens.userProfile.changePassword.passwordNotLongEnough',
                        {
                            length: MinPasswordLength,
                        },
                    ),
                );
                return;
            }
            if (formValues.newPassword !== formValues.newPassword2) {
                alert.error(
                    t('screens.userProfile.changePassword.passwordsDontMatch'),
                );
                return;
            }

            try {
                const response = await updatePassword({
                    variables: {
                        newPassword: formValues.newPassword,
                        oldPassword: formValues.oldPassword,
                    },
                });
                if (response.data?.updatePassword) {
                    alert.success('common.alerts.savedChanges');
                    reset();
                    setForm(initialForm);
                }
            } catch (error) {
                console.warn(error);
            }
        },
        [alert, updatePassword, reset, t],
    );

    useEffect(() => {
        if (!updateLoading && updateError) {
            if (updateError?.graphQLErrors?.length) {
                if (
                    updateError.graphQLErrors[0].extensions?.code ===
                    errorCodes.WrongCredentials
                ) {
                    reset();
                    alert.error('errors.wrongCredentials');
                    return;
                }
                console.warn('Error during update password', updateError);
                alert.error('errors.default');
                reset();
            }
        }
    }, [updateLoading, updateError, alert, reset, t]);

    const loading = updateLoading;

    return (
        <div className={styles.container}>
            <FormField
                id="change-password-password-old"
                name={'oldPassword'}
                label={t('screens.userProfile.changePassword.form.oldPassword')}
                value={form.oldPassword}
                type="password"
                updateValue={(name, value) =>
                    setForm(prev => ({ ...prev, [name]: value }))
                }
            />
            <FormField
                id="change-password-password-new"
                name={'newPassword'}
                label={t('screens.userProfile.changePassword.form.newPassword')}
                value={form.newPassword}
                type="password"
                updateValue={(name, value) =>
                    setForm(prev => ({ ...prev, [name]: value }))
                }
                limit={Limits.Password}
            />
            <FormField
                id="change-password-password-new2"
                name={'newPassword2'}
                label={t(
                    'screens.userProfile.changePassword.form.newPassword2',
                )}
                value={form.newPassword2}
                type="password"
                updateValue={(name, value) =>
                    setForm(prev => ({ ...prev, [name]: value }))
                }
                limit={Limits.Password}
            />
            <div className={styles.actions}>
                <TextButton
                    disabled={loading}
                    style="secondary"
                    text={t('common.buttons.cancel')}
                    onClick={onCancel}
                />
                <TextButton
                    disabled={loading}
                    style="primary"
                    text={t('common.buttons.apply')}
                    onClick={() => onSave(form)}
                />
            </div>
        </div>
    );
};

export default ChangePassword;

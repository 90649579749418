import { Appointment } from '../dto';
import { gql } from '@apollo/client';

const FullResponse = gql`
    mutation UpdateAppointment(
        $id: Int!
        $type: String!
        $companyId: Int!
        $customerId: Int!
        $employeeId: Int!
        $from: Date!
        $to: Date!
        $isEmergencyService: Boolean!
    ) {
        updateAppointment(
            appointment: {
                id: $id
                type: $type
                companyId: $companyId
                customerId: $customerId
                employeeId: $employeeId
                from: $from
                to: $to
                isEmergencyService: $isEmergencyService
            }
        ) {
            id
            type
            from
            to
            isEmergencyService
            createdAt
            createdBy
            updatedAt
            updatedBy
        }
    }
`;

export interface UpdateAppointmentData {
    updateAppointment: Appointment;
}

export const UpdateAppointmentMutation = {
    FullResponse,
};

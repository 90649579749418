import React, { useEffect, useMemo } from 'react';
import { FileDescription } from '../../__shared/graphql';
import { useLoadSourceFromApiUrl } from '../../hooks/useLoadSourceFromApiUrl';
import DefaultHTMLImageProps from '../../types/defaultHTMLImageProps';

interface ApiImageProps {
    fileDescription: FileDescription;
    fileDescriptionWithOptimalResolution?: FileDescription | null;
    nativeProps?: DefaultHTMLImageProps;
    onLoadingChanged?: (loadingState: boolean) => void;
    onError?: (error: unknown) => void;
}

const ApiImage: React.FC<ApiImageProps> = ({
    fileDescription,
    fileDescriptionWithOptimalResolution,
    nativeProps,
    onLoadingChanged,
    onError,
}) => {
    const { loading, error, optimalUrl, fallbackUrl } = useLoadSourceFromApiUrl(
        fileDescription,
        fileDescriptionWithOptimalResolution,
    );

    // logUtil.log('ApiImage:', { loading, error, optimalUrl, fallbackUrl });

    useEffect(() => {
        if (typeof onLoadingChanged === 'function') {
            onLoadingChanged(loading);
        }
    }, [onLoadingChanged, loading]);

    useEffect(() => {
        if (typeof onError === 'function') {
            onError(error);
        }
    }, [onError, error]);

    const url =
        optimalUrl?.replaceAll('\\', '/') ?? fallbackUrl?.replaceAll('\\', '/');

    const memorizedApiImage = useMemo(() => {
        // logUtil.log('memorizedApiImage changed');
        return <img {...nativeProps} src={url} />;
    }, [url, nativeProps]);

    return memorizedApiImage;
};

export default ApiImage;

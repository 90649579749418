const AnimalChatRoutes = {
    home: '/',
    app: '/app',
    appSubs: {
        chats: '/app/chats',
        chatSub: {
            media: '/app/chats/media',
        },
    },
    analysis: '/analysis',
    business: '/business',
    businessSubs: {
        login: '/business/login',
        forgotPassword: '/business/forgot-password',
        firstSteps: '/business/first-steps',
        firstStepsSubs: {
            termsOfUse: '/business/first-steps/tos',
            createAccount: '/business/first-steps/account',
            person: '/business/first-steps/person',
            verifyEmail: '/business/first-steps/verify-email',
            phone: '/business/first-steps/phone',
            verifyPhone: '/business/first-steps/verify-phone',
        },
        profile: '/business/profile',
        changePassword: '/business/change-password',
        company: '/business/company',
        payment: '/business/payment',
        downloads: '/business/downloads',
        serviceTimes: '/business/service-times',
        employees: '/business/employees',
        subscriptions: '/business/subscriptions',
        pleaseLogin: '/business/please-login',
    },
    settings: '/settings',
    settingsSub: {
        company: '/settings/company',
        employees: '/settings/employees',
        stripe: '/settings/stripe',
        serviceTimes: '/settings/service-times',
        widget: '/settings/widget',
        support: '/settings/support',
        subscriptions: '/settings/subscriptions',
    },
    userProfile: '/user-profile',
    userProfileSub: {
        profile: '/user-profile/profile',
        changePassword: '/user-profile/change-password',
    },
    login: '/login',
    forgotPassword: '/forgot-password',
};

export default AnimalChatRoutes;

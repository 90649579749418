import { Subject } from 'rxjs';

interface PageLifecycleChangeEvent {
    prevState: string;
    nextState: string;
}

const currentPageLifecycleChangeEventSubject =
    new Subject<PageLifecycleChangeEvent>();
const currentPageLifecycleChangeEvent$ =
    currentPageLifecycleChangeEventSubject.asObservable();

const setCurrentLifecycleChangeEvent = (
    pageLifecycleChangeEvent: PageLifecycleChangeEvent,
): void => {
    // logUtil.log(
    //     'RxPageLifecycle setCurrentLifecycleChangeEvent pageLifecycleChangeEvent:',
    //     pageLifecycleChangeEvent,
    // );
    currentPageLifecycleChangeEventSubject.next(pageLifecycleChangeEvent);
};

const RxPageLifecycle = {
    setCurrentLifecycleChangeEvent,
    currentPageLifecycleChangeEvent$,
};

export default RxPageLifecycle;

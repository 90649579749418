import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ChatMessage } from '../../../../../__shared/graphql';
import FullscreenMedia, {
    MediaFile,
} from '../../../../../components/fullscreenMedia/fullscreenMedia';
import { isVideoType } from '../../../../../utils/fileUtils';

export interface ChatImageViewProps {
    messages: ChatMessage[];
    showMessage?: ChatMessage;
    onClose: () => void;
}

const ChatImageView: React.FC<ChatImageViewProps> = ({
    messages: allMessages,
    showMessage,
    onClose: close,
}) => {
    const [isVisible, setIsVisible] = useState(false);
    const [cursor, setCursor] = useState<number | undefined>();
    const [messages, setMessages] = useState<ChatMessage[]>([]);

    useEffect(() => {
        if (showMessage && messages?.length && cursor === undefined) {
            setCursor(messages.findIndex(msg => msg.id === showMessage.id));
            setIsVisible(true);
        }
    }, [showMessage, messages, cursor]);

    useEffect(() => {
        if (allMessages?.length) {
            setMessages(
                allMessages
                    ?.filter(message => !!message.files?.length)
                    .reverse(),
            );
        } else {
            setMessages([]);
        }
    }, [allMessages]);

    const onClose = useCallback(() => {
        setIsVisible(false);
        setCursor(undefined);
        close();
    }, [close]);

    // convert messages to media files
    const mediaFiles = useMemo((): MediaFile[] => {
        return messages.map(message => {
            const firstFile = message.files?.length
                ? message.files[0]
                : undefined;
            return {
                name: firstFile?.name ?? '',
                type: isVideoType(firstFile?.fileType) ? 'video' : 'image',
                filesDescriptions: firstFile ? [firstFile] : [],
            };
        });
    }, [messages]);

    if (!isVisible) {
        return null;
    }

    return (
        <FullscreenMedia
            mediaFiles={mediaFiles}
            onClose={onClose}
            cursor={cursor}
        />
    );
};

export default ChatImageView;

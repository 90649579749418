import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FadeLoader } from 'react-spinners';
import Button from '../../../../../components/button/button';
import CheckBox from '../../../../../components/checkBox/checkBox';
import TextButton from '../../../../../components/textButton/textButton';
import { useObservable } from '../../../../../hooks/useObservable';
import RxUserProfile from '../../../../../rxjs/RxUserProfile';
import { buildNameFromUser } from '../../../../../utils/nameUtils';
import { DateUtil } from '../../../../../__shared/common';
import { openUrlInBrowser } from '../../../../../__shared/electron';
import { User } from '../../../../../__shared/graphql';
import Card from '../../../components/card/card';
import CardHeader from '../../../components/cardHeader/cardHeader';
import { NewCompanyForm } from './createCompany';
import styles from './summaryCreateCompany.module.scss';

interface SummaryCreateCompanyProps {
    onNext: () => void;
    onBack: () => void;
    formData: NewCompanyForm;
    isLoading: boolean;
}

// https://api.animalchat.net/api/graphql
const ServerUrl = process.env.REACT_APP_API_BASE_URL.replace('api/graphql', '');
const LicenceAgreementUrl = `${ServerUrl}contracts/licenceAgreement`;
const OrderProcessingAgreementUrl = `${ServerUrl}contracts/orderProcessingAgreement`;

const buildLicenceAgreementUrl = (
    form: NewCompanyForm,
    isLicenceAgreementAccepted: boolean,
) => {
    const params = new URLSearchParams({
        customerAreaCodeAndCity: `${form.address.zipCode} ${form.address.city}`,
        customerCompanyName: form.common.name,
        customerCountry: form.address.country,
        customerStreetAndNumber: `${form.address.streetName} ${form.address.houseNumber}`,
        date: isLicenceAgreementAccepted ? DateUtil.toDDMMYYYY(new Date()) : '',
    });
    return `${LicenceAgreementUrl}?${params.toString()}`;
};

const buildOrderProcessingAgreementUrl = (
    form: NewCompanyForm,
    isLicenceAgreementAccepted: boolean,
    user?: User,
) => {
    const params = new URLSearchParams({
        customerAreaCodeAndCity: `${form.address.zipCode} ${form.address.city}`,
        customerCompanyName: form.common.name,
        customerCountry: form.address.country,
        customerStreetAndNumber: `${form.address.streetName} ${form.address.houseNumber}`,
        customerCompanyRepresentative: buildNameFromUser(user),
        date: isLicenceAgreementAccepted ? DateUtil.toDDMMYYYY(new Date()) : '',
    });
    return `${OrderProcessingAgreementUrl}?${params.toString()}`;
};

const SummaryCreateCompany: React.FC<SummaryCreateCompanyProps> = ({
    onNext,
    onBack,
    formData,
    isLoading,
}) => {
    const [isLicenceAgreementAccepted, setIsLicenceAgreementAccepted] =
        useState(false);
    const [
        isOrderProcessingAgreementAccepted,
        setIsOrderProcessingAgreementAccepted,
    ] = useState(false);
    const user = useObservable(RxUserProfile.userProfile$);
    const { t } = useTranslation();

    const licenceAgreementUrl = buildLicenceAgreementUrl(
        formData,
        isLicenceAgreementAccepted,
    );
    const orderProcessingAgreementUrl = buildOrderProcessingAgreementUrl(
        formData,
        isOrderProcessingAgreementAccepted,
        user,
    );

    return (
        <Card>
            <CardHeader
                title={t('screens.business.company.summaryTitle')}
                onBack={onBack}
            />
            <p className={styles.text}>
                <span>{t('screens.business.company.summaryText.part1')}</span>
                <a
                    href="https://animalchat.net"
                    rel="noopener noreferrer"
                    target="_blank"
                    className={styles.websiteLink}>
                    {t('screens.business.company.summaryText.website')}
                </a>
                <span>{t('screens.business.company.summaryText.part2')}</span>
            </p>
            <TextButton
                onClick={() => openUrlInBrowser(licenceAgreementUrl)}
                text={t('screens.business.company.downloadLicenceContract')}
                className={styles.textButton}
            />
            <div className={styles.checkboxes}>
                <div className={styles.checkbox}>
                    <CheckBox
                        checked={isLicenceAgreementAccepted}
                        onChange={e =>
                            setIsLicenceAgreementAccepted(e.target.checked)
                        }
                        className={styles.checkbox}
                    />
                    <span
                        onClick={() =>
                            setIsLicenceAgreementAccepted(prev => !prev)
                        }>
                        {t('screens.business.company.acceptLicenceContract')}
                    </span>
                </div>
            </div>
            <TextButton
                onClick={() => openUrlInBrowser(orderProcessingAgreementUrl)}
                text={t('screens.business.company.downloadAvvContract')}
                className={styles.textButton}
            />
            <div className={styles.checkboxes}>
                <div className={styles.checkbox}>
                    <CheckBox
                        checked={isOrderProcessingAgreementAccepted}
                        onChange={e =>
                            setIsOrderProcessingAgreementAccepted(
                                e.target.checked,
                            )
                        }
                        className={styles.checkbox}
                    />
                    <span
                        onClick={() =>
                            setIsOrderProcessingAgreementAccepted(prev => !prev)
                        }>
                        {t('screens.business.company.acceptAvvContract')}
                    </span>
                </div>
            </div>
            {isLoading && (
                <div className={styles.loading}>
                    <FadeLoader />
                    <p className={styles.loadingText}>
                        {t('screens.business.company.waitingText')}
                    </p>
                </div>
            )}
            <Button
                onClick={onNext}
                disabled={
                    !isLicenceAgreementAccepted ||
                    !isOrderProcessingAgreementAccepted ||
                    !user ||
                    isLoading
                }
                className={styles.button}
                text={t('screens.business.company.createCompany')}
            />
        </Card>
    );
};

export default SummaryCreateCompany;

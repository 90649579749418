import React from 'react';
import SubmenuEntry from './submenuEntry';

import styles from './submenu.module.scss';
import { ClassNameProp } from '../../types/stylingProps';

export interface SubmenuEntryData {
    route: string;
    translationKey: string;
    iconName: string;
    disabled?: boolean;
    counter?: number;
}

interface SubmenuProps {
    entries: SubmenuEntryData[];
    onNavigate: (subRoute: string) => void;
    activeSubRoute: string;
}

const Submenu: React.FC<SubmenuProps & ClassNameProp> = ({
    entries,
    onNavigate,
    activeSubRoute,
    className,
}) => {
    return (
        <ul className={`${styles.navigation} ${className ?? ''}`.trim()}>
            {entries.map(entry => (
                <SubmenuEntry
                    key={entry.route}
                    {...entry}
                    isActive={entry.route === activeSubRoute}
                    onClick={() => onNavigate(entry.route)}
                    disabled={!!entry.disabled}
                />
            ))}
        </ul>
    );
};

export default Submenu;

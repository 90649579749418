import { useMutation } from '@apollo/client';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { uploadClient } from '../../../../../../api/apolloClient';
import FullscreenMedia, {
    MediaFile,
} from '../../../../../../components/fullscreenMedia/fullscreenMedia';
import { useAlert } from '../../../../../../hooks/useAlert';
import { useObservable } from '../../../../../../hooks/useObservable';
import RxAuthentication from '../../../../../../rxjs/RxAuthentication';
import {
    getFileInfoFromImage,
    getFileInfoFromVideo,
    isImageType,
    isVideoType,
} from '../../../../../../utils/fileUtils';
import { updateCache } from '../../../../../../utils/imageFileUtil';
import {
    ChatMessageTypes,
    PredefinedImageResolutionsThumbnailAndDetail,
} from '../../../../../../__shared/common';
import {
    CreateChatMessageData,
    CreateChatMessageMutation,
    FileInfo,
    MutationCreateChatMessageArgs,
    MutationSingleFileUploadArgs,
    SingleFileUploadData,
    SingleFileUploadMutation,
} from '../../../../../../__shared/graphql';

export interface ChatInputMediaProps {
    chatId: number;
    files?: File[];
    setFiles: (newFiles: File[]) => void;
    onClose: () => void;
    isLoading: (isLoading: boolean) => void;
}

const ChatInputMedia: React.FC<ChatInputMediaProps> = ({
    chatId,
    files,
    setFiles,
    onClose,
    isLoading,
}) => {
    const { t } = useTranslation();
    const accessToken = useObservable(RxAuthentication.accessToken$);
    const alert = useAlert();
    const userId = useObservable(RxAuthentication.userId$, 0);

    const [
        singleFileUpload,
        { data: singleFileUploadData, loading: singleFileUploadLoading },
    ] = useMutation<SingleFileUploadData, MutationSingleFileUploadArgs>(
        SingleFileUploadMutation.FullResponse,
        { client: uploadClient },
    );

    const [createChatMessage, { loading: createChatMessageLoading }] =
        useMutation<CreateChatMessageData, MutationCreateChatMessageArgs>(
            CreateChatMessageMutation.FullResponse,
        );

    useEffect(() => {
        const fileDescription = singleFileUploadData?.singleFileUpload;
        if (fileDescription?.id && fileDescription?.fileSize) {
            updateCache(fileDescription);
        }
    }, [singleFileUploadData, singleFileUploadLoading, accessToken]);

    // start upload by uploading all files
    const onSend = useCallback(
        async (textMessage: string, filesToUpload: File[]) => {
            if (!singleFileUploadLoading && !createChatMessageLoading) {
                for (const [index, mediaFile] of filesToUpload?.entries()) {
                    let fileInfo: FileInfo = {};
                    if (isVideoType(mediaFile.type)) {
                        fileInfo = await getFileInfoFromVideo(mediaFile);
                    }
                    if (isImageType(mediaFile.type)) {
                        fileInfo = getFileInfoFromImage(mediaFile);
                    }
                    try {
                        isLoading(true);
                        const result = await singleFileUpload({
                            variables: {
                                file: mediaFile,
                                fileInfo,
                                skipVideoConversion: true,
                                resolutions: Object.values(
                                    PredefinedImageResolutionsThumbnailAndDetail,
                                ),
                            },
                        });
                        const isLastMessage =
                            index === filesToUpload?.length - 1;
                        if (result?.data?.singleFileUpload?.id) {
                            await createChatMessage({
                                variables: {
                                    chatMessage: {
                                        authorId: userId,
                                        chatId,
                                        text: isLastMessage ? textMessage : '',
                                        type: ChatMessageTypes.Default,
                                        fileIds: [
                                            result.data.singleFileUpload.id,
                                        ],
                                    },
                                },
                            });
                        }
                    } catch (error) {
                        alert.error(
                            t(
                                'screens.chat.errors.createMessageWithImageFailed',
                            ),
                        );
                        console.warn(error);
                    } finally {
                        isLoading(false);
                    }
                }
            }
        },
        [
            chatId,
            userId,
            singleFileUpload,
            singleFileUploadLoading,
            createChatMessageLoading,
            alert,
            createChatMessage,
            isLoading,
            t,
        ],
    );

    const addImages = useCallback(
        async (oldFiles: File[], newFiles: FileList) => {
            const tmpFiles = [...oldFiles];
            for (let i = 0; i < newFiles.length; i += 1) {
                const file = newFiles.item(i);
                if (file) {
                    tmpFiles.push(file);
                }
            }
            setFiles(tmpFiles);
        },
        [setFiles],
    );

    // convert files to media files
    const mediaFiles = useMemo((): MediaFile[] => {
        if (!files?.length) {
            return [];
        }
        return files.map(file => {
            // file is different in electron vs browser
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const path = (file as any).path;
            return {
                name: file.name,
                type: isVideoType(file.type) ? 'video' : 'image',
                uri: path ? `acupload://${path}` : undefined,
                file: file,
                shouldReadFile: !path,
            };
        });
    }, [files]);

    if (!mediaFiles?.length) {
        return null;
    }

    return (
        <FullscreenMedia
            mediaFiles={mediaFiles}
            loading={singleFileUploadLoading || createChatMessageLoading}
            onClose={onClose}
            onAddMediaFiles={newFiles => addImages(files ?? [], newFiles)}
            onSend={(text, filesToUpload) => {
                onSend(text, filesToUpload);
                setTimeout(() => onClose(), 2000);
            }}
        />
    );
};

export default ChatInputMedia;

import { FadeLoader } from 'react-spinners';
import styles from './chatMessagesPlaceholder.module.scss';
import React from 'react';

interface ChatMessagesPlaceholderProps {
    isLoading?: boolean;
}

const ChatMessagesPlaceholder: React.FC<ChatMessagesPlaceholderProps> = ({
    isLoading,
}) => {
    return (
        <div className={styles.container}>
            <div className={styles.loadingNextPageSpinner}>
                {isLoading && <FadeLoader color={'gray'} />}
            </div>
        </div>
    );
};

export default ChatMessagesPlaceholder;

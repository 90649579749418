import { gql } from '@apollo/client';

const FullResponse = gql`
    query GetStripeConnectOnboardingLinkForExistingAccount(
        $accountId: String!
    ) {
        getStripeConnectOnboardingLinkForExistingAccount(accountId: $accountId)
    }
`;

export interface GetStripeConnectOnboardingLinkForExistingAccountData {
    getStripeConnectOnboardingLinkForExistingAccount: string | null | undefined;
}

export const GetStripeConnectOnboardingLinkForExistingAccountQuery = {
    FullResponse,
};

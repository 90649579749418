import { useCallback, useEffect, useRef, useState } from 'react';
import useChatMessageManager from '../../../../../stateHandlers/useChatMessageManager';
import { logMutation } from '../../../../../utils/loggingUtil';
import logUtil from '../../../../../utils/logUtil';
import { ChatMessage } from '../../../../../__shared/graphql';

interface ChatMessagesHook {
    onWasVisible: (message: ChatMessage, isVisible: boolean) => void;
}

const addToMarkAsViewed = (
    previous: ChatMessage[],
    newMessages: ChatMessage[],
): ChatMessage[] => {
    let result = previous?.filter(msg => !msg.isViewedByMe) ?? [];

    result = [
        ...result,
        ...newMessages?.filter(
            msg => !msg.isViewedByMe && !result.some(old => old.id === msg.id),
        ),
    ];
    return result;
};

const removeFromMarkAsViewed = (
    previous: ChatMessage[],
    toBeRemoved: ChatMessage[],
): ChatMessage[] => {
    return previous.filter(
        old =>
            !old.isViewedByMe && !toBeRemoved?.some(msg => msg.id === old.id),
    );
};

const useChatMessages = (
    onChatMessagesMarkedAsViewed: (viewedMessages: ChatMessage[]) => void,
): ChatMessagesHook => {
    const [markAsViewedQueue, setMarkAsViewedQueue] = useState<ChatMessage[]>(
        [],
    );
    const { loading, markMessagesAsViewed } = useChatMessageManager();

    const onWasVisible = useCallback(
        (message: ChatMessage, isVisible: boolean) => {
            if (isVisible) {
                setMarkAsViewedQueue(prev =>
                    addToMarkAsViewed(prev, [message]),
                );
            }
        },
        [],
    );

    useEffect(() => {
        if (markAsViewedQueue?.length) {
            logUtil.log(
                'useChatMessages markedMessages:',
                JSON.stringify(
                    markAsViewedQueue.map(msg => {
                        return {
                            id: msg.id,
                            text: msg.text,
                            isViewedByMe: msg.isViewedByMe,
                        };
                    }),
                ),
            );
        }
    }, [markAsViewedQueue]);

    const MarkMessageAsViewedIntervalInMS = 200;

    const timeoutId = useRef<NodeJS.Timeout | undefined>();
    useEffect(() => {
        if (markAsViewedQueue.length) {
            logUtil.log(
                'useChatMessages markedMessagesAsViewed:',
                JSON.stringify({
                    loading,
                    markAsViewedQueue: markAsViewedQueue?.length,
                }),
            );
            if (timeoutId.current) {
                clearTimeout(timeoutId.current);
                timeoutId.current = undefined;
            }
            timeoutId.current = setTimeout(() => {
                if (markAsViewedQueue?.length) {
                    const markMessages = async () => {
                        logMutation(
                            'MARK_MESSAGES_AS_VIEWED',
                            markAsViewedQueue,
                        );
                        const markedMessages = await markMessagesAsViewed(
                            markAsViewedQueue,
                        );
                        if (
                            typeof onChatMessagesMarkedAsViewed === 'function'
                        ) {
                            onChatMessagesMarkedAsViewed(markedMessages);
                        }
                        setMarkAsViewedQueue(prev =>
                            removeFromMarkAsViewed(prev, markedMessages ?? []),
                        );
                    };
                    markMessages();
                }
            }, MarkMessageAsViewedIntervalInMS);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [markAsViewedQueue, markMessagesAsViewed]);

    // // mark messages as viewed when loading state changes to false
    // // and/or the message queue is not empty
    // useEffect(() => {
    //     if (!loading && markAsViewedQueue?.length) {
    //         const markMessages = async () => {
    //             logMutation('MARK_MESSAGES_AS_VIEWED', markAsViewedQueue);
    //             const markedMessages = await markMessagesAsViewed(
    //                 markAsViewedQueue,
    //             );
    //             if (typeof onChatMessagesMarkedAsViewed === 'function') {
    //                 onChatMessagesMarkedAsViewed(markedMessages);
    //             }
    //             setMarkAsViewedQueue(prev =>
    //                 removeFromMarkAsViewed(prev, markedMessages ?? []),
    //             );
    //         };
    //         markMessages();
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [markAsViewedQueue, markMessagesAsViewed, onChatMessagesMarkedAsViewed]);

    return {
        onWasVisible,
    };
};

export default useChatMessages;

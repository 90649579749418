import { gql } from '@apollo/client';
import { AddressFragments } from './addressFragments';
import { CompanyFragments } from './companyFragments';
import { ContactFragments } from './contactFragments';
import { UserFragments } from './userFragments';

const CustomerWithUserAndContactAndAddressAndCompany = gql`
    fragment CustomerWithUserAndContactAndAddressAndCompany on Customer {
        id
        user {
            ...FullUser
        }
        company {
            ...FullCompany
        }
        contact {
            ...FullContact
            address {
                ...FullAddress
            }
        }
    }
    ${CompanyFragments.FullCompany}
    ${ContactFragments.FullContact}
    ${AddressFragments.FullAddress}
    ${UserFragments.FullUser}
`;

const CustomerWithContact = gql`
    fragment CustomerWithContact on Customer {
        id
        contact {
            ...FullContact
        }
    }
    ${ContactFragments.FullContact}
`;

export const CustomerFragments = {
    CustomerWithUserAndContactAndAddressAndCompany,
    CustomerWithContact,
};

import { FileDescription } from '../../graphql';
import { PredefinedSizes, ResolutionRange } from '../imageResolutions';
import { isNumber } from './compareUtil';
import { DateUtil } from './dateUtil';
import {
    getPrettyFileSizeTextFromNumber,
    getPrettyFileTypeTextFromFileType,
} from './fileUtil';

type Sides = 'width' | 'height';

const InitialDiff = 99999999;

type DiffResult = {
    isCloser: boolean;
    newDiff: number;
};

const isCloserToTarget = (
    diff: number,
    targetSize: number,
    side: number | null | undefined,
): DiffResult => {
    if (!isNumber(side)) {
        return {
            newDiff: diff,
            isCloser: false,
        };
    }
    const sideDiff = Math.abs(targetSize - side);
    const isCloser = diff > sideDiff;
    return {
        isCloser,
        newDiff: isCloser ? sideDiff : diff,
    };
};

const getChildWithShorterSideClosestTo = (
    targetSize: number,
    fileDesc?: FileDescription | null,
): FileDescription | undefined => {
    if (!fileDesc || !fileDesc.height || !fileDesc.width) {
        return undefined;
    }
    let side: Sides = 'width';
    if (fileDesc.width > fileDesc.height) {
        side = 'height';
    }
    let result: FileDescription | undefined = undefined;
    let difference = InitialDiff;
    let diffResult: DiffResult;

    fileDesc.children?.forEach(child => {
        diffResult = isCloserToTarget(difference, targetSize, child[side]);
        if (diffResult.isCloser) {
            difference = diffResult.newDiff;
            result = child;
        }
    });

    return result;
};

const getVersionClosestToAnySide = (
    targetSize: number,
    fileDesc?: FileDescription | null,
): FileDescription | undefined => {
    if (!fileDesc) {
        return undefined;
    }
    if (!fileDesc.children?.length) {
        // if (!fileDesc.parent && !fileDesc.children?.length) {
        return fileDesc;
    }
    let result = fileDesc;
    let resultDiff = InitialDiff;

    let diffResult = isCloserToTarget(resultDiff, targetSize, fileDesc.width);
    if (diffResult.isCloser) {
        resultDiff = diffResult.newDiff;
    }
    diffResult = isCloserToTarget(resultDiff, targetSize, fileDesc.height);
    if (diffResult.isCloser) {
        resultDiff = diffResult.newDiff;
    }

    // if (fileDesc.parent) {
    //     diffResult = isCloserToTarget(
    //         resultDiff,
    //         targetSize,
    //         fileDesc.parent.width,
    //     );
    //     if (diffResult.isCloser) {
    //         resultDiff = diffResult.newDiff;
    //         result = fileDesc.parent;
    //     }
    //     diffResult = isCloserToTarget(
    //         resultDiff,
    //         targetSize,
    //         fileDesc.parent.height,
    //     );
    //     if (diffResult.isCloser) {
    //         resultDiff = diffResult.newDiff;
    //         result = fileDesc.parent;
    //     }
    // }

    fileDesc.children?.forEach(child => {
        diffResult = isCloserToTarget(resultDiff, targetSize, child.width);
        if (diffResult.isCloser) {
            resultDiff = diffResult.newDiff;
            result = child;
        }
        diffResult = isCloserToTarget(resultDiff, targetSize, child.height);
        if (diffResult.isCloser) {
            resultDiff = diffResult.newDiff;
            result = child;
        }
    });

    return result;
};

const getVersionClosestToSide = (
    fileDesc: FileDescription,
    targetSize: number,
    side: Sides,
): FileDescription => {
    // if (!fileDesc.parent && !fileDesc.children?.length) {
    if (!fileDesc.children?.length) {
        return fileDesc;
    }
    let result = fileDesc;
    let resultDiff = InitialDiff;
    let diffResult = isCloserToTarget(resultDiff, targetSize, fileDesc[side]);
    resultDiff = diffResult.newDiff;

    // if (fileDesc.parent) {
    //     diffResult = isCloserToTarget(
    //         resultDiff,
    //         targetSize,
    //         fileDesc.parent[side],
    //     );
    //     if (diffResult.isCloser) {
    //         resultDiff = diffResult.newDiff;
    //         result = fileDesc.parent;
    //     }
    // }

    fileDesc.children?.forEach(child => {
        diffResult = isCloserToTarget(resultDiff, targetSize, child[side]);
        if (diffResult.isCloser) {
            resultDiff = diffResult.newDiff;
            result = child;
        }
    });

    return result;
};

const getVersionClosestToHeight = (
    targetHeight: number,
    fileDesc?: FileDescription | null,
): FileDescription | undefined => {
    if (!fileDesc) {
        return undefined;
    }
    return getVersionClosestToSide(fileDesc, targetHeight, 'height');
};

const getVersionClosestToWidth = (
    targetWidth: number,
    fileDesc?: FileDescription | null,
): FileDescription | undefined => {
    if (!fileDesc) {
        return undefined;
    }
    return getVersionClosestToSide(fileDesc, targetWidth, 'width');
};

const isFileDescriptionInRange = (
    range: ResolutionRange,
    fileDesc?: FileDescription | null,
): boolean => {
    if (!fileDesc || !fileDesc.width || !fileDesc.height) {
        return false;
    }
    const { width, height } = fileDesc;
    return (
        width >= range.min &&
        width <= range.max &&
        height >= range.min &&
        height <= range.max
    );
};

const getChildWithShorterSideEqualTo = (
    targetSize: number,
    fileDesc?: FileDescription | null,
): FileDescription | undefined => {
    if (!fileDesc || !fileDesc.width || !fileDesc.height) {
        return undefined;
    }
    let side: Sides = 'width';
    if (fileDesc.width > fileDesc.height) {
        side = 'height';
    }
    return fileDesc.children?.find(child => child[side] === targetSize);
};

const getFileDescriptionInRange = (
    range: ResolutionRange,
    targetSize: number,
    fileDesc?: FileDescription | null,
): FileDescription | undefined => {
    if (!fileDesc) {
        return undefined;
    }
    let possibleFileDescs: FileDescription[] = [];
    if (isFileDescriptionInRange(range, fileDesc)) {
        possibleFileDescs.push(fileDesc);
    }
    fileDesc.children?.forEach(child => {
        if (isFileDescriptionInRange(range, child)) {
            possibleFileDescs.push(child);
        }
    });
    if (!possibleFileDescs.length) {
        return undefined;
    }

    let result: FileDescription | undefined;
    let resultDiff = InitialDiff;
    let diffResult: DiffResult;
    possibleFileDescs.forEach(possibleResult => {
        diffResult = isCloserToTarget(
            resultDiff,
            targetSize,
            possibleResult.width,
        );
        if (diffResult.isCloser) {
            resultDiff = diffResult.newDiff;
            result = possibleResult;
        }
        diffResult = isCloserToTarget(
            resultDiff,
            targetSize,
            possibleResult.height,
        );
        if (diffResult.isCloser) {
            resultDiff = diffResult.newDiff;
            result = possibleResult;
        }
    });

    return result;
};

export const getCloseToProfilePicture = (
    fileDesc?: FileDescription | null,
): FileDescription | undefined => {
    if (!fileDesc) {
        return undefined;
    }
    return getVersionClosestToAnySide(
        PredefinedSizes.ProfilePictureSize,
        fileDesc,
    );
};

export const getCloseToDetailPicture = (
    fileDesc?: FileDescription | null,
): FileDescription | undefined => {
    if (!fileDesc) {
        return undefined;
    }
    return getVersionClosestToAnySide(
        PredefinedSizes.DetailPictureSize,
        fileDesc,
    );
};

export const getCloseToThumbnail = (
    fileDesc?: FileDescription | null,
): FileDescription | undefined => {
    if (!fileDesc) {
        return undefined;
    }
    return getVersionClosestToAnySide(PredefinedSizes.ThumbnailSize, fileDesc);
};

export const getDetailPicture = (
    fileDesc?: FileDescription | null,
): FileDescription | undefined => {
    if (!fileDesc) {
        return undefined;
    }
    return getChildWithShorterSideEqualTo(
        PredefinedSizes.DetailPictureSize,
        fileDesc,
    );
};
export const getProfilePicture = (
    fileDesc?: FileDescription | null,
): FileDescription | undefined => {
    if (!fileDesc) {
        return undefined;
    }
    return getChildWithShorterSideEqualTo(
        PredefinedSizes.ProfilePictureSize,
        fileDesc,
    );
};
export const getThumbnail = (
    fileDesc?: FileDescription | null,
): FileDescription | undefined => {
    if (!fileDesc) {
        return undefined;
    }
    return getChildWithShorterSideEqualTo(
        PredefinedSizes.ThumbnailSize,
        fileDesc,
    );
};

export const createFileNameForFileDownload = (
    appName: string,
    fileType: string,
): string => {
    const fileTypeDescription = getFileTypeDescriptionForFileType(fileType);
    const now = new Date();
    const date = DateUtil.toYYYYMMDD(now, '-');
    const time = DateUtil.toHHMMSS(now, '.');
    const fileExtensionWithLeadingDot =
        getFileExtensionFromFileTypeWithLeadingDot(fileType);

    return `${appName} ${fileTypeDescription} ${date} at ${time}${fileExtensionWithLeadingDot}`;
};

export const getFileTypeDescriptionForFileType = (fileType: string): string => {
    const isImageFileType = fileType.indexOf('image') > -1;
    const isVideoFileType = fileType.indexOf('video') > -1;

    let fileTypeDescription = '';
    if (isImageFileType) {
        fileTypeDescription = 'Image';
    } else if (isVideoFileType) {
        fileTypeDescription = 'Video';
    } else {
        fileTypeDescription = 'File';
    }

    return fileTypeDescription ?? '';
};

export const getFileExtensionFromFileTypeWithLeadingDot = (
    fileType: string,
): string => {
    const isImageFileType = fileType.indexOf('image') > -1;
    const isVideoFileType = fileType.indexOf('video') > -1;

    const fileTypeParts = fileType.split('/');
    let fileExtension = fileTypeParts[fileTypeParts.length - 1];

    // default
    if (!fileExtension) {
        if (isImageFileType) {
            fileExtension = 'jpeg';
        } else if (isVideoFileType) {
            fileExtension = 'mp4';
        }
    }

    return fileExtension ? `.${fileExtension}` : '';
};

export const getPrettyFileTypeText = (
    fileDescription: FileDescription,
): string => {
    return getPrettyFileTypeTextFromFileType(fileDescription.fileType);
};

export const getPrettyFileSizeText = (
    fileDescription: FileDescription,
): string => {
    return getPrettyFileSizeTextFromNumber(fileDescription.fileSize);
};

export const FileDescriptionUtil = {
    getCloseToDetailPicture,
    getCloseToProfilePicture,
    getCloseToThumbnail,
    getDetailPicture,
    getProfilePicture,
    getThumbnail,
    getVersionClosestToAnySide,
    getVersionClosestToHeight,
    getVersionClosestToWidth,
    createFileNameForFileDownload,
    getPrettyFileTypeText,
    getPrettyFileSizeText,
};

import { ChatMessage } from '../dto';
import { gql } from '@apollo/client';
import { ChatMessageFragments } from '../fragments';

export const ON_CHAT_MESSAGE_ADDED = gql`
    subscription OnMessageAdded($chatId: Int!) {
        onMessageAdded(chatId: $chatId) {
            ...FullChatMessage
        }
    }
    ${ChatMessageFragments.FullResponse}
`;

export interface OnChatMessageAddedData {
    onMessageAdded: ChatMessage;
}

export const ON_CHAT_MESSAGE_EDITED = gql`
    subscription OnMessageEdited($chatId: Int!) {
        onMessageEdited(chatId: $chatId) {
            ...FullChatMessage
        }
    }
    ${ChatMessageFragments.FullResponse}
`;

export interface OnChatMessageEditedData {
    onMessageEdited: ChatMessage;
}

export const ON_CHAT_MESSAGES_EDITED = gql`
    subscription OnMessagesEdited($chatId: Int!) {
        onMessagesEdited(chatId: $chatId) {
            ...FullChatMessage
        }
    }
    ${ChatMessageFragments.FullResponse}
`;

export interface OnChatMessagesEditedData {
    onMessagesEdited: ChatMessage[];
}

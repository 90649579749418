import { useLazyQuery, useSubscription } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { PAGE_SIZE } from '../../../../../environment/env';
import { useObservable } from '../../../../../hooks/useObservable';
import RxChat from '../../../../../rxjs/RxChat';
import RxPageLifecycle from '../../../../../rxjs/RxPageLifecycle';
import useChatMessageManager from '../../../../../stateHandlers/useChatMessageManager';
import { mergeChatMessagesByUuid } from '../../../../../utils/arrayUtils';
import { logSubscription } from '../../../../../utils/loggingUtil';
import logUtil from '../../../../../utils/logUtil';
import { PageLifecycleStateTypes } from '../../../../../utils/pageLifecycleUtils';
import { LogClient } from '../../../../../__shared/common/logging/logClient';
import { TestLoggerMessages } from '../../../../../__shared/common/logging/testConstants';
import {
    ChatMessage,
    FindMessagesData,
    FindMessagesQuery,
    OnChatMessageAddedData,
    OnChatMessageEditedData,
    OnChatMessagesEditedData,
    ON_CHAT_MESSAGES_EDITED,
    ON_CHAT_MESSAGE_ADDED,
    ON_CHAT_MESSAGE_EDITED,
    QueryFindChatMessagesArgs,
} from '../../../../../__shared/graphql';

interface ChatMainHook {
    messages: ChatMessage[];
    messagesWithFiles: ChatMessage[];
    loading: boolean;
    addMessages: (newMessages: ChatMessage[]) => void;
    setMessagesWithFiles: (newMessages: ChatMessage[]) => void;
    addMessagesWithFiles: (newMessages: ChatMessage[]) => void;
    loadNextPage: () => void;
}

const useChatMain = (
    chatId: number,
    searchInput = '',
    loaded = false,
): ChatMainHook => {
    const [messages, setMessages] = useState<ChatMessage[]>([]);
    const [messagesWithFiles, setMessagesWithFilesState] = useState<
        ChatMessage[]
    >([]);

    const currentPageLifecycleChangeEvent = useObservable(
        RxPageLifecycle.currentPageLifecycleChangeEvent$,
        undefined,
    );

    const { markMessagesAsReceived } = useChatMessageManager();

    const [
        findChatMessages,
        {
            data: findChatMessageData,
            loading: findChatMessagesLoading,
            refetch: findChatMessagesRefetch,
            error: findChatMessagesForUserError,
        },
    ] = useLazyQuery<FindMessagesData, QueryFindChatMessagesArgs>(
        FindMessagesQuery.FullResponse,
        {
            variables: { searchInput, chatId, pageSize: PAGE_SIZE },
        },
    );

    const [
        findChatMessagesWithFiles,
        {
            data: findChatMessagesWithFilesData,
            loading: findChatMessagesWithFilesLoading,
            refetch: findChatMessagesWithFilesRefetch,
            error: findChatMessagesWithFilesError,
        },
    ] = useLazyQuery<FindMessagesData, QueryFindChatMessagesArgs>(
        FindMessagesQuery.MediaResponse,
        {
            variables: {
                searchInput: '',
                chatId: chatId,
                onlyFiles: true,
            },
        },
    );

    useEffect(() => {
        if (chatId && chatId > 0 && loaded && !findChatMessagesLoading) {
            findChatMessages();
        }
        // DO NOT (!) put findChatMessagesLoading inside the dependancy array
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chatId, loaded, findChatMessages]);

    useEffect(() => {
        if (findChatMessagesForUserError) {
            console.warn(findChatMessagesForUserError);
        }
    }, [findChatMessagesForUserError]);

    useEffect(() => {
        if (findChatMessagesWithFilesError) {
            console.warn(findChatMessagesWithFilesError);
        }
    }, [findChatMessagesWithFilesError]);

    useEffect(() => {
        if (
            chatId &&
            chatId > 0 &&
            loaded &&
            !findChatMessagesWithFilesLoading
        ) {
            findChatMessagesWithFiles();
        }
        // DO NOT (!) put findChatMessagesWithFilesLoading inside the dependancy array
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chatId, loaded, findChatMessagesWithFiles]);

    useEffect(() => {
        if (
            currentPageLifecycleChangeEvent?.prevState ===
            PageLifecycleStateTypes.Frozen
        ) {
            // Wait because after frozen state maybe apollo client is recreated first
            setTimeout(() => {
                findChatMessagesWithFilesRefetch();
            }, 200);
        }
    }, [currentPageLifecycleChangeEvent, findChatMessagesWithFilesRefetch]);

    const setMessagesWithFiles = useCallback(
        (newMessagesWithFiles: ChatMessage[] = []) => {
            setMessagesWithFilesState(newMessagesWithFiles);
        },
        [setMessagesWithFilesState],
    );

    useEffect(() => {
        if (findChatMessagesWithFilesData?.findChatMessages) {
            setMessagesWithFiles(
                findChatMessagesWithFilesData?.findChatMessages ?? [],
            );
        }
    }, [
        chatId,
        findChatMessagesWithFilesData?.findChatMessages,
        setMessagesWithFiles,
    ]);

    const addMessagesWithFiles = useCallback(
        (newMessagesWithFiles: ChatMessage[] = []) => {
            setMessagesWithFilesState(s =>
                mergeChatMessagesByUuid(
                    s,
                    newMessagesWithFiles.filter(
                        x => x.files && x.files?.length > 0,
                    ),
                    true,
                ),
            );
        },
        [setMessagesWithFilesState],
    );

    useEffect(() => {
        addMessagesWithFiles(messages ?? []);
    }, [messages, addMessagesWithFiles]);

    // add messages in already loaded messages
    const addMessages = useCallback(
        (newMessages: ChatMessage[] = []) => {
            logUtil.log(
                'useChatMain addMessages newMessages:',
                JSON.stringify(newMessages),
            );
            setMessages(s => mergeChatMessagesByUuid(s, newMessages, true));
            addMessagesWithFiles(newMessages);
        },
        [setMessages, addMessagesWithFiles],
    );

    // handling loaded messages
    useEffect(() => {
        const markMessagesAndUpdateLocalMessages = async (
            messagesToMark: ChatMessage[],
        ) => {
            const markedMessages: ChatMessage[] = await markMessagesAsReceived(
                findChatMessageData?.findChatMessages ?? [],
            );
            // replace updated messages to avoid rerenderings
            const newMessages = mergeChatMessagesByUuid(
                messagesToMark,
                markedMessages,
            );

            addMessages(newMessages);
        };

        if (findChatMessageData?.findChatMessages?.length) {
            markMessagesAndUpdateLocalMessages(
                findChatMessageData.findChatMessages,
            );
        }
    }, [findChatMessageData, addMessages, markMessagesAsReceived]);

    // load next page
    const loadNextPage = useCallback(async () => {
        logUtil.log('Loading next page');
        const idCursor = messages?.length
            ? messages[messages.length - 1].id
            : 0;
        await findChatMessagesRefetch({
            searchInput,
            idCursor,
            chatId,
            pageSize: PAGE_SIZE,
        });
    }, [findChatMessagesRefetch, messages, searchInput, chatId]);

    //#region subscriptions
    useSubscription<OnChatMessageAddedData>(ON_CHAT_MESSAGE_ADDED, {
        variables: { chatId },
        shouldResubscribe: true,
        onSubscriptionData: ({ subscriptionData: { data } }) => {
            LogClient.test(TestLoggerMessages.SubscriptionOnChatMessageAdded, {
                id: data?.onMessageAdded?.id,
            });
            logSubscription('ON_CHAT_MESSAGE_ADDED', data);
            const message = data?.onMessageAdded;
            if (message) {
                markMessagesAsReceived([message]);
                addMessages([message]);
                RxChat.onChatMessageAdded(message);
            }
        },
    });

    useSubscription<OnChatMessageEditedData>(ON_CHAT_MESSAGE_EDITED, {
        variables: { chatId },
        shouldResubscribe: true,
        onSubscriptionData: ({ subscriptionData: { data } }) => {
            LogClient.test(TestLoggerMessages.SubscriptionOnChatMessageEdited, {
                id: data?.onMessageEdited?.id,
            });
            logSubscription('ON_CHAT_MESSAGE_EDITED', data);
            const message = data?.onMessageEdited;
            if (message) {
                addMessages([message]);
                RxChat.onChatMessageEdited(message);
            }
        },
    });

    useSubscription<OnChatMessagesEditedData>(ON_CHAT_MESSAGES_EDITED, {
        variables: { chatId },
        shouldResubscribe: true,
        onSubscriptionData: ({ subscriptionData: { data } }) => {
            LogClient.test(
                TestLoggerMessages.SubscriptionOnChatMessagesEdited,
                {
                    ids: data?.onMessagesEdited?.map(msg => msg.id) ?? [],
                },
            );
            logSubscription('ON_CHAT_MESSAGES_EDITED', data);
            if (data?.onMessagesEdited?.length) {
                addMessages(data?.onMessagesEdited);
                RxChat.onChatMessagesEdited(data?.onMessagesEdited);
            }
        },
    });
    //#endregion

    return {
        messages,
        messagesWithFiles,
        loading: findChatMessagesLoading,
        addMessages,
        addMessagesWithFiles,
        setMessagesWithFiles,
        loadNextPage,
    };
};

export default useChatMain;

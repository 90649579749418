import React from 'react';
import { ClassNameProp } from '../../../../types/stylingProps';
import styles from './card.module.scss';

const Card: React.FC<ClassNameProp> = ({ className, children }) => {
    return (
        <div className={`${styles.card} ${className ?? ''}`.trim()}>
            {children}
        </div>
    );
};

export default Card;

import { t } from 'i18next';
import React from 'react';
import { default as ReactSelect } from 'react-select';
import { Option } from '../../utils/option';
import styles from './select.module.scss';

interface SelectProps {
    onChange: (option: Option | readonly Option[] | null) => void;
    name?: string;
    openingDirection?: 'top' | 'bottom';
    options: Option<string>[];
    isMulti?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    defaultValue?: any | readonly any[] | null;
    isClearable?: boolean;
    isRequired?: boolean;
}

const Select: React.FC<SelectProps> = ({
    onChange,
    name,
    defaultValue: value,
    options = [],
    openingDirection = 'bottom',
    isMulti = false,
    isClearable = false,
    isRequired = false,
}) => {
    const menuStyles =
        openingDirection === 'top' ? { top: 'auto', bottom: '100%' } : {};

    const getDefaultValue = () => {
        return options.find(o => o.value === value);
    };

    return (
        <ReactSelect
            name={name}
            className={styles.container}
            styles={{
                menu: provided => ({
                    ...provided,
                    ...menuStyles,
                    borderRadius: '10px',
                }),
                control: provided => ({
                    ...provided,
                    borderColor: 'transparent',
                    borderRadius: '10px',
                    paddingLeft: '.5rem',
                    paddingRight: '.5rem',
                    minHeight: '50px',
                }),
                menuList: provided => ({
                    ...provided,
                    paddingTop: '8px',
                    paddingBottom: '8px',
                }),
            }}
            options={options}
            isClearable={isClearable}
            isMulti={isMulti}
            value={getDefaultValue()}
            placeholder={`${t('common.pleaseSelect')}${isRequired ? '*' : ''}`}
            onChange={option => onChange(option)}
        />
    );
};

export default Select;

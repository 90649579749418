import { gql } from '@apollo/client';
import { FileDescriptionFragments } from './fileDescriptionFragments';
import { OrderFragments } from './orderFragments';

const MediaResponse = gql`
    fragment MediaChatMessage on ChatMessage {
        id
        uuid
        chatId
        createdAt
        createdBy
        updatedAt
        updatedBy
        files {
            ...FullFileDescription
        }
    }
    ${FileDescriptionFragments.FullResponse}
`;

const RepliedChatMessageResponse = gql`
    fragment RepliedChatMessage on ChatMessage {
        id
        uuid
        chatId
        type
        text
        createdAt
        createdBy
        updatedAt
        updatedBy
        files {
            ...FullFileDescription
        }
        author {
            id
            title
            firstname
            lastname
        }
        authorCustomer {
            id
            contact {
                id
                title
                firstname
                lastname
                email
            }
        }
        authorEmployee {
            id
            contact {
                id
                title
                firstname
                lastname
                email
            }
        }
        order {
            ...FullOrder
        }
    }
    ${OrderFragments.FullOrder}
    ${FileDescriptionFragments.FullResponse}
`;

const FullResponse = gql`
    fragment FullChatMessage on ChatMessage {
        id
        uuid
        chatId
        type
        text
        createdAt
        createdBy
        updatedAt
        updatedBy
        isReceived
        isViewed
        isReceivedByMe
        isViewedByMe
        participants {
            user {
                id
                title
                firstname
                lastname
            }
        }
        files {
            ...FullFileDescription
        }
        author {
            id
            title
            firstname
            lastname
        }
        authorCustomer {
            id
            contact {
                id
                title
                firstname
                lastname
                email
            }
        }
        authorEmployee {
            id
            contact {
                id
                title
                firstname
                lastname
                email
            }
        }
        order {
            ...FullOrder
        }
        repliedChatMessage {
            ...RepliedChatMessage
        }
    }
    ${RepliedChatMessageResponse}
    ${OrderFragments.FullOrder}
    ${FileDescriptionFragments.FullResponse}
`;

export const ChatMessageFragments = {
    FullResponse,
    MediaResponse,
};

import { gql } from '@apollo/client';

const FullChatToUser = gql`
    fragment FullChatToUser on ChatToUser {
        userId
        chatId
        createdAt
        createdBy
        adminSince
        adminBy
        isReadWhenViewedByCompany
        user {
            id
        }
        chat {
            id
        }
    }
`;

export const ChatToUserFragments = { FullChatToUser };

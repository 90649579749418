import React from 'react';
import { ClassNameProp } from '../../types/stylingProps';
import { ButtonProps } from '../button/button';
import styles from './textButton.module.scss';

const TextButton: React.FC<ButtonProps & ClassNameProp> = ({
    text,
    onClick,
    style,
    disabled,
    className,
}) => {
    let buttonStyle;
    switch (style) {
        case 'secondary':
            buttonStyle = styles.secondary;
            break;

        default:
            buttonStyle = styles.primary;
            break;
    }

    const handleClick = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
        if (!disabled) {
            if (onClick) {
                onClick(event);
            }
        }
    };

    return (
        <button
            className={`${styles.container} ${buttonStyle} ${
                disabled ? styles.disabled : ''
            } ${className ?? ''}`.trim()}
            onClick={handleClick}>
            {text}
        </button>
    );
};

export default TextButton;

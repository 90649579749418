import { gql } from '@apollo/client';
import { User } from '../dto';
import { UserFragments } from '../fragments';

export const ON_USER_UPDATE = gql`
    subscription OnUserUpdate {
        onUserUpdate {
            ...FullUser
        }
    }
    ${UserFragments.FullUser}
`;

export const ON_USER_UPDATE_WITH_INVITATIONS_AND_LICENCES = gql`
    subscription OnUserUpdateWithInvitationsAndLicences {
        onUserUpdate {
            ...FullUserWithInvitationAndLicences
        }
    }
    ${UserFragments.FullUserWithInvitationAndLicences}
`;

export interface OnUserUpdateData {
    onUserUpdate: User;
}

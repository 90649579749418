export enum ErrorCode {
    AnimalAlreadyRelatedToThatUser = 'AnimalAlreadyRelatedToThatUser',
    CouldNotCreateWherebyMeeting = 'CouldNotCreateWherebyMeeting',
    EmailAlreadyTaken = 'EmailAlreadyTaken',
    EmailMustNotContainWhitespace = 'EmailMustNotContainWhitespace',
    FileDescriptionNotFound = 'FileDescriptionNotFound',
    OutdatedVersion = 'OutdatedVersion',
    PasswordIsWrong = 'PasswordIsWrong',
    PhoneNumberAlreadyTaken = 'PhoneNumberAlreadyTaken',
    UnknownLogLevel = 'UnknownLogLevel',
    UserCannotBeDeletedBecauseOfEmployeeRelation = 'UserCannotBeDeletedBecauseOfEmployeeRelation',
    UserCannotBeDeletedBecauseOfOpenAppointments = 'UserCannotBeDeletedBecauseOfOpenAppointments',
    UserCannotBeDeletedBecauseOfPendingPayments = 'UserCannotBeDeletedBecauseOfPendingPayments',
    UserHasNoPasswordResetToken = 'UserHasNoPasswordResetToken',
    UserHasNoPhoneActivationCode = 'UserHasNoPhoneActivationCode',
    UserHasNoPhoneNumber = 'UserHasNoPhoneNumber',
    UserNotFound = 'UserNotFound',
    UsernameAlreadyTaken = 'UsernameAlreadyTaken',
    UsernameOrPasswordIsWrong = 'UsernameOrPasswordIsWrong',
    WrongActivationCodeEmail = 'WrongActivationCodeEmail',
    WrongActivationCodeSms = 'WrongActivationCodeSms',
    WrongPasswordResetToken = 'WrongPasswordResetToken',
}

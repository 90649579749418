import React from 'react';
import Icon from '../icon/icon';
import styles from './navBackButton.module.scss';

interface NavBackButtonProps {
  position: {
    top: number;
    left: number;
  };
  onClick: () => void;
}

const NavBackButton: React.FC<NavBackButtonProps> = ({
  position: coordinates,
  onClick,
}) => {
  return (
    <div
      className={styles.navBackBtn}
      style={{
        top: `${coordinates.top}px`,
        left: `${coordinates.left}px`,
      }}
      onClick={onClick}
    >
      <span style={{ paddingTop: '5px', paddingRight: '2px' }}>
        <Icon name='chevron-back-outline' size={28} />
      </span>
    </div>
  );
};

export default NavBackButton;

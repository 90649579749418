import { gql } from '@apollo/client';

const FullResponse = gql`
    mutation InviteUserToBecomeEmployee($employeeId: Int!) {
        inviteUserToBecomeEmployee(employeeId: $employeeId)
    }
`;

export interface InviteUserToBecomeEmployeeData {
    inviteUserToBecomeEmployee: boolean;
}

export const InviteUserToBecomeEmployeeMutation = {
    FullResponse,
};

import { useEffect, useState } from 'react';
import { Observable } from 'rxjs';

export function useObservable<T>(observable: Observable<T>, defaultValue: T): T;

export function useObservable<T = undefined>(
  observable: Observable<T>
): T | undefined;

export function useObservable<T = undefined>(
  observable: Observable<T>,
  defaultValue?: T
): T | undefined {
  const [value, setValue] = useState<T | undefined>(defaultValue);

  useEffect(() => {
    const subscription = observable.subscribe((nextValue) => {
      setValue(nextValue);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [observable]);

  return value;
}

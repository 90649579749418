import { v4 as uuidv4 } from 'uuid';

export const addDashesToUuid = (uuidAsHex: string): string => {
    const original = uuidAsHex;

    const indices = [8, 12, 16, 20, 32];
    const delimiter = '-';

    let uuid = '';
    let prev = 0;

    indices.forEach((index, i) => {
        uuid += original.slice(prev, index);
        prev = index;
        if (Number(i) < indices.length - 1) {
            uuid += delimiter;
        }
    });

    return uuid;
};

export const getUuidFromBuffer = (buffer: Buffer): string => {
    return addDashesToUuid(buffer.toString('hex'));
};

export const getBufferFromUuid = (uuid: string): Buffer => {
    return Buffer.from(uuid.replace(/-/g, ''), 'hex');
};

export const generateUuidV4 = (): string => {
    return uuidv4();
};

export const UuidUtils = {
    addDashesToUuid,
    getUuidFromBuffer,
    getBufferFromUuid,
    generateUuidV4,
};

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NavBackButton from '../../../../components/navBackButton/navBackButton';
import { useObservable } from '../../../../hooks/useObservable';
import { useScreenHasLessWidthThan } from '../../../../hooks/useScreenSize';
import RxCompany from '../../../../rxjs/RxCompany';
import RxElectron from '../../../../rxjs/RxElectron';
import AnimalChatRoutes from '../../../../utils/routing';
import { isElectron } from '../../../../__shared/electron';
import Company from '../company/company';
import Employees from '../employees/employees';
import Stripe from '../payments/payment';
import ServiceTimes from '../serviceTimes/serviceTimes';
import Subscriptions from '../subscriptions/subscriptions';
import Support from '../support/support';
import Widget from '../widget/widget';
import styles from './settingsMain.module.scss';

interface SettingsMainProps {
    subRoute?: string;
    setShowMain?(show: boolean): void;
}

const SettingsMain: React.FC<SettingsMainProps> = ({
    subRoute,
    setShowMain,
}) => {
    const company = useObservable(RxCompany.currentCompany$);
    const isWindows = useObservable(RxElectron.isWindows$, true);
    const { t } = useTranslation();
    const [titleKey, setTitleKey] = useState<string>('');

    useEffect(() => {
        switch (subRoute) {
            case AnimalChatRoutes.settingsSub.company:
                setTitleKey('screens.settings.navigation.entries.company');
                break;
            case AnimalChatRoutes.settingsSub.employees:
                setTitleKey('screens.settings.navigation.entries.employees');
                break;
            case AnimalChatRoutes.settingsSub.serviceTimes:
                setTitleKey('screens.settings.navigation.entries.serviceTimes');
                break;
            case AnimalChatRoutes.settingsSub.stripe:
                setTitleKey('screens.settings.navigation.entries.payments');
                break;
            case AnimalChatRoutes.settingsSub.widget:
                setTitleKey('screens.settings.navigation.entries.widget');
                break;
            case AnimalChatRoutes.settingsSub.support:
                setTitleKey('screens.settings.navigation.entries.support');
                break;
            case AnimalChatRoutes.settingsSub.subscriptions:
                setTitleKey(
                    'screens.settings.navigation.entries.subscriptions',
                );
                break;

            default:
                setTitleKey('screens.settings.navigation.entries.company');
                break;
        }
    }, [subRoute]);

    const screenHasLessWidthThanXxs = useScreenHasLessWidthThan('xxs');

    return (
        <>
            <div className={styles.header}>
                {screenHasLessWidthThanXxs && (
                    <NavBackButton
                        position={{
                            top: 14,
                            left: isElectron() && !isWindows ? 74 : 14,
                        }}
                        onClick={() =>
                            setShowMain ? setShowMain(false) : null
                        }
                    />
                )}
                <div
                    style={
                        screenHasLessWidthThanXxs && isElectron() && !isWindows
                            ? { paddingLeft: '60px' }
                            : {}
                    }>
                    {t(titleKey)}
                </div>
            </div>
            <div className={styles.content}>
                {(!subRoute ||
                    subRoute === AnimalChatRoutes.settingsSub.company) && (
                    <Company />
                )}
                {subRoute === AnimalChatRoutes.settingsSub.employees && (
                    <Employees />
                )}
                {subRoute === AnimalChatRoutes.settingsSub.stripe && <Stripe />}
                {subRoute === AnimalChatRoutes.settingsSub.serviceTimes && (
                    <ServiceTimes />
                )}
                {subRoute === AnimalChatRoutes.settingsSub.widget && <Widget />}
                {subRoute === AnimalChatRoutes.settingsSub.support && (
                    <Support />
                )}
                {subRoute === AnimalChatRoutes.settingsSub.subscriptions &&
                    company && <Subscriptions company={company} />}
            </div>
        </>
    );
};

export default SettingsMain;

import { gql } from '@apollo/client';
import { CompanyFragments } from './companyFragments';
import { FileDescriptionFragments } from './fileDescriptionFragments';
import { FragmentDefinition } from './fragmentDefinition';
// import { FileDescriptionFragments, FragmentDefinition } from './index';
import { LicenceFragments } from './licenceFragments';
import { UserFragments } from './userFragments';

const FullLicenceToCompany = gql`
    fragment FullLicenceToCompany on LicenceToCompany {
        id
        licenceId
        companyId
        cancelledAt
        cancelledFor
        cancelledBy
        specialCancellationFor
        reasonForCancellation
        validSince
        userId
        createdBy
        createdAt
        updatedBy
        updatedAt
        user {
            ...FullUser
        }
        licence {
            ...FullLicence
        }
        company {
            ...FullCompany
        }
    }
    ${UserFragments.FullUser}
    ${LicenceFragments.FullLicence}
    ${CompanyFragments.FullCompany}
`;

const LicenceToCompanyWithLicence = gql`
    fragment LicenceToCompanyWithLicence on LicenceToCompany {
        id
        licenceId
        companyId
        cancelledAt
        cancelledFor
        cancelledBy
        specialCancellationFor
        reasonForCancellation
        validSince
        userId
        createdBy
        createdAt
        updatedBy
        updatedAt
        licence {
            ...FullLicence
        }
    }
    ${LicenceFragments.FullLicence}
`;

const LicenceToCompanyWithLicenceAndUser = gql`
    fragment LicenceToCompanyWithLicenceAndUser on LicenceToCompany {
        id
        licenceId
        companyId
        cancelledAt
        cancelledFor
        cancelledBy
        specialCancellationFor
        reasonForCancellation
        validSince
        userId
        createdBy
        createdAt
        updatedBy
        updatedAt
        user {
            ...FullUser
        }
        licence {
            ...FullLicence
        }
        company {
            name
            id
            colorBundle {
                hexColor
                hexColorGradient
            }
            imageFile {
                ...FullFileDescription
            }
        }
    }
    ${UserFragments.FullUser}
    ${LicenceFragments.FullLicence}
    ${FileDescriptionFragments.FullResponse}
`;

export const LicenceToCompanyFragments: FragmentDefinition = {
    FullLicenceToCompany,
    LicenceToCompanyWithLicence,
    LicenceToCompanyWithLicenceAndUser,
};

import { gql } from '@apollo/client';

export interface LoginData {
    login: string;
}

const Default = gql`
    mutation Login($email: String!, $password: String!) {
        login(email: $email, password: $password)
    }
`;

export const LoginMutation = {
    Default,
};

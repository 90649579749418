import { useQuery } from '@apollo/client';
import React, { useEffect, useLayoutEffect, useRef } from 'react';
import { FadeLoader } from 'react-spinners';
import ApiSearchParams from '../../../../api/searchParams';
import { FindChatsForUserQueries } from '../../../../graphql/queries/findChatForUserQueries';
import { useObservable } from '../../../../hooks/useObservable';
import RxAuthentication from '../../../../rxjs/RxAuthentication';
import RxChat from '../../../../rxjs/RxChat';
import RxCompany from '../../../../rxjs/RxCompany';
import RxPageLifecycle from '../../../../rxjs/RxPageLifecycle';
import { hasScrollbar } from '../../../../utils/htmlUtils';
import { PageLifecycleStateTypes } from '../../../../utils/pageLifecycleUtils';
import { LogClient } from '../../../../__shared/common';
import {
    FindChatsForUserData,
    QueryFindChatsForUserArgs,
} from '../../../../__shared/graphql';
import styles from './chats.module.scss';
import ChatsEntry from './chatsEntry';

const PageSize = 10;

const LogPrefix = '[Chats]';

// Load more if the visible end of chat list reached X percent of the chat list scroll height
const LoadMoreBreakevenOnScrollPositionInPercent = 0.8;

const Chats: React.FC = () => {
    const chats = useObservable(RxChat.chats$, []);
    const company = useObservable(RxCompany.currentCompany$);
    const userId = useObservable(RxAuthentication.userId$);
    const hasMoreChatResults = useRef(true);
    const reachedScrollbarHeight = useRef(false);
    const userScrolledNearBottom = useRef(false);
    const loadMoreCursorId = useRef(0);
    const ulRef = useRef<HTMLUListElement>(null);
    const currentPageLifecycleChangeEvent = useObservable(
        RxPageLifecycle.currentPageLifecycleChangeEvent$,
        undefined,
    );

    const findChatsForUserSearchParams = useRef<ApiSearchParams>({
        searchInput: '',
        sortOrder: 'desc',
        pageSize: PageSize,
        idCursor: loadMoreCursorId.current,
    });

    const {
        loading: findChatsForUserLoading,
        refetch: findChatsForUserRefetch,
    } = useQuery<FindChatsForUserData, QueryFindChatsForUserArgs>(
        FindChatsForUserQueries.ChatForChatListEntry,
        {
            variables: {
                ...findChatsForUserSearchParams.current,
            }, // TODO: Add pagination to chatlist!
            notifyOnNetworkStatusChange: true,
            skip: !userId || !company?.id,
            onError: error => {
                LogClient.error(
                    `${LogPrefix} Error while fetching chats`,
                    error,
                );
            },
            onCompleted: data => {
                const newLoadedChats = data.findChatsForUser;
                LogClient.debug(`${LogPrefix} Received new chats`, {
                    chatIds: newLoadedChats
                        ? newLoadedChats?.map(x => x.id)
                        : [],
                });
                LogClient.trace(`${LogPrefix} ulRef ${ulRef.current}`);
                if (!newLoadedChats?.length) {
                    hasMoreChatResults.current = false;
                    return;
                }
                RxChat.addChats(data.findChatsForUser);
                const idCursor = newLoadedChats[newLoadedChats.length - 1].id;
                if (idCursor) {
                    loadMoreCursorId.current = idCursor;
                }
                if (userScrolledNearBottom.current) {
                    LogClient.trace(
                        `${LogPrefix} load more because there is a "stored" scroll event`,
                    );
                    loadMore();
                }
            },
        },
    );

    useEffect(() => {
        if (
            currentPageLifecycleChangeEvent?.prevState ===
            PageLifecycleStateTypes.Frozen
        ) {
            // Wait because after frozen state maybe apollo client is recreated first
            setTimeout(() => {
                findChatsForUserRefetch();
            }, 200);
        }
    }, [currentPageLifecycleChangeEvent, findChatsForUserRefetch]);

    const loadMore = () => {
        LogClient.trace(
            `${LogPrefix} Loading more with idCursor: ${loadMoreCursorId.current}`,
        );
        userScrolledNearBottom.current = false;
        findChatsForUserRefetch({
            ...findChatsForUserSearchParams.current,
            idCursor: loadMoreCursorId.current,
        }).catch(e => {
            LogClient.error(`${LogPrefix} error while loading more`, e);
        });
    };

    // initially load chats until the <ul>-element gets a scrollbar
    useLayoutEffect(() => {
        LogClient.trace(`${LogPrefix} Loaded chats: ${chats?.length ?? 0}`);
        // auto load more until the chat last has a scroll bar
        if (reachedScrollbarHeight.current) {
            LogClient.trace(
                `${LogPrefix} auto loaded till scrollbar is reached`,
            );
            return;
        }
        if (!ulRef.current) {
            LogClient.trace(
                `${LogPrefix} Cannot calculate scrollbar on chat list, ulRef is not yet assigned`,
            );
            return;
        }
        if (hasScrollbar(ulRef.current)) {
            reachedScrollbarHeight.current = true;
            return;
        }
        if (!chats?.length || !hasMoreChatResults.current) {
            return;
        }
        loadMore();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chats]);

    // trigger load in case the user resizes and the list grows
    // the list might grow tall enough, that the user cannot scroll any more
    // and has no trigger to load more chats although there might be more
    useEffect(() => {
        const resizeListener = () => {
            if (!hasMoreChatResults.current) {
                return;
            }
            reachedScrollbarHeight.current = false;
            loadMore();
        };
        window.addEventListener('resize', resizeListener);
        return () => {
            window.removeEventListener('resize', resizeListener);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ul
            onScroll={e => {
                const ulElement = e.currentTarget;
                const bottomOfListPositionToScrollHeightInPercent =
                    (ulElement.offsetHeight + ulElement.scrollTop) /
                    ulElement.scrollHeight;
                LogClient.trace(
                    `${LogPrefix} ScrollPosition: ${
                        bottomOfListPositionToScrollHeightInPercent * 100
                    }%`,
                );

                if (
                    !hasMoreChatResults.current ||
                    bottomOfListPositionToScrollHeightInPercent <
                        LoadMoreBreakevenOnScrollPositionInPercent
                ) {
                    return;
                }
                if (findChatsForUserLoading) {
                    // "store" scroll trigger for when the current loading phase ends and trigger another load more
                    userScrolledNearBottom.current = true;
                    return;
                }
                loadMore();
            }}
            ref={ulRef}
            className={styles.chats}>
            {chats.map(chat => {
                return <ChatsEntry key={`chat-${chat?.uuid}`} chat={chat} />;
            })}
            {findChatsForUserLoading && (
                <li className={styles.loadingSpinner}>
                    <FadeLoader
                        color={'grey'}
                        height={10}
                        width={3}
                        radius={10}
                        margin={-2}
                    />
                </li>
            )}
        </ul>
    );
};

export default Chats;

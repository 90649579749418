import { Licence } from '../../graphql';

//#region UserLicence
export type UserLicenceType = 'consultant' | 'member' | 'starter';

interface IUserLicenceTypes {
    Consultant: 'consultant';
    Member: 'member';
    Starter: 'starter';
}
export const UserLicenceTypes: IUserLicenceTypes = {
    Consultant: 'consultant',
    Member: 'member',
    Starter: 'starter',
};

export const isUserLicenceType = (
    licence?: string,
): licence is UserLicenceType => {
    if (!licence) {
        return false;
    }
    return Object.values(UserLicenceTypes).some(l => l === licence);
};
//#endregion UserLicence

//#region CompanyLicence
export type CompanyLicenceType =
    | 'free'
    | 'standardPerMonth'
    | 'standardPerYear';

interface ICompanyLicenceTypes {
    Free: 'free';
    StandardPerMonth: 'standardPerMonth';
    StandardPerYear: 'standardPerYear';
}

export const CompanyLicenceTypes: ICompanyLicenceTypes = {
    Free: 'free',
    StandardPerMonth: 'standardPerMonth',
    StandardPerYear: 'standardPerYear',
};

export const isCompanyLicenceType = (
    licence?: string,
): licence is CompanyLicenceType => {
    if (!licence) {
        return false;
    }
    return Object.values(CompanyLicenceTypes).some(l => l === licence);
};
//#endregion CompanyLicence

//#region FeatureLicence
export type FeatureLicenceType = 'broadcast';

interface IFeatureLicences {
    Broadcast: 'broadcast';
}

export const FeatureLicences: IFeatureLicences = {
    Broadcast: 'broadcast',
};

export const isFeatureLicenceType = (
    licence?: string,
): licence is FeatureLicenceType => {
    if (!licence) {
        return false;
    }
    return Object.values(FeatureLicences).some(l => l === licence);
};

//#endregion FeatureLicence

//#region Licence
export const LicenceTypes = {
    User: UserLicenceTypes,
    Company: CompanyLicenceTypes,
};

export type LicenceType =
    | UserLicenceType
    | CompanyLicenceType
    | FeatureLicenceType;

export const isLicenceType = (licence: string): licence is LicenceType => {
    return isUserLicenceType(licence) || isCompanyLicenceType(licence);
};
//#endregion Licence

//#region SpecialNoticeReasons
export type SpecialNoticeReasons = '';

//#endregion

//#region Helper Functions
export const filterPurchasableUserLicencesForFreeCompany = (
    licences: Licence[],
): Licence[] => {
    return licences.filter(lic => lic.type === UserLicenceTypes.Starter);
};

export const filterPurchasableUserLicencesForAnyStandardCompany = (
    licences: Licence[],
): Licence[] => {
    return licences.filter(
        lic =>
            lic.type === UserLicenceTypes.Member ||
            lic.type === UserLicenceTypes.Consultant,
    );
};

export const filterLicencesForUpgrade = (
    sourceLicence: Licence,
    licences: Licence[],
): Licence[] => {
    switch (sourceLicence.type) {
        case UserLicenceTypes.Member:
            return licences.filter(
                lic => lic.type === UserLicenceTypes.Consultant,
            );
        case CompanyLicenceTypes.Free:
            return licences.filter(
                lic =>
                    lic.type === CompanyLicenceTypes.StandardPerMonth ||
                    lic.type === CompanyLicenceTypes.StandardPerYear,
            );
        case CompanyLicenceTypes.StandardPerMonth:
            return licences.filter(
                lic => lic.type === CompanyLicenceTypes.StandardPerYear,
            );

        default:
            return [];
    }
};
//#endregion

import React, { useEffect, useState } from 'react';
import DrawerMenu from './components/drawerMenu/drawerMenu';
import { Outlet } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { RxDrawerMenu } from '../../rxjs/RxDrawerMenu';
import useAutoLogin from '../../hooks/useAutoLogin';
import logUtil from '../../utils/logUtil';
import AnimalChatRoutes from '../../utils/routing';
import { useScreenHasLessWidthThan } from '../../hooks/useScreenSize';
import UserDataHandler from '../../stateHandlers/userDataHandler';
import CompanyDataHandler from '../../stateHandlers/companyDataHandler';
import { useObservable } from '../../__shared/react';
import RxCompany from '../../rxjs/RxCompany';
import RxAuthentication from '../../rxjs/RxAuthentication';
import { isElectron } from '../../__shared/electron';

const _isElectron = isElectron();

const MainBusinessLayout = (): JSX.Element => {
    logUtil.log(`[MainBusinessLayout]: isElectron: ${_isElectron}`);
    const navigate = useNavigate();
    if (_isElectron) {
        logUtil.log(
            `[MainBusinessLayout]: redirect to app. No Business Route allowed in the electron Shell`,
        );
        navigate(AnimalChatRoutes.app);
    }

    const userId = useObservable(RxAuthentication.userId$, 0); // get from above?
    const currentCompany = useObservable(RxCompany.currentCompany$);

    const { finishedLoading } = useAutoLogin();

    const screenHasLessWidthThanXs = useScreenHasLessWidthThan('xs');

    logUtil.log(
        `[MainBusinessLayout]: screenHasLessWidthThanXs:`,
        screenHasLessWidthThanXs,
    );

    const [finishedInitialisation, setFinishedInitialisation] = useState<
        boolean | undefined
    >(undefined);

    useEffect(() => {
        if (!finishedLoading) {
            setFinishedInitialisation(true);
        }
    }, [finishedLoading]);

    useEffect(() => {
        logUtil.log('finishedInitialisation:', finishedInitialisation);
    }, [finishedInitialisation]);

    const loadUserAndCompanyDataHandler = !!userId;

    return (
        <div
            style={{ flex: 1, background: 'white' }}
            onClick={() => {
                logUtil.log('MainBusinessLayout clicked');
            }}>
            {loadUserAndCompanyDataHandler && (
                <>
                    <UserDataHandler userId={userId} />
                    {currentCompany?.id && (
                        <CompanyDataHandler
                            userId={userId}
                            companyId={currentCompany?.id}
                        />
                    )}
                </>
            )}
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <DrawerMenu />
                <div
                    onClick={() => {
                        if (screenHasLessWidthThanXs) {
                            RxDrawerMenu.setShowDrawerMenu(false);
                        }
                    }}
                    style={{
                        display: 'flex',
                        flex: 1,
                        overflowY: 'auto',
                        width: '100%',
                        flexDirection: 'column',
                        height: '100vh',
                        minWidth: '250px',
                    }}>
                    {finishedInitialisation === true && <Outlet />}
                </div>
            </div>
        </div>
    );
};

export default MainBusinessLayout;

import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/button/button';
import { downloadFile } from '../../../../__shared/electron';
import styles from './downloads.module.scss';

const windowsText = 'screens.business.company.downloads.downloadForWindowsX64';
const windowsLink = 'https://pro.animalchat.net/animalchat-pro-setup.exe';
const macText = 'screens.business.company.downloads.downloadForMacOS';
const macLink = 'https://pro.animalchat.net/animalchat-pro.dmg';

const isMac = navigator.userAgent.toLowerCase().indexOf('mac') !== -1;

const primaryText = isMac ? macText : windowsText;
const primaryLink = isMac ? macLink : windowsLink;
const secondaryText = isMac ? windowsText : macText;
const secondaryLink = isMac ? windowsLink : macLink;

const Downloads: React.FC = () => {
    const { t } = useTranslation();
    return (
        <div className={styles.container}>
            <p>{t('screens.business.company.downloads.downloadSetup')}</p>
            <Button
                text={t(primaryText)}
                onClick={() => downloadFile(primaryLink)}
                className={styles.button}
            />
            <p>{t('screens.business.company.downloads.otherDownloads')}</p>
            <Button
                text={t(secondaryText)}
                onClick={() => downloadFile(secondaryLink)}
                style="secondary"
                className={`${styles.button} ${styles.secondaryButton}`}
            />
        </div>
    );
};

export default Downloads;

import { gql } from '@apollo/client';
import { ChatMessage } from '../dto';
import { ChatMessageFragments } from '../fragments';

const MediaResponse = gql`
    query FindChatMessages(
        $searchInput: String!
        $chatId: Int
        $animalId: Int
        $animalGroupId: Int
        $topicId: Int
        $companyId: Int
        $userId: Int
        $idCursor: Int
        $pageSize: Int
        $sortOrder: String
        $onlyFiles: Boolean
    ) {
        findChatMessages(
            searchInput: $searchInput
            chatId: $chatId
            animalId: $animalId
            animalGroupId: $animalGroupId
            topicId: $topicId
            companyId: $companyId
            userId: $userId
            idCursor: $idCursor
            pageSize: $pageSize
            sortOrder: $sortOrder
            onlyFiles: $onlyFiles
        ) {
            ...MediaChatMessage
        }
    }
    ${ChatMessageFragments.MediaResponse}
`;

const FullResponse = gql`
    query FindChatMessages(
        $searchInput: String!
        $chatId: Int
        $animalId: Int
        $animalGroupId: Int
        $topicId: Int
        $companyId: Int
        $userId: Int
        $idCursor: Int
        $pageSize: Int
        $sortOrder: String
        $onlyFiles: Boolean
    ) {
        findChatMessages(
            searchInput: $searchInput
            chatId: $chatId
            animalId: $animalId
            animalGroupId: $animalGroupId
            topicId: $topicId
            companyId: $companyId
            userId: $userId
            idCursor: $idCursor
            pageSize: $pageSize
            sortOrder: $sortOrder
            onlyFiles: $onlyFiles
        ) {
            ...FullChatMessage
        }
    }
    ${ChatMessageFragments.FullResponse}
`;

export type FindMessagesData = {
    findChatMessages: ChatMessage[];
};

export const FindMessagesQuery = {
    FullResponse,
    MediaResponse,
};

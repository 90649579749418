import { ChatToUserFragments } from './../fragments/chatToUserFragments';
import { gql } from '@apollo/client';
import { ChatToUser } from '../dto';

const UpdateChatToUser = gql`
    mutation UpdateChatToUser($chatToUserUpdate: ChatToUserUpdate!) {
        updateChatToUser(chatToUserUpdate: $chatToUserUpdate) {
            ...FullChatToUser
        }
    }
    ${ChatToUserFragments.FullChatToUser}
`;

export const ChatToUserMutations = {
    UpdateChatToUser,
};

export interface UpdateChatToUserData {
    updateChatToUser: ChatToUser;
}

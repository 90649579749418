import { ServiceHours } from '../../../../__shared/graphql';
import { DateUtil } from '../../../../__shared/common';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../../../../components/icon/icon';
import { useObservable } from '../../../../hooks/useObservable';
import RxCompany from '../../../../rxjs/RxCompany';
import styles from './timeTable.module.scss';

interface ServiceTimesState {
    [key: string]: {
        dayOfWeek: number;
        times: ServiceHours[];
        opened: boolean;
    };
}

const initialState: ServiceTimesState = {
    monday: { dayOfWeek: 1, times: [], opened: false },
    tuesday: { dayOfWeek: 2, times: [], opened: false },
    wednesday: { dayOfWeek: 3, times: [], opened: false },
    thursday: { dayOfWeek: 4, times: [], opened: false },
    friday: { dayOfWeek: 5, times: [], opened: false },
    saturday: { dayOfWeek: 6, times: [], opened: false },
    sunday: { dayOfWeek: 0, times: [], opened: false },
};

const SortFunc = (a: ServiceHours, b: ServiceHours) => a.from - b.from;

const toDSTSaveDate = (date: Date): Date => {
    const now = new Date();
    return new Date(
        Date.UTC(
            now.getFullYear(),
            now.getMonth(),
            now.getDate(),
            date.getHours(),
            date.getMinutes() + date.getTimezoneOffset(),
        ),
    );
};

const TimeTable: React.FC<{ title: string; onEdit(): void }> = ({
    title,
    onEdit,
}) => {
    const [schedule, setSchedule] = useState(initialState);
    const company = useObservable(RxCompany.currentCompany$);
    const { t } = useTranslation();

    useEffect(() => {
        if (company?.serviceHours) {
            const newState: ServiceTimesState = {
                monday: {
                    dayOfWeek: 1,
                    times:
                        company.serviceHours
                            .filter(s => s.dayOfTheWeek === 1)
                            ?.sort(SortFunc) ?? [],
                    opened: !!company.serviceHours.filter(
                        s => s.dayOfTheWeek === 1,
                    )?.length,
                },
                tuesday: {
                    dayOfWeek: 2,
                    times:
                        company.serviceHours
                            .filter(s => s.dayOfTheWeek === 2)
                            ?.sort(SortFunc) ?? [],
                    opened: !!company.serviceHours.filter(
                        s => s.dayOfTheWeek === 2,
                    )?.length,
                },
                thursday: {
                    dayOfWeek: 3,
                    times:
                        company.serviceHours
                            .filter(s => s.dayOfTheWeek === 3)
                            ?.sort(SortFunc) ?? [],
                    opened: !!company.serviceHours.filter(
                        s => s.dayOfTheWeek === 3,
                    )?.length,
                },
                wednesday: {
                    dayOfWeek: 4,
                    times:
                        company.serviceHours
                            .filter(s => s.dayOfTheWeek === 4)
                            ?.sort(SortFunc) ?? [],
                    opened: !!company.serviceHours.filter(
                        s => s.dayOfTheWeek === 4,
                    )?.length,
                },
                friday: {
                    dayOfWeek: 5,
                    times:
                        company.serviceHours
                            .filter(s => s.dayOfTheWeek === 5)
                            ?.sort(SortFunc) ?? [],
                    opened: !!company.serviceHours.filter(
                        s => s.dayOfTheWeek === 5,
                    )?.length,
                },
                saturday: {
                    dayOfWeek: 6,
                    times:
                        company.serviceHours
                            .filter(s => s.dayOfTheWeek === 6)
                            ?.sort(SortFunc) ?? [],
                    opened: !!company.serviceHours.filter(
                        s => s.dayOfTheWeek === 6,
                    )?.length,
                },
                sunday: {
                    dayOfWeek: 0,
                    times:
                        company.serviceHours
                            .filter(s => s.dayOfTheWeek === 0)
                            ?.sort(SortFunc) ?? [],
                    opened: !!company.serviceHours.filter(
                        s => s.dayOfTheWeek === 0,
                    )?.length,
                },
            };

            setSchedule(newState);
        }
    }, [company?.serviceHours]);

    return (
        <div className={styles.container}>
            <div className={styles.timeTable}>
                <div className={styles.row}>
                    <p className={styles.title}>{title}</p>
                    <Icon
                        name="pencil"
                        className={styles.editIcon}
                        onClick={onEdit}
                    />
                </div>
                {Object.entries(schedule).map(entry => {
                    let opened = (
                        <div className={styles.times}>
                            {t(
                                `screens.settings.serviceTimes.${
                                    entry[1].opened ? 'opened' : 'closed'
                                }`,
                            )}
                        </div>
                    );
                    if (entry[1].times?.length) {
                        opened = (
                            <div className={styles.times}>
                                {entry[1].times.map(t => (
                                    <span key={`${entry[1].dayOfWeek}${t.id}`}>
                                        {DateUtil.toHHMM(toDSTSaveDate(t.from))}{' '}
                                        - {DateUtil.toHHMM(toDSTSaveDate(t.to))}
                                    </span>
                                ))}
                            </div>
                        );
                    }
                    return (
                        <div className={styles.row} key={entry[1].dayOfWeek}>
                            <span className={styles.day}>
                                {t(`common.days.${entry[1].dayOfWeek}`)}
                            </span>
                            {opened}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default TimeTable;

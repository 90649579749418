import { IpcRenderer } from 'electron';
import { Subject } from 'rxjs';
import {
    isElectron,
    OpenChatEvent,
    OpenChatPayload,
} from '../__shared/electron';
import RxChat from './RxChat';

const onOpenSettingsSubject = new Subject<boolean>();

export const OpenSettingsEvent = 'AC_OPEN_SETTINGS';
export const registerOpenSettingsListener = (): void => {
    let ipcRenderer: IpcRenderer | undefined = undefined;
    if (window && isElectron()) {
        ipcRenderer = window.require('electron').ipcRenderer;
        ipcRenderer.on(OpenSettingsEvent, () => {
            openSettings();
        });
    }
};
const openSettings = (): void => {
    onOpenSettingsSubject.next(true);
};

export const registerOpenChatListener = (): void => {
    let ipcRenderer: IpcRenderer | undefined = undefined;
    if (window && isElectron()) {
        ipcRenderer = window.require('electron').ipcRenderer;
        ipcRenderer.on(
            OpenChatEvent,
            (_event: unknown, payload: OpenChatPayload) => {
                if (payload.chatId) {
                    RxChat.setCurrentChatId(payload.chatId);
                }
            },
        );
    }
};

export const onOpenSettings$ = onOpenSettingsSubject.asObservable();

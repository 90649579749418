import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { useObservable } from '../../hooks/useObservable';
import { RxContextMenu } from '../../rxjs/RxContextMenu';
import { CypressProp } from '../../types/cypressProps';
import { ClassNameProp } from '../../types/stylingProps';
import logUtil from '../../utils/logUtil';
import styles from './ctxMenu.module.scss';

type Place = 'top' | 'bottom';

interface Offsets {
    top?: number;
    bottom?: number;
    left?: number;
    right?: number;
}

interface CtxMenuProps extends CypressProp {
    place?: Place;
    menuClassName?: string;
    buttonClassName?: string;
    button: ReactNode;
    offsets?: Offsets;
}

const buildMenuClass = (show?: boolean, menuClassName?: string): string => {
    let menuClass = styles.menuContainer;
    if (!show) {
        menuClass += ` ${styles.hidden}`;
    }
    if (menuClassName?.length) {
        menuClass += ` ${menuClassName}`;
    }
    return menuClass;
};

export const ContextMenuCloseEvent = 'ac_closecontextmenu';

/**
 * Task list elements (_&lt;li&gt;_) as children
 */
const CtxMenu: React.FC<CtxMenuProps & ClassNameProp> = ({
    offsets = { top: 0, left: 0 },
    button,
    menuClassName,
    buttonClassName,
    children,
    className,
    dataCy,
}) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const ulRef = useRef<HTMLUListElement>(null);
    const [show, setShow] = useState(false);
    const [displayProps, setDisplayProps] = useState<Offsets>({
        top: 0,
        left: 0,
        right: undefined,
        bottom: undefined,
    });

    const currentContextMenu = useObservable(RxContextMenu.currentContextMenu$);

    useEffect(() => {
        if (containerRef !== currentContextMenu) {
            setShow(false);
        }
    }, [currentContextMenu]);

    useEffect(() => {
        setDisplayProps({ ...offsets });
    }, [offsets]);

    // close on escape or close-event
    useEffect(() => {
        const escapeHandler = (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                setShow(false);
            }
        };
        const closeEventHandler = () => setShow(false);
        window.addEventListener('keydown', escapeHandler);
        window.addEventListener(ContextMenuCloseEvent, closeEventHandler);
        return () => {
            window.removeEventListener('keydown', escapeHandler);
            window.removeEventListener(
                ContextMenuCloseEvent,
                closeEventHandler,
            );
        };
    }, []);

    useEffect(() => {
        const current = containerRef.current;
        if (!current) {
            return;
        }

        const mouseLeaveHandler = () => setShow(false);
        window.addEventListener('click', mouseLeaveHandler);
        return () => {
            window.removeEventListener('click', mouseLeaveHandler);
        };
    }, [containerRef]);

    const containerClass = `${styles.container} ${className ?? ''}`.trim();

    const toggleShowMenu = (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    ) => {
        e.stopPropagation();
        logUtil.log('toggleShowMenu');
        if (show) {
            setShow(false);
            RxContextMenu.setCurrentContextMenu(undefined);
        } else {
            setShow(true);
            RxContextMenu.setCurrentContextMenu(containerRef);
        }
    };

    return (
        <div className={containerClass} ref={containerRef} data-cy={dataCy}>
            <div className={buttonClassName ?? ''} onClick={toggleShowMenu}>
                {button}
            </div>
            <ul
                ref={ulRef}
                style={{ ...displayProps }}
                className={buildMenuClass(show, menuClassName)}>
                {children}
            </ul>
        </div>
    );
};

export default CtxMenu;

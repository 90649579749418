import React from 'react';
import ChatMessagesPlaceholder from '../chatMessagesPlaceholder/chatMessagesPlaceholder';
import styles from './chatMainPlaceholder.module.scss';

interface ChatMainPlaceholderProps {
    isLoading: boolean;
}

const ChatMainPlaceholder: React.FC<ChatMainPlaceholderProps> = ({
    isLoading,
}) => {
    return (
        <div className={styles.container} style={{ display: 'flex' }}>
            <section className={styles.main}>
                <ChatMessagesPlaceholder isLoading={isLoading} />
            </section>
        </div>
    );
};

export default ChatMainPlaceholder;

import React from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../../../../../components/icon/icon';
import NavBackButton from '../../../../../components/navBackButton/navBackButton';
import ProfileIcon from '../../../../../components/profileIcon/profileIcon';
import { DefaultColorBundle } from '../../../../../constants';
import { useObservable } from '../../../../../hooks/useObservable';
import { useScreenHasLessWidthThan } from '../../../../../hooks/useScreenSize';
import RxElectron from '../../../../../rxjs/RxElectron';
import { findChatContextOwnerUserFromCustomers } from '../../../../../utils/chatUtils';
import { buildNameFromUserCustomerOrEmployee } from '../../../../../utils/nameUtils';
import { ChatTypes } from '../../../../../__shared/common';
import { isElectron } from '../../../../../__shared/electron';
import {
    Chat,
    ColorBundle,
    FileDescription,
    User,
} from '../../../../../__shared/graphql';
import styles from './chatHeader.module.scss';

export interface ChatHeaderProps {
    chat: Chat | undefined;
    toggleShowDetail: () => void;
    closeCurrentChat: () => void;
}

interface HeaderTexts {
    title: string;
    subTitle?: string;
}

const getHeaderTexts = (chat?: Chat, profileName?: string): HeaderTexts => {
    if (chat?.type === ChatTypes.Internal) {
        const title = chat?.topic?.name ?? '';
        const names = chat.participants?.map(p =>
            buildNameFromUserCustomerOrEmployee({
                user: p.user,
                customer: p.customer,
                employee: p.employee,
            }),
        );

        return {
            title,
            subTitle: names?.join(', '),
        };
    }

    const title = chat?.animal?.name ?? chat?.animalGroup?.name ?? '';
    if (!title) {
        return {
            title: profileName ?? '',
            subTitle: chat?.topic?.name,
        };
    }

    const subTitle = profileName;
    return {
        title,
        subTitle,
    };
};

const getPrimaryImage = (
    chat?: Chat,
    user?: User | null,
): {
    imageFile: FileDescription | undefined;
    colorBundle: ColorBundle | undefined;
} => {
    if (chat?.type === ChatTypes.Internal) {
        return {
            imageFile:
                chat.topic?.imageFile ?? chat?.company?.imageFile ?? undefined,
            colorBundle: chat?.company?.colorBundle ?? DefaultColorBundle,
        };
    }

    if (!chat?.animal && !chat?.animalGroup) {
        return {
            imageFile: user?.imageFile ?? undefined,
            colorBundle: user?.colorBundle ?? DefaultColorBundle,
        };
    }

    if (chat.animal) {
        return {
            imageFile: chat.animal.imageFile ?? undefined,
            colorBundle: chat.animal.colorBundle ?? DefaultColorBundle,
        };
    }

    if (chat.animalGroup) {
        return {
            imageFile: chat.animalGroup.imageFile ?? undefined,
            colorBundle: chat.animalGroup.colorBundle ?? DefaultColorBundle,
        };
    }

    return { imageFile: undefined, colorBundle: DefaultColorBundle };
};

const getSecondaryImage = (
    chat?: Chat,
    user?: User | null,
): {
    imageFile: FileDescription | undefined;
    colorBundle: ColorBundle | undefined;
} => {
    if (chat?.type === ChatTypes.Internal) {
        return { imageFile: undefined, colorBundle: undefined };
    }
    if (!chat?.animal && !chat?.animalGroup) {
        return { imageFile: undefined, colorBundle: undefined };
    }
    return {
        imageFile: user?.imageFile ?? undefined,
        colorBundle: user?.colorBundle ?? DefaultColorBundle,
    };
};

const ChatHeader: React.FC<ChatHeaderProps> = ({
    chat,
    toggleShowDetail: toggleShowDetail,
    closeCurrentChat: closeCurrentChat,
}) => {
    const { t } = useTranslation();
    const isWindows = useObservable(RxElectron.isWindows$, true);
    const user = findChatContextOwnerUserFromCustomers(chat);
    const isInternal = chat?.type === ChatTypes.Internal;

    const { imageFile: primaryImage, colorBundle: primaryColorBundle } =
        getPrimaryImage(chat, user);
    const { imageFile: secondaryImage, colorBundle: secondaryColorBundle } =
        getSecondaryImage(chat, user);

    const isDeletedUser = !!user?.deletedAt;

    const profileName = buildNameFromUserCustomerOrEmployee({
        user,
        customer: chat?.customers?.length ? chat?.customers[0] : null,
        employee: null,
    });

    const headerTexts = getHeaderTexts(
        chat,
        `${profileName} ${
            isDeletedUser ? `(${t('common.deleted')})` : ''
        }`.trim(),
    );

    const screenHasLessWidthThanXxs = useScreenHasLessWidthThan('xxs');
    const primaryImageFile = primaryImage;
    const secondaryImageFile = secondaryImage;

    return (
        <div className={styles.container}>
            {(user || chat?.topic) && (
                <>
                    {screenHasLessWidthThanXxs && (
                        <NavBackButton
                            position={{
                                top: 14,
                                left: isElectron() && !isWindows ? 74 : 14,
                            }}
                            onClick={() => closeCurrentChat()}
                        />
                    )}
                    <ProfileIcon
                        className={styles.profileIcon}
                        style={
                            screenHasLessWidthThanXxs &&
                            isElectron() &&
                            !isWindows
                                ? { marginLeft: '60px' }
                                : {}
                        }
                        fileDescription={primaryImageFile}
                        colorBundle={primaryColorBundle}
                        onClick={toggleShowDetail}
                    />
                    {secondaryImage && (
                        <ProfileIcon
                            className={`${styles.profileIcon} ${styles.secondaryIcon}`}
                            fileDescription={secondaryImageFile}
                            colorBundle={secondaryColorBundle}
                            onClick={toggleShowDetail}
                        />
                    )}
                    <div className={styles.personDetail}>
                        <p className={styles.title} onClick={toggleShowDetail}>
                            {headerTexts.title}
                        </p>
                        <div
                            className={styles.subTitle}
                            onClick={toggleShowDetail}>
                            {isInternal && (
                                <span className={styles.subTitlePrefix}>
                                    {t('screens.chat.internalGroup')}:&nbsp;
                                </span>
                            )}
                            <span>{headerTexts.subTitle}</span>
                        </div>
                    </div>
                    <div className={styles.actions}>
                        <Icon name="search-outline" />
                        <Icon name="chevron-down-outline" />
                    </div>
                </>
            )}
        </div>
    );
};

export default ChatHeader;

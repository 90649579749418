import { gql } from '@apollo/client';
import { AddressFragments } from './addressFragments';
import { ContactFragments } from './contactFragments';
import { FileDescriptionFragments } from './fileDescriptionFragments';
import { ServiceHoursFragments } from './serviceHoursFragments';
import { UserFragments } from './userFragments';

// Note: You might wonder why the invitations from user and also from employee are requested
// Reason is that at the time of a new invitation user and employee are not connected,
// so for companies user is null, and for the existing user the employee is null

const FullCompany = gql`
    fragment FullCompany on Company {
        description
        website
        isWidgetActive
        widgetPosition
        widgetGreeting
        phoneNumber
        emergencyServicePhoneNumber
        stripeAccountId
        isOnboardedInStripe
        createdAt
        updatedAt
        defaultVideoChatTimeSlotInMinutes
        defaultVideoChatPricePerTimeSlotInEuroCents
        firstTimePeriodToAnswerInMinutes
        secondTimePeriodToAnswerInMinutes
        thirdTimePeriodToAnswerInMinutes
        medianResponseTimeInSeconds
        allowsDelayedPaymentMethods
        id
        name
        isActive
        isSearchable
        defaultIsAdvancePayment
        colorBundle {
            hexColor
            hexColorGradient
        }
        nametag
        type
        email
        address {
            ...FullAddress
        }
        employees {
            id
            title
            contact {
                ...FullContact
            }
            user {
                ...FullUserWithInvitationAndLicences
            }
            role {
                key
            }
            invitations {
                userId
                employeeId
                canAccept
            }
        }
        imageFile {
            ...FullFileDescription
        }
        profileInProfileImageFile {
            ...FullFileDescription
        }
        serviceHours {
            ...FullServiceHours
        }
        licencesToCompany {
            id
            licenceId
            companyId
            cancelledAt
            cancelledFor
            cancelledBy
            specialCancellationFor
            reasonForCancellation
            validSince
            userId
            licence {
                id
                type
                durationInMonths
                periodOfNoticeInMonths
            }
        }
    }
    ${ServiceHoursFragments.FullServiceHours}
    ${ContactFragments.FullContact}
    ${UserFragments.FullUserWithInvitationAndLicences}
    ${AddressFragments.FullAddress}
    ${FileDescriptionFragments.FullResponse}
`;

const PublicCompany = gql`
    fragment PublicCompany on Company {
        id
        companyUuid
        name
        description
        website
        isWidgetActive
        widgetPosition
        widgetGreeting
        phoneNumber
        isActive
        isSearchable
        emergencyServicePhoneNumber
        medianResponseTimeInSeconds
        defaultIsAdvancePayment
        colorBundle {
            hexColor
            hexColorGradient
        }
        nametag
        type
        email
        address {
            ...FullAddress
        }
        imageFile {
            ...FullFileDescription
        }
        profileInProfileImageFile {
            ...FullFileDescription
        }
    }
    ${AddressFragments.FullAddress}
    ${FileDescriptionFragments.FullResponse}
`;

export const CompanyFragments = {
    FullCompany,
    PublicCompany,
};

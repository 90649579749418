import React, { useEffect } from 'react';
import ReactPlayer, { ReactPlayerProps } from 'react-player';
import { FileDescription } from '../../__shared/graphql';
import { useLoadSourceFromApiUrl } from '../../hooks/useLoadSourceFromApiUrl';

interface ApiVideoPlayerProps {
    fileDescription: FileDescription;
    nativeProps?: ReactPlayerProps;
    onLoadingChanged?: (loadingState: boolean) => void;
    onError?: (error: unknown) => void;
}

const ApiVideoPlayer: React.FC<ApiVideoPlayerProps> = ({
    fileDescription,
    nativeProps = {},
    onLoadingChanged,
    onError,
}) => {
    const { loading, error, optimalUrl, fallbackUrl } = useLoadSourceFromApiUrl(
        fileDescription,
        fileDescription,
    );

    useEffect(() => {
        if (typeof onLoadingChanged === 'function') {
            onLoadingChanged(loading);
        }
    }, [onLoadingChanged, loading]);

    useEffect(() => {
        if (typeof onError === 'function') {
            onError(error);
        }
    }, [onError, error]);

    return (
        <ReactPlayer
            {...nativeProps}
            url={
                optimalUrl?.replaceAll('\\', '/') ??
                fallbackUrl?.replaceAll('\\', '/')
            }
        />
    );
};

export default ApiVideoPlayer;

import { isString } from '../utils/compareUtil';

const isSufficientVersion = (
    actualVersion?: string | string[],
    requiredVersion?: string,
): boolean => {
    if (!requiredVersion || !isString(actualVersion)) {
        return false;
    }
    const actualVersions = actualVersion.split('.');
    const requiredVersions = requiredVersion.split('.');

    for (const [index, version] of requiredVersions.entries()) {
        if (index > actualVersions.length - 1) {
            if (version === '0') {
                // eslint-disable-next-line no-continue
                continue;
            }
            return false;
        }
        try {
            const required = Number.parseInt(version, 10);
            const actual = Number.parseInt(actualVersions[index], 10);
            if (actual > required) {
                return true;
            }
            if (required > actual) {
                return false;
            }
        } catch (e) {
            console.error('error while comparing api versions', e);
            return false;
        }
    }

    // equal version numbers
    return true;
};

const isValidAppiOSVersion = (actualVersion?: string | string[]): boolean => {
    const requiredVersion = process.env.MIN_APP_IOS_VERSION;
    return isSufficientVersion(actualVersion, requiredVersion);
};

const isValidAppAndroidVersion = (
    actualVersion?: string | string[],
): boolean => {
    const requiredVersion = process.env.MIN_APP_ANDROID_VERSION;
    return isSufficientVersion(actualVersion, requiredVersion);
};

const isValidDashboardVersion = (
    actualVersion?: string | string[],
): boolean => {
    const requiredVersion = process.env.MIN_DASHBOARD_VERSION;
    return isSufficientVersion(actualVersion, requiredVersion);
};

const isValidDashboardShellVersion = (
    actualVersion?: string | string[],
): boolean => {
    const requiredVersion = process.env.MIN_DASHBOARD_SHELL_VERSION;
    return isSufficientVersion(actualVersion, requiredVersion);
};

const isValidProAppiOSVersion = (
    actualVersion?: string | string[],
): boolean => {
    const requiredVersion = process.env.MIN_PRO_APP_IOS_VERSION;
    return isSufficientVersion(actualVersion, requiredVersion);
};

const isValidProAppAndroidVersion = (
    actualVersion?: string | string[],
): boolean => {
    const requiredVersion = process.env.MIN_PRO_APP_ANDROID_VERSION;
    return isSufficientVersion(actualVersion, requiredVersion);
};

const isValidWidgetVersion = (actualVersion?: string | string[]): boolean => {
    const requiredVersion = process.env.MIN_WIDGET_VERSION;
    return isSufficientVersion(actualVersion, requiredVersion);
};

export interface VersionErrorObject {
    message: string;
    errorCode: string;
    supportedApiVersion: {
        [x: string]: string | undefined;
    };
}

export const apiVersionUtil = {
    isSufficientVersion,
    isValidAppAndroidVersion,
    isValidAppiOSVersion,
    isValidDashboardShellVersion,
    isValidDashboardVersion,
    isValidProAppiOSVersion,
    isValidProAppAndroidVersion,
    isValidWidgetVersion,
};

import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { FadeLoader } from 'react-spinners';
import { v4 as uuidv4 } from 'uuid';
import CounterBadge from '../../../../../components/counterBadge/counterBadge';
import ScrollToBottomButton from '../../../../../components/scrollToBottomButton/scrollToBottomButton';
import { PAGE_SIZE } from '../../../../../environment/env';
import useCloseInquiry from '../../../../../hooks/useCloseInquiry';
import { useObservable } from '../../../../../hooks/useObservable';
import { publishShowPreviewMessages } from '../../../../../rxjs/onShowPreviewMessages';
import RxAuthentication from '../../../../../rxjs/RxAuthentication';
import RxChat, { ScrollBehaviour } from '../../../../../rxjs/RxChat';
import RxCompany from '../../../../../rxjs/RxCompany';
import { sortByCreatedAt } from '../../../../../utils/arrayUtils';
import { filterMessagesWithImageAndVideo } from '../../../../../utils/fileUtils';
import logUtil from '../../../../../utils/logUtil';
import {
    ChatMessageTypes,
    ChatTypes,
    DateUtil,
} from '../../../../../__shared/common';
import { Chat, ChatMessage } from '../../../../../__shared/graphql';
import TrafficLightBadge from '../../navigation/trafficLightBadge';
import ChatMessageComponent from '../chatMessage/chatMessage';
import SecondaryChatHeader from '../secondaryChatHeader/secondaryChatHeader';
import styles from './chatMessages.module.scss';
import useChatMessages from './useChatMessages';

const createChatDateBadgeMessage = (
    chatId: number,
    date: Date,
): ChatMessage => {
    const createdAt = new Date(date);
    createdAt.setHours(0);
    createdAt.setMinutes(0);
    createdAt.setSeconds(0);
    createdAt.setMilliseconds(0);
    return {
        id: 0,
        uuid: uuidv4(),
        chatId,
        createdAt: createdAt,
        type: ChatMessageTypes.MsgDateBadge,
    };
};

const insertChatDateBadgeMessages = (
    msgs: ChatMessage[] = [],
): ChatMessage[] => {
    if (!msgs.length) {
        return [];
    }
    // remove existing badges to avoid duplicates
    const result = msgs.filter(
        msg => msg.type !== ChatMessageTypes.MsgDateBadge,
    );

    let index = 0;
    let length = result.length;
    while (index < length) {
        if (result[index]?.chatId) {
            const currentCreatedAt = result[index].createdAt;
            const previousMsgIndex = index + 1;
            if (previousMsgIndex > length - 1) {
                // last message => create badge
                result.splice(
                    index + 1,
                    0,
                    createChatDateBadgeMessage(
                        result[index].chatId ?? 0,
                        currentCreatedAt,
                    ),
                );
                // increase index and skip the badge
                index += 2;
                length = result.length;
                continue;
            }

            const previousCreatedAt = result[previousMsgIndex].createdAt;

            const currentMsgDate = DateUtil.toYYYYMMDD(result[index].createdAt);
            const previousMsgDate = DateUtil.toYYYYMMDD(previousCreatedAt);
            if (currentMsgDate !== previousMsgDate) {
                result.splice(
                    index + 1,
                    0,
                    createChatDateBadgeMessage(
                        result[index].chatId ?? 0,
                        result[index].createdAt,
                    ),
                );
                // increase index and skip the badge
                index += 2;
                length = result.length;
                continue;
            }

            // nothing changed
            index += 1;
        }
    }
    return result.sort(sortByCreatedAt);
};

interface ChatMessagesProps {
    chat?: Chat;
    chatMessages: ChatMessage[];
    isLoading: boolean;
    loadNextPage: () => void;
    onChatMessageReplyOptionClicked: (chatMessage: ChatMessage) => void;
    onChatMessagesMarkedAsViewed: (viewedMessages: ChatMessage[]) => void;
    show?: boolean;
    onSecondaryHeaderClicked: () => void;
}

const ChatMessages: React.FC<ChatMessagesProps> = ({
    chat,
    chatMessages,
    isLoading,
    loadNextPage,
    onChatMessageReplyOptionClicked,
    onChatMessagesMarkedAsViewed,
    show = false,
    onSecondaryHeaderClicked,
}) => {
    // const isInitialScroll = useRef(true);
    const [isInitialScrollState, setIsInitialScrollState] = useState(true);

    const { closeInquiry } = useCloseInquiry();
    const { onWasVisible } = useChatMessages(onChatMessagesMarkedAsViewed);
    const company = useObservable(RxCompany.currentCompany$);
    const canCloseInquiry =
        chat?.hasOpenRequest &&
        !!company?.id &&
        chat?.company?.id == company.id;

    const [sortedMessages, setSortedMessages] = useState<ChatMessage[]>([]);

    const userId = useObservable(RxAuthentication.userId$, 0); // get from above?

    const containerRef = useRef<HTMLDivElement>(null);
    const [scrollToBottomBtnProps, setScrollToBottomBtnProps] = useState({
        x: 0,
        y: 0,
        show: false,
    });

    const getDomIdFromChatMessageUuid = (uuid: string): string => {
        return `chat-message-${uuid}`;
    };

    const onScroll = useCallback(
        (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
            const div = event.target as HTMLDivElement;

            const showScrollToBottomBtn =
                div.scrollHeight - div.scrollTop - div.offsetHeight > 100;

            setScrollToBottomBtnProps(props => ({
                ...props,
                show: showScrollToBottomBtn,
            }));
        },
        [setScrollToBottomBtnProps],
    );

    useEffect(() => {
        setSortedMessages(insertChatDateBadgeMessages(chatMessages));
    }, [chatMessages]);

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const handleResize = (event: any) => {
            const rect = event[0].target?.getBoundingClientRect();
            if (rect) {
                setScrollToBottomBtnProps(prev => ({
                    ...prev,
                    x: rect.left + rect.width - 60,
                    y: rect.top + rect.height - 60,
                }));
            }
        };
        const observer = new ResizeObserver(handleResize);
        const current = containerRef.current;
        if (current) {
            observer.observe(current);
        }

        return () => {
            if (observer && current) {
                observer.unobserve(current);
            }
        };
    }, [containerRef]);

    const scrollToBottom = useCallback((behavior?: ScrollBehaviour) => {
        logUtil.log('chatMessages scrollToBottom:', { behavior });
        if (behavior !== undefined) {
            containerRef.current?.scroll({
                top: containerRef.current.scrollHeight,
                behavior,
            });
        } else {
            containerRef.current?.scroll({
                top: containerRef.current.scrollHeight,
            });
        }
    }, []);

    const scrollToMessage = useCallback(
        (chatMessageUuid: string, behavior?: ScrollBehaviour) => {
            logUtil.log('chatMessages scrollToMessage:', {
                chatMessageUuid,
                behavior,
            });
            const chatMessageDomElement = document.getElementById(
                getDomIdFromChatMessageUuid(chatMessageUuid),
            );

            if (chatMessageDomElement) {
                if (behavior !== undefined) {
                    chatMessageDomElement.scrollIntoView({
                        behavior,
                    });
                } else {
                    chatMessageDomElement.scrollIntoView();
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    useEffect(() => {
        scrollToBottom();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isInitialScrollState) {
            scrollToBottom();
        }
    }, [isInitialScrollState, isLoading, show, sortedMessages, scrollToBottom]);

    const onVisibilityChanged = useCallback(
        (msg: ChatMessage, isVisible: boolean, msgs: ChatMessage[]) => {
            logUtil.log('onVisibilityChanged message:', {
                id: msg.id,
                text: msg.text,
                isViewedByMe: msg.isViewedByMe,
                isVisible: isVisible,
            });

            if (!isVisible) {
                // Note: scroll to bottom until the first item is hidden (so that scrolling up is necessary)
                setIsInitialScrollState(false);
            } else {
                if (!msg.isViewedByMe) {
                    onWasVisible(msg, isVisible);
                }

                const indexOfMessage = msgs
                    .filter(x => x?.type && x.type === ChatMessageTypes.Default)
                    .map(x => x.id)
                    .indexOf(msg.id);

                const lastIndexOfMessage =
                    msgs.filter(
                        x => x?.type && x.type === ChatMessageTypes.Default,
                    ).length - 1;

                const invertedIndexOfMessage =
                    lastIndexOfMessage - indexOfMessage;

                // load when threshold or end reached (if threshold is unreachable somehow)
                if (
                    (invertedIndexOfMessage &&
                        invertedIndexOfMessage === lastIndexOfMessage) ||
                    invertedIndexOfMessage ===
                        lastIndexOfMessage -
                            Math.max(Math.floor((PAGE_SIZE ?? 2) / 2), 0)
                ) {
                    if (show && isVisible && !isLoading) {
                        // logUtil.log('onVisibilityChanged --> loadNextPage');
                        loadNextPage();
                    }
                }
            }
        },
        [show, isLoading, loadNextPage, onWasVisible, setIsInitialScrollState],
    );

    const onChatMessageAdded = useObservable(RxChat.onChatMessageAdded$);

    useEffect(() => {
        // for messages from others scroll to bottom if nearly there
        if (onChatMessageAdded) {
            if (
                !scrollToBottomBtnProps.show &&
                onChatMessageAdded.createdBy !== userId
            ) {
                scrollToBottom('smooth');
            }
        }
    }, [
        userId,
        onChatMessageAdded,
        scrollToBottomBtnProps.show,
        scrollToBottom,
    ]);

    const scrollChatToEndObserver = useObservable(RxChat.scrollChatToEnd$);
    const scrollChatToMessageObserver = useObservable(
        RxChat.scrollChatToMessage$,
    );

    useEffect(() => {
        if (scrollChatToEndObserver?.chatId !== chat?.id) {
            return;
        }
        logUtil.log(
            'chatMessages scrollChatToEndObserver:',
            scrollChatToEndObserver,
        );
        if (scrollChatToEndObserver?.scrollEnd === 'bottom') {
            scrollToBottom(scrollChatToEndObserver.behavior);
        }
    }, [scrollChatToEndObserver, chat?.id, scrollToBottom]);

    useEffect(() => {
        if (scrollChatToMessageObserver?.chatId !== chat?.id) {
            return;
        }
        logUtil.log(
            'chatMessages scrollChatToMessageObserver:',
            scrollChatToMessageObserver,
        );
        if (scrollChatToMessageObserver?.chatMessageUuid) {
            scrollToMessage(
                scrollChatToMessageObserver?.chatMessageUuid,
                scrollChatToMessageObserver.behavior,
            );
        }
    }, [scrollChatToMessageObserver, chat?.id, scrollToMessage]);

    const chatMessagesWithImageAndVideoFiles = useMemo(
        () => filterMessagesWithImageAndVideo(chatMessages),
        [chatMessages],
    );

    const hasSecondaryHeader =
        chat?.type !== ChatTypes.Internal &&
        (!!chat?.animal || !!chat?.animalGroup);

    return (
        <>
            <div
                className={styles.container}
                data-cy={`chat-messages-container-${chat?.id}`}
                ref={containerRef}
                onScroll={onScroll}>
                {useMemo(
                    () => (
                        <SecondaryChatHeader
                            chat={chat}
                            onClick={onSecondaryHeaderClicked}
                        />
                    ),
                    [chat, onSecondaryHeaderClicked],
                )}
                <div className={styles.loadingNextPageSpinner}>
                    {isLoading && <FadeLoader color={'gray'} />}
                </div>

                {useMemo(() => {
                    return sortedMessages.map(msg => (
                        <ChatMessageComponent
                            id={getDomIdFromChatMessageUuid(msg?.uuid ?? '')}
                            key={getDomIdFromChatMessageUuid(msg?.uuid ?? '')}
                            userId={userId}
                            message={msg}
                            authorParticipant={chat?.participants?.find(
                                x => x.user?.id === msg?.author?.id,
                            )}
                            hasHeaderPaddingForDateBadges={hasSecondaryHeader}
                            onShowImage={() =>
                                publishShowPreviewMessages(
                                    chatMessagesWithImageAndVideoFiles,
                                    msg,
                                )
                            }
                            // onWasVisible={onWasVisible}
                            onVisibilityChanged={(msg, isVisible) => {
                                onVisibilityChanged(
                                    msg,
                                    isVisible,
                                    sortedMessages,
                                );
                            }}
                            onChatMessageReplyOptionClicked={
                                onChatMessageReplyOptionClicked
                            }
                        />
                    ));
                    // eslint-disable-next-line react-hooks/exhaustive-deps
                }, [
                    sortedMessages,
                    userId,
                    hasSecondaryHeader,
                    // chat?.participants,
                    // onChatMessageReplyOptionClicked,
                    // onVisibilityChanged,
                ])}
            </div>
            {canCloseInquiry && (
                <div className={styles.closeInquiryLabelSticky}>
                    <TrafficLightBadge
                        onCloseInquiry={() => closeInquiry(chat)}
                        messageDate={chat.lastMessage?.createdAt}
                        roundCorners={false}
                    />
                </div>
            )}
            <ScrollToBottomButton
                visible={scrollToBottomBtnProps.show}
                position={{
                    top: scrollToBottomBtnProps.y,
                    left: scrollToBottomBtnProps.x,
                }}
                onClick={() => scrollToBottom('smooth')}
            />
            <div
                style={{
                    position: 'fixed',
                    top: scrollToBottomBtnProps.y - 14,
                    left: scrollToBottomBtnProps.x + 14,
                    zIndex: 39,
                    display: scrollToBottomBtnProps.show ? 'block' : 'none',
                }}>
                <CounterBadge count={chat?.unreadMessageCount ?? 0} />
            </div>
        </>
    );
};

export default ChatMessages;

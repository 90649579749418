import React from 'react';
import { CypressProp } from '../../types/cypressProps';
import { ClassNameProp } from '../../types/stylingProps';
import styles from './button.module.scss';

export interface ButtonProps {
    onClick?(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
    text: string;
    disabled?: boolean;
    style?: 'primary' | 'secondary';
}

const Button: React.FC<ButtonProps & ClassNameProp & CypressProp> = ({
    text,
    onClick,
    style,
    disabled = false,
    className,
    dataCy,
}) => {
    let buttonStyle;
    switch (style) {
        case 'secondary':
            buttonStyle = styles.secondary;
            break;

        default:
            buttonStyle = styles.primary;
            break;
    }

    if (disabled) {
        buttonStyle = `${buttonStyle} ${styles.disabled}`;
    }

    const handleClick = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
        if (disabled) {
            return;
        }
        if (onClick) {
            onClick(event);
        }
    };

    return (
        <button
            data-cy={dataCy}
            className={`${styles.container} ${buttonStyle} ${
                className ?? ''
            }`.trim()}
            onClick={handleClick}
            disabled={!!disabled}>
            {text}
        </button>
    );
};

export default Button;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useObservable } from '../../hooks/useObservable';
import RxElectron from '../../rxjs/RxElectron';
import { UpdateNoticeProps } from '../../rxjs/RxUpdateNotice';
import {
    MacDmgUrl,
    openUrlInBrowser,
    reloadBrowserWindow,
    WindowsSetupUrl,
} from '../../__shared/electron';
import styles from './updateNotice.module.scss';

const UpdateNotice: React.FC<UpdateNoticeProps> = ({
    translationKeys,
    updateType,
}) => {
    const { t } = useTranslation();
    const isWindows = useObservable(RxElectron.isWindows$, true);

    const handleClick = () => {
        if (updateType === 'web') {
            reloadBrowserWindow();
        }
        if (updateType === 'shell') {
            openUrlInBrowser(isWindows ? WindowsSetupUrl : MacDmgUrl);
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.header}>{t(translationKeys.header)}</div>
            <div className={styles.text}>{t(translationKeys.message)}</div>
            <div className={styles.button} onClick={handleClick}>
                {t(translationKeys.buttonText)}
            </div>
        </div>
    );
};

export default UpdateNotice;

import { gql } from '@apollo/client';

const FullResponse = gql`
    query GenerateRandomPassword($email: String!) {
        generateRandomPassword(email: $email)
    }
`;

export interface GenerateRandomPasswordData {
    generateRandomPassword: boolean;
}

export const GenerateRandomPasswordQuery = { FullResponse };

import { Subject } from 'rxjs';

const currentContextMenuSubject = new Subject<
    React.RefObject<HTMLDivElement> | undefined
>();
const currentContextMenu$ = currentContextMenuSubject.asObservable();

const setCurrentContextMenu = (
    ref: React.RefObject<HTMLDivElement> | undefined,
): void => {
    currentContextMenuSubject.next(ref);
};

export const RxContextMenu = {
    currentContextMenu$,
    setCurrentContextMenu,
};

import { Address } from '../dto';
import { gql } from '@apollo/client';
import { AddressFragments } from '../fragments';

const FullResponse = gql`
    mutation CreateAddress($address: AddressCreate!) {
        createAddress(address: $address) {
            ...FullAddress
        }
    }
    ${AddressFragments.FullAddress}
`;

export interface CreateAddressData {
    createAddress: Address;
}

export const CreateAddressMutation = { FullResponse };

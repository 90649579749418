import { gql } from '@apollo/client';

const FullResponse = gql`
    mutation SoftDeleteCustomer($customerId: Int!) {
        softDeleteCustomer(customerId: $customerId)
    }
`;

export interface SoftDeleteCustomerData {
    softDeleteCustomer: number;
}

export const SoftDeleteCustomerMutation = {
    FullResponse,
};

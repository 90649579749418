import { gql } from '@apollo/client';

const FullResponse = gql`
    mutation RemoveInvitationForUserToBecomeEmployee($employeeId: Int!) {
        removeInvitationForUserToBecomeEmployee(employeeId: $employeeId)
    }
`;

export interface RemoveInvitationForUserToBecomeEmployeeData {
    removeInvitationForUserToBecomeEmployee: boolean;
}

export const RemoveInvitationForUserToBecomeEmployeeMutation = {
    FullResponse,
};

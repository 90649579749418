import { FileDescription } from '../graphql';
import { isElectron } from './electronUtils';

export interface IsFileInTempDirRequestPayload {
    fileDescription: FileDescription;
}

export interface GetFileDescriptionFromDiskRequestPayload {
    fileDescription: FileDescription;
    accessToken: string;
}
export interface GetFileDescriptionFromDiskResponsePayload {
    filePath: string | null | undefined;
}

export const GetFilepathFromCacheRequest = 'AC_GET_FILEPATH_FROM_CACHE_REQ';
export const GetFilepathFromCacheResponse = 'AC_GET_FILEPATH_FROM_CACHE_RES_';
export const getFilepathFromCache = (
    fileDescription: FileDescription,
): Promise<string | undefined> => {
    if (!window || !isElectron) {
        return Promise.resolve(undefined);
    }
    const ipcRenderer = window.require('electron').ipcRenderer;
    return new Promise(resolve => {
        ipcRenderer.once(
            `${GetFilepathFromCacheResponse}${fileDescription.uuid}`,
            (_: unknown, response: string | undefined) => {
                resolve(response);
            },
        );
        ipcRenderer.send(GetFilepathFromCacheRequest, fileDescription);
    });
};

export interface GetFilepathAndStoreToCacheRequestPayload {
    fileDescription: FileDescription;
    accessToken: string;
}
export const GetFilepathAndStoreToCacheRequest =
    'AC_GET_FILEPATH_AND_STORE_TO_CACHE_REQ';
export const GetFilepathAndStoreToCacheResponse =
    'AC_GET_FILEPATH_AND_STORE_TO_CACHE_RES';
export const getFilepathAndStoreToCache = (
    fileDescription: FileDescription,
    accessToken: string,
): Promise<string | undefined> => {
    if (!window || !isElectron) {
        return Promise.resolve(undefined);
    }
    const ipcRenderer = window.require('electron').ipcRenderer;
    return new Promise(resolve => {
        ipcRenderer.once(
            `${GetFilepathAndStoreToCacheResponse}${fileDescription.uuid}`,
            (_: unknown, response: string | undefined) => {
                resolve(response);
            },
        );
        ipcRenderer.send(GetFilepathAndStoreToCacheRequest, {
            fileDescription,
            accessToken,
        });
    });
};

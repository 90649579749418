import { Company } from '../dto';
import { gql } from '@apollo/client';
import { CompanyFragments } from '../fragments';

const FullResponse = gql`
    query getCompany($companyId: Int!) {
        getCompany(companyId: $companyId) {
            ...FullCompany
        }
    }
    ${CompanyFragments.FullCompany}
`;

export interface GetCompanyData {
    getCompany: Company;
}

export const GetCompanyQuery = { FullResponse };

import { useEffect, useState } from 'react';
import logUtil from '../utils/logUtil';

type ScreenSize = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl';

// NOTE: Ensure that the usage of these methods are in sync with the css mediaQueries!

export const useScreenHasLessWidthThan = (size: ScreenSize): boolean => {
    const [matches, setMatches] = useState(false);

    useEffect(() => {
        logUtil.log('useScreenHasLessWidthThan:', { size, matches });
    }, [size, matches]);

    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            switch (size) {
                case 'xl':
                    setMatches(width < 1919);
                    break;
                case 'lg':
                    setMatches(width < 1535);
                    break;
                case 'md':
                    setMatches(width < 1199);
                    break;
                case 'sm':
                    setMatches(width < 899);
                    break;
                case 'xs':
                    setMatches(width < 599);
                    break;
                case 'xxs':
                    setMatches(width < 500);
                    break;

                default:
                    // fall through to xs
                    setMatches(true);
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [size]);

    return matches;
};

export const useScreenHasMoreWidthThan = (size: ScreenSize): boolean => {
    const [matches, setMatches] = useState(false);

    useEffect(() => {
        logUtil.log('useScreenHasMoreWidthThan:', { size, matches });
    }, [size, matches]);

    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            switch (size) {
                case 'xxs':
                    setMatches(width > 500);
                    break;
                case 'xs':
                    setMatches(width > 599);
                    break;
                case 'sm':
                    setMatches(width > 899);
                    break;
                case 'md':
                    setMatches(width > 1199);
                    break;
                case 'lg':
                    setMatches(width > 1535);
                    break;

                default:
                    // fall through to xl
                    setMatches(true);
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [size]);

    return matches;
};

import { ApolloError } from '@apollo/client';
import { GraphQLError } from 'graphql';
import { useCallback } from 'react';
import { useAlert } from './useAlert';

const errorTranslations = {
    AnimalAlreadyRelatedToThatUser: 'errors.AnimalAlreadyRelatedToThatUser',
    CouldNotCreateWherebyMeeting: 'errors.CouldNotCreateWherebyMeeting',
    EmailAlreadyTaken: 'errors.EmailAlreadyTaken',
    EmailMustNotContainWhitespace: 'errors.EmailMustNotContainWhitespace',
    PasswordIsWrong: 'errors.PasswordIsWrong',
    PhoneNumberAlreadyTaken: 'errors.PhoneNumberAlreadyTaken',
    UserNotFound: 'errors.UserNotFound',
    UsernameAlreadyTaken: 'errors.UsernameAlreadyTaken',
    UsernameOrPasswordIsWrong: 'errors.UsernameOrPasswordIsWrong',
    WrongActivationCodeEmail: 'errors.WrongActivationCodeEmail',
    WrongActivationCodeSms: 'errors.WrongActivationCodeSms',
};

const getTranslationForErrorCode = (errorCode?: string): string | undefined => {
    if (!errorCode) {
        return undefined;
    }
    let translation: string | undefined;
    Object.entries(errorTranslations).forEach(keyValueArray => {
        if (errorCode === keyValueArray[0]) {
            translation = keyValueArray[1];
        }
    });
    return translation;
};

interface UseErrorAlert {
    alertApolloError: (error?: ApolloError) => void;
    alertError: (error: unknown) => void;
    alertGraphQlError: (
        errorObject?: GraphQLError | readonly GraphQLError[],
    ) => void;
}

export const useErrorAlert = (): UseErrorAlert => {
    const alert = useAlert();

    const alertError = useCallback(
        (error: unknown) => {
            let translation: string | undefined;
            if (error instanceof ApolloError) {
                if (error.graphQLErrors.length > 0) {
                    translation = getTranslationForErrorCode(
                        error.graphQLErrors[0]?.extensions?.errorCode,
                    );
                }
            }
            if (!translation) {
                alert.error('common.alerts.defaultError');
                return;
            }
            alert.error(translation);
        },
        [alert],
    );

    const alertGraphQlError = useCallback(
        (errorObject?: GraphQLError | readonly GraphQLError[]) => {
            let error: GraphQLError | undefined;
            if (isReadonlyArray(errorObject)) {
                if (errorObject.length) {
                    error = errorObject[0];
                }
            } else {
                error = errorObject;
            }

            const translation = getTranslationForErrorCode(
                error?.extensions?.errorCode,
            );
            if (!translation) {
                alert.error('common.alerts.defaultError');
                return;
            }
            alert.error(translation);
            return error;
        },
        [alert],
    );

    const alertApolloError = useCallback(
        (apolloError?: ApolloError) => {
            if (apolloError?.graphQLErrors.length) {
                alertGraphQlError(apolloError.graphQLErrors);
                return;
            }
            if (apolloError?.clientErrors.length) {
                alertError(apolloError.clientErrors);
                return;
            }
            if (apolloError?.networkError) {
                alertError(apolloError.networkError);
            }
            return;
        },
        [alertGraphQlError, alertError],
    );

    return { alertError, alertGraphQlError, alertApolloError };
};

const isReadonlyArray = <T>(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    arg: ReadonlyArray<T> | any,
): arg is ReadonlyArray<T> => {
    return Array.isArray(arg);
};

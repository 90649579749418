import { Address, User } from '../__shared/graphql';
import { BehaviorSubject } from 'rxjs';

const userProfileSubject = new BehaviorSubject<User | undefined>(undefined);
const userProfile$ = userProfileSubject.asObservable();

const addressSubject = new BehaviorSubject<Address | undefined>(undefined);
const address$ = addressSubject.asObservable();

const setAddress = (address: Address): void => {
    addressSubject.next(address);
};

const setUser = (user: User): void => {
    if (user.address) {
        setAddress(user.address);
    }
    userProfileSubject.next(user);
};

const clear = (): void => {
    userProfileSubject.next(undefined);
    addressSubject.next(undefined);
};

const RxUserProfile = {
    clear,
    setAddress,
    setUser,
    address$,
    userProfile$,
};

export default RxUserProfile;

import { ChatMessage, FileInfo } from '../__shared/graphql';
export const AcceptImageFilesString = 'image/*';
export const AcceptVideoFilesString = 'video/*';
export const AcceptApplicationFilesString = 'application/*';
export const AcceptMediaString = [
    AcceptImageFilesString,
    AcceptVideoFilesString,
    AcceptApplicationFilesString,
].join(',');

const video = 'video';
export const isVideoType = (type?: string | null): boolean =>
    type?.toLowerCase().indexOf(video) !== -1;

const image = 'image';
export const isImageType = (type?: string | null): boolean =>
    type?.toLowerCase().indexOf(image) !== -1;

const application = 'application';
export const isApplicationType = (type?: string | null): boolean =>
    type?.toLowerCase().indexOf(application) !== -1;

export const filterMessagesWithImageAndVideo = (
    messages: ChatMessage[],
): ChatMessage[] => {
    if (!messages) {
        return [];
    }
    return messages.filter(
        x =>
            x.files &&
            x.files?.length > 0 &&
            (isVideoType(x.files[0].fileType) ||
                isImageType(x.files[0].fileType)),
    );
};

export const getFileInfoFromImage = (file: File): FileInfo => {
    return {
        fileName: file.name,
        fileSize: file.size,
        type: file.type,
    };
};

export const getFileInfoFromApplicationFile = (file: File): FileInfo => {
    return {
        fileName: file.name,
        fileSize: file.size,
        type: file.type,
    };
};

export const getFileInfoFromVideo = (file: File): Promise<FileInfo> => {
    return new Promise(resolve => {
        const url = window.URL.createObjectURL(file);
        const video = document.createElement('video');
        video.preload = 'metadata';
        video.addEventListener('loadedmetadata', () => {
            const result = {
                duration: video.duration,
                fileName: file.name,
                type: file.type,
                fileSize: file.size,
                height: video.videoHeight,
                width: video.videoWidth,
            };
            window.URL.revokeObjectURL(url);
            resolve(result);
        });
        video.src = url;
    });
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../components/button/button';
import ProfileIcon from '../../../../../components/profileIcon/profileIcon';
import { DefaultColorBundle } from '../../../../../constants';
import { ClassNameProp } from '../../../../../types/stylingProps';
import { Company } from '../../../../../__shared/graphql';
import Card from '../../../components/card/card';
import styles from './invitationCard.module.scss';

interface InvitationCardProps {
    company: Company;
    canAccept: boolean;
    isLoading: boolean;
    onAccept: () => void;
    onReject: () => void;
}

const InvitationCard: React.FC<InvitationCardProps & ClassNameProp> = ({
    company,
    canAccept,
    isLoading,
    onAccept,
    onReject,
    className,
}) => {
    const { t } = useTranslation();

    return (
        <Card className={`${styles.card} ${className ?? ''}`.trim()}>
            <ProfileIcon
                className={styles.image}
                colorBundle={company.colorBundle ?? DefaultColorBundle}
                fileDescription={company.imageFile}
                size={92}
            />
            <p className={styles.companyName}>{company.name}</p>
            <Button
                disabled={!canAccept || isLoading}
                className={styles.button}
                onClick={onAccept}
                text={t('screens.business.company.accept')}
            />
            <Button
                disabled={isLoading}
                className={styles.button}
                onClick={onReject}
                style="secondary"
                text={t('screens.business.company.reject')}
            />
            <p className={styles.hint}>
                {t('screens.business.company.invitationHint')}
            </p>
        </Card>
    );
};

export default InvitationCard;

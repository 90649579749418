import { BehaviorSubject, first } from 'rxjs';

export interface UpdateBannerProps {
    visible: boolean;
    translationKey?: string;
    level?: 'warning' | 'error';
}

export const initialUpdateBannerProps: UpdateBannerProps = {
    visible: false,
};

const updateBannerSubject = new BehaviorSubject<UpdateBannerProps>(
    initialUpdateBannerProps,
);
const updateBannerProps$ = updateBannerSubject.asObservable();

const showUpdateBanner = (
    translationKey: string,
    level: 'warning' | 'error',
): void => {
    updateBannerSubject.next({
        visible: true,
        translationKey,
        level,
    });
};
const hideUpdateBanner = (): void => {
    updateBannerProps$.pipe(first()).subscribe(old => {
        updateBannerSubject.next({
            ...old,
            visible: false,
        });
    });
};

const RxUpdateBanner = {
    showUpdateBanner,
    hideUpdateBanner,
    updateBannerProps$,
};

export default RxUpdateBanner;

import {
    GetCompaniesForUserData,
    GetCompaniesForUserQuery,
    GetUserData,
    LoginWithEmailOrUsernameData,
    MutationLoginWithEmailOrUsernameArgs,
    QueryGetUserArgs,
    UserMutations,
} from '../../../../__shared/graphql';
import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { FadeLoader } from 'react-spinners';
import Button from '../../../../components/button/button';
import Input from '../../../../components/input/input';
import { UserQueries } from '../../../../graphql/queries/userQueries';
import { useErrorAlert } from '../../../../hooks/useErrorAlert';
import { useObservable } from '../../../../hooks/useObservable';
import RxAuthentication from '../../../../rxjs/RxAuthentication';
import RxCompany from '../../../../rxjs/RxCompany';
import RxUserProfile from '../../../../rxjs/RxUserProfile';
import authenticationUtil from '../../../../utils/authenticationUtil';
import logUtil from '../../../../utils/logUtil';
import AnimalChatRoutes from '../../../../utils/routing';
import styles from './loginScreen.module.scss';

interface Credentials {
    emailOrUsername: string;
    password: string;
}

const LoginScreen: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { alertError } = useErrorAlert();

    const userId = useObservable(RxAuthentication.userId$, 0);

    // logout on navigation to login route
    useEffect(() => {
        authenticationUtil.logout(true);
    }, []);

    const [
        loginWithEmailOrUsernameMutation,
        { loading: loginLoading, error: loginError, reset: loginReset },
    ] = useMutation<
        LoginWithEmailOrUsernameData,
        MutationLoginWithEmailOrUsernameArgs
    >(UserMutations.LoginWithEmailOrUsername);

    const [credentials, setCredentials] = useState<Credentials>({
        emailOrUsername: process?.env?.REACT_APP_DEFAULT_USER ?? '',
        password: process?.env?.REACT_APP_DEFAULT_PASS ?? '',
    });

    const updateField = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        field: keyof Credentials,
    ) => {
        setCredentials({ ...credentials, [field]: event.target.value });
    };

    const [getUser, { data: getUserData }] = useLazyQuery<
        GetUserData,
        QueryGetUserArgs
    >(UserQueries.GetUser.Full);

    const [getCompaniesForUser, { data: getCompaniesForUserData }] =
        useLazyQuery<GetCompaniesForUserData>(
            GetCompaniesForUserQuery.FullResponse,
        );

    useEffect(() => {
        if (userId) {
            getUser({
                variables: {
                    userId,
                },
            });
        }
    }, [userId, getUser]);

    useEffect(() => {
        if (getUserData?.getUser) {
            RxUserProfile.setUser(getUserData?.getUser);
            getCompaniesForUser();
        }
    }, [getUserData, getCompaniesForUser]);

    useEffect(() => {
        const companies = getCompaniesForUserData?.getCompaniesForUser;
        if (companies) {
            RxCompany.setCompanies(companies);
            if (companies.length) {
                RxCompany.setCurrentCompany(companies[0]);
            } else {
                RxCompany.setCurrentCompany(null);
            }
            navigate(AnimalChatRoutes.businessSubs.firstSteps);
        }
    }, [getCompaniesForUserData, navigate, loginReset, alertError]);

    const onLogin = useCallback(
        async (emailOrUsername: string, pass: string) => {
            try {
                const loginResponse = await loginWithEmailOrUsernameMutation({
                    variables: {
                        login: {
                            emailOrUsername,
                            password: pass,
                        },
                    },
                });
                if (loginResponse.errors) {
                    console.warn(loginResponse);
                }
                const token = loginResponse.data?.loginWithEmailOrUsername;
                if (token) {
                    authenticationUtil.storeAccessToken(token);
                    RxAuthentication.setAccessToken(token);
                }
            } catch (e) {
                console.warn('error', e);
                loginReset();
            }
        },
        [loginWithEmailOrUsernameMutation, loginReset],
    );

    useEffect(() => {
        if (!loginLoading && loginError) {
            loginReset();
            logUtil.warn('Error during user login', loginError);
            alertError(loginError);
        }
    }, [loginLoading, loginError, loginReset, alertError]);

    // useEffect(() => {
    //     if (!loginData) {
    //         loginReset();
    //     }
    // }, [loginData, loginReset]);

    return (
        <div className={styles.container}>
            <form className={styles.form}>
                {loginLoading && (
                    <div
                        className={`${styles.formOverlay} ${styles.loadingSpinner}`}>
                        <FadeLoader />
                    </div>
                )}
                <div className={styles.input}>
                    <label htmlFor="username">{t('common.email')}</label>
                    <Input
                        type="text"
                        id="username"
                        dataCy="username-input"
                        value={credentials.emailOrUsername}
                        onChange={e => updateField(e, 'emailOrUsername')}
                    />
                </div>
                <div className={styles.input}>
                    <label htmlFor="password">{t('common.password')}</label>
                    <Input
                        type="password"
                        id="password"
                        dataCy="password-input"
                        value={credentials.password}
                        onChange={e => updateField(e, 'password')}
                    />
                </div>
                <div className={styles.buttons}>
                    <Button
                        dataCy="login-btn"
                        disabled={loginLoading}
                        text={t('common.buttons.login')}
                        onClick={e => {
                            e.preventDefault();
                            onLogin(
                                credentials.emailOrUsername,
                                credentials.password,
                            );
                        }}
                    />
                </div>
                <div className={styles.passwordActions}>
                    <Link to={AnimalChatRoutes.businessSubs.forgotPassword}>
                        {t('screens.login.forgotPassword')}
                    </Link>
                </div>

                <div className={styles.buttons}>
                    <Button
                        dataCy="login-btn"
                        text={t('common.buttons.register')}
                        onClick={() => {
                            navigate(AnimalChatRoutes.businessSubs.firstSteps);
                        }}
                    />
                </div>
            </form>
        </div>
    );
};

export default LoginScreen;

import { gql } from '@apollo/client';

const FullResponse = gql`
    mutation UpdateActivationCodeSms($phoneNumber: String!) {
        updateActivationCodeSms(phoneNumber: $phoneNumber)
    }
`;

export interface UpdateActivationCodeSmsResponse {
    updateActivationCodeSms: boolean;
}

export const UpdateActivationCodeSmsMutation = {
    FullResponse,
};

import { User, UserFragments } from '../../__shared/graphql';
import { gql } from '@apollo/client';

const CreateUser = gql`
    mutation createUser($user: UserCreate!) {
        createUser(user: $user) {
            ...FullUserWithInvitationAndLicences
            accessToken
        }
    }
    ${UserFragments.FullUserWithInvitationAndLicences}
`;
export interface CreateUserData {
    createUser: User;
}

const LoginWithEmailOrUsername = gql`
    mutation LoginWithEmailOrUsername($login: LoginOptions!) {
        loginWithEmailOrUsername(login: $login)
    }
`;
export interface LoginWithEmailOrUsernameData {
    loginWithEmailOrUsername: string;
}

const ActivateUser = gql`
    mutation ActivateUser($userActivate: UserActivate!) {
        activateUser(userActivate: $userActivate)
    }
`;
export interface ActivateUserData {
    activateUser: boolean;
}

const UpdateEmail = gql`
    mutation UpdateEmail($email: String!, $activationCode: String!) {
        updateEmail(email: $email, activationCode: $activationCode) {
            ...FullUserWithInvitationAndLicences
        }
    }
    ${UserFragments.FullUserWithInvitationAndLicences}
`;
export interface UpdateEmailData {
    updateEmail: User;
}

const UpdatePhoneNumber = gql`
    mutation UpdatePhoneNumber(
        $phoneNumber: String!
        $activationCode: String!
    ) {
        updatePhoneNumber(
            phoneNumber: $phoneNumber
            activationCode: $activationCode
        ) {
            ...FullUserWithInvitationAndLicences
        }
    }
    ${UserFragments.FullUserWithInvitationAndLicences}
`;
export interface UpdatePhoneNumberData {
    updatePhoneNumber: User;
}

const UpdateActivationCodeEmail = gql`
    mutation UpdateActivationCodeEmail($email: String!) {
        updateActivationCodeEmail(email: $email)
    }
`;
export interface UpdateActivationCodeEmailData {
    updateActivationCodeEmail: boolean;
}

const UpdateActivationCodeSms = gql`
    mutation UpdateActivationCodeSms($phoneNumber: String!) {
        updateActivationCodeSms(phoneNumber: $phoneNumber)
    }
`;
export interface UpdateActivationCodeSmsData {
    updateActivationCodeSms: boolean;
}

const UpdateUser = gql`
    mutation UpdateUser(
        $user: UserUpdate!
        $imageResolutions: [ImageResolution!]
    ) {
        updateUser(user: $user, imageResolutions: $imageResolutions) {
            ...FullUserWithInvitationAndLicences
        }
    }
    ${UserFragments.FullUserWithInvitationAndLicences}
`;
export interface UpdateUserData {
    updateUser: User;
}

const AcceptInvitationToBecomeEmployee = gql`
    mutation AcceptInvitationToBecomeEmployee(
        $userId: Int!
        $employeeId: Int!
    ) {
        acceptInvitationToBecomeEmployee(
            userId: $userId
            employeeId: $employeeId
        ) {
            ...FullUserWithInvitationAndLicences
        }
    }
    ${UserFragments.FullUserWithInvitationAndLicences}
`;
export interface AcceptInvitationToBecomeEmployeeData {
    acceptInvitationToBecomeEmployee: User;
}

const RejectInvitationToBecomeEmployee = gql`
    mutation RejectInvitationToBecomeEmployee(
        $userId: Int!
        $employeeId: Int!
    ) {
        rejectInvitationToBecomeEmployee(
            userId: $userId
            employeeId: $employeeId
        ) {
            ...FullUserWithInvitationAndLicences
        }
    }
    ${UserFragments.FullUserWithInvitationAndLicences}
`;
export interface RejectInvitationToBecomeEmployeeData {
    rejectInvitationToBecomeEmployee: User;
}

const SendActivationCodeEmail = gql`
    mutation SendActivationCodeEmail($userId: Int!) {
        sendActivationCodeEmail(userId: $userId)
    }
`;
export interface SendActivationCodeEmailData {
    sendActivationCodeEmail: boolean;
}

export const UserMutations = {
    AcceptInvitationToBecomeEmployee,
    ActivateUser,
    CreateUser,
    LoginWithEmailOrUsername,
    RejectInvitationToBecomeEmployee,
    SendActivationCodeEmail,
    UpdateActivationCodeEmail,
    UpdateActivationCodeSms,
    UpdateEmail,
    UpdatePhoneNumber,
    UpdateUser,
};

import { gql } from '@apollo/client';
import { Company } from '../dto';
import { CompanyFragments } from '../fragments';

export const ON_COMPANY_UPDATE = gql`
    subscription OnCompanyUpdate {
        onCompanyUpdate {
            ...FullCompany
        }
    }
    ${CompanyFragments.FullCompany}
`;

export const ON_PUBLIC_COMPANY_UPDATE = gql`
    subscription OnCompanyUpdate {
        onCompanyUpdate {
            ...PublicCompany
        }
    }
    ${CompanyFragments.PublicCompany}
`;

export interface OnCompanyUpdateData {
    onCompanyUpdate: Company;
}

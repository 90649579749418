import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SubmenuEntryData } from '../../../../components/submenu/submenu';
import SubmenuEntry from '../../../../components/submenu/submenuEntry';
import { useObservable } from '../../../../hooks/useObservable';
import { useScreenHasLessWidthThan } from '../../../../hooks/useScreenSize';
import RxCompany from '../../../../rxjs/RxCompany';
import { RxDrawerMenu } from '../../../../rxjs/RxDrawerMenu';
import RxUserProfile from '../../../../rxjs/RxUserProfile';
import AnimalChatRoutes from '../../../../utils/routing';
import { Company, User } from '../../../../__shared/graphql';
import styles from './drawerMenu.module.scss';

const DrawerMenu = (): JSX.Element => {
    const location = useLocation();
    const navigate = useNavigate();

    const businessRoutes = AnimalChatRoutes.businessSubs;

    const showDrawerMenu = useObservable(RxDrawerMenu.showDrawerMenu$);
    const user = useObservable(RxUserProfile.userProfile$);

    const company = useObservable(RxCompany.currentCompany$);

    const screenHasLessWidthThanXs = useScreenHasLessWidthThan('xs');
    const screenHasLessWidthThanSm = useScreenHasLessWidthThan('sm');
    const oldScreenIsSmallerThanXs = useRef<boolean>(screenHasLessWidthThanXs);

    const navRef = useRef<HTMLBaseElement>(null);

    const [menuEntries, setMenuEntries] = useState<SubmenuEntryData[]>([]);

    const _menuEntries: SubmenuEntryData[] = useMemo(
        () => [
            {
                translationKey: 'screens.business.routes.firstSteps',
                iconName: 'compass-outline',
                route: AnimalChatRoutes.businessSubs.firstSteps,
            },
            {
                translationKey: 'screens.business.routes.userProfile',
                iconName: 'person-circle-outline',
                route: AnimalChatRoutes.businessSubs.profile,
            },
            {
                translationKey: 'screens.business.routes.company',
                iconName: 'business',
                route: AnimalChatRoutes.businessSubs.company,
            },
            {
                translationKey: 'screens.business.routes.downloads',
                iconName: 'cloud-download',
                route: AnimalChatRoutes.businessSubs.downloads,
            },
            {
                translationKey: 'screens.business.routes.payment',
                iconName: 'cash-outline',
                route: AnimalChatRoutes.businessSubs.payment,
            },
            {
                translationKey: 'screens.business.routes.serviceTimes',
                iconName: 'time-outline',
                route: AnimalChatRoutes.businessSubs.serviceTimes,
            },
            {
                translationKey: 'screens.business.routes.employees',
                iconName: 'people-circle-outline',
                route: AnimalChatRoutes.businessSubs.employees,
            },
            {
                translationKey: 'screens.business.routes.subscriptions',
                iconName: 'card-outline',
                route: AnimalChatRoutes.businessSubs.subscriptions,
            },
            {
                translationKey: 'screens.business.routes.changePassword',
                iconName: 'key-outline',
                route: AnimalChatRoutes.businessSubs.changePassword,
            },
        ],
        [],
    );

    const disableRoute = useCallback(
        (_entries: SubmenuEntryData[], routeName: string): void => {
            const route = _entries.find(e => e.route === routeName);
            if (route) {
                route.disabled = true;
                route.counter = 0;
            }
        },
        [],
    );

    const disableProfileAndCompanyRoutes = useCallback(
        (_entries: SubmenuEntryData[]): void => {
            disableRoute(_entries, businessRoutes.profile);
            disableRoute(_entries, businessRoutes.company);
            disableRoute(_entries, businessRoutes.payment);
            disableRoute(_entries, businessRoutes.employees);
            disableRoute(_entries, businessRoutes.subscriptions);
            disableRoute(_entries, businessRoutes.serviceTimes);
        },
        [disableRoute, businessRoutes],
    );

    const enableValidRoutes = useCallback(
        (
            _entries: SubmenuEntryData[],
            user?: User | null,
            company?: Company | null,
        ): void => {
            _entries.forEach(e => (e.disabled = false));
            const userIsVerified =
                user && user.emailConfirmedAt && user.phoneNumberConfirmedAt;
            const companyRoute = _entries.find(
                e => e.route === businessRoutes.company,
            );
            const profileRoute = _entries.find(
                e => e.route === businessRoutes.profile,
            );
            const changePasswordRoute = _entries.find(
                e => e.route === businessRoutes.changePassword,
            );
            if (profileRoute) {
                profileRoute.disabled = !user;
            }

            if (companyRoute) {
                companyRoute.disabled = !userIsVerified || !company;
            }
            const employeesRoute = _entries.find(
                e => e.route === businessRoutes.employees,
            );
            if (employeesRoute) {
                employeesRoute.disabled = !userIsVerified || !company;
            }
            const subscriptionsRoute = _entries.find(
                e => e.route === businessRoutes.subscriptions,
            );
            if (subscriptionsRoute) {
                subscriptionsRoute.disabled = !userIsVerified || !company;
            }
            const serviceTimesRoute = _entries.find(
                e => e.route === businessRoutes.serviceTimes,
            );
            if (serviceTimesRoute) {
                serviceTimesRoute.disabled = !userIsVerified || !company;
            }
            const paymentRoute = _entries.find(
                e => e.route === businessRoutes.payment,
            );
            if (paymentRoute) {
                paymentRoute.disabled = !userIsVerified || !company;
            }
            if (changePasswordRoute) {
                changePasswordRoute.disabled = !user;
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [user, company, businessRoutes],
    );

    const updateAllCounter = useCallback(
        (
            _entries: SubmenuEntryData[],
            user?: User | null,
            company?: Company | null,
        ): void => {
            let userCounter = 0;
            if (user && !user.emailConfirmedAt) {
                userCounter += 1;
            }
            if (user && !user.phoneNumberConfirmedAt) {
                userCounter += 1;
            }

            const profileRoute = _entries.find(
                e => e.route === businessRoutes.profile,
            );
            if (profileRoute) {
                profileRoute.counter = userCounter;
            }

            const isProfileComplete =
                !!user?.emailConfirmedAt && !!user.phoneNumberConfirmedAt;

            const companyRoute = _entries.find(
                e => e.route === businessRoutes.company,
            );

            if (companyRoute) {
                companyRoute.counter = user
                    ? user.invitations?.length
                    : Number(isProfileComplete && !company);
            }

            const paymentRoute = _entries.find(
                e => e.route === businessRoutes.payment,
            );

            if (!company) {
                return;
            }
            const isStripeConfigured =
                !!company?.stripeAccountId && !!company.isOnboardedInStripe;

            if (paymentRoute) {
                paymentRoute.counter = company
                    ? Number(
                          company && !isStripeConfigured && isProfileComplete,
                      )
                    : 0;
            }

            return;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [user, company, businessRoutes],
    );

    useEffect(() => {
        const entries = _menuEntries.slice();
        disableProfileAndCompanyRoutes(entries);
        enableValidRoutes(entries, user, company);
        updateAllCounter(entries, user, company);
        setMenuEntries(entries);
    }, [
        showDrawerMenu,
        user,
        company,
        _menuEntries,
        businessRoutes,
        disableRoute,
        disableProfileAndCompanyRoutes,
        enableValidRoutes,
        updateAllCounter,
        setMenuEntries,
    ]);

    // close menu on escape
    useEffect(() => {
        const handleEscapeKey = (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                RxDrawerMenu.setShowDrawerMenu(!showDrawerMenu);
            }
        };
        document.addEventListener('keydown', handleEscapeKey);
        return () => document.removeEventListener('keydown', handleEscapeKey);
    }, [showDrawerMenu]);

    useEffect(() => {
        if (!screenHasLessWidthThanXs) {
            RxDrawerMenu.setShowDrawerMenu(true);
        }
        if (!oldScreenIsSmallerThanXs.current && screenHasLessWidthThanXs) {
            RxDrawerMenu.setShowDrawerMenu(false);
        }
        oldScreenIsSmallerThanXs.current = screenHasLessWidthThanXs;
    }, [screenHasLessWidthThanXs]);

    useEffect(() => {
        if (!screenHasLessWidthThanSm) {
            RxDrawerMenu.setShowDrawerMenu(true);
        }
    }, [screenHasLessWidthThanSm]);

    return (
        <div
            className={
                showDrawerMenu ? styles.container : styles.containerHidden
            }>
            <nav
                ref={navRef}
                className={showDrawerMenu ? styles.menu : styles.menuHidden}>
                {menuEntries.map(entry => {
                    return (
                        <SubmenuEntry
                            key={entry.route}
                            {...entry}
                            isActive={location.pathname.startsWith(entry.route)}
                            onClick={() => {
                                if (screenHasLessWidthThanXs) {
                                    RxDrawerMenu.setShowDrawerMenu(false);
                                }
                                if (!entry.disabled) {
                                    navigate(entry.route);
                                }
                            }}
                            counter={entry.counter}
                            disabled={entry.disabled}
                        />
                    );
                })}
            </nav>
        </div>
    );
};

export default DrawerMenu;

import { gql } from '@apollo/client';
import { Topic } from '../dto';
import { TopicFragments } from './../fragments/topicFragments';

const FullCreateTopic = gql`
    mutation CreateTopic($topic: TopicCreate!) {
        createTopic(topic: $topic) {
            ...FullTopic
        }
    }
    ${TopicFragments.FullTopic}
`;

export interface CreateTopicData {
    createTopic: Topic;
}

export const CreateTopicMutation = {
    FullCreateTopic,
};

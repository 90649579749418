import {
    LazyQueryResult,
    OperationVariables,
    useLazyQuery,
} from '@apollo/client';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { LicencesToCompaniesQueries } from '../graphql/queries/licenceToCompanyQueries';
import { UserQueries } from '../graphql/queries/userQueries';
import RxAuthentication from '../rxjs/RxAuthentication';
import RxCompany from '../rxjs/RxCompany';
import RxLicenceToCompanies from '../rxjs/RxLicenceToCompanies';
import RxUserProfile from '../rxjs/RxUserProfile';
import authenticationUtil from '../utils/authenticationUtil';
import AnimalChatRoutes from '../utils/routing';
import {
    GetCompaniesForUserData,
    GetCompaniesForUserQuery,
    GetLicencesToCompaniesData,
    GetUserData,
    QueryGetLicencesToCompaniesArgs,
    QueryGetUserArgs,
} from '../__shared/graphql';
import { useObservable } from './useObservable';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LogPrefix = '[useAutoLogin]';

const useAutoLogin = (): {
    isLoading: boolean;
    finishedLoading: boolean;
    hasLicence: boolean | undefined;
    getCompaniesForUserQuery: LazyQueryResult<
        GetCompaniesForUserData,
        OperationVariables
    >;
    getLicencesQuery: LazyQueryResult<
        GetLicencesToCompaniesData,
        QueryGetLicencesToCompaniesArgs
    >;
    getUserQuery: LazyQueryResult<GetUserData, QueryGetUserArgs>;
} => {
    const userId = useObservable(RxAuthentication.userId$, 0);
    const accessToken = useObservable(RxAuthentication.accessToken$);
    const location = useLocation();
    const navigate = useNavigate();
    const [hasLicence, setHasLicence] = useState<boolean | undefined>(
        undefined,
    );

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [finishedLoading, setFinishedLoading] = useState<boolean>(false);

    const [loadAccessTokenFromStorage, setLoadAccessTokenFromStorage] =
        useState(true);

    useEffect(() => {
        if (!loadAccessTokenFromStorage) {
            return;
        }
        if (!accessToken) {
            const token = authenticationUtil.loadAccessToken();
            if (token) {
                setLoadAccessTokenFromStorage(false);
                RxAuthentication.setAccessToken(token);
            } else {
                // TODO: Remove redirects to specific Layout (separation of concerns)

                // All routes in app need login first
                if (!location.pathname.startsWith(AnimalChatRoutes.business)) {
                    navigate(AnimalChatRoutes.login);
                } else if (
                    // Routes in business layout that need login or data first
                    location.pathname.startsWith(
                        AnimalChatRoutes.businessSubs.company,
                    ) ||
                    location.pathname.startsWith(
                        AnimalChatRoutes.businessSubs.payment,
                    ) ||
                    location.pathname.startsWith(
                        AnimalChatRoutes.businessSubs.profile,
                    )
                ) {
                    // Show what to do in welcome guide
                    navigate(AnimalChatRoutes.businessSubs.firstSteps);
                }
            }
        }
        setLoadAccessTokenFromStorage(false);
    }, [loadAccessTokenFromStorage, accessToken, navigate, location.pathname]);

    const [getUser, getUserQuery] = useLazyQuery<GetUserData, QueryGetUserArgs>(
        UserQueries.GetUser.Full,
    );

    const [getCompaniesForUser, getCompaniesForUserQuery] =
        useLazyQuery<GetCompaniesForUserData>(
            GetCompaniesForUserQuery.FullResponse,
            {
                onCompleted: data => {
                    const companies = data.getCompaniesForUser;
                    if (!companies?.length) {
                        RxCompany.setCurrentCompany(null);
                        if (
                            !location.pathname.startsWith(
                                AnimalChatRoutes.business,
                            )
                        ) {
                            navigate(AnimalChatRoutes.login);
                        }
                        return;
                    }
                    const currentCompany = companies[0];
                    RxCompany.setCompanies(companies);
                    if (!currentCompany.id || !userId) {
                        return;
                    }
                    RxCompany.setCurrentCompany(currentCompany);
                    getLicences({
                        variables: {
                            filter: {
                                active: true,
                                companyId: currentCompany.id,
                                userId,
                            },
                        },
                    });
                },
            },
        );

    useEffect(() => {
        if (userId > 0 && accessToken) {
            getUser({
                variables: {
                    userId,
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId, accessToken]);

    useEffect(() => {
        if (!getUserQuery.data?.getUser) {
            return;
        }
        RxUserProfile.setUser(getUserQuery.data.getUser);
        getCompaniesForUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getUserQuery.data]);

    const [getLicences, getLicencesQuery] = useLazyQuery<
        GetLicencesToCompaniesData,
        QueryGetLicencesToCompaniesArgs
    >(LicencesToCompaniesQueries.GetLicencesToCompanies);

    useEffect(() => {
        // logUtil.log('getLincencesData:', getLicencesQuery.data);
        if (getLicencesQuery.data?.getLicencesToCompanies !== undefined) {
            RxLicenceToCompanies.setLicenceToCompanies(
                getLicencesQuery.data?.getLicencesToCompanies,
            );
            setHasLicence(
                getLicencesQuery.data.getLicencesToCompanies.length > 0,
            );
        }
    }, [getLicencesQuery?.data]);

    // useEffect(() => {
    //     const companies = getCompaniesForUserQuery?.data?.getCompaniesForUser;
    //     if (companies) {
    //         RxCompany.setCompanies(companies);
    //         if (companies?.length) {
    //             RxCompany.setCurrentCompany(companies[0]);
    //             if (userId) {
    //                 getLicences({
    //                     variables: {
    //                         filter: {
    //                             active: true,
    //                             companyId: companies[0].id,
    //                             userId,
    //                         },
    //                     },
    //                 });
    //             }
    //         } else {
    //             // TODO: Remove to AppLayout (separation of concerns)
    //             if (!location.pathname.startsWith(AnimalChatRoutes.business)) {
    //                 RxCompany.setCurrentCompany(null);
    //                 navigate(AnimalChatRoutes.login);
    //             }
    //         }
    //     }
    // }, [
    //     userId,
    //     getCompaniesForUserQuery.data,
    //     getLicences,
    //     navigate,
    //     location.pathname,
    // ]);

    useEffect(() => {
        setIsLoading(
            (getUserQuery.loading && getUserQuery.called) ||
                (getCompaniesForUserQuery.loading &&
                    getCompaniesForUserQuery.called) ||
                (getLicencesQuery.loading && getLicencesQuery.called),
        );
    }, [
        setIsLoading,
        getUserQuery.loading,
        getCompaniesForUserQuery.loading,
        getLicencesQuery.loading,
        getUserQuery.called,
        getCompaniesForUserQuery.called,
        getLicencesQuery.called,
    ]);

    useEffect(() => {
        const a = !!getUserQuery.data;
        const b = !!getCompaniesForUserQuery.data;
        const c = !!getLicencesQuery.data;
        const d = a && b && c;
        setFinishedLoading(d);
    }, [
        setFinishedLoading,
        getUserQuery.data,
        getCompaniesForUserQuery.data,
        getLicencesQuery.data,
        getUserQuery.called,
        getCompaniesForUserQuery.called,
        getLicencesQuery.called,
    ]);

    return {
        isLoading,
        finishedLoading,
        hasLicence,
        getCompaniesForUserQuery,
        getLicencesQuery,
        getUserQuery,
    };
};

export default useAutoLogin;

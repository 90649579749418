import { gql } from '@apollo/client';

const FullContact = gql`
    fragment FullContact on Contact {
        id
        email
        firstname
        lastname
        phoneNumber
        gender
        title
    }
`;

export const ContactFragments = { FullContact };

import { gql } from '@apollo/client';
import { User } from './dto';
import { UserFragments } from './fragments';

const GetUserQueryFullUser = gql`
    query getUser($userId: Int!) {
        getUser(userId: $userId) {
            ...FullUser
        }
    }
    ${UserFragments.FullUser}
`;

const GetUserQueryFullUserWithInvitationAndLicences = gql`
    query getUser($userId: Int!) {
        getUser(userId: $userId) {
            ...FullUserWithInvitationAndLicences
        }
    }
    ${UserFragments.FullUserWithInvitationAndLicences}
`;

const IsPreviewAddress = gql`
    query IsPreviewAddress($email: String!) {
        isPreviewAddress(email: $email)
    }
`;

export const GraphQLUser = {
    Queries: {
        GetUserQuery: {
            FullUser: GetUserQueryFullUser,
            FullUserWithLicencesAndInvitation:
                GetUserQueryFullUserWithInvitationAndLicences,
        },
        IsPreviewAddress,
    },
    Mutations: {},
};

export interface GetUserData {
    getUser: User;
}

export interface IsPreviewAddressData {
    isPreviewAddress: boolean;
}

import { Company, CompanyFragments } from '../../__shared/graphql';
import { gql } from '@apollo/client';

const CreateStarterCompanyAsOwner = gql`
    mutation CreateStarterCompanyAsOwner($company: CompanyCreate!) {
        createStarterCompanyAsOwner(company: $company) {
            ...FullCompany
        }
    }
    ${CompanyFragments.FullCompany}
`;

const CreateStarterCompanyAsOwnerWithAcceptedLicenceAndOrderProcessingAgreements = gql`
    mutation CreateStarterCompanyAsOwnerWithAcceptedLicenceAndOrderProcessingAgreements(
        $company: CompanyCreateWithAgreements!
    ) {
        createStarterCompanyAsOwnerWithAcceptedLicenceAndOrderProcessingAgreements(
            company: $company
        ) {
            ...FullCompany
        }
    }
    ${CompanyFragments.FullCompany}
`;

export interface CreateStarterCompanyAsOwnerData {
    createStarterCompanyAsOwner: Company;
}

export interface CreateStarterCompanyAsOwnerWithAcceptedLicenceAndOrderProcessingAgreementsData {
    createStarterCompanyAsOwnerWithAcceptedLicenceAndOrderProcessingAgreements: Company;
}

export const CompanyMutations = {
    CreateStarterCompanyAsOwner,
    CreateStarterCompanyAsOwnerWithAcceptedLicenceAndOrderProcessingAgreements,
};

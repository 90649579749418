const currencyRegexWithEuroSign =
  /^(\d{1,3}[.](\d{3}[.])*\d{3}|\d+)([,]\d{1,2})?\s?€?$/;
const isAllowedCurrencyTypingInputRegex = /^([\d.]*)([,]?\d{0,2})$/;

export const toAmountInEuroCent = (value: string): number => {
  if (!isValidCurrencyStringWithEuroSign(value)) {
    throw new Error(`'${value}' is not a valid currency string`);
  }
  let parsedValue = value;

  // remove possible space before €
  if (parsedValue.indexOf(' ') !== -1) {
    parsedValue = parsedValue.replace(' ', '');
  }

  // remove possible €
  if (parsedValue.indexOf('€') !== -1) {
    parsedValue = parsedValue.replace('€', '');
  }

  // remove all german thousands separators
  while (parsedValue.indexOf('.') !== -1) {
    parsedValue = parsedValue.replace('.', '');
  }

  // replace german decimal separator with the english version to parse the number
  parsedValue = parsedValue.replace(',', '.');
  const result = Number.parseFloat(parsedValue) * 100;
  return result;
};

export const fixPriceValue = (value: string): string => {
  if (!value?.length) {
    return '0,00 €';
  }
  const parsedValue = value.replace(' ', '').replace('€', '');
  const commaIndex = parsedValue.indexOf(',');
  if (commaIndex === -1) {
    return `${toIntegerValueWithProperThousandsSeparators(parsedValue)},00 €`;
  }
  let [integerValue, decimalValue] = parsedValue.split(',');
  while (decimalValue.length < 2) {
    decimalValue += '0';
  }
  integerValue = toIntegerValueWithProperThousandsSeparators(integerValue);

  return `${integerValue},${decimalValue} €`;
};

export const isValidCurrencyStringWithEuroSign = (value: string): boolean =>
  currencyRegexWithEuroSign.test(value);

export const isAllowedCurrencyTypingInput = (value: string): boolean =>
  isAllowedCurrencyTypingInputRegex.test(value);

export const toIntegerValueWithProperThousandsSeparators = (
  value?: string | null
): string => {
  if (!value?.length) {
    return '0';
  }

  // remove all .
  // remove leading zeros with the unary operator
  // reverse the string
  // insert a . every 3 characters
  // reverse it again
  // remove a possible leading .

  let result = value.replace(/\./g, '');
  result = (+result).toString();

  result = result
    .split('')
    .reverse()
    .join('')
    .replace(/(.{3})/g, '$1.')
    .split('')
    .reverse()
    .join('');

  return result.startsWith('.') ? result.substring(1) : result;
};

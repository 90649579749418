import { useMutation } from '@apollo/client';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormField from '../../../../../components/form/formField';
import TextButton from '../../../../../components/textButton/textButton';
import { useAlert } from '../../../../../hooks/useAlert';
import { Limits } from '../../../../../utils/inputLimits';
import { isValidValueWithLimit } from '../../../../../utils/validationUtil';
import {
    Address,
    CreateAddressData,
    CreateAddressMutation,
    MutationCreateAddressArgs,
    MutationUpdateAddressArgs,
    UpdateAddressData,
    UpdateAddressMutation,
} from '../../../../../__shared/graphql';
import styles from './addressForm.module.scss';

interface AddressFormProps {
    address?: Address | null;
    onSave: (address: Address) => void;
    onCancel: () => void;
}

interface FormValues {
    streetName?: string;
    houseNumber?: string;
    zipCode?: string;
    city?: string;
    country?: string;
}

const emptyForm = {
    streetName: '',
    houseNumber: '',
    zipCode: '',
    city: '',
    country: '',
};

const isValidForm = (form: FormValues) => {
    return (
        isValidValueWithLimit(Limits.StreetName, form.streetName) &&
        isValidValueWithLimit(Limits.HouseNumber, form.houseNumber) &&
        isValidValueWithLimit(Limits.ZipCode, form.zipCode) &&
        isValidValueWithLimit(Limits.City, form.city) &&
        isValidValueWithLimit(Limits.Country, form.country)
    );
};

const AddressForm: React.FC<AddressFormProps> = ({
    address,
    onSave,
    onCancel,
}) => {
    const { t } = useTranslation();
    const alert = useAlert();
    const [form, setForm] = useState<FormValues>(emptyForm);
    const [updateAddress, { loading: updateLoading }] = useMutation<
        UpdateAddressData,
        MutationUpdateAddressArgs
    >(UpdateAddressMutation.FullResponse);
    const [createAddress, { loading: createLoading }] = useMutation<
        CreateAddressData,
        MutationCreateAddressArgs
    >(CreateAddressMutation.FullResponse);

    const reset = useCallback(() => {
        setForm({
            streetName: address?.streetName ?? '',
            houseNumber: address?.houseNumber ?? '',
            zipCode: address?.zipCode ?? '',
            city: address?.city ?? '',
            country: address?.country ?? '',
        });
    }, [address]);

    useEffect(() => {
        reset();
    }, [reset]);

    const update = async (addressId: number): Promise<Address | undefined> => {
        const response = await updateAddress({
            variables: {
                address: {
                    ...mapFormToAddress(form),
                    id: addressId,
                },
            },
        });
        return response?.data?.updateAddress;
    };

    const create = async (): Promise<Address | undefined> => {
        const response = await createAddress({
            variables: {
                address: {
                    ...mapFormToAddress(form),
                },
            },
        });
        return response?.data?.createAddress;
    };

    const save = async () => {
        const newAddress = !address?.id
            ? await create()
            : await update(address.id);
        if (newAddress) {
            alert.success('common.alerts.savedChanges');
            onSave(newAddress);
        }
    };

    const loading = createLoading || updateLoading;

    return (
        <div className={styles.container}>
            <FormField
                id="address-form-streetname"
                name="streetName"
                value={form.streetName}
                label={t('screens.settings.company.addressFields.streetName')}
                updateValue={(name, value) =>
                    setForm(prev => ({ ...prev, [name]: value }))
                }
                limit={Limits.StreetName}
            />
            <FormField
                id="address-form-housenumber"
                name="houseNumber"
                value={form.houseNumber}
                label={t('screens.settings.company.addressFields.houseNumber')}
                updateValue={(name, value) =>
                    setForm(prev => ({ ...prev, [name]: value }))
                }
                limit={Limits.HouseNumber}
            />
            <FormField
                id="address-form-zipcode"
                name="zipCode"
                value={form.zipCode}
                label={t('screens.settings.company.addressFields.zipCode')}
                updateValue={(name, value) =>
                    setForm(prev => ({ ...prev, [name]: value }))
                }
                limit={Limits.ZipCode}
            />
            <FormField
                id="address-form-city"
                name="city"
                value={form.city}
                label={t('screens.settings.company.addressFields.city')}
                limit={Limits.City}
                updateValue={(name, value) =>
                    setForm(prev => ({ ...prev, [name]: value }))
                }
            />
            <FormField
                id="address-form-country"
                name="country"
                value={form.country}
                limit={Limits.Country}
                label={t('screens.settings.company.addressFields.country')}
                updateValue={(name, value) =>
                    setForm(prev => ({ ...prev, [name]: value }))
                }
            />
            <div className={styles.actions}>
                <TextButton
                    style="secondary"
                    text={t('common.buttons.cancel')}
                    onClick={() => {
                        reset();
                        if (onCancel) {
                            onCancel();
                        }
                    }}
                    disabled={loading}
                />
                <TextButton
                    style="primary"
                    text={t('common.buttons.save')}
                    onClick={save}
                    disabled={loading || !isValidForm(form)}
                />
            </div>
        </div>
    );
};

export default AddressForm;

const mapFormToAddress = (form: FormValues): Address => {
    const result: Address = {
        streetName: (form?.streetName ?? '') as string,
        houseNumber: (form?.houseNumber ?? '') as string,
        zipCode: (form?.zipCode ?? '') as string,
        city: (form?.city ?? '') as string,
        country: (form?.country ?? '') as string,
    };
    return result;
};

import React from 'react';
import Card from '../card/card';
import styles from './registerCard.module.scss';

interface RegisterCardProps {
    cardClassName?: string;
}

const RegisterCard: React.FC<RegisterCardProps> = ({
    children,
    cardClassName,
}) => {
    return (
        <div className={styles.container}>
            <img
                src="/images/vet.svg"
                alt="vet logo"
                className={styles.vetImg}
            />
            <Card className={cardClassName}>{children}</Card>
            <img
                src="/images/pet.svg"
                alt="pet logo"
                className={styles.petImg}
            />
        </div>
    );
};

export default RegisterCard;

import { gql } from '@apollo/client';

const FullNotificationSetting = gql`
    fragment FullNotificationSetting on NotificationSetting {
        id
        type
        isActive
        userId
    }
`;

export const NotificationSettingFragments = {
    FullNotificationSetting,
};

import React from 'react';
import Icon from '../icon/icon';
import styles from './scrollToBottomButton.module.scss';

interface ScrollToBottomButtonProps {
    position: {
        top: number;
        left: number;
    };
    onClick: () => void;
    visible: boolean;
}

const ScrollToBottomButton: React.FC<ScrollToBottomButtonProps> = ({
    position: coordinates,
    onClick,
    visible,
}) => {
    let className = styles.scrollToBottomBtn;
    if (visible) {
        className += ` ${styles.visible}`;
    }
    return (
        <div
            className={className}
            style={{
                top: `${coordinates.top}px`,
                left: `${coordinates.left}px`,
            }}
            onClick={onClick}>
            <Icon name="arrow-down" size={28} />
        </div>
    );
};

export default ScrollToBottomButton;

import {
    Chat,
    MutationMarkChatAsReadArgs,
    MutationUpdateChatToUserArgs,
    Participant,
} from '../../../../__shared/graphql';
import {
    ChatTypes,
    DateUtil,
    isNumber,
    SystemChatMessageTypes,
} from '../../../../__shared/common';
import { useMutation } from '@apollo/client';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CounterBadge from '../../../../components/counterBadge/counterBadge';
import CtxMenu from '../../../../components/ctxMenu/ctxMenu';
import CtxMenuEntry from '../../../../components/ctxMenu/ctxMenuEntry';
import Icon from '../../../../components/icon/icon';
import ProfileIcon from '../../../../components/profileIcon/profileIcon';
import { AppointmentTypes, DefaultColorBundle } from '../../../../constants';
import {
    ChatMutations,
    MarkChatAsReadData,
} from '../../../../graphql/mutations/chatMutations';
import {
    ChatToUserMutations,
    UpdateChatToUserData,
} from '../../../../graphql/mutations/chatToUserMutations';
import { useAlert } from '../../../../hooks/useAlert';
import useCloseInquiry from '../../../../hooks/useCloseInquiry';
import { useErrorAlert } from '../../../../hooks/useErrorAlert';
import { useObservable } from '../../../../hooks/useObservable';
import RxAuthentication from '../../../../rxjs/RxAuthentication';
import RxChat from '../../../../rxjs/RxChat';
import RxCompany from '../../../../rxjs/RxCompany';
import RxLicenceToCompanies from '../../../../rxjs/RxLicenceToCompanies';
import logUtil from '../../../../utils/logUtil';
import {
    buildNameFromCustomer,
    buildNameFromUser,
} from '../../../../utils/nameUtils';
import { getSystemMessageText } from '../main/chatMessage/chatMessage';
import ChatMessageCheckmark from '../main/chatMessage/chatMessageCheckmark/chatMessageCheckmark';
import styles from './chatsEntry.module.scss';
import TrafficLightBadge from './trafficLightBadge';

interface ChatsEntryProps {
    chat: Chat;
}

const getCustomerToDisplay = (chat: Chat): Participant | undefined => {
    const { animal, animalGroup, topic } = chat;
    let result: Participant | undefined;
    const customers = chat.participants?.filter(p => !!p.customer);
    if (!customers?.length) {
        return undefined;
    }
    if (animal) {
        result = customers?.find(c => c.user?.id === animal.createdBy);
    }
    if (animalGroup) {
        result = customers?.find(c => c.user?.id === animalGroup.createdBy);
    }
    if (topic) {
        result = customers?.find(c => c.user?.id === topic.createdBy);
    }

    return result ?? customers[0];
};

const ChatsEntry: React.FC<ChatsEntryProps> = ({ chat }) => {
    const userId = useObservable(RxAuthentication.userId$, 0);
    const currentChatId = useObservable(RxChat.currentChatId$, 0);
    const [active, setActive] = useState<boolean>(false);
    const [chatState, setChatState] = useState<Chat>();

    useEffect(() => {
        setActive(currentChatId === chat.id);
    }, [currentChatId, chat.id, setActive]);

    useEffect(() => {
        setChatState(chatState);
    }, [chatState]);

    const { t } = useTranslation();
    const company = useObservable(RxCompany.currentCompany$);
    const hasOpenRequest = chat.hasOpenRequest ?? false;
    const showOpenRequest =
        hasOpenRequest && !!company?.id && company.id === chat.company?.id;
    const alert = useAlert();
    const { alertError, alertGraphQlError } = useErrorAlert();
    const { animal, animalGroup, topic, lastMessage, unreadMessageCount } =
        chat;

    const [hasLicence, setHasLicence] = useState(false);
    const licences = useObservable(RxLicenceToCompanies.licenceToCompanies$);

    useEffect(() => {
        setHasLicence(!!licences?.length);
    }, [licences]);

    const { closeInquiry } = useCloseInquiry();

    const [markChatAsReadMutation] = useMutation<
        MarkChatAsReadData,
        MutationMarkChatAsReadArgs
    >(ChatMutations.MarkChatAsReadForChatListEntry);

    const markChatAsRead = useCallback(
        async (chatId?: number | null) => {
            if (!chatId) {
                logUtil.warn(`Cannot mark chat (id: ${chatId}) as read`);
                return;
            }
            try {
                const response = await markChatAsReadMutation({
                    variables: {
                        chatId,
                    },
                });
                if (response.data?.markChatAsRead) {
                    RxChat.addChats([response.data?.markChatAsRead]);
                    return;
                }
                if (response.errors?.length) {
                    alertGraphQlError(response.errors);
                    logUtil.warn(`Cannot mark chat (id: ${chatId}) as read`);
                }
            } catch (error) {
                logUtil.warn(`Cannot mark chat (id: ${chatId}) as read`, error);
                alertError(error);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [markChatAsReadMutation],
    );

    const [updateChatToUserMutation] = useMutation<
        UpdateChatToUserData,
        MutationUpdateChatToUserArgs
    >(ChatToUserMutations.UpdateChatToUser);

    const toggleReadByCompany = useCallback(
        async (isReadByCompany: boolean, _userId: number) => {
            if (!isNumber(chat.id)) {
                return;
            }
            try {
                const response = await updateChatToUserMutation({
                    variables: {
                        chatToUserUpdate: {
                            chatId: chat.id,
                            userId: _userId,
                            isReadWhenViewedByCompany: isReadByCompany,
                        },
                    },
                });
                if (response.data?.updateChatToUser.chatId) {
                    alert.success('common.alerts.savedChanges');
                    return;
                }
                alertGraphQlError(response.errors);
                logUtil.warn('Could not update chat to user');
            } catch (error) {
                logUtil.warn('Could not update chat to user', error);
                alertError(error);
            }
            logUtil.log('toggle read by company');
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [updateChatToUserMutation],
    );

    const chatContext = animal?.name ?? animalGroup?.name ?? topic?.name ?? '';
    const customerToDisplay = getCustomerToDisplay(chat);
    const profileName =
        (customerToDisplay &&
            buildNameFromCustomer(customerToDisplay.customer)) ??
        buildNameFromUser(customerToDisplay?.user);
    const lastMessageDisplayTime = lastMessage?.createdAt
        ? DateUtil.getPrettyDateAndTimeOffsetText(lastMessage?.createdAt, t)
        : '';

    const animalCreatorUserId =
        chat.animal?.createdBy ?? chat.animalGroup?.createdBy ?? 0;

    const animalOwners = chat.animal?.owners ?? chat.animalGroup?.owners ?? [];
    const animalContextOwner = animalOwners.filter(
        x => x?.user?.id === animalCreatorUserId,
    )[0];

    let lastMessageText = lastMessage?.text ?? ''; // default text message
    let iconName: string | undefined; // possible icon name for special chat messages

    const isOwnMessage = userId === lastMessage?.author?.id;

    const hasImage =
        (
            lastMessage?.files?.filter(
                x => x?.fileType && x?.fileType.indexOf('image') > -1,
            ) ?? []
        )?.length > 0;
    const hasVideo =
        (
            lastMessage?.files?.filter(
                x => x?.fileType && x?.fileType.indexOf('video') > -1,
            ) ?? []
        )?.length > 0;

    const hasDocument =
        (
            lastMessage?.files?.filter(
                x => x?.fileType && x?.fileType.indexOf('application') > -1,
            ) ?? []
        )?.length > 0;

    if (hasImage) {
        iconName = 'camera';
    } else if (hasVideo) {
        iconName = 'videocam';
    } else if (hasDocument) {
        iconName = 'document';
    }

    if (lastMessage?.order?.appointment) {
        const appointmentType = lastMessage.order.appointment.type;
        if (appointmentType === AppointmentTypes.VideoChat) {
            iconName = 'videocam';
        }
        lastMessageText = t(
            `screens.chat.appointment.types.${appointmentType}`,
        );
    }

    if (!lastMessageText && iconName) {
        if (hasVideo) {
            lastMessageText = t('common.video');
        } else if (hasImage) {
            lastMessageText = t('common.image');
        } else if (hasDocument) {
            lastMessageText =
                lastMessage?.files?.length && lastMessage?.files[0].name
                    ? lastMessage?.files[0].name
                    : t('common.document');
        }
    }

    const canToggleIsReadyByCOmpany = chat.type === ChatTypes.Open;

    const isSystemMessageType = SystemChatMessageTypes.some(
        type => type === lastMessage?.type,
    );
    if (lastMessage && isSystemMessageType) {
        lastMessageText = getSystemMessageText({
            message: lastMessage,
            t,
            userId,
        });
    }

    let lastMessageTextHtml = `<span>${lastMessageText}</span>`;

    let messagePrefix = '';
    if (isOwnMessage) {
        messagePrefix = `${t('common.you')}`;
    } else if (
        animalCreatorUserId !== lastMessage?.author?.id &&
        !isSystemMessageType
    ) {
        messagePrefix = `${buildNameFromUser(lastMessage?.author) ?? ''}`;
    }
    messagePrefix += messagePrefix ? ':' : '';

    lastMessageTextHtml = `${
        messagePrefix
            ? `<span style='font-weight:500;padding-right:3px'>${messagePrefix}</span>`
            : ''
    }${lastMessageTextHtml}`;

    const hasAnimalContext = !!animal || !!animalGroup;

    const profileIcon = useMemo(() => {
        const imageFile =
            animal?.imageFile ??
            animalGroup?.imageFile ??
            topic?.imageFile ??
            ((chat.type === ChatTypes.Open && !hasAnimalContext) ||
            chat.type === ChatTypes.Personal
                ? customerToDisplay?.user?.imageFile
                : chat.type === ChatTypes.Internal
                ? chat.company?.imageFile
                : undefined);

        const profileInProfileImageFile = animalContextOwner?.user?.imageFile;

        return (
            <ProfileIcon
                fileDescription={imageFile}
                colorBundle={
                    animal?.colorBundle ??
                    animalGroup?.colorBundle ??
                    (chat.type === ChatTypes.Personal
                        ? customerToDisplay?.user?.colorBundle
                        : undefined) ??
                    DefaultColorBundle
                }
                profileInProfileFileDescription={profileInProfileImageFile}
                profileInProfileSize={18}
                style={{
                    background: `linear-gradient(${
                        animal?.colorBundle?.hexColor ??
                        animalGroup?.colorBundle?.hexColor ??
                        DefaultColorBundle.hexColor
                    }, ${
                        animal?.colorBundle?.hexColorGradient ??
                        animalGroup?.colorBundle?.hexColorGradient ??
                        DefaultColorBundle.hexColorGradient
                    }`,
                }}
            />
        );
    }, [
        hasAnimalContext,
        animal?.imageFile,
        animal?.colorBundle,
        animalGroup?.imageFile,
        animalGroup?.colorBundle,
        animalContextOwner?.user?.imageFile,
        customerToDisplay?.user?.imageFile,
        customerToDisplay?.user?.colorBundle,
        topic,
        chat?.company?.imageFile,
        chat?.type,
    ]);

    let chatName = profileName;
    if (chat?.type === ChatTypes.Internal) {
        chatName = t('screens.chat.internalGroup');
    } else if (
        !!company &&
        chat.company?.name &&
        company.id !== chat.company?.id
    ) {
        // this is a chat with a different company --> show the name
        chatName = chat.company?.name;
    }

    const memorizedChatEntry = useMemo(() => {
        logUtil.log('memorizedChatEntry changed');
        return (
            <li
                data-cy={`chats-entry-${chat.id}`}
                onClick={() => RxChat.setCurrentChatId(chat?.id ?? 0)}
                className={
                    active
                        ? `${styles.listItem} ${styles.active}`
                        : styles.listItem
                }>
                <div className={styles.profile}>{profileIcon}</div>
                <div className={styles.chatPreview}>
                    <p
                        className={styles.profileName}
                        data-cy={`chat-name-${chat.id}`}>
                        {chatName}
                    </p>
                    <p className={styles.chatContextName}>{chatContext}</p>
                    <div
                        className={styles.messagePreview}
                        style={{ position: 'relative' }}>
                        {isOwnMessage && lastMessage && (
                            <div className={styles.checkmark}>
                                <ChatMessageCheckmark message={lastMessage} />
                            </div>
                        )}
                        {iconName && <Icon size={16} name={iconName} />}
                        <span>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: lastMessageTextHtml,
                                }}
                                className={styles.textHtml}></span>
                        </span>
                    </div>
                </div>
                <div className={styles.chatInfo}>
                    {showOpenRequest ? (
                        <TrafficLightBadge
                            onCloseInquiry={() => {
                                // TODO: alert if the current user is not allowed to close inquiries
                                if (hasLicence) {
                                    closeInquiry(chat);
                                }
                            }}
                            messageDate={lastMessage?.createdAt}
                        />
                    ) : (
                        <span className={styles.lastMessageTime}>
                            {lastMessageDisplayTime}
                        </span>
                    )}
                    <div className={styles.counterBadgeContainer}>
                        <CounterBadge
                            className={`${styles.counterBadge} ${
                                hasLicence ? '' : styles.noLicence
                            }`.trim()}
                            count={unreadMessageCount ?? 0}
                        />
                        {hasLicence && (
                            <CtxMenu
                                offsets={{ right: 4, top: 24 }}
                                button={
                                    <Icon
                                        size={20}
                                        name="chevron-down-outline"
                                    />
                                }
                                buttonClassName={styles.contextMenu}>
                                <CtxMenuEntry
                                    text={t(
                                        'screens.chat.navigation.contextMenu.markAllAsViewed',
                                    )}
                                    onClick={() => {
                                        markChatAsRead(chat.id);
                                    }}
                                />
                                {canToggleIsReadyByCOmpany && (
                                    <CtxMenuEntry
                                        text={t(
                                            chat.chatToUser
                                                ?.isReadWhenViewedByCompany
                                                ? 'screens.chat.navigation.contextMenu.readBySelf'
                                                : 'screens.chat.navigation.contextMenu.readByCompany',
                                        )}
                                        onClick={() => {
                                            toggleReadByCompany(
                                                !chat.chatToUser
                                                    ?.isReadWhenViewedByCompany,
                                                userId,
                                            );
                                        }}
                                        tooltip={{
                                            id: `read-by-company-tooltip-${chat.id}`,
                                            iconName: 'help-circle-outline',
                                            tooltipClassName: styles.tooltip,
                                            content: t(
                                                'screens.chat.navigation.contextMenu.readByCompanyInfo',
                                            ),
                                        }}
                                    />
                                )}
                            </CtxMenu>
                        )}
                    </div>
                </div>
            </li>
        );
    }, [
        chat,
        active,
        chatContext,
        closeInquiry,
        hasLicence,
        iconName,
        isOwnMessage,
        lastMessage,
        lastMessageDisplayTime,
        lastMessageTextHtml,
        markChatAsRead,
        profileIcon,
        t,
        toggleReadByCompany,
        unreadMessageCount,
        userId,
        canToggleIsReadyByCOmpany,
        chatName,
        showOpenRequest,
    ]);

    return memorizedChatEntry;
};

export default ChatsEntry;

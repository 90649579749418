import { gql } from '@apollo/client';
import { Employee } from '../dto';
import { EmployeeFragments } from '../fragments';

export const GetEmployeesForCompany = gql`
    query GetEmployeesForCompany(
        $companyId: Int!
        $searchInput: String
        $pagingParams: PagingParams
        $sortParams: SortParams
    ) {
        getEmployeesForCompany(
            companyId: $companyId
            searchInput: $searchInput
            pagingParams: $pagingParams
            sortParams: $sortParams
        ) {
            ...FullEmployee
            invitations {
                userId
                employeeId
                canAccept
            }
        }
    }
    ${EmployeeFragments.FullEmployee}
`;

// TODO: move query to dashboard graphql

export interface GetEmployeesForCompanyData {
    getEmployeesForCompany: Employee[];
}

const GetEmployeesForCompanyQueries = {
    GetEmployeesForCompany,
};

export const EmployeeQueries = {
    GetEmployeesForCompanyQueries,
};

import React from 'react';
import { AlertTemplateProps } from 'react-alert';
import Icon from '../icon/icon';
import styles from './alertTemplate.module.scss';

const AlertTemplate: React.FC<AlertTemplateProps> = ({
    message,
    options,
    style,
    close,
}) => {
    let containerStyle = styles.container;
    let messageIconName = 'information-circle-outline';

    switch (options.type) {
        case 'error':
            containerStyle += ` ${styles.error}`;
            messageIconName = 'alert-circle-outline';
            break;

        case 'success':
            containerStyle += ` ${styles.success}`;
            messageIconName = 'checkmark-circle-outline';
            break;

        default:
            break;
    }
    return (
        <div style={style} className={containerStyle}>
            <Icon size={28} name={messageIconName} className={styles.icon} />
            <span className={styles.text}>{message}</span>
            <Icon
                onClick={close}
                size={28}
                name="close-outline"
                className={styles.close}
            />
        </div>
    );
};

export default AlertTemplate;

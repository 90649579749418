import { useMutation } from '@apollo/client';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import Button from '../../../../../../components/button/button';
import EmployeeItem from '../../../../../../components/employeeItem/employeeItem';
import {
    AppointmentJoinBufferInMinutes,
    PermissionTypes,
} from '../../../../../../constants';
import useModal from '../../../../../../hooks/useModal';
import { useObservable } from '../../../../../../hooks/useObservable';
import RxCompany from '../../../../../../rxjs/RxCompany';
import { hasPermission } from '../../../../../../utils/permissionUtils';
import { openVideoCall } from '../../../../../../__shared/electron';
import {
    Appointment,
    CancelOrderData,
    CancelOrderMutation,
    ChatMessage,
    CloseOrderData,
    CloseOrderMutation,
    MutationCancelOrderArgs,
    MutationCloseOrderArgs,
} from '../../../../../../__shared/graphql';
import styles from './chatMessageActions.module.scss';

export interface ChatMessageActionsProps {
    message: ChatMessage;
}

const joinablePeriodTimer = 1000 * 10;

const ChatMessageActions: React.FC<ChatMessageActionsProps> = ({ message }) => {
    const { t } = useTranslation();
    const company = useObservable(RxCompany.currentCompany$);
    const [appointment, setAppointment] = useState(message?.order?.appointment);
    const [order, setOrder] = useState(message?.order);
    const [isJoinablePeriod, setIsJoinablePeriod] = useState(false);
    const { show: showModal } = useModal();

    const [
        cancelOrder,
        {
            data: cancelOrderData,
            loading: cancelOrderLoading,
            error: cancelOrderError,
        },
    ] = useMutation<CancelOrderData, MutationCancelOrderArgs>(
        CancelOrderMutation.FullResponse,
    );

    const [
        closeOrder,
        {
            data: closeOrderData,
            loading: closeOrderLoading,
            error: closeOrderError,
        },
    ] = useMutation<CloseOrderData, MutationCloseOrderArgs>(
        CloseOrderMutation.FullResponse,
    );

    // handle cancel order error
    useEffect(() => {
        if (cancelOrderError) {
            console.warn('cancel order error', cancelOrderError);
        }
    }, [cancelOrderError]);

    // handle cancel order response
    useEffect(() => {
        if (cancelOrderData?.cancelOrder) {
            setOrder(cancelOrderData.cancelOrder);
        }
    }, [cancelOrderData]);

    // handle close order response
    useEffect(() => {
        if (closeOrderData?.closeOrder) {
            setOrder(closeOrderData.closeOrder);
        }
    }, [closeOrderData]);

    // handle close order error
    useEffect(() => {
        if (closeOrderError) {
            console.warn('close order error', closeOrderError);
        }
    }, [closeOrderError]);

    useEffect(() => {
        setAppointment(message?.order?.appointment);
        setOrder(message?.order);
    }, [message?.order]);

    const isOrderCancelled = !!order?.canceledAt;

    const calculateJoinablePeriod = useCallback(
        (givenAppointment: Appointment) => {
            if (givenAppointment?.from && givenAppointment?.to) {
                const from = givenAppointment.from as Date;
                const to = givenAppointment.to as Date;

                const joinableBegin = new Date(from);
                joinableBegin.setMinutes(
                    from.getMinutes() - AppointmentJoinBufferInMinutes,
                );
                const joinableEnd = new Date(to);
                joinableEnd.setMinutes(
                    to.getMinutes() + AppointmentJoinBufferInMinutes,
                );

                setIsJoinablePeriod(
                    // TODO setIsJoinablePeriod does not work!
                    true, //joinableBegin.getTime() < now && now < joinableEnd.getTime()
                );
            }
        },
        [setIsJoinablePeriod],
    );

    // calculate joinable period
    useEffect(() => {
        if (!appointment) {
            return;
        }
        //initial calculation
        calculateJoinablePeriod(appointment);

        const interval = setInterval(() => {
            calculateJoinablePeriod(appointment);
        }, joinablePeriodTimer);

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [calculateJoinablePeriod, appointment]);

    if (!appointment) {
        return null;
    }

    const canCancelOrder =
        !isOrderCancelled &&
        hasPermission(PermissionTypes.canCancel, order?.permissions);
    const canJoinAppointment =
        !isOrderCancelled &&
        hasPermission(PermissionTypes.canJoin, appointment?.permissions);

    const consultantEmployee = company?.employees?.find(
        employee =>
            employee.id ===
            (appointment.employee?.id ?? appointment.employeeId),
    );

    const isClosedOrder = !!order?.closedAt;

    const onCancel = async () => {
        // open Dialog
        const proceed = await showModal({
            title: t('screens.chat.appointment.cancelAppointmentDialog.title'),
            text: t('screens.chat.appointment.cancelAppointmentDialog.text'),
            okButtonText: t('common.buttons.yes'),
            cancelButtonText: t('common.buttons.no'),
            closeOnBackdropClick: true,
        });
        if (proceed) {
            cancelOrder({ variables: { orderId: order?.id ?? 0 } });
        }
    };

    const onJoin = () => {
        if (appointment?.wherebyMeeting?.hostRoomUrl) {
            openVideoCall(appointment.wherebyMeeting.hostRoomUrl);
        }
    };

    const onClose = () => {
        closeOrder({ variables: { orderId: order?.id ?? 0 } });
    };

    let joinButtonText = t('common.buttons.join');
    const isWaitingForPayment = order?.isAdvancePayment && !order?.paidAt;
    const isWaitingForConfirmation =
        !order?.isAdvancePayment && !order?.appointment?.confirmedAt;
    if (isWaitingForPayment) {
        joinButtonText = t('screens.chat.payment.waitingForPayment');
    }
    if (isWaitingForConfirmation) {
        joinButtonText = t('screens.chat.payment.waitingForConfirmation');
    }

    const isOrderCancelable =
        !isClosedOrder &&
        canCancelOrder &&
        !order?.paidAt &&
        !(!order?.isAdvancePayment && order?.appointment?.confirmedAt);

    const canCloseConsultation =
        !isClosedOrder &&
        canCancelOrder &&
        (order?.paidAt ||
            (!order?.isAdvancePayment && order?.appointment?.confirmedAt));

    return (
        <div className={styles.container}>
            {consultantEmployee && (
                <div className={styles.consultant}>
                    {t('screens.chat.appointment.consultant')}{' '}
                    <EmployeeItem
                        className={styles.employeeItem}
                        employee={consultantEmployee}
                    />
                </div>
            )}
            {!isClosedOrder && canJoinAppointment && (
                <>
                    {!isJoinablePeriod && (
                        <ReactTooltip
                            className={styles.tooltip}
                            id={`join-meeting-btn-${appointment.id}`}
                            effect="solid"
                            place="top"
                            getContent={() =>
                                t('screens.chat.appointment.tooltipJoinButton')
                            }
                        />
                    )}
                    <div
                        data-tip
                        data-for={`join-meeting-btn-${appointment.id}`}>
                        <Button
                            disabled={
                                isWaitingForPayment || isWaitingForConfirmation
                            }
                            onClick={onJoin}
                            text={joinButtonText}
                            dataCy={`join-meeting-btn-${message.id}`}
                        />
                    </div>
                </>
            )}
            {isOrderCancelable && (
                <Button
                    disabled={cancelOrderLoading}
                    onClick={onCancel}
                    text={t('common.buttons.cancelOrder')}
                    style="secondary"
                    dataCy={`cancel-order-btn-${message.id}`}
                />
            )}
            {canCloseConsultation && (
                <Button
                    disabled={closeOrderLoading}
                    onClick={onClose}
                    text={t('common.buttons.closeConsultation')}
                    style="secondary"
                    dataCy={`close-consultation-btn-${message.id}`}
                />
            )}
        </div>
    );
};

export default ChatMessageActions;

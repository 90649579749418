import React from 'react';
import { useTranslation } from 'react-i18next';
import ProfileIcon from '../../../../../components/profileIcon/profileIcon';
import { DefaultColorBundle } from '../../../../../constants';
import { ChatTypes } from '../../../../../__shared/common';
import { Animal, AnimalGroup, Chat } from '../../../../../__shared/graphql';
import styles from './secondaryChatHeader.module.scss';

const millisecondsToWeeks = 1000 * 60 * 60 * 24 * 7;

interface SecondaryChatHeaderProps {
    chat?: Chat;
    onClick: () => void;
}

const SecondaryChatHeader: React.FC<SecondaryChatHeaderProps> = ({
    chat,
    onClick,
}) => {
    const isInternal = chat?.type === ChatTypes.Internal;

    const { t } = useTranslation();

    const animal: Animal | null | undefined = chat?.animal;
    const animalGroup: AnimalGroup | null | undefined = chat?.animalGroup;
    const topic = chat?.topic;

    const hasAnimalContext = animal || animalGroup;

    if (!animal && !animalGroup && !topic) {
        return null;
    }

    if (chat?.type === ChatTypes.Internal) {
        // internal chat types are rendered with a primary topic header
        return null;
    }

    const now = new Date();

    const birthday = animal?.birthday ?? animalGroup?.averageBirthday;
    const animalType = animal?.type ?? animalGroup?.type;
    const breed = animal?.breed ?? animalGroup?.breed;
    const name =
        animal?.name ??
        animalGroup?.name ??
        topic?.name ??
        'Unbenanntes Tierprofil';

    const ageInWeeks = birthday
        ? Math.round((now.getTime() - birthday.getTime()) / millisecondsToWeeks)
        : 0;

    let ageText: string;
    let age: number;
    switch (true) {
        case ageInWeeks > 26 && ageInWeeks < 53:
            // TODO: calc in months via date function
            age = Math.round(ageInWeeks / 4);
            ageText = t(
                age === 1 ? 'screens.chat.monthOld' : 'screens.chat.monthsOld',
                {
                    age,
                },
            );
            break;

        // case ageInWeeks > 52:
        // TODO: calc in years via date function

        default:
            age = Math.round(ageInWeeks / 52);
            ageText = t(
                age === 1 ? 'screens.chat.yearOld' : 'screens.chat.yearsOld',
                {
                    age,
                },
            );
            break;
    }

    let infoText = animalType ? t(`common.animalTypes.${animalType}`) : '';
    if (topic) {
        infoText = t('screens.chat.requestWithoutAnimal');
    } else {
        if (breed) {
            infoText += `, ${breed}`;
        }
        infoText += `, ${ageText}`;
    }

    const imageFile = animal?.imageFile ?? animalGroup?.imageFile;
    return (
        <div
            className={`${styles.container} ${
                isInternal || !hasAnimalContext ? styles.hidden : ''
            }`.trim()}>
            <div
                className={styles.animalInfo}
                onClick={event => {
                    event.stopPropagation();
                    event.preventDefault();
                    onClick();
                }}>
                <ProfileIcon
                    fileDescription={imageFile}
                    colorBundle={
                        animal?.colorBundle ?? animalGroup?.colorBundle
                    }
                    style={{
                        background: `linear-gradient(${
                            animal?.colorBundle?.hexColor ??
                            animalGroup?.colorBundle?.hexColor ??
                            DefaultColorBundle.hexColor
                        }, ${
                            animal?.colorBundle?.hexColorGradient ??
                            animalGroup?.colorBundle?.hexColorGradient ??
                            DefaultColorBundle.hexColorGradient
                        }`,
                    }}
                />
                <div className={styles.animalDetail}>
                    <span className={styles.name}>{name}</span>
                    <span className={styles.details}>{infoText}</span>
                </div>
            </div>
            <div />
        </div>
    );
};

export default SecondaryChatHeader;

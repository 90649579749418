import React from 'react';
import ReactTooltip from 'react-tooltip';
import { CypressProp } from '../../types/cypressProps';
import Icon from '../icon/icon';
import { ContextMenuCloseEvent } from './ctxMenu';
import styles from './ctxMenuEntry.module.scss';

interface CtxMenuEntryProps extends CypressProp {
    text: string;
    onClick: () => void;
    icon?: {
        iconName: string;
        onClick?: () => void;
        className?: string;
        size?: number;
    };
    tooltip?: {
        id: string;
        content: string;
        iconName: string;
        iconClassName?: string;
        tooltipClassName?: string;
    };
}

const dispatchCloseContextMenuEvent = () =>
    window.dispatchEvent(new CustomEvent(ContextMenuCloseEvent));

const CtxMenuEntry: React.FC<CtxMenuEntryProps> = ({
    text,
    onClick,
    icon,
    tooltip,
    dataCy,
}) => {
    const handleClick = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        e.stopPropagation();
        onClick();
        dispatchCloseContextMenuEvent();
    };

    const handleIconClick = (e: React.ChangeEvent<MouseEvent>) => {
        e.stopPropagation();
        if (typeof icon?.onClick === 'function') {
            icon.onClick();
        } else {
            onClick();
        }
        dispatchCloseContextMenuEvent();
    };

    return (
        <li className={styles.container} onClick={handleClick} data-cy={dataCy}>
            {!!icon && (
                <Icon
                    onClick={handleIconClick}
                    className={`${styles.icon} ${icon.className ?? ''}`.trim()}
                    name={icon.iconName}
                    size={icon.size}
                />
            )}
            <span>{text}</span>
            {!!tooltip && (
                <>
                    <div
                        className={`${styles.tooltipIcon} ${
                            tooltip.iconClassName ?? ''
                        }`.trim()}
                        data-tip
                        data-for={tooltip.id}>
                        <Icon name="help-circle-outline" />
                    </div>
                    <ReactTooltip
                        className={`${styles.tooltip} ${
                            tooltip.tooltipClassName ?? ''
                        }`.trim()}
                        id={tooltip.id}
                        effect="solid"
                        place="bottom"
                        getContent={() => tooltip.content}
                    />
                </>
            )}
        </li>
    );
};

export default CtxMenuEntry;

import { Chat } from '../../__shared/graphql';
import { gql } from '@apollo/client';
import { ChatFragments } from '../fragments/chatFragments';

export const OnChatUpdateForUser = gql`
    subscription OnChatUpdateForUser($userId: Int!) {
        onChatUpdateForUser(userId: $userId) {
            ...ChatForChatListEntryFragment
        }
    }
    ${ChatFragments.ChatForChatListEntryFragment}
`;

export const ChatSubscriptions = {
    OnChatUpdateForUser,
};

export interface OnChatUpdateForUserData {
    onChatUpdateForUser: Chat;
}

import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { FadeLoader } from 'react-spinners';
import logo from '../../assets/images/AnimalchatLogoWhite.png';
import Button from '../../components/button/button';
import Input from '../../components/input/input';
import { UserQueries } from '../../graphql/queries/userQueries';
import { useAlert } from '../../hooks/useAlert';
import { useErrorAlert } from '../../hooks/useErrorAlert';
import { useObservable } from '../../hooks/useObservable';
import RxAuthentication from '../../rxjs/RxAuthentication';
import RxCompany from '../../rxjs/RxCompany';
import RxUserProfile from '../../rxjs/RxUserProfile';
import authenticationUtil from '../../utils/authenticationUtil';
import logUtil from '../../utils/logUtil';
import AnimalChatRoutes from '../../utils/routing';
import { LogClient } from '../../__shared/common/logging/logClient';
import { TestLoggerMessages } from '../../__shared/common/logging/testConstants';
import { isElectron } from '../../__shared/electron';
import {
    GetCompaniesForUserData,
    GetCompaniesForUserQuery,
    GetUserData,
    LoginWithEmailOrUsernameData,
    MutationLoginWithEmailOrUsernameArgs,
    QueryGetUserArgs,
    UserMutations,
} from '../../__shared/graphql';
import styles from './loginScreen.module.scss';

interface Credentials {
    emailOrUsername: string;
    password: string;
}

const LoginScreen: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const alert = useAlert();
    const { alertError } = useErrorAlert();
    const userId = useObservable(RxAuthentication.userId$, 0);

    // logout on navigation to login route
    useEffect(() => {
        authenticationUtil.logout(true);
    }, []);

    const [
        loginWithEmailOrUsernameMutation,
        { loading: loginLoading, error: loginError, reset: loginReset },
    ] = useMutation<
        LoginWithEmailOrUsernameData,
        MutationLoginWithEmailOrUsernameArgs
    >(UserMutations.LoginWithEmailOrUsername);

    const [credentials, setCredentials] = useState<Credentials>({
        emailOrUsername: process?.env?.REACT_APP_DEFAULT_USER ?? '',
        password: process?.env?.REACT_APP_DEFAULT_PASS ?? '',
    });

    const updateField = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        field: keyof Credentials,
    ) => {
        setCredentials({ ...credentials, [field]: event.target.value });
    };

    const [getUser, { data: getUserData }] = useLazyQuery<
        GetUserData,
        QueryGetUserArgs
    >(UserQueries.GetUser.Full);

    const [
        getCompaniesForUser,
        { data: getCompaniesForUserData, loading: getCompaniesForUserLoading },
    ] = useLazyQuery<GetCompaniesForUserData>(
        GetCompaniesForUserQuery.FullResponse,
    );

    useEffect(() => {
        logUtil.log('getUser');
        if (userId) {
            getUser({
                variables: {
                    userId,
                },
            });
        }
    }, [userId, getUser]);

    useEffect(() => {
        logUtil.log('getUserData');
        if (getUserData?.getUser) {
            RxUserProfile.setUser(getUserData?.getUser);
            getCompaniesForUser();
        }
    }, [getUserData, getCompaniesForUser]);

    useEffect(() => {
        logUtil.log('getCompaniesForUser');
        const companies = getCompaniesForUserData?.getCompaniesForUser;
        if (companies && !getCompaniesForUserLoading) {
            RxCompany.setCompanies(companies);
            if (companies?.length) {
                RxCompany.setCurrentCompany(companies[0]);
                navigate(AnimalChatRoutes.app);
            } else {
                RxCompany.setCurrentCompany(null);
                alert.error(t('screens.login.noCompany'));
            }
        }
        // Note: Do not put alert into deps, causes effect on text inputs
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        getCompaniesForUserLoading,
        getCompaniesForUserData?.getCompaniesForUser,
        navigate,
        loginReset,
        t,
    ]);

    const onLogin = useCallback(
        async (emailOrUsername: string, pass: string) => {
            try {
                const loginResponse = await loginWithEmailOrUsernameMutation({
                    variables: {
                        login: {
                            emailOrUsername,
                            password: pass,
                        },
                    },
                });
                if (loginResponse.errors) {
                    console.warn(loginResponse);
                }
                const token = loginResponse.data?.loginWithEmailOrUsername;
                if (token) {
                    LogClient.test(TestLoggerMessages.LoginWithEmailOrUsername);
                    authenticationUtil.storeAccessToken(token);
                    RxAuthentication.setAccessToken(token);
                }
            } catch (e) {
                console.warn('error', e);
            } finally {
                loginReset();
            }
        },
        [loginWithEmailOrUsernameMutation, loginReset],
    );

    useEffect(() => {
        if (!loginLoading && loginError) {
            loginReset();
            logUtil.warn('Error during user login', loginError);
            alertError(loginError);
        }
    }, [loginLoading, loginError, loginReset, alertError]);

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <img src={logo} alt="" />
            </div>
            <form className={styles.form}>
                {loginLoading && (
                    <div
                        className={`${styles.formOverlay} ${styles.loadingSpinner}`}>
                        <FadeLoader />
                    </div>
                )}
                <div className={styles.input}>
                    <label htmlFor="username">{t('common.email')}</label>
                    <Input
                        type="text"
                        id="username"
                        dataCy="username-input"
                        value={credentials.emailOrUsername}
                        onChange={e => updateField(e, 'emailOrUsername')}
                    />
                </div>
                <div className={styles.input}>
                    <label htmlFor="password">{t('common.password')}</label>
                    <Input
                        type="password"
                        id="password"
                        dataCy="password-input"
                        value={credentials.password}
                        onChange={e => updateField(e, 'password')}
                    />
                </div>
                <div className={styles.buttons}>
                    <Button
                        dataCy="login-btn"
                        disabled={loginLoading}
                        text={t('common.buttons.login')}
                        onClick={e => {
                            e.preventDefault();
                            onLogin(
                                credentials.emailOrUsername,
                                credentials.password,
                            );
                        }}
                    />
                </div>
                <div className={styles.passwordActions}>
                    <Link to={AnimalChatRoutes.forgotPassword}>
                        {t('screens.login.forgotPassword')}
                    </Link>
                </div>

                {
                    <div className={styles.buttons}>
                        <Button
                            dataCy="register-btn"
                            text={t('common.buttons.register')}
                            onClick={() => {
                                if (isElectron()) {
                                    window.open(
                                        AnimalChatRoutes.businessSubs
                                            .firstSteps,
                                        '_blank',
                                    );
                                } else {
                                    navigate(
                                        AnimalChatRoutes.businessSubs
                                            .firstSteps,
                                    );
                                }
                            }}
                        />
                    </div>
                }
            </form>
        </div>
    );
};

export default LoginScreen;

import { BehaviorSubject } from 'rxjs';
import { RegisterFormData } from '../pages/business/views/firstSteps/createAccount';

const createAccountFormSubject = new BehaviorSubject<
    RegisterFormData | undefined
>(undefined);
const createAccountForm$ = createAccountFormSubject.asObservable();

const setCreateAccountForm = (form: RegisterFormData | undefined): void => {
    createAccountFormSubject.next(form);
};

const emailForVerificationSubject = new BehaviorSubject<string>('');
const emailForVerification$ = emailForVerificationSubject.asObservable();

const setEmailForVerification = (email: string): void => {
    emailForVerificationSubject.next(email);
};

const phoneNumberForVerificationSubject = new BehaviorSubject<string>('');
const phoneNumberForVerification$ = phoneNumberForVerificationSubject.asObservable();

const setPhoneNumberForVerification = (phoneNumber: string): void => {
    phoneNumberForVerificationSubject.next(phoneNumber);
};

export const RxCreateAccount = {
    createAccountForm$,
    setCreateAccountForm,
    emailForVerification$,
    setEmailForVerification,
    phoneNumberForVerification$,
    setPhoneNumberForVerification,
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/button/button';
import { reloadBrowserWindow } from '../../../__shared/electron';
import styles from './missingLicence.module.scss';
import ChatNavigation from './navigation/chatNavigation';

const MissingLicence: React.FC = () => {
    const { t } = useTranslation();

    return (
        <div className={styles.container}>
            {/* <div className={styles.navigation}> */}
            {/* eslint-disable-next-line @typescript-eslint/no-empty-function */}
            {/* <CompanyHeader onShowChatCreation={() => {}} />
            </div> */}
            <ChatNavigation />
            <div className={styles.main}>
                <div className={styles.header} />
                <div className={styles.content}>
                    <h3>{t('screens.missingLicence.title')}</h3>
                    <p>{t('screens.missingLicence.text')}</p>
                    <Button
                        className={styles.button}
                        text={t('common.buttons.refresh')}
                        onClick={() => {
                            reloadBrowserWindow();
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default MissingLicence;

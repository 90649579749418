import * as Environment from '../environment/env';
import { DateUtil } from '../__shared/common';

// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
const log = (message?: any, ...optionalParams: any[]): void => {
    if (Environment.IsConsoleEnabled) {
        console.log(message, ...optionalParams);
    }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
const info = (message?: any, ...optionalParams: any[]): void => {
    console.log(`${DateUtil.getTimeStamp()}:\t`, message, ...optionalParams);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
const warn = (message?: any, ...optionalParams: any[]): void => {
    console.warn(`${DateUtil.getTimeStamp()}:\t`, message, ...optionalParams);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
const error = (message?: any, ...optionalParams: any[]): void => {
    console.error(`${DateUtil.getTimeStamp()}:\t`, message, ...optionalParams);
};

export default {
    log,
    info,
    warn,
    error,
};

import { gql } from '@apollo/client';
import { AnimalContext } from '../dto';
import { AnimalFragments } from '../fragments';
import { AnimalGroupFragments } from '../fragments';

const FullResponse = gql`
    query FindAnimalContextsForUser(
        $userId: Int!
        $searchInput: String!
        $sortOrder: String
        $pageSize: Int
        $idCursor: Int
    ) {
        findAnimalContextsForUser(
            userId: $userId
            searchInput: $searchInput
            sortOrder: $sortOrder
            pageSize: $pageSize
            idCursor: $idCursor
        ) {
            id
            animal {
                ...FullAnimal
            }
            animalGroup {
                ...FullAnimalGroup
            }
            createdAt
        }
    }
    ${AnimalFragments.FullAnimal}
    ${AnimalGroupFragments.FullAnimalGroup}
`;

export interface FindAnimalContextsForUserData {
    findAnimalContextsForUser: AnimalContext[];
}

export const FindAnimalContextsForUserQuery = {
    FullResponse,
};

import { gql } from '@apollo/client';
import { ChatToUser } from '../../__shared/graphql';

const UpdateChatToUser = gql`
    mutation UpdateChatToUser($chatToUserUpdate: ChatToUserUpdate!) {
        updateChatToUser(chatToUserUpdate: $chatToUserUpdate) {
            userId
            chatId
            createdAt
            createdBy
            adminSince
            adminBy
            isReadWhenViewedByCompany
            user {
                id
            }
            chat {
                id
            }
        }
    }
`;

export const ChatToUserMutations = {
    UpdateChatToUser,
};

export interface UpdateChatToUserData {
    updateChatToUser: ChatToUser;
}

import { gql } from '@apollo/client';

const FullWherebyMeeting = gql`
    fragment FullWherebyMeeting on WherebyMeeting {
        meetingId
        startDate
        endDate
        roomUrl
        hostRoomUrl
    }
`;

export const WherebyMeetingFragments = { FullWherebyMeeting };

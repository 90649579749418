import { Chat, ChatMessage } from '../../../../__shared/graphql';
import { t } from 'i18next';
import React from 'react';
import PreviewItemMedia from '../../../../components/previewItemMedia/previewItemMedia';
import Icon from '../../../../components/icon/icon';
import ProfileIcon from '../../../../components/profileIcon/profileIcon';
import { DefaultColorBundle } from '../../../../constants';
import styles from './companyProfile.module.scss';
import { publishShowPreviewMessages } from '../../../../rxjs/onShowPreviewMessages';

interface CompanyProfileProps {
    chat: Chat | undefined;
    chatMessagesWithFiles: ChatMessage[];
    onShowMedia: () => void;
}

const CompanyProfile: React.FC<CompanyProfileProps> = ({
    chat,
    chatMessagesWithFiles,
    onShowMedia,
}) => {
    if (!chat) {
        return null;
    }

    const topic = chat.topic;

    return (
        <div className={styles.container}>
            <div className={styles.profilePicture}>
                <div className={styles.profileIcon}>
                    <ProfileIcon
                        size={180}
                        fileDescription={topic?.imageFile}
                        colorBundle={DefaultColorBundle}
                    />
                </div>
                <div className={styles.profileName}>
                    <p>{topic?.name}</p>
                </div>
            </div>
            <div className={styles.media}>
                <div className={styles.title} onClick={onShowMedia}>
                    <span>{t('screens.chat.detail.customer.media')}</span>
                    <Icon size={16} name="chevron-forward-outline" />
                </div>
                <div className={styles.preview}>
                    {chatMessagesWithFiles.slice(0, 3).map(message => {
                        return (
                            <PreviewItemMedia
                                className={styles.previewItem}
                                key={message.id}
                                message={message}
                                onClick={() =>
                                    publishShowPreviewMessages(
                                        chatMessagesWithFiles,
                                        message,
                                    )
                                }
                            />
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default CompanyProfile;

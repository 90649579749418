import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useObservable } from '../../hooks/useObservable';
import RxUpdateBanner, {
    initialUpdateBannerProps,
} from '../../rxjs/RxUpdateBanner';
import { isElectron, restartApplication } from '../../__shared/electron';
import Icon from '../icon/icon';
import styles from './updateBanner.module.scss';

const _isElectron = isElectron();
const restartText = _isElectron
    ? 'common.restartApplication'
    : 'common.reloadApplication';

const UpdateBanner: React.FC = () => {
    const [show, setShow] = useState(false);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const { visible, translationKey, level } = useObservable(
        RxUpdateBanner.updateBannerProps$,
        initialUpdateBannerProps,
    );

    const { t } = useTranslation();

    const handleClose = () => RxUpdateBanner.hideUpdateBanner();
    const handleRestart = () =>
        _isElectron ? restartApplication() : window.location.reload();

    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.style.top = `-${containerRef.current.clientHeight}px`;
        }
    }, []);

    useEffect(() => {
        if (visible) {
            setShow(true);
            // need this timeout because immediately after setShow(true) the containerRef will still be null
            setTimeout(() => {
                if (containerRef.current) {
                    containerRef.current.style.top = '0px';
                }
            }, 100);
        } else {
            if (containerRef.current) {
                containerRef.current.style.top = `-${containerRef.current.clientHeight}px`;
            }
            setTimeout(() => {
                setShow(false);
            }, 400);
        }
    }, [visible]);

    if (!show) {
        return null;
    }

    return (
        <div
            ref={containerRef}
            className={`${styles.container} ${
                level === 'error' ? styles.error : ''
            }`.trim()}>
            <div className={styles.textContainer}>
                <div>
                    <p>{t(translationKey ?? '')}</p>
                    <span
                        className={`${styles.restart} ${
                            level === 'error' ? styles.error : ''
                        }`.trim()}
                        onClick={handleRestart}>
                        {t(restartText)}
                    </span>
                </div>
            </div>
            <div
                className={`${styles.closeBtn} ${
                    level === 'error' ? styles.error : ''
                }`.trim()}
                onClick={handleClose}>
                <Icon name="close" />
            </div>
        </div>
    );
};

export default UpdateBanner;
